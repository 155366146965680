import { QueryKey, useMutation, UseMutationOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'

import { SUBSCRIPTION_ENDPOINT_KEY } from './constants'

export const cancelSubscriptionSelf = (): Promise<null> => api.post(`/subscription/cancel`)

export const useCancelSubscriptionSelf = ({
  queryOptions,
  customQueryKey,
}: {
  queryOptions?: UseMutationOptions<Awaited<ReturnType<typeof cancelSubscriptionSelf>>, ApiError>
  customQueryKey?: QueryKey
} = {}) => useMutation(customQueryKey || [SUBSCRIPTION_ENDPOINT_KEY], cancelSubscriptionSelf, queryOptions)
