import React, { ReactNode } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RootState } from 'store'
import GdsSelectContextProvider from 'components/GdsSelect/GdsSelectContextProvider'
import HotelSelectContextProvider from 'components/HotelSelect/HotelSelectContextProvider'
import SidebarWrapperContextProvider from 'components/SidebarWrapper/SidebarWrapperContextProvider'
import { MainLayout, DefaultLayout, AdminLayout, PreviewLayout } from 'layouts'
import Error404 from 'layouts/components/Error404'
import routes from './moduleRoutes'
import { DashboardRangeDatePickerProvider } from '../context/DashboardRangeDatePickerContext'
import { UserRoles } from '../modules/UsersModule/types'

const Routes: React.FC = () => {
  const { isAuthenticated, profile } = useSelector((state: RootState) => state.auth)
  const layouts: { [key: string]: React.FC<{ children: ReactNode }> } = {
    main: MainLayout,
    default: DefaultLayout,
    admin: AdminLayout,
    preview: PreviewLayout,
  }

  return (
    <GdsSelectContextProvider>
      <HotelSelectContextProvider>
        <DashboardRangeDatePickerProvider>
          <SidebarWrapperContextProvider>
            <Router>
              <Switch>
                <Route exact path="/">
                  <Redirect
                    to={
                      isAuthenticated
                        ? profile?.is_admin
                          ? '/admin'
                          : profile?.role === UserRoles.TravelAgent
                          ? '/search/hotel'
                          : '/dashboard'
                        : '/login'
                    }
                  />
                </Route>
                <Route exact path="/admin">
                  <Redirect to="/admin/users" />
                </Route>
                {routes.map(({ component: Component, path, layout, propsForRoute }) => {
                  const Layout = Object.keys(layouts).includes(layout || '')
                    ? layouts[layout as string]
                    : layouts['default']
                  return (
                    <Route {...propsForRoute} path={path} key={path}>
                      <Layout>
                        <Component />
                      </Layout>
                    </Route>
                  )
                })}
                <Route path="*">
                  <Error404 />
                </Route>
              </Switch>
            </Router>
          </SidebarWrapperContextProvider>
        </DashboardRangeDatePickerProvider>
      </HotelSelectContextProvider>
    </GdsSelectContextProvider>
  )
}

export default Routes
