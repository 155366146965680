import { useMutation, UseMutationOptions } from 'react-query'
import api from '../index'
import { ApiError } from '../types/ApiError'

type LoginResponse = { data: { token: string; hotel: { id: number; name: string } } }

type LoginRequestParams = {
  hash?: string
}

const login = (params: LoginRequestParams): Promise<LoginResponse> =>
  api.post(`/login/${params.hash}`, { device_name: 'frontend_app' })

export const useLoginWithToken = (
  queryOptions?: UseMutationOptions<Awaited<ReturnType<typeof login>>, ApiError, LoginRequestParams>,
) => useMutation('useLoginWithToken', (params) => login(params), queryOptions)
