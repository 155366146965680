import { QueryKey, useQuery, UseQueryOptions } from 'react-query'
import qs from 'qs'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { HotelCandidate } from 'types/domain/HotelCandidate'

import { HOTEL_CANDIDATE_ENDPOINT_KEY } from './constants'

type GetHotelCandidateNextResourceRequestParams = {
  hotel_candidate: number
}

type GetHotelCandidateNextResourceRequestQuery = {
  direction: 'asc' | 'desc'
  similarity_from?: number
  similarity_to?: number
}

export const getHotelCandidateNextResource = ({
  params,
  query,
}: {
  params: GetHotelCandidateNextResourceRequestParams
  query: GetHotelCandidateNextResourceRequestQuery
}): Promise<{ data: HotelCandidate }> =>
  api.get(`/hotel-candidate/${params.hotel_candidate}/next?${qs.stringify(query, { skipNulls: true })}`)

export const useGetHotelCandidateNextResource = ({
  params,
  query,
  queryOptions,
  customQueryKey,
}: {
  params: GetHotelCandidateNextResourceRequestParams
  query: GetHotelCandidateNextResourceRequestQuery
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getHotelCandidateNextResource>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || [HOTEL_CANDIDATE_ENDPOINT_KEY, ...Object.values(params || {}), ...Object.values(query || {})],
    () => getHotelCandidateNextResource({ params, query }),
    queryOptions,
  )
