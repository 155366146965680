import { useMutation, UseMutationOptions } from 'react-query'
import api from '../index'
import { ApiError } from '../types/ApiError'

type LoginResponse = { data: { token: string } }

type LoginRequestBody = {
  email: string
  password: string
}

const login = (body: LoginRequestBody): Promise<LoginResponse> =>
  api.post('/login', { ...body, device_name: 'frontend_app' })

export const useLogin = (
  queryOptions?: UseMutationOptions<Awaited<ReturnType<typeof login>>, ApiError, LoginRequestBody>,
) => useMutation('useLogin', (body) => login(body), queryOptions)
