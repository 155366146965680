import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { Close } from '@material-ui/icons'

import AlertBar from 'ui/AlertBar'
import CardProviderIcon from 'ui/PaymentMethodProviderIcon'
import PageLoader from 'ui/PageLoader'
import { useGetSubscriptionResource } from 'api/subscription/getSubscriptionResource'
import { useGetSubscriptionUserResource } from 'api/subscription/getSubscriptionUserResource'
import ContactUsModal from 'layouts/components/ContactUsModal'
import { notification } from 'helpers'
import SubscriptionDetailsTiles from 'components/SubscriptionDetailsTiles'
import PaymentHistoryList from './components/PaymentHistoryList'
import ConfirmCancelSubscriptionModal from './components/ConfirmCancelSubscriptionModal'
import useEditSubscription from './useEditSubscription'
import useEditTrial from './useEditTrial'
import { Button } from 'ui-v2'

import styles from './SubscriptionAndBillingTab.module.scss'

type Props = {
  isTravelAgency: boolean
  openPaymentSettingModal: () => void
}

const SubscriptionAndBillingTab = (props: Props) => {
  const { isTravelAgency, openPaymentSettingModal } = props

  const { userId } = useParams<{ userId: string | undefined }>()
  const isViewedByAdmin = !!userId
  const [isOpenContactModal, setIsOpenContactModal] = useState(false)
  const [isOpenConfirmationCancelModal, setIsOpenConfirmationCancelModal] = useState(false)
  const [isTrialAlertBarVisible, setIsTrialAlertBarVisible] = useState<boolean>(false)

  const subscriptionResource = useGetSubscriptionResource({ queryOptions: { enabled: !isViewedByAdmin } })
  const subscriptionUserResource = useGetSubscriptionUserResource({
    params: { user_id: userId ? parseInt(userId) : -1 },
    queryOptions: { enabled: isViewedByAdmin },
  })

  const subscriptionData = subscriptionUserResource.data?.data || subscriptionResource.data?.data
  const paymentMethod = subscriptionData?.payment_method
  const subscription = subscriptionData?.subscription
  const paymentsHistory = subscriptionData?.payments_history
  const trialEndsAtDate = useMemo(
    () => (subscriptionData?.trial_ends_at ? moment(subscriptionData?.trial_ends_at) : null),
    [subscriptionData?.trial_ends_at],
  )
  const daysToEndTrial = moment(trialEndsAtDate).diff(moment(), 'days')

  const editSubscription = useEditSubscription({
    subscription,
    userId: userId ? parseInt(userId) : undefined,
    isViewedByAdmin,
  })

  const editTrial = useEditTrial({
    userId: userId ? parseInt(userId) : undefined,
    trialEndsAtDate,
    isViewedByAdmin,
    onSuccessEdit: subscriptionUserResource.refetch,
  })

  const onSuccessCancelSubscription = () => {
    setIsOpenConfirmationCancelModal(false)
    notification.success({ message: 'Subscription has been canceled' })
    subscriptionResource.refetch()
  }

  useEffect(() => {
    if (!isViewedByAdmin && daysToEndTrial >= 0) {
      setIsTrialAlertBarVisible(true)
    }
  }, [daysToEndTrial, isViewedByAdmin])

  if (subscriptionResource.isLoading || subscriptionUserResource.isLoading) {
    return <PageLoader />
  }

  return (
    <div>
      {subscriptionUserResource.isFetching && (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-50 opacity-75">
          <span className="absolute top-36 left-1/2 w-12 h-12 inline-flex items-center justify-center -mt-6 -ml-6">
            <svg
              aria-hidden="true"
              className="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-800"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </span>
        </div>
      )}

      <div className="flex flex-col gap-2 md:gap-4">
        {isTrialAlertBarVisible && (
          <AlertBar color="warning">
            <span>
              Your trial ends in <b> {moment.duration(daysToEndTrial || 1, 'day').humanize()}</b>, at{' '}
              <b>{moment(trialEndsAtDate).format('MMMM DD, YYYY')}</b>
            </span>
            <Close onClick={() => setIsTrialAlertBarVisible(false)} className={styles.closeTrialBarBtn} />
          </AlertBar>
        )}

        <div className="flex flex-col items-start gap-2 md:gap-4 lg:flex-row">
          <div className="w-full lg:w-1/2 p-2 bg-bg-light rounded-md flex flex-col gap-2 md:gap-4 md:p-6">
            <SubscriptionDetailsTiles
              subscription={subscription}
              trialEndsAtDate={trialEndsAtDate}
              editTrial={editTrial}
              editSubscription={editSubscription}
              isTravelAgency={isTravelAgency}
              isViewedByAdmin={isViewedByAdmin}
            />

            {!isViewedByAdmin && subscription && (
              <Button
                variant="secondary"
                onClick={() => setIsOpenConfirmationCancelModal(true)}
                isDisabled={!!subscription.ends_at}
                className="self-start"
              >
                Cancel subscription
              </Button>
            )}

            {(!subscription || !!subscription?.ends_at) && !isViewedByAdmin && (
              <div>
                To activate your account, please
                <button
                  className="bg-transparent border-none p-2 text-blue-800"
                  onClick={() => setIsOpenContactModal(true)}
                >
                  contact our Support
                </button>
                .
              </div>
            )}
          </div>

          <div className="w-full lg:w-1/2 p-2 bg-bg-light rounded-md flex flex-col gap-2 md:gap-4 md:p-6">
            <div className="text-lg leading-8 tracking-wider uppercase font-semibold text-gray-800">Payment method</div>
            {subscriptionData?.subscription?.active ? (
              <>
                <div className="flex items-center justify-start text-base text-gray-700 flex-wrap">
                  {paymentMethod?.card_last_four && paymentMethod.card_brand ? (
                    <>
                      <CardProviderIcon cardProviderName={paymentMethod.card_brand} className="mr-2" />
                      <span className="mr-10">Payment method:</span>
                      <span className="inline-flex h-10 px-4 bg-white items-center text-gray-500">
                        **** *** ***** *** {paymentMethod.card_last_four}
                      </span>
                    </>
                  ) : (
                    '-'
                  )}
                </div>

                <Button onClick={openPaymentSettingModal} className="self-start" variant="secondary">
                  {paymentMethod ? 'Payment Setting' : 'Add payment method'}
                </Button>
              </>
            ) : (
              '-'
            )}
          </div>
        </div>

        <PaymentHistoryList paymentsHistory={paymentsHistory || []} />
      </div>

      {isOpenConfirmationCancelModal && subscription && (
        <ConfirmCancelSubscriptionModal
          onCancel={() => setIsOpenConfirmationCancelModal(false)}
          onSuccess={onSuccessCancelSubscription}
          subscription={subscription}
          userId={userId ? parseInt(userId) : undefined}
        />
      )}

      {isOpenContactModal && <ContactUsModal close={() => setIsOpenContactModal(false)} />}
    </div>
  )
}

export default SubscriptionAndBillingTab
