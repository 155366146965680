import React, { useEffect } from 'react'
import { FormikHelpers, FormikProvider, useFormik } from 'formik'
import { twMerge } from 'tailwind-merge'
import { ArrowRightIcon, TrashIcon } from '@heroicons/react/16/solid'
import { PencilIcon } from '@heroicons/react/24/solid'

import { Billing } from 'types/domain/Billing'
import { BillingPaymentFormType } from 'types/PaymentForm'
import { BillingContactFormFields } from 'components/PaymentForm'
import { Button } from 'ui-v2'
import { EditForm } from '../types'

type Props = {
  handleSubmit: (data: BillingPaymentFormType, formikHelpers: FormikHelpers<BillingPaymentFormType>) => void
  data?: Billing
  isEdit: EditForm | null
  setEdit: (edit: EditForm | null) => void
}

const BillingContact: React.FC<Props> = ({ data, handleSubmit, isEdit, setEdit }) => {
  const editable = isEdit === EditForm.BILLING_CONTACT

  const formik = useFormik<BillingPaymentFormType>({
    initialValues: data
      ? {
          ...data,
          country: { id: data.country },
          stateUs: { id: data.country === 'US' ? data?.state || '' : '' },
          state: data.country !== 'US' ? data?.state || '' : '',
          vat_id: data.vat_id || '',
        }
      : {
          address: '',
          suite: '',
          city: '',
          stateUs: { id: '' },
          state: '',
          company: '',
          country: { id: '' },
          post_code: '',
          vat_id: '',
        },
    onSubmit: (data, formikHelpers) => {
      formikHelpers.setSubmitting(true)
      handleSubmit(data, formikHelpers)
    },
    enableReinitialize: true,
  })

  const isCountryUnitedStates = formik.values.country.id === 'US'

  useEffect(() => {
    !editable && formik.resetForm()
  }, [editable])

  return (
    <div
      className={twMerge(
        'p-2 bg-bg-light rounded-md flex flex-col gap-2 md:gap-4 md:p-6 w-full',
        editable && 'border border-indigo-300 shadow-xl',
      )}
    >
      <div className="flex items-center justify-between gap-2 text-lg leading-8 tracking-wider uppercase text-gray-800 font-semibold">
        Billing contact
        {!editable && (
          <Button onClick={() => setEdit(EditForm.BILLING_CONTACT)}>
            <span className="inline-flex items-center gap-2">
              Edit <PencilIcon className="w-4" />
            </span>
          </Button>
        )}
      </div>

      <FormikProvider value={formik}>
        <BillingContactFormFields isCountryUnitedStates={isCountryUnitedStates} disabledFields={!editable} />
      </FormikProvider>

      {editable && (
        <div className="flex justify-end gap-2">
          <Button size="large" onClick={() => setEdit(null)} isDisabled={formik.isSubmitting}>
            <span className="inline-flex gap-2">
              Cancel <TrashIcon className="w-4" />
            </span>
          </Button>

          <Button size="large" variant="primary" isLoading={formik.isSubmitting} onClick={() => formik.handleSubmit()}>
            <span className="inline-flex gap-2">
              Submit <ArrowRightIcon className="w-4" />
            </span>
          </Button>
        </div>
      )}
    </div>
  )
}

export default BillingContact
