import React, { useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { Close } from '@material-ui/icons'
import { Spin, Typography } from 'antd'

import api from 'api'
import type { Error } from 'api'
import { getCxFromStyles } from 'helpers'
import User, { Hotel } from 'modules/UsersModule/types/User'
import { useUpdateUser } from 'api/users/updateUser'
import { Button, Tabs } from 'ui'
import { notification } from 'helpers'
import Hotels from '../Hotels'
import Gds from '../Gds'
import SubscriptionSelect from '../SubscriptionSelect'

import styles from './ActivationModal.module.scss'
import { UserRoles } from '../../types'

type Props = {
  user?: User
  onClose: () => void
  onSuccess?: () => void
}

enum TAB_KEYS {
  subscription = 'subscription',
  hotels = 'hotels',
  gds = 'gds',
}

const ActivationModal: React.FC<Props> = ({ onClose, user, onSuccess }) => {
  const history = useHistory()
  const cx = getCxFromStyles(styles)
  const [selectedTabKey, setSelectedTabKey] = useState<string>(TAB_KEYS.subscription)
  const [userHotels, setUserHotels] = useState<Hotel[]>(user?.hotels || [])
  const [selectedGds, setSelectedGds] = useState<{ id: number }[]>(user?.gds || [])
  const [activationPlan, setActivationPlan] = useState<string>('')

  const { mutate } = useUpdateUser({})

  const isTravelAgent = useMemo(() => user?.role === UserRoles.TravelAgent, [user])
  const tabs = useMemo(() => {
    const factoredTabs = [
      {
        key: TAB_KEYS.subscription,
        label: 'Add subscription plan',
      },
      {
        key: TAB_KEYS.gds,
        label: 'Add GDSs',
      },
    ]

    if (!isTravelAgent) {
      factoredTabs.splice(1, 0, {
        key: TAB_KEYS.hotels,
        label: 'Add hotels',
      })
    }

    return factoredTabs
  }, [isTravelAgent])

  const { mutate: activateUser, isLoading: isActivation } = useMutation(
    (): Promise<void> =>
      api.post(`/users/${user?.id}/confirm`, {
        first_plan: activationPlan,
        hotels: userHotels.map((item) => item.id),
        // gds: selectedGds, todo: HRC-371 - uncomment this line
      }),
    {
      onSuccess: () => {
        // todo: HRC-371 - remove this GDS mutation
        if (user) mutate({ id: user.id, data: { gds: selectedGds, role: user.role } })
        if (onSuccess) {
          return onSuccess()
        }

        history.push('/admin/users')
      },
      onError: (error: Error) => {
        notification.error({ message: error.message })
      },
    },
  )

  const onConfirmButtonClick = () => {
    switch (selectedTabKey) {
      case TAB_KEYS.subscription:
        setSelectedTabKey(isTravelAgent ? TAB_KEYS.gds : TAB_KEYS.hotels)
        break
      case TAB_KEYS.hotels:
        setSelectedTabKey(TAB_KEYS.gds)
        break
      case TAB_KEYS.gds:
        activateUser()
        break
    }
  }

  const renderConfirmButton = () => {
    if (selectedTabKey === TAB_KEYS.gds)
      return (
        <Button
          large
          primary
          onClick={onConfirmButtonClick}
          className={cx('confirmBtn')}
          disabled={(!isTravelAgent && userHotels.length === 0) || selectedGds.length === 0 || !activationPlan}
          loading={isActivation}
        >
          Confirm
        </Button>
      )

    return (
      <Button large primary onClick={onConfirmButtonClick} className={cx('confirmBtn')}>
        Next
      </Button>
    )
  }

  return (
    <Spin spinning={isActivation || !user} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}>
      <div className={cx('root')}>
        <div className={cx('title')}>
          <Typography.Title level={2}>
            Activate
            <span>
              : {user?.first_name} {user?.last_name}
            </span>
          </Typography.Title>

          <div className={cx('actions')}>
            {renderConfirmButton()}

            <div className={cx('close')} onClick={onClose}>
              <Close />
            </div>
          </div>
        </div>

        <Tabs tabs={tabs} selectedTabKey={selectedTabKey} className={cx('tabs')} onChange={setSelectedTabKey} small />

        {!!user && selectedTabKey === 'subscription' ? (
          <SubscriptionSelect user={user} activationPlan={activationPlan} setActivationPlan={setActivationPlan} />
        ) : null}
        {!!user && selectedTabKey === 'hotels' ? (
          <Hotels user={{ ...user, hotels: userHotels }} setUserHotels={setUserHotels} />
        ) : null}
        {!!user && selectedTabKey === 'gds' ? <Gds selectedGds={selectedGds} onChange={setSelectedGds} /> : null}

        <div className={cx('actions', 'mt')}>{renderConfirmButton()}</div>
      </div>
    </Spin>
  )
}

export default ActivationModal
