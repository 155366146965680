import React from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import api from 'api'
import type { Error } from 'api'
import { notification } from 'helpers'
import ForgotPassword from './ForgotPassword'

const ForgotPasswordContainer: React.FC = () => {
  const history = useHistory()

  const {
    mutate: onSubmit,
    isLoading,
    error,
  } = useMutation(async (data: { [key: string]: string }): Promise<void> => api.post('/forgot-password', data), {
    onSuccess: () => {
      notification.success({
        message: 'Email has been sent',
      })
      history.push('/')
    },
    onError: (error: Error) => {
      notification.error({ message: error.message })
    },
  })

  return <ForgotPassword onSubmit={onSubmit} isSubmitting={isLoading} apiErrors={error?.errors} />
}

export default ForgotPasswordContainer
