import React from 'react'

import Hotel from 'modules/HotelsModule/types/Hotel'

import { Header, GeneralInfo, Keywords } from './components'

interface Props {
  data?: Hotel
  refreshData: (mode?: string) => void
}

const HotelCard: React.FC<Props> = ({ data, refreshData }) => {
  return (
    <>
      <Header
        name={data?.name}
        propertyId={data?.property_id}
        ratingAAA={data?.details?.rating_aaa}
        ratingNTM={data?.details?.rating_ntm}
        hotelId={data?.id}
      />
      <GeneralInfo data={data} refreshData={refreshData} />
      <Keywords keywords={data?.keywords} />
    </>
  )
}

export default HotelCard
