export const TRAVEL_PORT_KEY = 'travel_port'
export const TRAVEL_PORT_ID = 1

export const SABRE_KEY = 'sabre'
export const SABRE_ID = 2

export const GDS_LIST: { id: number; name: string; label: string }[] = [
  { id: TRAVEL_PORT_ID, name: TRAVEL_PORT_KEY, label: 'Travelport-Galileo' },
  { id: SABRE_ID, name: SABRE_KEY, label: 'Sabre' },
]
