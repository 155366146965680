import React from 'react'
import {
  Category,
  ChromeReaderMode,
  Face,
  Fastfood,
  Hotel,
  Image,
  Loop,
  Loyalty,
  MonetizationOn,
  More,
  QueryBuilder,
  SmokingRooms,
  Subtitles,
} from '@material-ui/icons'
import { uniq } from 'lodash'

import { RateDetails } from 'types/domain/RateDetails'
import { parseCancellationDeadlineOffsetObjectToString } from 'helpers/cancellationDeadlineOffset'

const createSections = (isSabre: boolean, firstRate: RateDetails, secondRate?: RateDetails) => [
  {
    label: 'Rate',
    fields: [
      {
        icon: <Subtitles />,
        title: ' Booking code:',
        values1: [firstRate.rate_plan],
        values2: [secondRate?.rate_plan],
      },
      {
        icon: <Category />,
        title: 'Category:',
        values1: [firstRate.rate_category?.value],
        values2: [secondRate?.rate_category?.value],
      },
      {
        icon: <Loyalty />,
        title: 'Name:',
        values1: [firstRate.rate_name],
        values2: [secondRate?.rate_name],
      },
      {
        icon: <Loyalty />,
        title: 'Description:',
        values1: [firstRate.rate_description],
        values2: [secondRate?.rate_description],
      },
    ],
  },
  {
    label: 'Room',
    fields: [
      {
        icon: <ChromeReaderMode />,
        title: 'Description:',
        values1: [firstRate.room_rate_description],
        values2: [secondRate?.room_rate_description],
      },
      isSabre
        ? null
        : {
            icon: <Face />,
            title: 'Adult count in room:',
            values1: ['1'],
            values2: ['1'],
          },
      {
        icon: <Hotel />,
        title: 'Bed types:',
        values1:
          !Array.isArray(firstRate.beds) || firstRate.beds.length < 1
            ? [undefined]
            : firstRate.beds.map((bed) => `${bed.type} x ${bed.quantity}`),
        values2:
          secondRate && (!Array.isArray(secondRate?.beds) || secondRate?.beds.length < 1)
            ? [undefined]
            : firstRate.beds.map((bed) => `${bed.type} x ${bed.quantity}`),
      },
      {
        icon: <Fastfood />,
        title: 'Meal plans:',
        values1: [],
        values2: [],
        sublines: [
          {
            title: 'Breakfast:',
            values1: [firstRate.breakfast === true ? 'Yes' : firstRate.breakfast === false ? 'No' : undefined],
            values2: [secondRate?.breakfast === true ? 'Yes' : secondRate?.breakfast === false ? 'No' : undefined],
          },
          {
            title: 'Lunch:',
            values1: [firstRate.lunch === true ? 'Yes' : firstRate.lunch === false ? 'No' : undefined],
            values2: [secondRate?.lunch === true ? 'Yes' : secondRate?.lunch === false ? 'No' : undefined],
          },
          {
            title: 'Dinner:',
            values1: [firstRate.dinner === true ? 'Yes' : firstRate.dinner === false ? 'No' : undefined],
            values2: [secondRate?.dinner === true ? 'Yes' : secondRate?.dinner === false ? 'No' : undefined],
          },
        ],
      },
      {
        icon: <SmokingRooms />,
        title: 'Smoking room:',
        values1: [firstRate.smoking === true ? 'Yes' : firstRate.smoking === false ? 'No' : undefined],
        values2: [secondRate?.smoking === true ? 'Yes' : secondRate?.smoking === false ? 'No' : undefined],
      },
      {
        icon: <Image />,
        title: 'Room view:',
        values1: [firstRate.room_view?.value],
        values2: [secondRate?.room_view?.value],
      },
    ],
  },
  {
    label: 'Cancellation Policy',
    fields: [
      {
        icon: <Loop />,
        title: 'Refundable:',
        values1: [firstRate.refundable === true ? 'Yes' : firstRate.refundable === false ? 'No' : undefined],
        values2: [secondRate?.refundable === true ? 'Yes' : secondRate?.refundable === false ? 'No' : undefined],
      },
      {
        icon: <QueryBuilder />,
        title: 'Cancel deadline:',
        values1: [
          firstRate.cancellation_until || firstRate.cancellation_deadline_offset
            ? parseCancellationDeadlineOffsetObjectToString(firstRate.cancellation_deadline_offset)
            : undefined,
        ],
        values2: [
          secondRate?.cancellation_until || secondRate?.cancellation_deadline_offset
            ? parseCancellationDeadlineOffsetObjectToString(secondRate.cancellation_deadline_offset)
            : undefined,
        ],
      },
      {
        icon: <More />,
        title: 'Rate guarantee info:',
        values1: [firstRate.guarantee_type, ...(isSabre ? [] : [firstRate.guarantee_deadline])],
        values2: [secondRate?.guarantee_type, ...(isSabre ? [] : [secondRate?.guarantee_deadline])],
      },
      isSabre
        ? null
        : {
            icon: <MonetizationOn />,
            title: 'Commission:',
            values1: [
              firstRate.commission_value
                ? `${firstRate.commission_value}${
                    firstRate.commission_type === 'Percentage' ? '%' : ' ' + firstRate.commission_type
                  }`
                : undefined,
            ],
            values2: [
              secondRate?.commission_value
                ? `${secondRate.commission_value}${
                    secondRate.commission_type === 'Percentage' ? '%' : ' ' + secondRate.commission_type
                  }`
                : undefined,
            ],
          },
    ],
  },
  {
    label: 'Pricing',
    fields: [
      {
        icon: <MonetizationOn />,
        title: 'Rate base:',
        values1: [firstRate.rate_base],
        values2: [secondRate?.rate_base],
      },
      {
        icon: <MonetizationOn />,
        title: 'Taxes:',
        values1: [firstRate.taxes],
        values2: [secondRate?.taxes],
      },
      isSabre
        ? null
        : {
            icon: <MonetizationOn />,
            title: 'Surcharges:',
            values1: [firstRate.surcharges],
            values2: [secondRate?.surcharges],
          },
      isSabre
        ? null
        : {
            icon: <MonetizationOn />,
            title: 'Other fees:',
            values1: [firstRate.other_fees === true ? 'Yes' : firstRate.other_fees === false ? 'No' : undefined],
            values2: [secondRate?.other_fees === true ? 'Yes' : secondRate?.other_fees === false ? 'No' : undefined],
          },
      isSabre
        ? {
            icon: <MonetizationOn />,
            title: 'Fees:',
            values1: [firstRate.total_fees],
            values2: [secondRate?.total_fees],
            sublines: uniq([
              ...(firstRate.fees?.map((fee) => fee.name) || []),
              ...(secondRate?.fees?.map((fee) => fee.name) || []),
            ]).map((key) => ({
              title: key + ':',
              values1: firstRate.fees?.filter((fee) => fee.name === key).map((fee) => fee.amount) || [],
              values2: secondRate?.fees?.filter((fee) => fee.name === key).map((fee) => fee.amount) || [],
            })),
          }
        : null,
      {
        icon: <MonetizationOn />,
        title: 'Total:',
        values1: [firstRate.total],
        values2: [secondRate?.total],
      },
      isSabre
        ? null
        : {
            icon: <MonetizationOn />,
            title: 'Rate change indicator:',
            values1: [
              firstRate.rate_change_indicator === true
                ? 'Yes'
                : firstRate.rate_change_indicator === false
                ? 'No'
                : undefined,
            ],
            values2: [
              secondRate?.rate_change_indicator === true
                ? 'Yes'
                : secondRate?.rate_change_indicator === false
                ? 'No'
                : undefined,
            ],
          },
    ],
  },
  {
    label: 'Extra Charges',
    fields: [
      {
        icon: <MonetizationOn />,
        title: 'Extra adult amount:',
        values1: [firstRate.extra_adult_amount],
        values2: [secondRate?.extra_adult_amount],
      },
      {
        icon: <MonetizationOn />,
        title: 'Adult rollaway charge:',
        values1: [firstRate.extra_adult_rollaway_amount],
        values2: [secondRate?.extra_adult_rollaway_amount],
      },
      isSabre
        ? null
        : {
            icon: <MonetizationOn />,
            title: 'Resort fee:',
            values1: [firstRate.extra_resort_fee],
            values2: [secondRate?.extra_resort_fee],
          },
    ],
  },
]

export default createSections
