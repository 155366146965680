import React, { useCallback, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Moment } from 'moment/moment'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import { Button, DateRangePicker, Select } from 'ui-v2'

interface ParamsProps {
  dates: [Moment | null, Moment | null]
  onDatesChange: (dates: [Moment | null, Moment | null]) => void
  handleSearch: () => void
  selectedGds: string | undefined
  setSelectedGds: (gds: string) => void
  gdsSelectOptions: any
  isLoading: boolean
}

const Params = ({
  gdsSelectOptions,
  dates,
  onDatesChange,
  handleSearch,
  setSelectedGds,
  selectedGds,
  isLoading,
}: ParamsProps) => {
  const [currentRangePickerValues, setCurrentRangePickerValues] = useState([dates[0], dates[1]])

  useEffect(() => {
    const listener = (e: { code: string }) => e.code === 'Enter' && handleSearch()
    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [handleSearch])

  const onRangePickerChange = useCallback(
    (dates) => {
      if (dates?.[0] && dates?.[1]) {
        onDatesChange([dates?.[0], dates?.[1]])
      }
    },
    [onDatesChange],
  )

  return (
    <>
      <div className="p-4 bg-bg-accent-7 flex flex-col gap-3 md:px-7 lg:flex-row lg:items-start">
        <div className="lg:hidden">
          <div className="flex items-start justify-between gap-2 mb-2">
            <DateRangePicker
              value={dates}
              onChange={(dates): void => {
                !!dates && onDatesChange(dates)
              }}
              labels={['From', 'To']}
            />
            <Button
              isLoading={isLoading}
              className="w-10 rounded p-0 flex items-center justify-center"
              onClick={handleSearch}
              size="large"
              variant="primary"
            >
              <MagnifyingGlassIcon className="text-white w-5" />
            </Button>
          </div>
        </div>

        <Select
          className="hidden lg:block flex-grow"
          options={gdsSelectOptions}
          label="GDS"
          value={selectedGds?.label}
          onChange={(value) => setSelectedGds(gdsSelectOptions.find((gds) => gds.value === value))}
        />
        <DateRangePicker
          value={currentRangePickerValues}
          onChange={(dates): void => {
            !!dates && onDatesChange(dates)
          }}
          labels={['From', 'To']}
          onRangePickerChange={onRangePickerChange}
          onCalendarChange={setCurrentRangePickerValues}
        />
        <Button
          className={twMerge('lg:block lg:w-auto')}
          onClick={handleSearch}
          isLoading={isLoading}
          variant="primary"
          size="large"
        >
          Search
        </Button>
      </div>
    </>
  )
}

export default Params
