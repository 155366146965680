import { Demo } from 'types/domain/Demo'

const mapDataToBackend = (data: Demo) => {
  const { hotel, ...d } = data

  return {
    ...d,
    hotels: [{ id: hotel.id }],
  }
}

export { mapDataToBackend }
