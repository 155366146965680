import React from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { Spin, Pagination } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { ListUser, UserStatus, UserListQueryParams, ListUserMeta } from 'modules/UsersModule/types'
import { Sorter } from 'ui/Table'
import { Table, TableDropdown } from 'ui-v2'

interface Props {
  meta: Partial<ListUserMeta>
  data: ListUser[]
  isLoading: boolean
  setPage: (page: number) => void
  setSorter: (sorter: Sorter<keyof ListUser>) => void
  activateUser: (user: ListUser) => void
  rejectUser: (user: ListUser) => void
  params: UserListQueryParams
}

const List: React.FC<Props> = ({ meta, data, isLoading, setPage, setSorter, activateUser, rejectUser, params }) => {
  const history = useHistory()
  const sorter: Sorter<keyof ListUser> = { by: params.sort_by, direction: params.sort_direction }

  return (
    <Spin
      spinning={isLoading}
      indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
      wrapperClassName="bg-white px-4 pr-[30px]"
    >
      <div className="flex flex-col gap-4">
        <div className="text-base leading-none font-normal text-gray-500">Number of accounts: {meta.total}</div>

        <div className="flex flex-col gap-2 lg:hidden">
          {(data || []).map((user, index) => (
            <div className="flex flex-col gap-2 border border-indigo-100 rounded p-2 even:bg-bg-light" key={user.id}>
              <div className="flex gap-2">
                <div className="w-1/2">
                  <div className="text-xs leading-none font-normal text-gray-500">#</div>
                  <div className="text-base leading-6 font-normal text-gray-700">
                    {(params.page - 1) * params.length + index + 1}
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="text-xs leading-none font-normal text-gray-500">User name</div>
                  <div className="text-base leading-6 font-normal text-gray-700">{user.name}</div>
                </div>
              </div>

              <div className="flex gap-2">
                <div className="w-full">
                  <div className="text-xs leading-none font-normal text-gray-500">Email</div>
                  <div className="text-base leading-6 font-normal text-gray-700 break-words">{user.email}</div>
                </div>
              </div>

              <div className="flex gap-2">
                <div className="w-1/2">
                  <div className="text-xs leading-none font-normal text-gray-500">Users</div>
                  <div className="text-base leading-6 font-normal text-gray-700">{user.subordinates_count || '-'}</div>
                </div>
                <div className="w-1/2">
                  <div className="text-xs leading-none font-normal text-gray-500">Created</div>
                  <div className="text-base leading-6 font-normal text-gray-700">
                    {user.created_at ? moment(user.created_at).format('DD.MM.YYYY') : undefined}
                  </div>
                </div>
              </div>

              <div className="flex gap-2">
                <div className="w-1/2">
                  <div className="text-xs pb-1 leading-none font-normal text-gray-500">Active</div>
                  <div className="text-base leading-6 font-normal text-gray-700">
                    <div
                      className={twMerge(
                        'px-2.5 py-0.5 rounded-[10px] justify-center items-center inline-flex',
                        user.status === UserStatus.Active ? 'bg-emerald-100' : 'bg-red-100',
                      )}
                    >
                      <span
                        className={twMerge(
                          'text-center text-xs',
                          user.status === UserStatus.Active ? 'text-emerald-800' : 'text-red-800',
                        )}
                      >
                        {user.status === UserStatus.Active ? 'Active' : 'Inactive'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="text-xs pb-1 leading-none font-normal text-gray-500">Payment</div>
                  <div
                    className={twMerge(
                      'px-2.5 py-0.5 rounded-[10px] justify-center items-center inline-flex',
                      user.payment_status ? 'bg-emerald-100' : 'bg-red-100',
                    )}
                  >
                    <span
                      className={twMerge(
                        'text-center text-xs',
                        user.payment_status ? 'text-emerald-800' : 'text-red-800',
                      )}
                    >
                      {user.payment_status ? 'Yes' : 'No'}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex gap-2">
                <div className="w-1/2">
                  <div className="text-xs leading-none font-normal text-gray-500">Amount</div>
                  {user.amount && user.amount_currency ? user.amount + user.amount_currency.toUpperCase() : '-'}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="hidden lg:block">
          <Table className="table-fixed">
            <thead>
              <Table.Tr className="h-min-[46px]">
                <Table.Th className="w-10">#</Table.Th>
                <Table.Th sorter={sorter} setSorter={setSorter} name="name">
                  User name
                </Table.Th>
                <Table.Th sorter={sorter} setSorter={setSorter} name="email">
                  Email
                </Table.Th>
                <Table.Th sorter={sorter} setSorter={setSorter} name="subordinates_count" className="w-20">
                  Users
                </Table.Th>
                <Table.Th sorter={sorter} setSorter={setSorter} name="created_at" className="w-[100px]">
                  Created
                </Table.Th>
                <Table.Th className="w-[100px]">Active</Table.Th>
                <Table.Th className="w-[100px]">Payment</Table.Th>
                <Table.Th className="w-[90px]">Amount</Table.Th>
                <Table.Th className="w-14"></Table.Th>
              </Table.Tr>
            </thead>
            <tbody>
              {(data || []).map((user, index) => (
                <Table.Tr key={user.id}>
                  <Table.Td className="w-10">{(params.page - 1) * params.length + index + 1}</Table.Td>
                  <Table.Td>{user.name}</Table.Td>
                  <Table.Td className="break-words">{user.email}</Table.Td>
                  <Table.Td>
                    <div className="flex h-100 justify-center w-20">{user.subordinates_count || '-'}</div>
                  </Table.Td>
                  <Table.Td className="w-[100px]">
                    {user.created_at ? moment(user.created_at).format('DD.MM.YYYY') : undefined}
                  </Table.Td>
                  <Table.Td className="w-[100px]">
                    <div className="flex h-100 justify-center">
                      <div
                        className={twMerge(
                          'px-2.5 py-0.5 rounded-[10px] justify-center items-center inline-flex',
                          user.status === UserStatus.Active ? 'bg-emerald-100' : 'bg-red-100',
                        )}
                      >
                        <span
                          className={twMerge(
                            'text-center text-xs',
                            user.status === UserStatus.Active ? 'text-emerald-800' : 'text-red-800',
                          )}
                        >
                          {user.status === UserStatus.Active ? 'Active' : 'Inactive'}
                        </span>
                      </div>
                    </div>
                  </Table.Td>
                  <Table.Td className="w-[100px]">
                    <div className="flex h-100 justify-center">
                      <div
                        className={twMerge(
                          'px-2.5 py-0.5 rounded-[10px] justify-center items-center inline-flex',
                          user.payment_status ? 'bg-emerald-100' : 'bg-red-100',
                        )}
                      >
                        <span
                          className={twMerge(
                            'text-center text-xs',
                            user.payment_status ? 'text-emerald-800' : 'text-red-800',
                          )}
                        >
                          {user.payment_status ? 'Yes' : 'No'}
                        </span>
                      </div>
                    </div>
                  </Table.Td>
                  <Table.Td className="w-[90px]">
                    <div className="flex h-100 justify-center">
                      {user.amount && user.amount_currency ? user.amount + user.amount_currency.toUpperCase() : '-'}
                    </div>
                  </Table.Td>
                  <Table.Td className="align-middle px-0">
                    <TableDropdown<ListUser>
                      record={user}
                      menuItems={[
                        {
                          key: 'profile',
                          label: 'Manage',
                          onClick: (user) => history.push(`/admin/users/${user.id}`),
                        },
                        {
                          key: 'activate',
                          label: 'Activate user account',
                          onClick: (record) => activateUser(record),
                          hide: user.status !== UserStatus.Unconfirmed,
                        },
                        {
                          key: 'dismiss',
                          label: 'Dismiss user',
                          onClick: (record) => rejectUser(record),
                          hide: user.status !== UserStatus.Unconfirmed,
                        },
                        {
                          key: 'dashboard',
                          label: 'User Dashboard',
                          onClick: (record) => history.push(`/admin/users/${record.id}/dashboard`),
                          hide: user.status === UserStatus.Unconfirmed,
                        },
                      ]}
                    />
                  </Table.Td>
                </Table.Tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div className="flex items-center justify-center pt-5">
          <Pagination
            current={meta.current_page || 1}
            total={meta.total || 1}
            onChange={setPage}
            showSizeChanger={false}
            hideOnSinglePage
            pageSize={meta.per_page || 10}
          />
        </div>

        {!isLoading && data && data.length === 0 && (
          <div className="text-center text-base text-gray-500 p-5">No users</div>
        )}
      </div>
    </Spin>
  )
}

export default List
