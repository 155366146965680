import { Route } from 'routes/types/Route'

import { ProfilePage, UserManagementPage, InviteUserPage } from './pages'

const routes: Route[] = [
  { path: '/admin/users/invite-user', component: InviteUserPage, layout: 'admin' },
  { path: '/admin/users/:userId/:section?', component: ProfilePage, layout: 'admin' },
  { path: '/profile/:section?', component: ProfilePage, layout: 'main' },
  { path: '/admin/users', component: UserManagementPage, layout: 'admin' },
]

export default routes
