import React from 'react'
import Header from '../Header'
import ActionButtonGroup from '../ActionButtonGroup'
import { Modal } from '../index'

import styles from './ConfirmationModal.module.scss'

interface Props {
  onCancel: () => void
  onSuccess: () => void
  title: string
  visible: boolean
  isLoading?: boolean
}

const ConfirmationModal: React.FC<Props> = ({ title, visible, onSuccess, onCancel, isLoading }) => {
  return (
    <Modal onCancel={onCancel} visible={visible}>
      <Header withoutLine classes={{ root: styles.title }}>
        {title}
      </Header>
      <ActionButtonGroup
        classes={{ root: styles.buttons }}
        cancelButton={{ children: 'No', onClick: onCancel, disabled: isLoading }}
        submitButton={{ children: 'Yes', onClick: onSuccess, loading: isLoading }}
      />
    </Modal>
  )
}

export default ConfirmationModal
