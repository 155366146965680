import React from 'react'

import { User } from 'modules/UsersModule/types'
import { ACCOUNT_TYPE_LIST } from 'constants/accounts'
import { Input } from 'ui-v2'

type Props = {
  user?: User
}

const AccountType: React.FC<Props> = ({ user }) => {
  const accountType = ACCOUNT_TYPE_LIST.find((accountType) => user?.role === accountType.userRole)?.label || ''
  return (
    <div>
      <Input value={accountType} label="Account type" isDisabled />
    </div>
  )
}

export default AccountType
