import { useQuery, QueryKey, UseQueryOptions } from 'react-query'
import qs from 'qs'

import api from 'api/index'
import type { ApiError } from 'api/types/ApiError'
import { RateRules } from 'types/domain/RateRules'
import { RequestHeadersWithGdsScope } from 'api/types/RequestHeadersWithGdsScope'

import { RATES_ENDPOINT_KEY } from './constants'

type GetRateRulesResourceRequestQuery = {
  rate_plan: string
  hotel_id: number
  start_date: string
  end_date: string
  user_id?: number
}

export type GetRateRulesResourceResponse = {
  data: RateRules
}

const getRateRulesResource = ({
  query,
  headers,
}: {
  headers: RequestHeadersWithGdsScope
  query: GetRateRulesResourceRequestQuery
}): Promise<GetRateRulesResourceResponse> => api.get(`/rates?${qs.stringify(query, { skipNulls: true })}`, { headers })

export const useGetRateRulesResource = ({
  headers,
  query,
  queryOptions,
  customQueryKey,
}: {
  headers: RequestHeadersWithGdsScope
  query: GetRateRulesResourceRequestQuery
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getRateRulesResource>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || [RATES_ENDPOINT_KEY, ...Object.values(query), ...Object.values(headers)],
    () => getRateRulesResource({ query, headers }),
    queryOptions,
  )
