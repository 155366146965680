import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'

import { ISSUES_ENDPOINT_KEY } from './constants'

const closeIssue = ({ issueId }: { issueId: number }): Promise<void> =>
  api.post(`/${ISSUES_ENDPOINT_KEY}/${issueId}/close`)

export const useCloseIssue = (
  queryOptions?: UseMutationOptions<Awaited<ReturnType<typeof closeIssue>>, ApiError, number>,
): UseMutationResult<void, ApiError, number> => useMutation((issueId) => closeIssue({ issueId }), queryOptions)
