import { PhoneInput as ReactInternationalPhone } from 'react-international-phone'
import './PhoneInput.scss'
import { twMerge } from 'tailwind-merge'

type PhoneInputProps = {
  label: string
  value: string
  onChange: (value: string) => void
  error?: string
  className?: string
  isDisabled?: boolean
}

export const PhoneInput = ({ value, onChange, className, isDisabled, error, label }: PhoneInputProps) => {
  return (
    <div
      className={twMerge(
        'phoneInputRoot',
        'relative border border-gray-100 bg-white rounded h-[42px] px-2 pt-4',
        className,
        isDisabled && 'border-gray-100',
        error && 'border-validation-error-outline mb-4',
      )}
    >
      <div
        className={twMerge(
          'absolute top-0.5 leading-none font-normal text-gray-500 text-xs left-2',
          isDisabled && 'text-gray-300',
          error && 'text-validation-error',
        )}
      >
        {label}:
      </div>

      <ReactInternationalPhone
        value={value}
        onChange={onChange}
        placeholder="-"
        disabled={isDisabled}
        defaultCountry="pl"
      />

      {error && (
        <span className="text-validation-error absolute -bottom-4 left-0 text-xs whitespace-nowrap overflow-ellipsis w-full overflow-hidden">
          {error}
        </span>
      )}
    </div>
  )
}
