import React, { useMemo } from 'react'
import { Moment } from 'moment'
import {
  AssignmentLate,
  Description,
  Hotel,
  Image,
  LocalCafe,
  MonetizationOn,
  Note,
  QueryBuilder,
  SmokingRooms,
  Subtitles,
} from '@material-ui/icons'
import { twMerge } from 'tailwind-merge'

import { stringifyMomentDate } from 'helpers/date'
import { useGetRateRulesResource } from 'api/rates/getRateRulesResource'
import useGdsSelectContext from 'components/GdsSelect/useGdsSelectContext'

interface Props {
  isUnavailable: boolean
  startDate: Moment
  endDate: Moment
  bookingCode: string
  hotelId: number
  userId?: number
}

const Rules = (props: Props) => {
  const { bookingCode, hotelId, userId, startDate, endDate, isUnavailable } = props

  const { headers, isSelectedSabre, isSelectedTravelport } = useGdsSelectContext()

  const rateRules = useGetRateRulesResource({
    headers,
    query: {
      rate_plan: bookingCode,
      hotel_id: hotelId,
      start_date: stringifyMomentDate(startDate),
      end_date: stringifyMomentDate(endDate),
      user_id: userId,
    },
    queryOptions: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      enabled: !isUnavailable,
    },
  })

  const isRateRulesLoading = rateRules.isLoading
  const isRateRulesError = rateRules.isError

  const rules = useMemo(() => rateRules.data?.data, [rateRules.data?.data])

  if (isRateRulesError || isUnavailable) {
    return (
      <div className="bg-bg-light rounded-md p-4">
        <div className="text-lg text-gray-500 text-center">Unavailable</div>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4 lg:flex-row relative">
      {isRateRulesLoading ? (
        <div className="bg-bg-light rounded-md p-4 relative h-20">
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-50 opacity-55 z-10">
            <span className="absolute top-1/2 left-1/2 w-12 h-12 inline-flex items-center justify-center -mt-6 -ml-6">
              <svg
                aria-hidden="true"
                className="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-800"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </span>
          </div>
        </div>
      ) : (
        <>
          <div className="bg-bg-light rounded-md p-4 flex flex-col gap-2 lg:w-1/2">
            <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
              <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                <span className="w-6">
                  <Note className="scale-[60%]" />
                </span>
                Promo text:
              </span>

              {rules?.gds_promotion_field ? (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  )}
                >
                  {rules.gds_promotion_field}
                </span>
              ) : (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                    'text-validation-error bg-validation-error-bg border-validation-error-outline',
                  )}
                >
                  Missing
                </span>
              )}
            </div>

            <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
              <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                <span className="w-6">
                  <Description className="scale-[60%]" />
                </span>
                Rate description:
              </span>

              {rules?.rate_description ? (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  )}
                >
                  {rules.rate_description}
                </span>
              ) : (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                    'text-validation-error bg-validation-error-bg border-validation-error-outline',
                  )}
                >
                  Missing
                </span>
              )}
            </div>

            <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
              <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                <span className="w-6">
                  <Description className="scale-[60%]" />
                </span>
                Room rate description:
              </span>

              {rules?.room_rate_description ? (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  )}
                >
                  {rules.room_rate_description}
                </span>
              ) : (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                    'text-validation-error bg-validation-error-bg border-validation-error-outline',
                  )}
                >
                  Missing
                </span>
              )}
            </div>

            <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
              <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                <span className="w-6">
                  <Description className="scale-[60%]" />
                </span>
                Room details:
              </span>

              {rules?.room_detail ? (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  )}
                >
                  {rules.room_detail}
                </span>
              ) : (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                    'text-validation-error bg-validation-error-bg border-validation-error-outline',
                  )}
                >
                  Missing
                </span>
              )}
            </div>

            {isSelectedSabre && (
              <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
                <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                  <span className="w-6">
                    <Subtitles className="scale-[60%]" />
                  </span>
                  Location Info:
                </span>

                {rules?.location_info ? (
                  <span
                    className={twMerge(
                      'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                    )}
                  >
                    {rules.location_info}
                  </span>
                ) : (
                  <span
                    className={twMerge(
                      'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                      'text-validation-error bg-validation-error-bg border-validation-error-outline',
                    )}
                  >
                    Missing
                  </span>
                )}
              </div>
            )}

            {isSelectedSabre && (
              <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
                <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                  <span className="w-6">
                    <Description className="scale-[60%]" />
                  </span>
                  Property description:
                </span>

                {rules?.property_description ? (
                  <span
                    className={twMerge(
                      'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                    )}
                  >
                    {rules.property_description}
                  </span>
                ) : (
                  <span
                    className={twMerge(
                      'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                      'text-validation-error bg-validation-error-bg border-validation-error-outline',
                    )}
                  >
                    Missing
                  </span>
                )}
              </div>
            )}

            <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
              <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                <span className="w-6">
                  <Image className="scale-[60%]" />
                </span>
                Room view:
              </span>

              {rules?.room_view?.value ? (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  )}
                >
                  {rules?.room_view?.value}
                </span>
              ) : (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                    'text-validation-error bg-validation-error-bg border-validation-error-outline',
                  )}
                >
                  Missing
                </span>
              )}
            </div>

            <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
              <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                <span className="w-6">
                  <SmokingRooms className="scale-[60%]" />
                </span>
                Smoking room:
              </span>

              {typeof rules?.smoking === 'boolean' ? (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  )}
                >
                  {rules.smoking ? 'Yes' : 'No'}
                </span>
              ) : (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                    'text-validation-error bg-validation-error-bg border-validation-error-outline',
                  )}
                >
                  Missing
                </span>
              )}
            </div>

            <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
              <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                <span className="w-6">
                  <Hotel className="scale-[60%]" />
                </span>
                Bed types:
              </span>

              {rules && rules.beds?.length > 0 ? (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  )}
                >
                  {rules.beds.map((bed) => `${bed.type} x ${bed.quantity}`).join(', ')}
                </span>
              ) : (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                    'text-validation-error bg-validation-error-bg border-validation-error-outline',
                  )}
                >
                  Missing
                </span>
              )}
            </div>

            <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
              <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                <span className="w-6">
                  <LocalCafe className="scale-[60%]" />
                </span>
                Meal plan:
              </span>

              <div className="flex flex-wrap gap-x-4 gap-y-2">
                <div className="flex items-center gap-4">
                  <span className="text-gray-700 text-base leading-none font-normal">Breakfast:</span>
                  {typeof rules?.breakfast === 'boolean' ? (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                      )}
                    >
                      {rules.breakfast ? 'Yes' : 'No'}
                    </span>
                  ) : (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                        'text-validation-error bg-validation-error-bg border-validation-error-outline',
                      )}
                    >
                      Missing
                    </span>
                  )}
                </div>

                <div className="flex items-center gap-4">
                  <span className="text-gray-700 text-base leading-none font-normal">Lunch:</span>
                  {typeof rules?.lunch === 'boolean' ? (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                      )}
                    >
                      {rules.lunch ? 'Yes' : 'No'}
                    </span>
                  ) : (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                        'text-validation-error bg-validation-error-bg border-validation-error-outline',
                      )}
                    >
                      Missing
                    </span>
                  )}
                </div>

                <div className="flex items-center gap-4">
                  <span className="text-gray-700 text-base leading-none font-normal">Dinner:</span>
                  {typeof rules?.dinner === 'boolean' ? (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                      )}
                    >
                      {rules.dinner ? 'Yes' : 'No'}
                    </span>
                  ) : (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                        'text-validation-error bg-validation-error-bg border-validation-error-outline',
                      )}
                    >
                      Missing
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-bg-light rounded-md p-4 flex flex-col gap-2 lg:w-1/2">
            <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
              <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                <span className="w-6">
                  <MonetizationOn className="scale-[60%]" />
                </span>
                Rate base all days:
              </span>

              <div className="flex flex-wrap gap-x-4 gap-y-2">
                <div className="flex items-center gap-4">
                  <span className="text-gray-700 text-base leading-none font-normal">Base:</span>
                  {rules?.rate_base ? (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                      )}
                    >
                      {rules?.rate_base}
                    </span>
                  ) : (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                        'text-validation-error bg-validation-error-bg border-validation-error-outline',
                      )}
                    >
                      Missing
                    </span>
                  )}
                </div>

                <div className="flex items-center gap-4">
                  <span className="text-gray-700 text-base leading-none font-normal">Total:</span>
                  {rules?.rate_total ? (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                      )}
                    >
                      {rules.rate_total}
                    </span>
                  ) : (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                        'text-validation-error bg-validation-error-bg border-validation-error-outline',
                      )}
                    >
                      Missing
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
              <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                <span className="w-6">
                  <MonetizationOn className="scale-[60%]" />
                </span>
                Taxes:
              </span>

              {/\d/.test(rules?.taxes || '') ? (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  )}
                >
                  {rules?.taxes}
                </span>
              ) : (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                    'text-validation-error bg-validation-error-bg border-validation-error-outline',
                  )}
                >
                  Missing
                </span>
              )}
            </div>

            {isSelectedTravelport && (
              <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
                <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                  <span className="w-6">
                    <MonetizationOn className="scale-[60%]" />
                  </span>
                  Surcharges:
                </span>

                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  )}
                >
                  {rules?.surcharges || '-'}
                </span>
              </div>
            )}

            {isSelectedSabre && (
              <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
                <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                  <span className="w-6">
                    <MonetizationOn className="scale-[60%]" />
                  </span>
                  Total:
                </span>

                {rules?.total ? (
                  <span
                    className={twMerge(
                      'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                    )}
                  >
                    {rules?.total}
                  </span>
                ) : (
                  <span
                    className={twMerge(
                      'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                      'text-validation-error bg-validation-error-bg border-validation-error-outline',
                    )}
                  >
                    Missing
                  </span>
                )}
              </div>
            )}

            <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
              <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                <span className="w-6">
                  <QueryBuilder className="scale-[60%]" />
                </span>
                Price breakdown:
              </span>

              <div className="flex flex-wrap gap-x-4 gap-y-2">
                <div className="flex items-center gap-4">
                  <span className="text-gray-700 text-base leading-none font-normal">From:</span>
                  {rules?.price_base_from ? (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                      )}
                    >
                      {rules?.price_base_from}
                    </span>
                  ) : (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                        'text-validation-error bg-validation-error-bg border-validation-error-outline',
                      )}
                    >
                      Missing
                    </span>
                  )}
                </div>

                <div className="flex items-center gap-4">
                  <span className="text-gray-700 text-base leading-none font-normal">To:</span>
                  {rules?.price_base_to ? (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                      )}
                    >
                      {rules?.price_base_to}
                    </span>
                  ) : (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                        'text-validation-error bg-validation-error-bg border-validation-error-outline',
                      )}
                    >
                      Missing
                    </span>
                  )}
                </div>

                <div className="flex items-center gap-4">
                  <span className="text-gray-700 text-base leading-none font-normal">Base:</span>
                  {rules?.price_base ? (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                      )}
                    >
                      {rules?.price_base}
                    </span>
                  ) : (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                        'text-validation-error bg-validation-error-bg border-validation-error-outline',
                      )}
                    >
                      Missing
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
              <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                <span className="w-6">
                  <MonetizationOn className="scale-[60%]" />
                </span>
                Extra charges:
              </span>

              {rules?.extra_charges ? (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  )}
                >
                  {rules?.extra_charges}
                </span>
              ) : (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                    'text-validation-error bg-validation-error-bg border-validation-error-outline',
                  )}
                >
                  Missing
                </span>
              )}
            </div>

            <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
              <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                <span className="w-6">
                  <MonetizationOn className="scale-[60%]" />
                </span>
                Commission:
              </span>

              {typeof rules?.commission_value === 'string' ? (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  )}
                >
                  {rules.commission_value}
                  {rules.commission_type === 'Percentage' ? '%' : ' ' + rules.commission_type}
                </span>
              ) : (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                    'text-validation-error bg-validation-error-bg border-validation-error-outline',
                  )}
                >
                  Missing
                </span>
              )}
            </div>

            <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
              <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                <span className="w-6">
                  <MonetizationOn className="scale-[60%]" />
                </span>
                Rate guarantee info:
              </span>

              <div className="flex flex-wrap gap-x-4 gap-y-2">
                <div className="flex items-center gap-4">
                  <span className="text-gray-700 text-base leading-none font-normal">Type:</span>
                  {rules?.guarantee_type ? (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                      )}
                    >
                      {rules?.guarantee_type}
                    </span>
                  ) : (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                        'text-validation-error bg-validation-error-bg border-validation-error-outline',
                      )}
                    >
                      Missing
                    </span>
                  )}
                </div>

                {isSelectedSabre && (
                  <div className="flex items-center gap-4">
                    <span className="text-gray-700 text-base leading-none font-normal">Deadline:</span>
                    {rules?.guarantee_deadline ? (
                      <span
                        className={twMerge(
                          'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                        )}
                      >
                        {rules?.guarantee_deadline}
                      </span>
                    ) : (
                      <span
                        className={twMerge(
                          'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                          'text-validation-error bg-validation-error-bg border-validation-error-outline',
                        )}
                      >
                        Missing
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>

            {isSelectedSabre && (
              <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
                <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                  <span className="w-6">
                    <MonetizationOn className="scale-[60%]" />
                  </span>
                  Deposit amount:
                </span>

                {rules?.deposit_amount ? (
                  <span
                    className={twMerge(
                      'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                    )}
                  >
                    {rules?.deposit_amount}
                  </span>
                ) : (
                  <span
                    className={twMerge(
                      'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                      'text-validation-error bg-validation-error-bg border-validation-error-outline',
                    )}
                  >
                    Missing
                  </span>
                )}
              </div>
            )}

            <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
              <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0 whitespace-nowrap">
                <span className="w-6">
                  <Subtitles className="scale-[60%]" />
                </span>
                Credit card for guarantee:
              </span>

              {rules?.credit_card_deposit_guarantee ? (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  )}
                >
                  {rules?.credit_card_deposit_guarantee}
                </span>
              ) : (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                    'text-validation-error bg-validation-error-bg border-validation-error-outline',
                  )}
                >
                  Missing
                </span>
              )}
            </div>

            <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
              <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
                <span className="w-6">
                  <AssignmentLate className="scale-[60%]" />
                </span>
                Cancellation policy:
              </span>

              <div className="flex flex-col gap-x-4 gap-y-2">
                <div className="flex items-center gap-4">
                  <span className="text-gray-700 text-base leading-none font-normal">Info:</span>
                  {rules?.cancellation_info ? (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                      )}
                    >
                      {rules?.cancellation_info}
                    </span>
                  ) : (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                        'text-validation-error bg-validation-error-bg border-validation-error-outline',
                      )}
                    >
                      Missing
                    </span>
                  )}
                </div>

                {isSelectedSabre && (
                  <div className="flex items-center gap-4">
                    <span className="text-gray-700 text-base leading-none font-normal">Check-in - Check-out:</span>
                    {rules?.checkin_checkout ? (
                      <span
                        className={twMerge(
                          'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                        )}
                      >
                        {rules?.checkin_checkout}
                      </span>
                    ) : (
                      <span
                        className={twMerge(
                          'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                          'text-validation-error bg-validation-error-bg border-validation-error-outline',
                        )}
                      >
                        Missing
                      </span>
                    )}
                  </div>
                )}

                <div className="flex items-center gap-4">
                  <span className="text-gray-700 text-base leading-none font-normal">Refundable:</span>
                  {typeof rules?.cancellation_policy === 'boolean' ? (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                      )}
                    >
                      {rules.cancellation_policy ? 'Yes' : 'No'}
                    </span>
                  ) : (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                        'text-validation-error bg-validation-error-bg border-validation-error-outline',
                      )}
                    >
                      Missing
                    </span>
                  )}
                </div>

                <div className="flex items-center gap-4">
                  <span className="text-gray-700 text-base leading-none font-normal">Cancellation deadline:</span>
                  {rules?.cancellation_deadline ? (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                      )}
                    >
                      {rules?.cancellation_deadline}
                    </span>
                  ) : (
                    <span
                      className={twMerge(
                        'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                        'text-validation-error bg-validation-error-bg border-validation-error-outline',
                      )}
                    >
                      Missing
                    </span>
                  )}
                </div>

                {isSelectedSabre && (
                  <div className="flex items-center gap-4">
                    <span className="text-gray-700 text-base leading-none font-normal">Absolute cancel deadline:</span>
                    {rules?.guarantee_deadline ? (
                      <span
                        className={twMerge(
                          'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                        )}
                      >
                        {rules?.guarantee_deadline}
                      </span>
                    ) : (
                      <span
                        className={twMerge(
                          'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                          'text-validation-error bg-validation-error-bg border-validation-error-outline',
                        )}
                      >
                        Missing
                      </span>
                    )}
                  </div>
                )}

                {isSelectedSabre && (
                  <div className="flex items-center gap-4">
                    <span className="text-gray-700 text-base leading-none font-normal">Cancel penalty:</span>
                    {rules?.cancel_penalty_amount ? (
                      <span
                        className={twMerge(
                          'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                        )}
                      >
                        {rules?.cancel_penalty_amount}
                      </span>
                    ) : (
                      <span
                        className={twMerge(
                          'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                          'text-validation-error bg-validation-error-bg border-validation-error-outline',
                        )}
                      >
                        Missing
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
              <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0 whitespace-nowrap">
                <span className="w-6">
                  <Subtitles className="scale-[60%]" />
                </span>
                Accepted payment:
              </span>

              {rules?.accepted_payment ? (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  )}
                >
                  {rules?.accepted_payment}
                </span>
              ) : (
                <span
                  className={twMerge(
                    'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                    'text-validation-error bg-validation-error-bg border-validation-error-outline',
                  )}
                >
                  Missing
                </span>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Rules
