import React, { useState } from 'react'

import { HotelsImportListItem } from 'types/domain/HotelsImportListItem'

import { Imports, ImportHotels } from './components'

import styles from './HotelMatch.module.scss'

const HotelMatch = () => {
  const [currentImport, setCurrentImport] = useState<HotelsImportListItem | null>(null)

  return (
    <div className={styles.root}>
      {currentImport ? (
        <ImportHotels importData={currentImport} onClose={() => setCurrentImport(null)} />
      ) : (
        <Imports setCurrentImport={setCurrentImport} />
      )}
    </div>
  )
}

export default HotelMatch
