import { QueryKey, useQuery, UseQueryOptions } from 'react-query'
import qs from 'qs'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { ApiResponseWithMeta } from 'api/types/ApiResponseWithMeta'
import UserRoles from 'modules/UsersModule/types/UserRoles'

export type TrialInvitation = {
  id: number
  hash: string
  email: string
  first_name?: string
  last_name?: string
  gds_ids?: number[]
  hotel_ids?: number[]
  role: UserRoles
  trial_ends_at: string
  used_at?: string
  created_at: string
}

type TrialInvitationResponse = ApiResponseWithMeta<Array<TrialInvitation>, unknown>
type TrialInvitationRequest = { page: number; length: number }

const getTrialInvitations = (query?: TrialInvitationRequest): Promise<TrialInvitationResponse> =>
  api.get(`/trial-invitations?${qs.stringify(query, { skipNulls: true })}`)

export const useGetTrialInvitations = ({
  query,
  queryOptions,
  customQueryKey,
}: {
  query?: TrialInvitationRequest
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getTrialInvitations>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || ['trial-invitations', ...Object.values(query || {})],
    () => getTrialInvitations(query),
    queryOptions,
  )
