import { ComponentType, useMemo, useState } from 'react'
import ReactSelect, {
  ClearIndicatorProps,
  GroupBase,
  OptionProps,
  OptionsOrGroups,
  ValueContainerProps,
} from 'react-select'
import { twMerge } from 'tailwind-merge'
import { Checkbox } from 'antd'
import { Check } from '@material-ui/icons'
import { CheckIcon } from '@heroicons/react/24/outline'

type SelectProps = {
  options: { value: string; label: string }[]
  value: string[]
  className?: string
  onChange?: (value: string[]) => void
  isDisabled?: boolean
  label: string
  placeholder?: string
}

export const MultiSelect = ({ className, options, value, onChange, isDisabled, label, placeholder }: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const currentValue = useMemo(() => options.filter((item) => value.includes(item.value)), [options, value])
  const selectOptions: OptionsOrGroups<SelectProps['options'][0], GroupBase<SelectProps['options'][0]>> = options

  return (
    <div
      className={twMerge(
        'relative border border-gray-100 bg-white rounded',
        className,
        isDisabled && 'border-gray-100',
      )}
    >
      <div
        className={twMerge(
          'absolute top-0.5 leading-none font-normal text-gray-500 text-xs left-2',
          isDisabled && 'text-gray-300',
        )}
      >
        {label}:
      </div>
      <ReactSelect
        isMulti
        closeMenuOnSelect={false}
        value={currentValue}
        menuIsOpen={isOpen}
        onMenuClose={() => setIsOpen(false)}
        onMenuOpen={() => setIsOpen(true)}
        unstyled
        isSearchable={false}
        placeholder={placeholder || '-'}
        options={selectOptions}
        onChange={(newValue) => onChange?.(newValue.map((item) => item.value))}
        isDisabled={isDisabled}
        hideSelectedOptions={false}
        classNames={{
          control: () => twMerge('!min-h-0 h-10 p-1 pl-2'),
          dropdownIndicator: () =>
            twMerge(
              'bg-indigo-100 border border-indigo-300 flex items-center justify-center text-blue-800 rounded-sm w-[32px] h-[32px] -mt-1',
              isOpen && 'bg-indigo-300 rotate-180',
              isDisabled && 'bg-gray-50 border-gray-200 text-gray-400',
            ),
          menu: () => twMerge('shadow-md bg-white rounded mt-1 !z-10 text-gray-800 py-3'),
          multiValue: ({}) => twMerge('!hidden'),
        }}
        components={{
          Option: CustomOption,
          ClearIndicator: CustomClearIndicator,
          ValueContainer: CustomValueContainer,
        }}
      />
    </div>
  )
}

const CustomOption: ComponentType<
  OptionProps<SelectProps['options'][0], true, GroupBase<SelectProps['options'][0]>>
> = ({ innerProps, children, isSelected }) => {
  return (
    <div className="py-1 px-5 flex items-center gap-2 cursor-pointer hover:bg-blue-50" {...innerProps}>
      <input
        type="checkbox"
        checked={isSelected}
        onChange={() => null}
        className="h-4 w-4 rounded border-gray-300 text-blue-800"
      />
      {children}
    </div>
  )
}

const CustomClearIndicator: ComponentType<
  ClearIndicatorProps<SelectProps['options'][0], true, GroupBase<SelectProps['options'][0]>>
> = ({ innerProps }) => {
  return (
    <div className="text-[#1E40AF] mb-1 mr-1" {...innerProps}>
      <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="close-circle"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
      </svg>
    </div>
  )
}

const CustomValueContainer: ComponentType<
  ValueContainerProps<SelectProps['options'][0], true, GroupBase<SelectProps['options'][0]>>
> = ({ innerProps, isDisabled, selectProps, children }) => {
  const values: SelectProps['options'] = Array.isArray(selectProps.value) ? selectProps.value : []
  const label = values.length > 1 ? `${values.length} selected` : values[0]?.label
  return (
    <div
      className={twMerge(
        'leading-none font-normal pr-2 text-gray-800 mt-[11px] text-base flex !flex-nowrap flex-row items-center gap-px',
        isDisabled && 'text-gray-500',
      )}
      {...innerProps}
    >
      {label}
      {children}
    </div>
  )
}
