import React, { useCallback, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { Pagination } from 'antd'

import { Input } from 'ui-v2'
import { TablePagination } from 'ui/Table'
import { HotelSummary } from 'types/domain/HotelSummary'
import { parseStringDate } from 'helpers/date'
import AccuracyTable from 'modules/DashboardModule/components/AccuracyTable'
import AccuracyTiles from 'modules/DashboardModule/components/AccuracyTiles'
import { Props as AccuracyTableProps } from 'modules/DashboardModule/components/AccuracyTable/AccuracyTable'

interface Props {
  title: string
  type: AccuracyTableProps['type']
  data: Array<{
    checkup_date?: string
    key?: number | string
    id: number | string
    name?: string
    summary: HotelSummary
    created_at?: string
    from_date?: string
    to_date?: string
  }>
  isFetching: boolean
  handleSelect: (itemId: number | string) => void
  onFiltersChange?: (filters: { [key: string]: string }) => void
  pagination?: TablePagination
  hotelId?: number
}

const AccuracyDetails: React.FC<Props> = ({
  title,
  type,
  data,
  handleSelect,
  isFetching,
  onFiltersChange,
  pagination,
  hotelId,
}) => {
  const [search, setSearch] = useState('')

  const tableItems = useMemo<AccuracyTableProps['items']>(
    () =>
      data.map((item) => ({
        key: item.id,
        name: item.name || '',
        ...item.summary,
        ...item,
      })),
    [data],
  )

  const tilesItems = useMemo(
    () =>
      data.map((item) => ({
        id: item.id,
        key: `${item.key}-${item.id}`,
        name: item.name || '',
        summary: item.summary,
        percent: item.summary.all,
        request_date: item.created_at ? parseStringDate(item.created_at).format('DD.MM.YYYY') : undefined,
        booking_date:
          item.from_date && item.to_date
            ? `${parseStringDate(item.from_date).format('DD.MM.YYYY')} - ${parseStringDate(item.to_date).format(
                'DD.MM.YYYY',
              )}`
            : undefined,
      })),
    [data],
  )

  const emitOnFiltersChange = useCallback(
    debounce((filters) => {
      onFiltersChange && onFiltersChange(filters)
    }, 500),
    [onFiltersChange],
  )

  return (
    <div className="p-6 rounded-lg bg-white border border-indigo-100">
      <h2 className="text-2xl leading-[18px] tracking-tight font-bold text-gray-800 mb-4">{title}</h2>

      {type === 'rates' && (
        <Input
          className="w-full mb-2 border-indigo-100"
          label="Search Booking Code"
          value={search}
          onChange={(value) => {
            setSearch(value || '')
            emitOnFiltersChange({ name: value })
          }}
        />
      )}

      {pagination && (
        <div className="mb-2">
          <Pagination
            current={pagination.page}
            total={pagination.totalItems}
            onChange={pagination.onChange}
            showSizeChanger={false}
            hideOnSinglePage
            pageSize={pagination.pageSize || 10}
          />
        </div>
      )}

      <div className="md:hidden">
        <AccuracyTiles items={tilesItems} handleSelect={handleSelect} />
      </div>

      <div className="hidden md:block">
        <AccuracyTable
          type={type}
          items={tableItems}
          handleSelect={handleSelect}
          isFetching={isFetching}
          hotelId={hotelId}
        />
      </div>
    </div>
  )
}

export default AccuracyDetails
