import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'

import { RootState } from 'store'
import { User, UserRoles, UserStatus } from '../../types'
import type { Error } from 'api'
import api from 'api'
import { BillingSettings, Employee, Employees, GdsCredentials, Profile } from './components'
import { notification } from 'helpers'
import { ActivationModal, Hotels } from 'modules/UsersModule/components'
import { useIsTravelAgent } from 'hooks'
import { Header, Tabs, Button } from 'ui-v2'
import GdsTab from './components/GdsTab/GdsTab'
import SubscriptionAndBillingTab from './components/SubscriptionAndBillingTab/SubscriptionAndBillingTab'

const ProfileContainer: React.FC = () => {
  const { userId, section } = useParams<{ userId: string; section: string }>()
  const history = useHistory()
  const profile = useSelector((state: RootState) => state.auth.profile)
  const [selectedEmployee, setSelectedEmployee] = useState<{
    id: number
    tab?: 'hotels' | 'employees' | 'user-role'
  } | null>(null)
  const [user, setUser] = useState<User>()
  const [isBillingSettingsVisible, setIsBillingSettingsVisible] = useState(false)
  const [isActivationModalVisible, setIsActivationModalVisible] = useState(false)
  const isTravelAgent = useIsTravelAgent()

  const tabs = [
    {
      key: 'profile',
      label: 'Profile',
    },
    ...(profile?.status === UserStatus.Unconfirmed
      ? []
      : [
          ...(profile?.is_admin || profile?.role === UserRoles.Owner || profile?.role === UserRoles.Manager
            ? [
                {
                  key: 'employees',
                  label: 'Employees',
                },
              ]
            : []),
          ...(profile?.is_admin
            ? [
                {
                  key: 'gds',
                  label: 'GDSs',
                },
              ]
            : []),
          ...(profile?.role !== UserRoles.TravelAgent && user?.role !== UserRoles.TravelAgent
            ? [
                {
                  key: 'hotels',
                  label: 'Hotels',
                },
              ]
            : []),
          ...(isTravelAgent
            ? [
                {
                  key: 'gds_credentials',
                  label: 'GDS Credentials',
                },
              ]
            : []),
          ...(profile?.role === UserRoles.Owner || profile?.role === UserRoles.TravelAgent || profile?.is_admin
            ? [
                {
                  key: 'billing',
                  label: 'Subscription & Billing',
                },
              ]
            : []),
        ]),
  ]

  const getTabKey = (tabKey: string) => tabs.find((tab) => tab.key === tabKey)?.key || tabs[0].key
  const [selectedTabKey, setSelectedTabKey] = useState(getTabKey(section))

  useEffect(() => {
    setSelectedTabKey(getTabKey(section))
  }, [section]) // eslint-disable-line

  useEffect(() => {
    const section = selectedTabKey
    history.push(userId ? `/admin/users/${userId}/${section}` : `/profile/${section}`)
  }, [selectedTabKey]) // eslint-disable-line

  const getUserById = (key: string, userId: number): Promise<{ data: User }> => api.get(`/users/${userId}`)
  const { isLoading: isUserLoading, refetch } = useQuery(
    ['user', userId],
    () => getUserById(['user', userId].join(','), parseInt(userId, 10)),
    {
      enabled: !!userId,
      onSuccess: ({ data }) => {
        setUser(data)
      },
      onError: (error: Error) => {
        notification.error({ message: error.message })
      },
    },
  )

  const userData = userId ? user : profile

  if (!!selectedEmployee && !!userData && !isTravelAgent) {
    return (
      <Employee
        id={selectedEmployee.id}
        supervisor={userData}
        onClose={(): void => setSelectedEmployee(null)}
        tab={selectedEmployee.tab}
        selectEmployee={(id, tab): void => setSelectedEmployee({ id, tab })}
      />
    )
  }

  if (isBillingSettingsVisible) {
    return <BillingSettings onClose={(): void => setIsBillingSettingsVisible(false)} />
  }

  if (isActivationModalVisible) {
    return <ActivationModal onClose={(): void => setIsActivationModalVisible(false)} user={userData} />
  }

  return (
    <div>
      <Header>
        <div className="flex items-center justify-between w-full">
          <h1 className="text-xl !leading-7 font-medium text-gray-800 m-0">
            Settings
            {user && (
              <span className="text-gray-500">
                : {user.first_name} {user.last_name}
              </span>
            )}
          </h1>
          {!!user && (
            <Button
              variant="secondary"
              onClick={(): void => history.push(`/admin/users/${user?.id}/dashboard?isTravelAgent=${isTravelAgent}`)}
            >
              User dashboard
            </Button>
          )}
        </div>
      </Header>

      <div className="mx-2 my-4 rounded-lg md:mx-7">
        <Tabs items={tabs} onChange={setSelectedTabKey} selectedKey={selectedTabKey} />

        <div className="relative p-2 bg-white md:p-6">
          {section === 'profile' && (
            <Profile
              user={userData}
              setUser={setUser}
              isProfile={!userId}
              isLoading={isUserLoading}
              showActivationModal={(): void => setIsActivationModalVisible(true)}
              onUserUpdated={() => {
                if (userId) {
                  refetch()
                }
              }}
            />
          )}
          {section === 'employees' && !!userData && (
            <Employees selectEmployee={(id, tab): void => setSelectedEmployee({ id, tab })} user={userData} />
          )}
          {section === 'hotels' && !!userData && <Hotels user={userData} setUser={setUser} />}
          {section === 'billing' && !!userData && (
            <SubscriptionAndBillingTab
              isTravelAgency={isTravelAgent}
              openPaymentSettingModal={() => setIsBillingSettingsVisible(true)}
            />
          )}
          {section === 'gds' && !!userData && profile?.is_admin && <GdsTab userId={userId} />}
          {section === 'gds_credentials' && !!userData && <GdsCredentials userGds={profile?.gds} />}
        </div>
      </div>
    </div>
  )
}

export default ProfileContainer
