import React from 'react'

import { Header as UiHeader } from 'ui'

import styles from './NoData.module.scss'
import image from 'assets/hotel.svg'

type Props = {
  title: string
  children?: JSX.Element
  noSubtitle?: boolean
}

const NoData: React.FC<Props> = ({ title, children, noSubtitle }) => {
  return (
    <div className={styles.hasNoData}>
      <img className={styles.image} src={image} alt="image" />
      <UiHeader withoutLine>{title}</UiHeader>
      {!noSubtitle &&
        (children ? (
          children
        ) : (
          <div className={styles.text}>Try selecting different dates/properties and reload rates.</div>
        ))}
    </div>
  )
}

export default NoData
