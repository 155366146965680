import React, { useCallback, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import { Button, Input, Select } from 'ui-v2'
import { allCountriesWithCodes } from 'constants/countries'
import { ApiError } from 'api/types/ApiError'

export interface HotelSearchParams {
  property_id?: string
  name?: string
  city?: string
  country?: string
  chain_code?: string
}

interface ParamsProps {
  onFiltersChange: (values: HotelSearchParams) => void
  errors: ApiError | null
  isLoading?: boolean
}

const Params = ({ errors, onFiltersChange, isLoading }: ParamsProps) => {
  const [areFiltersExpanded, setAreFiltersExpanded] = useState(false)
  const [name, setName] = useState<string>('')
  const [country, setCountry] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [chainCode, setChainCode] = useState<string>('')
  const [propertyCode, setPropertyCode] = useState<string>('')
  const [countrySelectOptions, setCountrySelectOptions] = useState<
    {
      value: string
      label: string
    }[]
  >(allCountriesWithCodes)

  const handleSearch = useCallback(() => {
    onFiltersChange({ name, country, city, property_id: propertyCode, chain_code: chainCode })
  }, [onFiltersChange, name, country, city, propertyCode, chainCode])

  useEffect(() => {
    const listener = (e: { code: string }) => e.code === 'Enter' && handleSearch()
    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [handleSearch])

  return (
    <>
      <div className="p-4 bg-bg-accent-7 flex flex-col gap-3 md:px-7 lg:flex-row lg:items-start">
        <div className={twMerge('lg:hidden', areFiltersExpanded && 'mb-4')}>
          <div className="flex items-start justify-between gap-2 mb-2">
            <Input
              className="flex-1"
              label="Hotel name"
              value={name}
              onChange={(value) => setName(value || '')}
              error={errors?.errors?.name}
            />

            <Input
              className="w-28"
              label="Property ID"
              value={propertyCode}
              onChange={(value) => setPropertyCode(value || '')}
              error={errors?.errors?.property_id}
            />

            <Button
              isLoading={isLoading}
              className="w-10 rounded p-0 flex items-center justify-center"
              onClick={handleSearch}
              size="large"
              variant="primary"
              isDisabled={areFiltersExpanded}
            >
              <MagnifyingGlassIcon className={twMerge('text-white w-5', areFiltersExpanded && 'text-gray-400')} />
            </Button>
          </div>

          <button
            className="bg-transparent border-none text-sm leading-4 font-medium text-blue-800"
            onClick={() => setAreFiltersExpanded((prevState) => !prevState)}
          >
            {areFiltersExpanded ? 'Less' : 'More'} filters
          </button>
        </div>

        <Input
          className="hidden lg:block lg:flex-1 lg:min-w-40"
          label="Hotel name"
          value={name}
          onChange={(value) => setName(value || '')}
          error={errors?.errors?.name}
        />

        <Input
          className={twMerge('lg:block lg:w-44')}
          value={city}
          onChange={(value) => setCity(value || '')}
          label="City"
          error={errors?.errors?.city}
        />

        <Input
          className="hidden lg:block lg:w-40"
          label="Property ID"
          value={propertyCode}
          onChange={(value) => setPropertyCode(value || '')}
          error={errors?.errors?.property_id}
        />

        <Button
          className={twMerge('lg:block lg:w-auto')}
          onClick={handleSearch}
          isLoading={isLoading}
          variant="primary"
          size="large"
        >
          Search
        </Button>

        <button
          className="bg-transparent border-none text-sm leading-4 font-medium text-blue-800"
          onClick={() => setAreFiltersExpanded((prevState) => !prevState)}
        >
          {areFiltersExpanded ? 'Less' : 'More'} filters
        </button>
      </div>
      {areFiltersExpanded && (
        <div className="p-4 bg-bg-accent-7 flex flex-col gap-3 md:px-7 lg:flex-row lg:items-start">
          <Select
            className={twMerge('lg:block lg:w-60')}
            label="Country"
            value={country || undefined}
            options={countrySelectOptions}
            onChange={(value) => setCountry(value || '')}
            error={errors?.errors?.country}
            isSearchable
            isClearable
          />

          <Input
            className={twMerge('lg:block lg:w-44')}
            value={chainCode}
            onChange={(value) => setChainCode(value || '')}
            label="Chain"
            error={errors?.errors?.chain_code}
          />
        </div>
      )}
    </>
  )
}

export default Params
