import { useQuery, QueryKey, UseQueryOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { GdsListItem, GdsRateCategoryListItem } from 'types/domain/GdsListItem'

import { GDS_ENDPOINT_KEY } from './constants'

type GetGdsListResourceResponse = {
  data: Array<GdsListItem>
}

type GetGdsRateCategoryListResourceResponse = {
  data: GdsRateCategoryListItem[]
}

export const getGdsListResource = (): Promise<GetGdsListResourceResponse> => api.get('/gds')

export const useGetGdsListResource = ({
  queryOptions,
  customQueryKey,
}: {
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getGdsListResource>>, ApiError>
  customQueryKey?: QueryKey
} = {}) => useQuery(customQueryKey || [GDS_ENDPOINT_KEY], getGdsListResource, queryOptions)

export const getGdsRateCategoryList = (): Promise<GetGdsRateCategoryListResourceResponse> =>
  api.get('/gds/rate-categories')

export const useGetGdsRateCategoryListResource = ({
  queryOptions,
  customQueryKey,
}: {
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getGdsRateCategoryList>>, ApiError>
  customQueryKey?: QueryKey
} = {}) => useQuery(customQueryKey || ['gds-rate-category'], getGdsRateCategoryList, queryOptions)
