import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FormInstance } from 'antd/lib/form'
import { useMutation } from 'react-query'
import { PencilIcon } from '@heroicons/react/24/solid'
import { twMerge } from 'tailwind-merge'

import { getCxFromStyles } from 'helpers'
import api from 'api'
import type { Error } from 'api'
import { setProfile } from 'modules/AuthModule/slices/auth'
import { UserStatus, User } from 'modules/UsersModule/types'
import { notification } from 'helpers'
import { Button as ButtonA } from 'ui'
import { ArrowRightAlt, Close } from '@material-ui/icons'
import { About, AccountType, Email, General, Password, Status } from './components'
import { Button } from 'ui-v2'

import styles from './Account.module.scss'

type Props = {
  user?: User
  isProfile?: boolean
  showActivationModal: () => void
  onUserUpdated: () => void
}

const Account: React.FC<Props> = ({ user, isProfile, showActivationModal, onUserUpdated }) => {
  const cx = getCxFromStyles(styles)
  const dispatch = useDispatch()
  const [editedSection, setEditedSection] = useState<string>()

  const {
    mutate: changePassword,
    isLoading: isChangingPassword,
    error: changePasswordError,
  } = useMutation(
    async (data: { old_password: string; password: string; password_confirmation: string }): Promise<void> =>
      api.post('/change-password', data),
    {
      onSuccess: () => {
        setEditedSection(undefined)
        notification.success({ message: 'Password has been changed' })
      },
      onError: (error: Error) => {
        notification.error({ message: error.message })
      },
    },
  )

  const {
    mutate: saveUser,
    isLoading: isSavingUser,
    error: saveUserError,
  } = useMutation(
    async (data: Partial<User>): Promise<{ data: User }> =>
      api.patch(isProfile ? '/profile' : `/users/${user?.id}`, data),
    {
      onSuccess: async ({ data }) => {
        isProfile && dispatch(setProfile(data))
        setEditedSection(undefined)
        notification.success({ message: `${isProfile ? 'Profile' : 'User'} has been saved` })
        onUserUpdated()
      },
      onError: (error: Error) => {
        notification.error({ message: error.message })
      },
    },
  )

  const handleSubmit = (values: { [key: string]: unknown }): void => {
    saveUser({
      status: values.status as UserStatus,
      first_name: values.first_name as string,
      last_name: values.last_name as string,
      phone: values.phone as string,
      email: values.email as string,
      about:
        values.responsibility || values.hotel || values.city || values.travel_agency
          ? {
              responsibility: values.responsibility as string,
              hotel: values.hotel as string,
              city: values.city as string,
              travel_agency: values.travel_agency as string,
            }
          : undefined,
    })
  }

  const { mutate: sendForgotPassword, isLoading: isForgotPasswordLoading } = useMutation(
    async (): Promise<void> => api.post('/forgot-password', { email: user?.email }),
    {
      onSuccess: async () => {
        notification.success({ message: 'Email has been sent' })
      },
      onError: (error: Error) => {
        notification.error({ message: error.message })
      },
    },
  )

  const renderActions = (form: FormInstance): JSX.Element => (
    <div className={cx('actions')}>
      <ButtonA
        large
        className={cx('cancelButton')}
        onClick={(): void => {
          form.resetFields()
          setEditedSection(undefined)
        }}
      >
        <span>Cancel</span>
        <Close fontSize="small" />
      </ButtonA>

      <ButtonA
        primary
        large
        loading={isSavingUser || isChangingPassword}
        className={cx('saveButton')}
        onClick={form.submit}
      >
        <span>Save</span>
        <ArrowRightAlt fontSize="small" />
      </ButtonA>
    </div>
  )

  return (
    <div className="flex flex-col gap-2 md:gap-6 lg:flex-row">
      {!isProfile && (
        <Status
          user={user}
          saveUserError={saveUserError}
          handleSubmit={handleSubmit}
          renderActions={renderActions}
          edit={(): void => setEditedSection('status')}
          isEdited={editedSection === 'status'}
          showActivationModal={showActivationModal}
        />
      )}

      <div className="w-full flex flex-col gap-2 md:gap-6">
        <div className="p-2 bg-bg-light rounded-md md:p-6">
          <AccountType user={user} />
        </div>

        <div
          className={twMerge(
            'p-2 bg-bg-light rounded-md flex flex-col gap-2 md:gap-4 md:p-6',
            editedSection === 'general' && 'border border-indigo-300 shadow-xl',
          )}
        >
          {editedSection !== 'general' && (
            <div className="text-right">
              <Button onClick={() => setEditedSection('general')}>
                <span className="inline-flex items-center gap-2">
                  Edit <PencilIcon className="w-4" />
                </span>
              </Button>
            </div>
          )}

          <General
            user={user}
            saveUserError={saveUserError}
            onSubmit={handleSubmit}
            onCancel={() => setEditedSection(undefined)}
            isEdited={editedSection === 'general'}
            isSaving={isSavingUser}
          />
        </div>
      </div>

      <div className="w-full flex flex-col gap-2 md:gap-6">
        <div
          className={twMerge(
            'p-2 bg-bg-light rounded-md flex flex-col gap-2 md:gap-4 md:p-6',
            editedSection === 'email' && 'border border-indigo-300 shadow-xl',
          )}
        >
          {editedSection !== 'email' && (
            <div className="text-right">
              <Button onClick={() => setEditedSection('email')}>
                <span className="inline-flex items-center gap-2">
                  Edit <PencilIcon className="w-4" />
                </span>
              </Button>
            </div>
          )}

          <Email
            user={user}
            saveUserError={saveUserError}
            onSubmit={handleSubmit}
            onCancel={() => setEditedSection(undefined)}
            isEdited={editedSection === 'email'}
            isSaving={isSavingUser}
          />
        </div>

        <div
          className={twMerge(
            'p-2 bg-bg-light rounded-md flex flex-col gap-2 md:gap-4 md:p-6',
            editedSection === 'about' && 'border border-indigo-300 shadow-xl',
          )}
        >
          {editedSection !== 'about' && (
            <div className="text-right">
              <Button onClick={() => setEditedSection('about')}>
                <span className="inline-flex items-center gap-2">
                  Edit <PencilIcon className="w-4" />
                </span>
              </Button>
            </div>
          )}

          <About
            user={user}
            saveUserError={saveUserError}
            onSubmit={handleSubmit}
            onCancel={() => setEditedSection(undefined)}
            isEdited={editedSection === 'about'}
            isSaving={isSavingUser}
          />
        </div>

        <div
          className={twMerge(
            'p-2 bg-bg-light rounded-md flex flex-col gap-2 md:gap-4 md:p-6',
            editedSection === 'password' && 'border border-indigo-300 shadow-xl',
          )}
        >
          {isProfile ? (
            <>
              {editedSection !== 'password' && (
                <div className="text-right">
                  <Button onClick={() => setEditedSection('password')}>
                    <span className="inline-flex items-center gap-2">
                      Edit <PencilIcon className="w-4" />
                    </span>
                  </Button>
                </div>
              )}

              <Password
                changePasswordError={changePasswordError}
                changePassword={changePassword}
                onCancel={() => setEditedSection(undefined)}
                isEdited={editedSection === 'password'}
                isSaving={isChangingPassword}
              />
            </>
          ) : (
            <Button
              isLoading={isForgotPasswordLoading}
              onClick={sendForgotPassword}
              className="w-full"
              variant="primary"
            >
              Send password reset link
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Account
