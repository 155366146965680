import React, { useMemo } from 'react'
import classNames from 'classnames'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { Select } from 'ui'
import { ISSUE_STATUS_KEYS, ISSUE_STATUSES } from 'constants/issuesStatuses'
import { useCloseIssue } from 'api/issues/closeIssue'
import { notification } from 'helpers'

import styles from './StatusSelect.module.scss'
import './Select.scss'

interface IProps {
  status: ISSUE_STATUS_KEYS
  issueId: number
  onStatusChanged: () => void
}

const EDITABLE_STATUSES = [ISSUE_STATUS_KEYS.IN_PROGRESS]
const EDITABLE_STATUSES_FOR_ADMIN = [ISSUE_STATUS_KEYS.IN_PROGRESS, ISSUE_STATUS_KEYS.OPEN]

const StatusSelect = ({ status, issueId, onStatusChanged }: IProps) => {
  const isAdmin = location.pathname.includes('admin')

  const { mutate: closeIssue, isLoading } = useCloseIssue({
    onSuccess: () => {
      notification.success({
        message: 'Issue has been closed',
      })
      onStatusChanged()
    },
    onError: (error) =>
      notification.error({
        message: error.message,
      }),
  })

  const editableStatuses = useMemo(() => (isAdmin ? EDITABLE_STATUSES_FOR_ADMIN : EDITABLE_STATUSES), [isAdmin])

  return (
    <div className={styles.root}>
      <Select
        options={editableStatuses.includes(status) ? [ISSUE_STATUSES[ISSUE_STATUS_KEYS.CLOSED]] : []}
        value={ISSUE_STATUSES[status].label}
        className={classNames([
          styles.select,
          styles[ISSUE_STATUSES[status].className],
          { [styles.disabled]: !editableStatuses.includes(status) },
        ])}
        dropdownClassName={classNames([!editableStatuses.includes(status) ? styles.disabled : '', styles.dropdown])}
        onChange={() => closeIssue(issueId)}
        isLoading={isLoading}
        disabled={!editableStatuses.includes(status)}
        isStatusSelect
      />
      <div className={styles.loaderWrapper}>
        <Spin spinning={isLoading} indicator={<LoadingOutlined className={styles.loader} spin />} />
      </div>
    </div>
  )
}

export default StatusSelect
