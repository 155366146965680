import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { ReportedIssue as Issue } from 'types/domain/ReportedIssue'

import { ISSUES_ENDPOINT_KEY } from './constants'
import { RequestHeadersWithGdsScope } from '../types/RequestHeadersWithGdsScope'

type GetIssueResourceRequestParams = {
  id: number
}

type GetIssueResourceResponse = {
  data: Issue
}

export const getIssueResource = ({
  params,
  headers,
}: {
  params: GetIssueResourceRequestParams
  headers?: RequestHeadersWithGdsScope
}): Promise<GetIssueResourceResponse> => api.get(`/${ISSUES_ENDPOINT_KEY}/${params.id}`, { headers })

export const useGetIssueResource = ({
  params,
  headers,
  queryOptions,
  customQueryKey,
}: {
  params: GetIssueResourceRequestParams
  headers?: RequestHeadersWithGdsScope
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getIssueResource>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || [ISSUES_ENDPOINT_KEY, ...Object.values(params || {}), ...Object.values(headers || {})],
    () => getIssueResource({ params, headers }),
    queryOptions,
  )
