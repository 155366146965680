import React, { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Header, Tabs } from 'ui-v2'
import { useGetUserListResource } from 'api/users/getUserListResource'
import { ACCOUNT_TYPE_KEYS } from 'constants/accounts'
import { UserListQueryParams } from 'modules/UserModule/types'
import { ApiError } from 'api/types/ApiError'

import { ListContainer, TrialInvitations } from './components'
import { Params } from './components/List/components'

const TABS = [
  {
    key: 'property_owners',
    label: 'Hotels',
  },
  {
    key: 'travel_agencies',
    label: 'Travel Agencies',
  },
  {
    key: 'trial_invitations',
    label: 'Trial Invitations',
  },
]

const UserManagementContainer: React.FC = () => {
  const urlQuery = new URLSearchParams(useLocation().search)
  const [selectedTabKey, setSelectedTabKey] = useState(urlQuery.get('tab') || TABS[0].key)
  const [error, setError] = useState<ApiError | null>(null)

  const initialParams: UserListQueryParams = useMemo(
    () => ({
      page: 1,
      length: 25,
      sort_by: 'created_at',
      sort_direction: 'desc',
      filters: {},
    }),
    [],
  )

  const [params, setParams] = useState<UserListQueryParams>(initialParams)

  const handleSetPage = useCallback((page): void => setParams((old) => ({ ...old, page })), [])

  const { data } = useGetUserListResource({
    query: { page: 1, length: 1, sort_by: 'created_at', sort_direction: 'desc', filters: {} },
  })

  return (
    <div>
      <Header title="User Management" />

      {data && selectedTabKey !== 'trial_invitations' && (
        <div className="lg:hidden px-4 pt-4 text-right text-base leading-none font-normal text-gray-500">
          Total number of accounts: {data?.meta.total}
        </div>
      )}

      <Params error={error} onFiltersChange={(filters): void => setParams((old) => ({ ...old, filters, page: 1 }))} />

      <div className="mx-2 my-4 rounded-lg md:mx-7">
        <div className="flex items-center justify-between">
          <Tabs
            items={TABS}
            selectedKey={selectedTabKey}
            onChange={(tabKey) => {
              setSelectedTabKey(tabKey)
              handleSetPage(1)
            }}
          />
          {data && selectedTabKey !== 'trial_invitations' && (
            <div className="hidden lg:block px-4 pt-4 text-right text-base leading-none font-normal text-gray-500">
              Total number of accounts: {data?.meta.total}
            </div>
          )}
        </div>

        <div className="relative py-2 bg-white md:py-6">
          {selectedTabKey === 'property_owners' && (
            <ListContainer
              accountType={ACCOUNT_TYPE_KEYS.PROPERTY_OWNER}
              params={params}
              onError={setError}
              setParams={setParams}
              setPage={handleSetPage}
            />
          )}
          {selectedTabKey === 'travel_agencies' && (
            <ListContainer
              accountType={ACCOUNT_TYPE_KEYS.TRAVEL_AGENT}
              params={params}
              onError={setError}
              setParams={setParams}
              setPage={handleSetPage}
            />
          )}
          {selectedTabKey === 'trial_invitations' && (
            <TrialInvitations params={params} onError={setError} setParams={setParams} setPage={handleSetPage} />
          )}
        </div>
      </div>
    </div>
  )
}

export default UserManagementContainer
