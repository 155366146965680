import { useMutation, UseMutationOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'

type CreateHotelFromCandidateRequestParams = {
  hotel_candidate: number
}

const createHotelFromCandidate = ({ params }: { params: CreateHotelFromCandidateRequestParams }): Promise<never> =>
  api.post(`/hotel-candidate/${params.hotel_candidate}`, {})

export const useCreateHotelFromCandidate = ({
  params,
  queryOptions,
}: {
  params: CreateHotelFromCandidateRequestParams
  queryOptions?: UseMutationOptions<Awaited<ReturnType<typeof createHotelFromCandidate>>, ApiError>
}) => useMutation(() => createHotelFromCandidate({ params }), queryOptions)
