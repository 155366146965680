import React from 'react'
import { twMerge } from 'tailwind-merge'

type TabsProps = {
  items: {
    key: string
    label: string
  }[]
  onChange: (key: string) => void
  selectedKey: string
  className?: string
}

export const Tabs = ({ items, onChange, selectedKey, className = '' }: TabsProps) => (
  <div className={twMerge('flex items-center justify-start overflow-x-auto pt-4', className)}>
    {items.map((tab) => (
      <button
        key={tab.key}
        onClick={() => onChange(tab.key)}
        className={twMerge(
          'border-none bg-transparent h-[55px] rounded-t-lg',
          tab.key === selectedKey && 'bg-white shadow-[0_-3px_3px_0_rgba(39,58,195,0.08)]',
        )}
      >
        <span className="text-lg leading-8 font-semibold text-gray-700 whitespace-nowrap">{tab.label}</span>
      </button>
    ))}
  </div>
)
