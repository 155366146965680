import React from 'react'
import { Field, FieldAttributes } from 'formik'
import { Checkbox } from 'antd'

import styles from './FormikCheckbox.module.scss'

type Props = {
  name: string
  label: string
}

const FormikCheckbox: React.FC<Props> = ({ name, label }) => {
  return (
    <Field name={name}>
      {({ field: { value }, form: { setFieldValue } }: FieldAttributes<any>) => {
        return (
          <div className={styles.root}>
            <Checkbox
              checked={value}
              onChange={(event): void => {
                setFieldValue(name, event.target.checked)
              }}
            >
              {label}
            </Checkbox>
          </div>
        )
      }}
    </Field>
  )
}

export default FormikCheckbox
