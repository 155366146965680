import React, { useCallback, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { TrashIcon } from '@heroicons/react/16/solid'
import { PencilIcon } from '@heroicons/react/24/solid'
import { ArrowLongRightIcon } from '@heroicons/react/20/solid'

import { ListUser, User, UserRoles, UserStatus } from 'modules/UsersModule/types'
import { Button, Select, Checkbox } from 'ui-v2'

type Props = {
  user: User
  supervisor: User
  managers: ListUser[]
  updateUser: (data: { status: UserStatus; role?: UserRoles; user_id?: number }) => void
  isUserUpdating: boolean
  isUpdatingSuccess: boolean
}

const Settings: React.FC<Props> = ({ user, supervisor, managers, updateUser, isUserUpdating, isUpdatingSuccess }) => {
  const [isEdit, setIsEdit] = useState(false)
  const [role, setRole] = useState<UserRoles>(user.role)
  const [isNewManagerChecked, setIsNewManagerChecked] = useState(false)
  const [status, setStatus] = useState<UserStatus>(user.status)
  const [isDeactivateChecked, setIsDeactivateChecked] = useState(false)
  const [managerId, setManagerId] = useState<number>(user.user_id || supervisor.id)

  const reset = useCallback((): void => {
    setIsEdit(false)
    setRole(user.role)
    setStatus(user.status)
    setManagerId(user.user_id || supervisor.id)
    setIsNewManagerChecked(false)
    setIsDeactivateChecked(false)
  }, [user, supervisor])

  const save = (): void =>
    updateUser({
      status,
      role,
      ...(supervisor.role === UserRoles.Owner
        ? { user_id: role === UserRoles.Employee ? managerId : supervisor.id }
        : {}),
    })

  useEffect(() => {
    isUpdatingSuccess && reset()
  }, [isUpdatingSuccess, reset])

  const onIsNewManagerChange = (isChecked: boolean): void => {
    setIsNewManagerChecked(isChecked)
    !isChecked && setManagerId(supervisor.id)
  }

  return (
    <div className="flex flex-col gap-2 md:gap-6 lg:flex-row">
      <div className="w-full flex flex-col gap-2 md:gap-6">
        <div
          className={twMerge(
            'p-2 bg-bg-light rounded-md flex flex-col gap-2 md:gap-4 md:p-6 lg:w-1/2',
            isEdit && 'border border-indigo-300 shadow-xl',
          )}
        >
          {!isEdit && (
            <div className="text-right">
              <Button onClick={() => setIsEdit(true)}>
                <span className="inline-flex items-center gap-2">
                  Edit <PencilIcon className="w-4" />
                </span>
              </Button>
            </div>
          )}

          {supervisor.role === UserRoles.Owner && (
            <>
              <div>
                <Select
                  label="Role"
                  className="mb-1"
                  isDisabled={!isEdit}
                  value={role}
                  onChange={(role): void => setRole(role as UserRoles)}
                  options={[
                    { value: UserRoles.Employee, label: 'Employee' },
                    { value: UserRoles.Manager, label: 'Manager' },
                  ]}
                />

                {user.role === UserRoles.Manager && role === UserRoles.Employee && (
                  <label className="flex gap-2 items-center text-gray-800">
                    <Checkbox
                      isChecked={isNewManagerChecked}
                      onChange={(isChecked) => onIsNewManagerChange(isChecked)}
                    />
                    Add new Manager for employees
                  </label>
                )}
              </div>

              {role === UserRoles.Employee && (user.role === UserRoles.Employee || isNewManagerChecked) && (
                <Select
                  label="Manager"
                  isDisabled={!isEdit}
                  value={managerId.toString()}
                  onChange={(managerId): void => {
                    managerId && setManagerId(parseInt(managerId))
                  }}
                  options={[
                    {
                      value: supervisor.id.toString(),
                      label: `${supervisor.first_name} ${supervisor.last_name} (owner)`,
                    },
                    ...managers.map((manager) => ({ value: manager.id.toString(), label: manager.name })),
                  ]}
                />
              )}
            </>
          )}

          <div>
            <Select
              label="Status"
              isDisabled={!isEdit}
              value={status}
              className="mb-1"
              onChange={(status): void => setStatus(status as UserStatus)}
              options={[
                { value: UserStatus.Active, label: 'Active' },
                {
                  value: UserStatus.Inactive,
                  label: user.status === UserStatus.Active ? 'Deactivate user' : 'Inactive',
                },
              ]}
            />

            {user.status === UserStatus.Active && status === UserStatus.Inactive && (
              <label className="flex gap-2 items-center text-gray-800 leading-none">
                <Checkbox
                  isChecked={isDeactivateChecked}
                  onChange={(isChecked): void => setIsDeactivateChecked(isChecked)}
                />
                Are you sure you want to deactivate the user?
              </label>
            )}
          </div>

          {isEdit && (
            <div className="flex items-center justify-end gap-2">
              <Button onClick={reset}>
                <span className="inline-flex items-center gap-2">
                  Cancel <TrashIcon className="w-4" />
                </span>
              </Button>

              <Button
                variant="primary"
                isDisabled={user.status === UserStatus.Active && status === UserStatus.Inactive && !isDeactivateChecked}
                isLoading={isUserUpdating}
                onClick={save}
              >
                <span className="inline-flex items-center gap-2">
                  Save <ArrowLongRightIcon className="w-5" />
                </span>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Settings
