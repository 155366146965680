import { Route } from './types/Route'

import authRoutes from '../modules/AuthModule/routes'
import dashboardRoutes from '../modules/DashboardModule/routes'
import hotelsRoutes from '../modules/HotelsModule/routes'
import usersRoutes from '../modules/UsersModule/routes'
import statisticsRoutes from '../modules/StatisticsModule/routes'
import adminRoutes from 'modules/AdminModule/routes'
import demosRoutes from 'modules/DemosModule/routes'
import searchRoutes from 'modules/SearchModule/routes'
import reportedIssuesRoutes from 'modules/ReportedIssuesModule/routes'

const routes: Route[] = [
  ...authRoutes,
  ...dashboardRoutes,
  ...searchRoutes,
  ...adminRoutes,
  ...hotelsRoutes,
  ...usersRoutes,
  ...statisticsRoutes,
  ...demosRoutes,
  ...reportedIssuesRoutes
]

export default routes
