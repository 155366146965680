import React, { useState } from 'react'
import { Field, FieldAttributes, useFormikContext } from 'formik'

import states from './states'
import { allCountries } from 'constants/countries'
import { Input, Select } from 'ui-v2'

const allStates = states.map((state) => ({
  value: state.abbreviation,
  label: state.abbreviation,
}))

interface Props {
  isCountryUnitedStates: boolean
  fieldNamePrefix?: string
  disabledFields?: boolean
}

const BillingContactFormFields: React.FC<Props> = ({ isCountryUnitedStates, fieldNamePrefix = '', disabledFields }) => {
  const { errors } = useFormikContext<Record<string, string>>()

  const [countryOptions, setCountryOptions] = useState(allCountries)
  const [stateOptions, setStateOptions] = useState(allStates)

  const onCountrySearch = (value: string): void => {
    setCountryOptions(
      !value
        ? allCountries
        : allCountries.filter((country) => country.label.toUpperCase().includes(value.toUpperCase())),
    )
  }

  const onStateSearch = (value: string): void => {
    setStateOptions(
      !value ? allStates : allStates.filter((state) => state.label.toLowerCase().includes(value.toLowerCase())),
    )
  }

  return (
    <div className="flex flex-col gap-2 md:gap-4">
      <div className="w-full flex flex-col gap-2 md:gap-4 xl:flex-row">
        <Field name={`${fieldNamePrefix}company`}>
          {({ field: { value, name }, form: { setFieldValue }, meta }: FieldAttributes<any>) => {
            return (
              <Input
                value={value}
                label="Company name:"
                isDisabled={disabledFields}
                onChange={(value) => setFieldValue(name, value)}
                error={meta.error}
                className="w-full"
              />
            )
          }}
        </Field>

        <Field name={`${fieldNamePrefix}address`}>
          {({ field: { value, name }, form: { setFieldValue }, meta }: FieldAttributes<any>) => {
            return (
              <Input
                value={value}
                label="Street address:"
                isDisabled={disabledFields}
                onChange={(value) => setFieldValue(name, value)}
                error={meta.error}
                className="w-full"
              />
            )
          }}
        </Field>
      </div>

      <div className="w-full flex flex-col gap-2 md:gap-4 xl:flex-row">
        <Field name={`${fieldNamePrefix}country`}>
          {({ field: { value, name }, form: { setFieldValue }, meta }: FieldAttributes<any>) => {
            return (
              <Select
                options={countryOptions}
                label="Country"
                isSearchable
                isDisabled={disabledFields}
                onChange={(country) => setFieldValue(name, { id: country })}
                value={value.id}
                error={meta.error}
                className="w-full"
              />
            )
          }}
        </Field>

        {isCountryUnitedStates ? (
          <Field name={`${fieldNamePrefix}stateUs`}>
            {({ field: { value, name }, form: { setFieldValue }, meta }: FieldAttributes<any>) => {
              return (
                <Select
                  options={stateOptions}
                  label="State:"
                  isSearchable
                  isClearable
                  isDisabled={disabledFields}
                  onChange={(state) => setFieldValue(name, { id: state })}
                  value={value.id}
                  error={errors.state}
                  className="w-full"
                />
              )
            }}
          </Field>
        ) : (
          <Field name={`${fieldNamePrefix}state`}>
            {({ field: { value, name }, form: { setFieldValue }, meta }: FieldAttributes<any>) => {
              return (
                <Input
                  value={value}
                  label="State:"
                  isDisabled={disabledFields}
                  onChange={(value) => setFieldValue(name, value)}
                  error={meta.error}
                  className="w-full"
                />
              )
            }}
          </Field>
        )}
      </div>

      <div className="w-full flex flex-col gap-2 md:gap-4 xl:flex-row">
        <Field name={`${fieldNamePrefix}city`}>
          {({ field: { value, name }, form: { setFieldValue }, meta }: FieldAttributes<any>) => {
            return (
              <Input
                value={value}
                label="City:"
                isDisabled={disabledFields}
                onChange={(value) => setFieldValue(name, value)}
                error={meta.error}
                className="w-full"
              />
            )
          }}
        </Field>

        <Field name={`${fieldNamePrefix}post_code`}>
          {({ field: { value, name }, form: { setFieldValue }, meta }: FieldAttributes<any>) => {
            return (
              <Input
                value={value}
                label="ZIP/Postal code:"
                isDisabled={disabledFields}
                onChange={(value) => setFieldValue(name, value)}
                error={meta.error}
                className="w-full"
              />
            )
          }}
        </Field>
      </div>

      <Field name={`${fieldNamePrefix}vat_id`}>
        {({ field: { value, name }, form: { setFieldValue }, meta }: FieldAttributes<any>) => {
          return (
            <Input
              value={value}
              label="VAT ID (optional):"
              isDisabled={disabledFields}
              onChange={(value) => setFieldValue(name, value)}
              error={meta.error}
              className="w-full"
            />
          )
        }}
      </Field>
    </div>
  )
}

export default BillingContactFormFields
