import React, { useState } from 'react'
import { default as DemoComponent } from 'modules/DemosModule/components/Demo'
import { Close } from '@material-ui/icons'
import qs from 'qs'
import { useHistory } from 'react-router-dom'

import { PageWrapper } from 'ui'
import { Header } from 'ui-v2'
import { useGetHotelListResource } from 'api/hotels/getHotelListResource'
import { Demo } from 'types/domain/Demo'
import { DEMO_LINKS_URL } from '../../const'

import styles from './DemoContainer.module.scss'

const DemoContainer: React.FC = () => {
  const { push: routerPush, location } = useHistory()
  const [hotelsFilter, setHotelsFilter] = useState<string>('')
  const { data, isFetching } = useGetHotelListResource({
    query: {
      name: hotelsFilter,
    },
  })

  const getInitialDemoIfEdit = () => {
    const parsedQs = location.pathname.includes('edit') ? qs.parse(location.search?.slice(1)) : null

    return parsedQs
      ? {
          id: parsedQs.id,
          name: parsedQs.name,
          hotel: { id: parsedQs.hotel_id, name: parsedQs.hotel_name },
          deadline_at: parsedQs.deadline_at,
          queries: parsedQs.queries,
          gds_ids: parsedQs.gds_ids,
        }
      : null
  }

  const navigateToDemoList = () => routerPush(DEMO_LINKS_URL)

  const initialDemo = getInitialDemoIfEdit()
  const hotels = data?.data || []

  return (
    <>
      <div className="px-[30px] py-6 flex justify-between mb-8 border-b border-indigo-100 bg-white">
        <Header size="big" title={`${initialDemo ? 'Edit' : 'Add'} Demo`} />
        <div className={styles.close} onClick={navigateToDemoList}>
          <Close />
        </div>
      </div>
      <PageWrapper>
        <DemoComponent
          initialData={initialDemo as Partial<Demo>}
          hotels={hotels}
          onHotelSearch={(hotel) => {
            setHotelsFilter(hotel)
          }}
          isHotelListLoading={isFetching}
          onCancel={navigateToDemoList}
          onSuccess={navigateToDemoList}
        />
      </PageWrapper>
    </>
  )
}

export default DemoContainer
