import { useQuery } from 'react-query'

import api from 'api'
import type { Error, Meta } from 'api'
import { RequestHeadersWithGdsScope } from 'api/types/RequestHeadersWithGdsScope'
import StatisticsItem from 'modules/StatisticsModule/types/StatisticsItem'
import { notification } from 'helpers'

export type QueryParams = {
  page: number
  length: number
  from: string
  to: string
  owner_id?: number
  account_type?: 'hotel' | 'travel_agency'
}

export type ListMeta = Meta & {
  tiles: {
    gds_queries: number
    gds_queries_per_user?: number
    gds_queries_per_hotel?: number
    hotel_searches: number
    rd_queries: number
    rr_queries: number
    copied_links: number
    issues: number
  }
}

type ReturnType = {
  data?: StatisticsItem[]
  meta?: ListMeta
  isLoading: boolean
  isFetched: boolean
  isRefetching: boolean
}

const useMeasurementsQuery = (params: QueryParams, headers?: RequestHeadersWithGdsScope): ReturnType => {
  const fetchMeasurements = (key: string, params: QueryParams): Promise<{ data: StatisticsItem[]; meta: ListMeta }> =>
    api.get('/measurements', { headers, params })

  const { data, isLoading, isFetched, isRefetching } = useQuery(
    ['measurements', params, headers],
    () => fetchMeasurements('measurements', params),
    {
      onError: (error: Error) => {
        notification.error({ message: error.message })
      },
    },
  )

  return {
    data: data?.data,
    meta: data?.meta,
    isLoading,
    isFetched,
    isRefetching,
  }
}

export default useMeasurementsQuery
