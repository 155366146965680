import React, { useMemo } from 'react'
import {
  Alarm,
  AssignmentLate,
  Hotel,
  Image,
  LocalCafe,
  PeopleOutline,
  SmokeFree,
  VerifiedUser,
} from '@material-ui/icons'
import { twMerge } from 'tailwind-merge'
import { StarIcon } from '@heroicons/react/24/outline'

import { Select } from 'ui-v2'
import { RateDetails } from 'types/domain/RateDetails'
import { parseCancellationDeadlineOffsetObjectToString } from 'helpers/cancellationDeadlineOffset'

interface Props {
  rateList: RateDetails[]
  rate?: RateDetails
  onSelectBookingCode: (bookingCode: string) => void
}

const CodeDetails: React.FC<Props> = ({ rateList, rate, onSelectBookingCode }) => {
  const selectOptions = useMemo(
    () =>
      rateList.map((item) => ({
        value: item.rate_plan,
        label: item.rate_plan,
      })),
    [rateList],
  )

  return (
    <div className="bg-white p-4 flex flex-col gap-4 rounded-lg">
      <div className="bg-bg-accent-7 px-4 py-3 rounded flex items-center gap-6 text-lg leading-8 tracking-wider uppercase text-gray-800 font-semibold whitespace-nowrap">
        <span className="hidden md:inline">Select booking code:</span>
        <Select
          className="w-full md:max-w-[520px] border-indigo-300"
          options={selectOptions}
          label="Booking code"
          value={rate?.rate_plan}
          onChange={(value) => onSelectBookingCode(value || '')}
          isSearchable
        />
      </div>

      <div className="p-4 md:p-6 flex flex-col items-start gap-3">
        <div className="text-lg leading-8 font-medium text-gray-900">
          {typeof rate?.room_rate_description === 'string' ? (
            rate.room_rate_description
          ) : (
            <span
              className={twMerge(
                'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                'text-validation-error bg-validation-error-bg border-validation-error-outline',
              )}
            >
              Missing room description
            </span>
          )}
        </div>

        <div className="text-sm leading-5 font-normal text-gray-500">
          {typeof rate?.rate_name === 'string' ? (
            rate.rate_name
          ) : (
            <span
              className={twMerge(
                'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                'text-validation-error bg-validation-error-bg border-validation-error-outline',
              )}
            >
              Missing rate description
            </span>
          )}
        </div>

        <div className="flex items-center gap-4 mt-2">
          <span className="text-base leading-none font-normal text-gray-700 inline-flex items-center">
            <span className="w-6">
              <StarIcon className="w-4" />
            </span>
            Rate category:
          </span>
          <span
            className={twMerge(
              'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
              typeof rate?.rate_category?.value !== 'string' &&
                'text-validation-error bg-validation-error-bg border-validation-error-outline',
            )}
          >
            {rate?.rate_category?.value || 'Missing'}
          </span>
        </div>
      </div>

      <div className="flex flex-col gap-4 lg:flex-row">
        <div className="bg-bg-light rounded-md p-4 flex flex-col gap-2 lg:w-1/2">
          <span className="text-lg leading-8 tracking-wider uppercase text-gray-800 font-semibold">
            Basic information:
          </span>

          <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
            <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
              <span className="w-6">
                <Hotel className="scale-[60%]" />
              </span>
              Bed type:
            </span>

            {rate && rate.beds.length > 0 ? (
              <span
                className={twMerge(
                  'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                )}
              >
                {rate.beds.map((bed) => `${bed.type} x ${bed.quantity}`).join(', ')}
              </span>
            ) : (
              <span
                className={twMerge(
                  'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  'text-validation-error bg-validation-error-bg border-validation-error-outline',
                )}
              >
                Missing
              </span>
            )}
          </div>

          <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
            <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
              <span className="w-6">
                <SmokeFree className="scale-[60%]" />
              </span>
              Smoking room:
            </span>

            {typeof rate?.smoking === 'boolean' ? (
              <span
                className={twMerge(
                  'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                )}
              >
                {rate.smoking ? 'Yes' : 'No'}
              </span>
            ) : (
              <span
                className={twMerge(
                  'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  'text-validation-error bg-validation-error-bg border-validation-error-outline',
                )}
              >
                Missing
              </span>
            )}
          </div>

          <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
            <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
              <span className="w-6">
                <LocalCafe className="scale-[60%]" />
              </span>
              Meal:
            </span>

            {typeof rate?.breakfast === 'boolean' ? (
              <span
                className={twMerge(
                  'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                )}
              >
                Breakfast {rate.breakfast ? '' : 'not'} included
              </span>
            ) : (
              <span
                className={twMerge(
                  'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  'text-validation-error bg-validation-error-bg border-validation-error-outline',
                )}
              >
                Missing
              </span>
            )}
          </div>

          <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
            <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
              <span className="w-6">
                <Image className="scale-[60%]" />
              </span>
              Room view:
            </span>

            {typeof rate?.room_view?.value === 'string' ? (
              <span
                className={twMerge(
                  'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                )}
              >
                {rate.room_view.value}
              </span>
            ) : (
              <span
                className={twMerge(
                  'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  'text-validation-error bg-validation-error-bg border-validation-error-outline',
                )}
              >
                Missing
              </span>
            )}
          </div>

          <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
            <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
              <span className="w-6">
                <PeopleOutline className="scale-[60%]" />
              </span>
              Occupancy:
            </span>

            <span
              className={twMerge(
                'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
              )}
            >
              Adults: {rate?.adults_count}, Children: {rate?.children_count}
            </span>
          </div>
        </div>

        <div className="bg-bg-light rounded-md p-4 flex flex-col gap-2 lg:w-1/2 lg:pt-14">
          <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
            <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
              <span className="w-6">
                <AssignmentLate className="scale-[60%]" />
              </span>
              Cancellation policy:
            </span>

            {typeof rate?.refundable === 'boolean' ? (
              <span
                className={twMerge(
                  'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                )}
              >
                {rate.refundable ? 'Refundable' : 'No refund'}
              </span>
            ) : (
              <span
                className={twMerge(
                  'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  'text-validation-error bg-validation-error-bg border-validation-error-outline',
                )}
              >
                Missing
              </span>
            )}
          </div>

          <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
            <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
              <span className="w-6">
                <Alarm className="scale-[60%]" />
              </span>
              Cancel deadline:
            </span>

            {typeof rate?.cancellation_until === 'string' || rate?.cancellation_deadline_offset ? (
              <span
                className={twMerge(
                  'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                )}
              >
                {rate.cancellation_until ||
                  parseCancellationDeadlineOffsetObjectToString(rate.cancellation_deadline_offset)}
              </span>
            ) : (
              <span
                className={twMerge(
                  'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  'text-validation-error bg-validation-error-bg border-validation-error-outline',
                )}
              >
                Missing
              </span>
            )}
          </div>

          <div className="flex items-start flex-col xl:gap-4 xl:flex-row">
            <span className="text-base leading-none font-normal text-gray-700 w-52 inline-flex items-center flex-shrink-0">
              <span className="w-6">
                <VerifiedUser className="scale-[60%]" />
              </span>
              Guarantee type:
            </span>

            {typeof rate?.guarantee_type === 'string' ? (
              <span
                className={twMerge(
                  'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                )}
              >
                {rate.guarantee_type}
              </span>
            ) : (
              <span
                className={twMerge(
                  'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
                  'text-validation-error bg-validation-error-bg border-validation-error-outline',
                )}
              >
                Missing
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CodeDetails
