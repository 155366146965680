import React, { HTMLInputTypeAttribute } from 'react'
import classNames from 'classnames'

import styles from './Input.module.scss'

// TODO:
// name property
// tabindex property

export type Props = {
  name?: string
  label?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  value?: string
  type?: HTMLInputTypeAttribute
  error?: string
  hideError?: boolean
  disabled?: boolean
  placeholder?: string
  inputRef?: any
  classes?: {
    input?: string
    root?: string
    label?: string
  }
  displayPlaceholderAsLabel?: boolean
}

const Input = (props: Props) => {
  const {
    name,
    onChange,
    onBlur,
    value,
    label,
    type = 'text',
    error,
    disabled,
    placeholder,
    classes,
    inputRef,
    displayPlaceholderAsLabel,
    hideError,
  } = props

  const labelValue = label || placeholder

  return (
    <div
      className={classNames(styles.root, classes?.root, { [styles.hasError]: !!error, [styles.isDisabled]: disabled })}
    >
      {(!!label || (!!placeholder && displayPlaceholderAsLabel)) && (
        <label
          className={classNames(styles.label, classes?.label, {
            [styles.invisible]: displayPlaceholderAsLabel && !value,
          })}
        >
          {labelValue}
        </label>
      )}
      <input
        name={name}
        className={classNames(styles.input, classes?.input, {
          [styles.displayPlaceholderAsLabel]: displayPlaceholderAsLabel,
        })}
        onChange={onChange}
        onBlur={onBlur}
        value={value === null ? undefined : value}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        ref={inputRef}
      />
      {/*by default error div is shown even if empty - otherwise forms height 'jumping' after receiving an error*/}
      {!hideError && <div className={styles.error}>{error ? error : ''}</div>}
    </div>
  )
}

export default Input
