import React, { ReactNode, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Edit as EditIcon } from '@material-ui/icons'
import moment from 'moment'
import { Pagination } from 'antd'

import { Button, PageWrapper } from 'ui'
import { Header, Tabs } from 'ui-v2'
import { Column } from 'ui/Table'
import { HotelListItem } from 'types/domain/HotelListItem'
import { useHotelListQuery } from 'hooks'
import { getGdsColumns } from 'modules/commons'
import useIsTravelAgent from 'hooks/useIsTravelAgent'

import Params from './components/Params'
import Properties from './components/Properties'

const TABS = [
  {
    key: 'all',
    label: 'All Properties',
  },
  {
    key: 'rc',
    label: 'RC Properties',
  },
]

const HotelListContainer: React.FC = () => {
  const [selectedTabKey, setSelectedTabKey] = useState(TABS[0].key)
  const { push: routerPush } = useHistory()
  const allTabSelected = selectedTabKey === 'all'
  const isTravelAgent = useIsTravelAgent()

  const { data, meta, setPage, isFetching, setSorter, setFilters, sorter, error } = useHotelListQuery({
    only_active: isTravelAgent ? false : undefined,
  })

  const tilesFields: Column<HotelListItem>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      filter: true,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      filter: {
        name: 'country',
      },
    },
    {
      title: 'City',
      dataIndex: 'city',
      filter: {
        name: 'city',
      },
    },
    ...getGdsColumns(true),
    ...(allTabSelected
      ? [
          {
            title: 'Added',
            dataIndex: 'created_at' as keyof HotelListItem,
            renderCell: (record: HotelListItem): ReactNode =>
              record.created_at ? moment(record.created_at).format('DD.MM.YYYY') : undefined,
            sorter: true,
          },
        ]
      : []),
    {
      title: '',
      key: 'actions',
      renderCell: (record: HotelListItem) => (
        <div>
          <Button secondary onClick={() => routerPush(`/admin/properties/${record.id}`)}>
            <EditIcon />
          </Button>
        </div>
      ),
    },
  ]

  const onFiltersChange = useCallback((filters) => {
    setPage(1)
    setFilters(filters)
  }, [])

  return (
    <>
      <Header>
        <h1 className="text-gray-800 text-3xl font-semibold leading-9 mb-0">Properties</h1>
      </Header>
      <Params onFiltersChange={onFiltersChange} errors={error} isLoading={isFetching} />
      <div className="bg-[#f8f9fe]">
        <PageWrapper className="!mt-0 !pr-0">
          <Tabs
            items={TABS}
            selectedKey={selectedTabKey}
            onChange={(tabKey) => {
              setSelectedTabKey(tabKey)
              setFilters({ only_active: allTabSelected })
            }}
          />
          <Properties
            data={data}
            isLoading={isFetching}
            meta={meta}
            sorter={sorter}
            setSorter={setSorter}
            setFilters={setFilters}
            setPage={setPage}
            tilesFields={tilesFields}
            filtersExpanded
          />
          <div className="flex items-center justify-center pt-5">
            <Pagination
              current={meta.current_page || 1}
              total={meta.total || 1}
              onChange={setPage}
              showSizeChanger={false}
              hideOnSinglePage
              pageSize={meta.per_page || 10}
            />
          </div>
        </PageWrapper>
      </div>
    </>
  )
}

export default HotelListContainer
