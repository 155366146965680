import React from 'react'

import { useHotelsQuery } from 'hooks'

import Hotels from './Hotels'
import { HotelListItem } from 'types/domain/HotelListItem'

type Props = {
  assignedHotels: HotelListItem[]
  setAssignedHotels: (hotels: HotelListItem[]) => void
}

const HotelContainer: React.FC<Props> = ({ assignedHotels, setAssignedHotels }) => {
  const {
    data: hotels,
    meta,
    setPage,
    isFetching,
    setSorter,
    setFilters,
    sorter,
    error,
  } = useHotelsQuery({
    length: 10,
  })

  return (
    <Hotels
      hotels={hotels}
      assignedHotels={assignedHotels}
      meta={meta}
      setPage={setPage}
      isLoading={isFetching}
      sorter={sorter}
      setSorter={setSorter}
      setFilters={setFilters}
      setAssignedHotels={setAssignedHotels}
      filtersExpanded
      errors={error}
    />
  )
}

export default HotelContainer
