import React, { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type HeaderProps = {
  title?: string
  children?: ReactNode
  className?: string
  size?: 'normal' | 'big'
}

export const Header = ({ children, title, size = 'normal', className = '' }: HeaderProps) => (
  <div
    className={twMerge(
      'px-6 py-4 bg-white border-b border-indigo-100 flex flex-col items-center justify-between gap-1 border-none md:flex-row md:items-start md:gap-3 md:px-7',
      size === 'big' && 'py-6',
      className,
    )}
  >
    {title && (
      <h1
        className={twMerge(
          'text-xl !leading-7 font-medium text-gray-800 m-0',
          size === 'big' && 'text-3xl font-semibold leading-9',
        )}
      >
        {title}
      </h1>
    )}
    {children}
  </div>
)
