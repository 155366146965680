import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Edit as EditIcon } from '@material-ui/icons'
import moment from 'moment/moment'

import { Meta } from 'api/types/Meta'
import { HotelListItem } from 'types/domain/HotelListItem'
import { Tiles } from 'ui'
import { useIsTablet } from 'hooks'
import { Column as TileField } from 'ui/Table'
import { Button } from 'ui-v2'
import { Table, Sorter } from 'ui-v2/Table/Table'
import { GDS_LIST } from 'constants/gds'

export interface Props {
  meta: Partial<Meta>
  data: HotelListItem[]
  isLoading: boolean
  setPage: (page: number) => void
  sorter: Sorter<keyof HotelListItem>
  setSorter: (sorter: Sorter<keyof HotelListItem>) => void
  setFilters: (filters: { [key: string]: string }) => void
  tilesFields: TileField<HotelListItem>[]
  filtersExpanded?: boolean
}

const Properties: React.FC<Props> = ({ meta, data, isLoading, setPage, sorter, tilesFields, setSorter, children }) => {
  const isTablet = useIsTablet()
  const { push: routerPush } = useHistory()

  const renderGdsProperties = useCallback((gdsProperties, fieldName) => {
    return gdsProperties.map((gdsHotel) => {
      const gdsName = GDS_LIST.find((gds) => gds.id === gdsHotel?.gds_id)?.label.split('-')[0]

      return (
        <div className="w-1/2">
          <div className="text-sm leading-tight font-normal text-gray-700">
            <div className="flex flex-col text-sm leading-none font-normal border-none bg-transparent p-0 h-auto text-blue-800">
              <span className="text-gray-900 leading-tight">{gdsHotel[fieldName]}</span>
              <span className="text-xs leading-none font-normal text-gray-400">{gdsName}</span>
            </div>
          </div>
        </div>
      )
    })
  }, [])

  const onEdit = useCallback(
    (hotelId, event) => {
      event.preventDefault()
      const url = `${window.location.origin}/admin/properties/${hotelId}`
      if (event.ctrlKey || event.metaKey) {
        window.open(url, '_blank')
      } else {
        routerPush(`/admin/properties/${hotelId}`)
      }
    },
    [routerPush],
  )

  return (
    <Spin
      spinning={isLoading}
      indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
      wrapperClassName="bg-white px-4 pr-[30px]"
    >
      <div className="flex space-between align-center mb-6 pt-4">
        <div className="text-base text-gray-500">No. of properties: {meta.total}</div>
        {children}
      </div>
      {isTablet ? (
        <Tiles<HotelListItem>
          dataSource={isLoading ? [] : data}
          fields={tilesFields}
          pagination={
            isLoading
              ? undefined
              : {
                  pageSize: meta.per_page,
                  page: meta.current_page,
                  totalItems: meta.total,
                  onChange: setPage,
                }
          }
        />
      ) : (
        <Table className="px-4">
          <thead>
            <Table.Tr>
              <Table.Th sorter={sorter} setSorter={setSorter} name="name">
                Name
              </Table.Th>
              <Table.Th>ID</Table.Th>
              <Table.Th sorter={sorter} setSorter={setSorter} name="city">
                City
              </Table.Th>
              <Table.Th sorter={sorter} setSorter={setSorter} name="country" className="w-[135px]">
                Country
              </Table.Th>
              <Table.Th>Chain code</Table.Th>
              <Table.Th sorter={sorter} setSorter={setSorter} name="created_at">
                Added
              </Table.Th>
              <Table.Th className="w-14"></Table.Th>
            </Table.Tr>
          </thead>
          <tbody>
            {data.map((hotel) => (
              <Table.Tr key={hotel.id} onClick={(e) => onEdit(hotel.id, e)}>
                <Table.Td>{hotel.name}</Table.Td>
                <Table.Td>
                  <div className="flex gap-2">{renderGdsProperties(hotel.gds_properties, 'property_id')}</div>
                </Table.Td>
                <Table.Td>{hotel.city}</Table.Td>
                <Table.Td>{hotel.country}</Table.Td>
                <Table.Td>
                  <div className="flex gap-2">{renderGdsProperties(hotel.gds_properties, 'chain_code')}</div>
                </Table.Td>
                <Table.Td>{hotel.created_at ? moment(hotel.created_at).format('DD.MM.YYYY') : undefined}</Table.Td>
                <Table.Td>
                  <div>
                    <Button variant="secondary" onClick={() => onEdit(hotel.id)} className="border-none" rounded>
                      <EditIcon fontSize="small" />
                    </Button>
                  </div>
                </Table.Td>
              </Table.Tr>
            ))}
          </tbody>
        </Table>
      )}
    </Spin>
  )
}

export default Properties
