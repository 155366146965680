import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { HotelCandidate } from 'types/domain/HotelCandidate'

import { HOTEL_CANDIDATE_ENDPOINT_KEY } from './constants'

type GetHotelCandidateResourceRequestParams = {
  hotel_candidate: number
}

export const getHotelCandidateResource = ({
  params,
}: {
  params: GetHotelCandidateResourceRequestParams
}): Promise<{ data: HotelCandidate }> => api.get(`/hotel-candidate/${params.hotel_candidate}`)

export const useGetHotelCandidateResource = ({
  params,
  queryOptions,
  customQueryKey,
}: {
  params: GetHotelCandidateResourceRequestParams
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getHotelCandidateResource>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || [HOTEL_CANDIDATE_ENDPOINT_KEY, ...Object.values(params || {})],
    () => getHotelCandidateResource({ params }),
    queryOptions,
  )
