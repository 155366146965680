import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import { Helmet } from 'react-helmet'

import './index.css'
import store from 'store/index'
import App from './App'

import './theme/antd.less'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN_URL,
  integrations: [new BrowserTracing(), new CaptureConsoleIntegration({ levels: ['error'] })],
  tracesSampleRate: 1.0,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  release: import.meta.env.VITE_SENTRY_RELEASE_VERSION,
  initialScope: {
    tags: { VERSION_TAG: import.meta.env.VITE_VERSION_TAG },
  },
  enabled: Boolean(import.meta.env.VITE_SENTRY_DSN_URL && import.meta.env.VITE_SENTRY_ENVIRONMENT),
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Helmet>
        <title>Rate Cleaner</title>
      </Helmet>

      <App />
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root'),
)
