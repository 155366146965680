import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { ArrowRightIcon } from '@heroicons/react/16/solid'

import { RootState } from 'store'
import { Button, Input } from 'ui-v2'

interface Props {
  isSubmitting: boolean
  onSubmit: (values: { [key: string]: string }) => void
  apiErrors: { [key: string]: string }
}

const Login: React.FC<Props> = ({ onSubmit, isSubmitting, apiErrors }) => {
  const history = useHistory()
  const { isAuthenticated } = useSelector((state: RootState) => state.auth)

  const { values, errors, handleSubmit, setFieldValue, setErrors } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup.string().email('Incorrect e-mail address').required('Email is required'),
      password: yup.string().required('Password is required'),
    }),
    validateOnChange: false,
    onSubmit: (values) => onSubmit(values),
  })

  useEffect(() => {
    setErrors(apiErrors || {})
  }, [apiErrors.email, apiErrors.password])

  if (isAuthenticated) {
    history.replace('/')
  }

  return (
    <div className="w-full">
      <div className="pb-4 mb-4 border-b border-secondary">
        <h1 className="text-3xl leading-9 font-normal text-gray-800 m-0 mb-2">Sign in</h1>
        <div className="text-sm leading-5 font-normal text-gray-600">
          New to Rate Cleaner?
          <Link to="/register" className="ml-2 font-medium text-blue-800">
            Create account
          </Link>
        </div>
      </div>

      <form>
        <div className="flex flex-col items-center gap-4">
          <Input
            value={values.email}
            label="Email"
            onChange={(value) => setFieldValue('email', value)}
            error={errors.email}
            className="w-full"
          />

          <Input
            value={values.password}
            label="Password"
            htmlType="password"
            onChange={(value) => setFieldValue('password', value)}
            error={errors.password}
            className="w-full"
          />

          <Button
            onClick={(event) => {
              event.preventDefault()
              handleSubmit()
            }}
            isLoading={isSubmitting}
            className="mt-8 w-[248px]"
            variant="primary"
            htmlType="submit"
            size="large"
          >
            <span className="inline-flex items-center gap-2">
              Sign in <ArrowRightIcon className="w-3" />
            </span>
          </Button>
        </div>
      </form>

      <div className="mt-12 pt-6 border-t border-secondary flex items-center">
        <div className="pl-[13px] mr-auto">
          <Link to="/forgot-password" className="text-xs leading-4 font-medium text-gray-400 hover:text-blue-800">
            Forgot password?
          </Link>
        </div>
        <div className="pl-[13px] pr-[11px]">
          <Link to="/email-verification" className="text-xs leading-4 font-medium text-gray-400 hover:text-blue-800">
            Resend activation link
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Login
