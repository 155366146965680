import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import moment, { Moment } from 'moment'
import { Menu, Transition } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'
import { ArrowsUpDownIcon, DocumentChartBarIcon, EyeIcon } from '@heroicons/react/24/outline'

import { Hotel } from 'types/domain/Hotel'
import { useExportFile, useIsTravelAgent } from 'hooks'
import { RateDetails } from 'types/domain/RateDetails'
import useGdsSelectContext from 'components/GdsSelect/useGdsSelectContext'
import { Button } from 'ui-v2'

type Props = {
  hotel: Hotel
  selectedRate: Nullable<RateDetails>
  selectedDate: Moment
  areRateRulesAvailable: boolean
  showOTAView: () => void
}

const Header = (props: Props) => {
  const { hotel, areRateRulesAvailable, showOTAView, selectedRate, selectedDate } = props
  const { isLoading, saveFile } = useExportFile()
  const { headers, label: gdsName } = useGdsSelectContext()
  const history = useHistory()
  const isTravelAgent = useIsTravelAgent()

  const renderDetails = () => {
    return (
      <div>
        <h1 className="text-3xl !leading-9 font-medium text-gray-800 m-0 pb-1">{hotel.name}</h1>
        <span className="text-base leading-none font-normal text-gray-500">Property ID: {hotel.property_id}</span>
      </div>
    )
  }

  const renderButtons = () => {
    return (
      <div className="flex items-center justify-start gap-3 md:flex-row-reverse md:justify-between md:flex-1">
        <Menu as="div" className="relative inline-block">
          <>
            <Menu.Button className="p-0 h-auto border-none bg-transparent">
              <Button variant="secondary" isLoading={isLoading} className="px-3">
                <span className="inline-flex items-center gap-2">
                  Export <DocumentChartBarIcon className="w-4 stroke-2" />
                </span>
              </Button>
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-1 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="p-3 flex flex-col text-gray-700">
                  <Menu.Item>
                    <a
                      onClick={(event): Promise<void> =>
                        saveFile(
                          '/print',
                          `${hotel.name} - ${gdsName} - RD ${selectedRate?.rate_plan} - ${moment().format(
                            'YYYYMMDD',
                          )}.pdf`,
                          {
                            type: 'hotel-rate',
                            hotel_id: hotel.id,
                            rate_id: selectedRate?.id,
                          },
                          headers,
                        )
                      }
                      className={twMerge(
                        'inline-block px-5 py-2 text-base leading-none font-normal text-gray-700 hover:text-gray-700 hover:bg-blue-50 whitespace-nowrap',
                        !selectedRate?.id && 'pointer-events-none text-gray-300',
                      )}
                    >
                      Rate Details (PDF)
                    </a>
                  </Menu.Item>

                  <Menu.Item>
                    <a
                      onClick={(event): Promise<void> =>
                        saveFile(
                          '/print',
                          `${hotel.name} - ${gdsName} - RR ${selectedRate?.rate_plan} - ${moment().format(
                            'YYYYMMDD',
                          )}.pdf`,
                          {
                            type: 'hotel-rate-rules',
                            hotel_id: hotel.id,
                            rate_id: selectedRate?.id,
                          },
                          headers,
                        )
                      }
                      className={twMerge(
                        'inline-block px-5 py-2 text-base leading-none font-normal text-gray-700 hover:text-gray-700 hover:bg-blue-50 whitespace-nowrap',
                        !areRateRulesAvailable && 'pointer-events-none text-gray-300',
                      )}
                    >
                      Rate Rules (PDF)
                    </a>
                  </Menu.Item>

                  <Menu.Item>
                    <a
                      onClick={(event): Promise<void> =>
                        saveFile(
                          '/exports/rate-details',
                          `${hotel.name} - ${gdsName} rates - ${moment().format('DD.MM.YYYY HH.mm')}.xlsx`,
                          {
                            type: 'rate-details',
                            hotel_id: hotel.id,
                            date: selectedDate.format('YYYY-MM-DD'),
                          },
                          headers,
                        )
                      }
                      className={twMerge(
                        'inline-block px-5 py-2 text-base leading-none font-normal text-gray-700 hover:text-gray-700 hover:bg-blue-50 whitespace-nowrap',
                        !selectedRate?.id && 'pointer-events-none text-gray-300',
                      )}
                    >
                      All booking codes (XLS)
                    </a>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        </Menu>

        {!isTravelAgent && (
          <Button onClick={showOTAView} className="flex-1 max-w-72 md:flex-grow-0 whitespace-nowrap">
            OTA View
          </Button>
        )}

        {isTravelAgent && (
          <Button onClick={(): void => history.push('/search/hotel')} className="flex-1 max-w-72 md:flex-grow-0">
            <span className="inline-flex items-center gap-2">
              Change <ArrowsUpDownIcon className="w-4" />
            </span>
          </Button>
        )}
      </div>
    )
  }

  return (
    <div className="px-6 py-4 bg-white border-b border-indigo-100 flex flex-col items-center justify-between gap-1 md:flex-row md:items-start md:gap-3 md:px-7">
      <div className="w-full md:w-auto">{renderDetails()}</div>
      <div className="w-full md:w-auto md:flex-1">{renderButtons()}</div>
    </div>
  )
}

export default Header
