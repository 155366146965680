import { useCallback, useEffect, useState } from 'react'
import { Moment } from 'moment'

import { useUpdateUser } from 'api/users/updateUser'
import { notification } from 'helpers'
import useGetUser from 'api/users/getUser'
import { UserRoles } from 'modules/UsersModule/types'

type Props = {
  userId?: number
  trialEndsAtDate: Moment | null
  isViewedByAdmin: boolean
  onSuccessEdit?: () => void
}

export type Returned = {
  onStart: () => void
  isActive: boolean
  isSaving: boolean
  onSelectDate: (date: Moment | null) => void
  selectedEndsAtDate: Moment | null | undefined
  onCancel: () => void
  onSave: () => void
  onDelete: () => void
  canEdit: boolean
}

const useEditTrial = (props: Props): Returned => {
  const { userId, trialEndsAtDate, isViewedByAdmin, onSuccessEdit } = props
  const { data: userData } = useGetUser(userId || '')

  const [selectedEndsAtDate, setSelectedEndsAtDate] = useState<Moment | null | undefined>()
  const [isActiveEdit, setIsActiveEdit] = useState(false)
  const canEdit = isViewedByAdmin
  const { mutate: updateUser } = useUpdateUser({})
  const { mutate, isLoading } = useUpdateUser({
    queryOptions: {
      onSuccess: () => {
        setIsActiveEdit(false)
        // todo: HRC-371 - remove this GDS mutation, move it to API
        updateUser({ id: userId, data: { gds: [{ id: 1 }, { id: 2 }] } })
        notification.success({ message: 'Trial set successfully.' })
        if (onSuccessEdit) {
          onSuccessEdit()
        }
      },
      onError: (error) => notification.error({ message: error.message }),
    },
  })

  const onSave = useCallback(async () => {
    if (userData?.data.role !== UserRoles.TravelAgent && userData?.data.hotels.length === 0) {
      notification.warning({ message: 'Assign at least one hotel to user before setting a trial.' })
      return
    }

    if (userId && selectedEndsAtDate) {
      await mutate({ id: userId, data: { trial_ends_at: selectedEndsAtDate.toISOString() } })
    }
  }, [mutate, selectedEndsAtDate, userData])

  const onDelete = useCallback(async () => {
    if (userId) {
      await mutate({ id: userId, data: { trial_ends_at: null } })
    }
  }, [mutate, userId])

  const onCancel = useCallback(() => {
    setIsActiveEdit(false)
    setSelectedEndsAtDate(trialEndsAtDate)
  }, [trialEndsAtDate])

  const onStart = useCallback(() => setIsActiveEdit(true), [])

  useEffect(() => {
    if (trialEndsAtDate) {
      setSelectedEndsAtDate(trialEndsAtDate)
    }
  }, [trialEndsAtDate])

  return {
    onStart,
    onSave,
    onCancel,
    onSelectDate: setSelectedEndsAtDate,
    isActive: isActiveEdit,
    isSaving: isLoading,
    selectedEndsAtDate,
    canEdit,
    onDelete,
  }
}

export default useEditTrial
