import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'

const deleteHotelCandidate = (hotel_candidate: number): Promise<void> =>
  api.delete(`/hotel-candidate/${hotel_candidate}`)

export const useDeleteHotelCandidate = (
  queryOptions?: UseMutationOptions<Awaited<ReturnType<typeof deleteHotelCandidate>>, ApiError, number>,
): UseMutationResult<void, ApiError, number> =>
  useMutation((hotel_candidate) => deleteHotelCandidate(hotel_candidate), queryOptions)
