import React from 'react'

interface ReportedIssueCounter {
  status: string
  total: number
}

export interface ReportedIssueCounters extends Array<ReportedIssueCounter> {
  [index: number]: ReportedIssueCounter
}

interface Props {
  counters: ReportedIssueCounters
}

const Counters = ({ counters }: Props): JSX.Element => {
  return (
    <div className="flex items-center gap-4 text-base my-2 lg:my-0 leading-none font-normal text-gray-500 md:gap-8">
      <div>Total: {counters.reduce((prev, next) => prev + next.total, 0)}</div>
      {counters.map(
        (counter: ReportedIssueCounter): JSX.Element => (
          <div key={counter.status}>
            {counter.status.charAt(0).toUpperCase() + counter.status.replace('_', ' ').slice(1)}: {counter.total}
          </div>
        ),
      )}
    </div>
  )
}

export default Counters
