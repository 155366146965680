import React, { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { Select as AntSelect } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { getCxFromStyles } from 'helpers'
import styles from './Select.module.scss'
import './Select.scss'

interface Props {
  options?: { value: string; label: string | JSX.Element }[]
  placeholder?: string
  className?: string
  dropdownClassName?: string
  value?: string
  onChange?: (value: string) => void
  disabled?: boolean
  isError?: boolean
  allowClear?: boolean
  isLoading?: boolean
  isLoadingMore?: boolean
  onSearchChange?: (search: string) => void
  onLoadMore?: () => void
  hasMore?: boolean
  alternative?: boolean
  initialSearchValue?: string
  isStatusSelect?: boolean
  error?: string
}

const Select: React.FC<Props> = ({
  options = [],
  placeholder,
  value,
  onChange = () => {}, // eslint-disable-line
  className,
  dropdownClassName,
  disabled,
  isError,
  allowClear = false,
  isLoading,
  isLoadingMore,
  onSearchChange,
  onLoadMore,
  hasMore,
  alternative,
  initialSearchValue,
  isStatusSelect,
  error,
}) => {
  const cx = getCxFromStyles(styles)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [search, setSearch] = useState(initialSearchValue)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const emitOnSearchChange = useCallback(
    debounce((filters) => {
      if (onSearchChange) {
        onSearchChange(filters)
      }
    }, 500),
    [],
  )

  useEffect(() => {
    emitOnSearchChange(search)
  }, [search, emitOnSearchChange])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const emitLoadMore = useCallback(
    debounce(() => {
      if (onLoadMore) {
        onLoadMore()
      }
    }, 100),
    [],
  )

  return (
    <div>
      <AntSelect
        placeholder={placeholder}
        value={value || undefined}
        className={cx(className, 'select', {
          isOpen,
          disabled,
          error: isError,
          allowClear,
          hasValue: value !== undefined && value !== null,
          isStatusSelect,
        })}
        dropdownClassName={`hrc-select-dropdown ${alternative ? 'hrc-select-dropdown-alt' : ''} ${
          dropdownClassName || ''
        }`}
        showArrow={false}
        bordered={false}
        size="large"
        onDropdownVisibleChange={setIsOpen}
        onChange={(value) => {
          onChange(value)
          setSearch('')
        }}
        disabled={disabled}
        allowClear={allowClear}
        showSearch={!!onSearchChange}
        onSearch={onSearchChange ? setSearch : undefined}
        onClear={() => setSearch('')}
        filterOption={false}
        // eslint-disable-next-line
        onPopupScroll={(event: any): void => {
          hasMore &&
            !isLoading &&
            !isLoadingMore &&
            event.target.scrollHeight - event.target.scrollTop - event.target.offsetHeight < 100 &&
            emitLoadMore()
        }}
        notFoundContent={null}
        dropdownMatchSelectWidth={!isStatusSelect}
      >
        {!isLoading &&
          options.map((item) => (
            <AntSelect.Option value={item.value} key={item.value}>
              {item.label}
            </AntSelect.Option>
          ))}
        {(isLoading || isLoadingMore) && (
          <AntSelect.Option className={cx('loading')} value="loadingPlaceholder" disabled>
            <LoadingOutlined style={{ fontSize: 20 }} spin />
          </AntSelect.Option>
        )}
      </AntSelect>
      <div className="text-[12px] height-[15px] text-[#ff2e00]">{error ? error : ''}</div>
    </div>
  )
}

export default Select
