import { QueryKey, useQuery, UseQueryOptions } from 'react-query'
import qs from 'qs'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { Hotel } from 'types/domain/Hotel'

import { HOTELS_ENDPOINT_KEY } from './constants'
import { RequestHeadersWithGdsScope } from '../types/RequestHeadersWithGdsScope'

type GetHotelResourceRequestParams = {
  id: number
}

type GetHotelResourceRequestQuery = {
  hotel?: number
  rates_only?: string
  details_only?: string
  checkup_rate_id?: number
  checkup_id?: number
  travel_port_property_id?: string
  sabre_property_id?: string
}

type GetHotelResourceResponse = {
  data: Hotel
}

export const getHotelResource = ({
  params,
  headers,
  query,
}: {
  params: GetHotelResourceRequestParams
  headers?: RequestHeadersWithGdsScope
  query?: GetHotelResourceRequestQuery
}): Promise<GetHotelResourceResponse> =>
  api.get(`/hotels/${params.id}?${qs.stringify(query, { skipNulls: true })}`, { headers })

export const useGetHotelResource = ({
  params,
  headers,
  query,
  queryOptions,
  customQueryKey,
}: {
  params: GetHotelResourceRequestParams
  headers?: RequestHeadersWithGdsScope
  query?: GetHotelResourceRequestQuery
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getHotelResource>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || [
      HOTELS_ENDPOINT_KEY,
      ...Object.values(params || {}),
      ...Object.values(query || {}),
      ...Object.values(headers || {}),
    ],
    () => getHotelResource({ params, query, headers }),
    queryOptions,
  )
