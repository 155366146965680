import { useQuery, QueryKey, UseQueryOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { PaymentMethod } from 'types/domain/PaymentMethod'
import { PaymentHistoryListItem } from 'types/domain/PaymentHistoryListItem'
import { Subscription } from 'types/domain/Subscription'

import { SUBSCRIPTION_ENDPOINT_KEY } from './constants'

type GetSubscriptionResourceResponse = {
  data: {
    payment_method: PaymentMethod
    payments_history: PaymentHistoryListItem[]
    subscription: Subscription
    trial_ends_at: Nullable<string>
  }
}

export const getSubscriptionResource = (): Promise<GetSubscriptionResourceResponse> => api.get(`/subscription`)

export const useGetSubscriptionResource = ({
  queryOptions,
  customQueryKey,
}: {
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionResource>>, ApiError>
  customQueryKey?: QueryKey
} = {}) => useQuery(customQueryKey || [SUBSCRIPTION_ENDPOINT_KEY], () => getSubscriptionResource(), queryOptions)
