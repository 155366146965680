import React, { useCallback, useEffect, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/16/solid'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { twMerge } from 'tailwind-merge'

import { Input, Button } from 'ui-v2'
import { ApiError } from 'api/types/ApiError'

export interface HotelEmailsParams {
  name?: string
  city?: string
  email?: string
  property_id?: string
}

interface ParamsProps {
  onFiltersChange: (values: HotelEmailsParams) => void
  errors: ApiError | null
  isLoading?: boolean
}

const Params = ({ errors, onFiltersChange, isLoading }: ParamsProps): JSX.Element => {
  const [name, setName] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [propertyCode, setPropertyCode] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  const [areFiltersExpanded, setAreFiltersExpanded] = useState(false)

  const handleSearch = useCallback(() => {
    onFiltersChange({ name, city, email, property_id: propertyCode })
  }, [onFiltersChange, name, city, email, propertyCode])

  useEffect(() => {
    const listener = (e: { code: string }) => e.code === 'Enter' && handleSearch()
    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [handleSearch])

  return (
    <div className="p-4 bg-bg-accent-7 flex flex-col gap-3 md:px-7 lg:flex-row lg:items-start">
      <div className={twMerge('lg:hidden', areFiltersExpanded && 'mb-4')}>
        <div className="flex items-start justify-between gap-2 mb-2">
          <Input
            value={name}
            onChange={(value) => setName(value || '')}
            label="Hotel name"
            className="border-indigo-300 w-full"
            error={errors?.errors?.name}
          />

          <Button
            isLoading={isLoading}
            className="w-10 rounded p-0 flex items-center justify-center relative"
            isDisabled={areFiltersExpanded}
            onClick={handleSearch}
            size="large"
            variant="primary"
          >
            <MagnifyingGlassIcon className="w-5" />

            {(!!name || !!city || !!email || !!propertyCode) && !areFiltersExpanded && (
              <button
                className="absolute -top-2 -right-2 rounded-full w-6 h-6 flex items-center justify-center p-0 border-indigo-300 bg-indigo-100 text-blue-800"
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  setCity('')
                  setName('')
                  setPropertyCode('')
                  setEmail('')
                  onFiltersChange({ name: '', city: '', email: '', property_id: '' })
                }}
              >
                <XMarkIcon className="w-4" />
              </button>
            )}
          </Button>
        </div>

        <button
          className="bg-transparent border-none text-sm leading-4 font-medium text-blue-800"
          onClick={() => setAreFiltersExpanded((prevState) => !prevState)}
        >
          {areFiltersExpanded ? 'Less' : 'More'} filters
        </button>
      </div>

      <Input
        value={name}
        onChange={(value) => setName(value || '')}
        label="Hotel name"
        className={twMerge('hidden border-indigo-300 w-full lg:block lg:flex-1 flex-shrink-0')}
        error={errors?.errors?.name}
      />

      <Input
        value={city}
        onChange={(value) => setCity(value || '')}
        label="City"
        className={twMerge('border-indigo-300 w-full lg:block lg:w-44 flex-shrink-0', !areFiltersExpanded && 'hidden')}
        error={errors?.errors?.city}
      />

      <Input
        value={propertyCode}
        onChange={(value) => setPropertyCode(value || '')}
        label="Property ID"
        className={twMerge('border-indigo-300 w-full lg:block lg:w-44 flex-shrink-0', !areFiltersExpanded && 'hidden')}
        error={errors?.errors?.property_id}
      />

      <Input
        value={email}
        onChange={(value) => setEmail(value || '')}
        label="E-mail"
        className={twMerge('border-indigo-300 w-full lg:block lg:w-44 flex-shrink-0', !areFiltersExpanded && 'hidden')}
        error={errors?.errors?.email}
      />

      <Button
        className={twMerge('lg:block lg:w-auto', !areFiltersExpanded && 'hidden')}
        onClick={handleSearch}
        isLoading={isLoading}
        variant="primary"
        size="large"
      >
        Search
        {(!!name || !!city || !!email || !!propertyCode) && (
          <button
            className="absolute -top-2 -right-2 rounded-full w-6 h-6 flex items-center justify-center p-0 border-indigo-300 bg-indigo-100 text-blue-800"
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              setCity('')
              setName('')
              setPropertyCode('')
              setEmail('')
              onFiltersChange({ name: '', city: '', email: '', property_id: '' })
            }}
          >
            <XMarkIcon className="w-4" />
          </button>
        )}
      </Button>
    </div>
  )
}

export default Params
