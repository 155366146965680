import { QueryObserverResult, useQuery } from 'react-query'
import api from 'api'
import type { Error } from 'api'

import { notification } from 'helpers'
import { Subscription as SubscriptionType } from 'modules/UsersModule/types'

type ReturnType = {
  data?: SubscriptionType
  isLoading: boolean
  isFetching: boolean
  refetch: () => Promise<QueryObserverResult<{ data: SubscriptionType } | undefined>>
}

type Options = {
  enabled?: boolean
}

const useSubscriptionQuery = (options: Options = {}): ReturnType => {
  const fetchSubscription = (): Promise<{ data: SubscriptionType }> => api.get('/subscription')

  const { data, isFetching, isLoading, refetch } = useQuery(['subscription'], fetchSubscription, {
    onError: (error: Error) => {
      notification.error({ message: error.message })
    },
    ...options,
  })

  return {
    data: data?.data,
    isFetching,
    isLoading,
    refetch,
  }
}

export default useSubscriptionQuery
