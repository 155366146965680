import * as yup from 'yup'
import { requiredString } from 'helpers/validations'

const PROPERTY_ID_FORMAT_ERROR_MESSAGE = 'Invalid format. The field should have six characters'
const BRAND_FORMAT_ERROR_MESSAGE = 'Invalid format. The field should have two uppercase letters'
const PROPERTY_ID_REQUIRED_ERROR_MESSAGE = 'Field is required if the brand field is not empty'
const BRAND_REQUIRED_ERROR_MESSAGE = 'Field is required if the property id field is not empty'

const PROPERTY_ID_REGEX = /^[A-Za-z0-9]{1,6}$/
const BRAND_REGEX = /^[A-Z]{2}$/

const GDS_SCHEMA_VALIDATION = yup.object().shape(
  {
    property_id: yup.string().when(['chain_code'], {
      is: (chain_code: string) => !!chain_code,
      then: (schema) =>
        schema
          .required(PROPERTY_ID_REQUIRED_ERROR_MESSAGE)
          .matches(PROPERTY_ID_REGEX, PROPERTY_ID_FORMAT_ERROR_MESSAGE),
    }),
    chain_code: yup.string().when(['property_id'], {
      is: (property_id: string) => !!property_id,
      then: (schema) => schema.required(BRAND_REQUIRED_ERROR_MESSAGE).matches(BRAND_REGEX, BRAND_FORMAT_ERROR_MESSAGE),
    }),
  },
  [['property_id', 'chain_code']],
)

export default yup
  .object()
  .shape({
    name: requiredString(),
    travel_port: GDS_SCHEMA_VALIDATION,
    sabre: GDS_SCHEMA_VALIDATION,
  })
  .required()
