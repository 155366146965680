import { ComponentType, ReactNode, useMemo, useState } from 'react'
import ReactSelect, { ClearIndicatorProps, GroupBase, OptionsOrGroups, PropsValue } from 'react-select'
import { twMerge } from 'tailwind-merge'

import './Select.css'

type SelectProps = {
  options: { value: string; label: ReactNode }[]
  value?: string
  className?: string
  onChange?: (value?: string) => void
  isDisabled?: boolean
  isSearchable?: boolean
  isClearable?: boolean
  label: string
  size?: 'small' | 'normal'
  error?: string
}

export const Select = ({
  className,
  options,
  value,
  onChange,
  isDisabled,
  label,
  size = 'normal',
  isSearchable = false,
  isClearable = false,
  error,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const currentValue = useMemo(() => {
    if (typeof value === 'undefined') {
      return undefined
    }

    const option = options.find((item) => item.value === value)

    return option ? option : value ? ({ value, label: value } as PropsValue<SelectProps['options'][0]>) : undefined
  }, [options, value])

  const selectOptions: OptionsOrGroups<SelectProps['options'][0], GroupBase<SelectProps['options'][0]>> = options

  return (
    <div
      className={twMerge(
        'relative border border-gray-100 bg-white rounded font-normal leading-none',
        className,
        isDisabled && 'border-gray-100',
        error && 'border-validation-error-outline mb-4',
      )}
    >
      <div
        className={twMerge(
          'absolute leading-none font-normal text-gray-500',
          isDisabled && 'text-gray-300',
          size === 'normal' && 'text-xs left-2 top-1 leading-3',
          size === 'small' && 'text-[8px] left-1.5 top-1',
          error && 'text-validation-error',
        )}
      >
        {label}:
      </div>

      <ReactSelect
        value={currentValue}
        menuIsOpen={isOpen}
        onMenuClose={() => setIsOpen(false)}
        onMenuOpen={() => setIsOpen(true)}
        unstyled
        isSearchable={isSearchable}
        placeholder="-"
        options={selectOptions}
        onChange={(newValue: any) => onChange?.(newValue?.value)}
        isDisabled={isDisabled}
        isClearable={isClearable}
        classNames={{
          control: () =>
            twMerge(
              '!min-h-0 !border-none',
              size === 'normal' && 'h-10 p-1 pl-2',
              size === 'small' && 'h-7 px-1.5 py-px',
            ),
          valueContainer: () =>
            twMerge(
              'leading-none font-normal pr-2 text-gray-800',
              isDisabled && 'text-gray-500',
              size === 'normal' && 'mt-[11px] text-base',
              size === 'small' && 'mt-[9px] text-sm',
            ),
          dropdownIndicator: () =>
            twMerge(
              'bg-indigo-100 border border-indigo-300 flex items-center justify-center text-blue-800 rounded-sm',
              isOpen && 'bg-indigo-300 rotate-180 border border-indigo-300',
              isDisabled && 'bg-gray-50 border-gray-200 text-gray-400',
              size === 'normal' && 'w-[32px] h-[32px] -mt-1',
              size === 'small' && 'w-[22px] h-[22px] -mt-1',
            ),
          menu: () =>
            twMerge(
              'shadow-md bg-white rounded mt-1 !z-10 text-gray-800',
              size === 'normal' && 'p-3',
              size === 'small' && 'p-2',
            ),
          option: ({}) => twMerge('odd:bg-blue-50 py-4 px-2', size === 'small' && 'lg:py-1'),
        }}
        styles={{
          dropdownIndicator: (provided) => ({
            ...provided,
            svg: {
              width: size === 'small' ? '14px' : 'inherit',
            },
          }),
        }}
        components={{
          ClearIndicator: CustomClearIndicator,
        }}
      />

      {error && <span className="text-validation-error absolute -bottom-4 left-0 text-xs">{error}</span>}
    </div>
  )
}

const CustomClearIndicator: ComponentType<
  ClearIndicatorProps<SelectProps['options'][0], true, GroupBase<SelectProps['options'][0]>>
> = ({ innerProps }) => {
  return (
    <div className="text-[#1E40AF] mb-1 mr-1" {...innerProps}>
      <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="close-circle"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
      </svg>
    </div>
  )
}
