import React from 'react'

import { getCxFromStyles } from 'helpers'

import { Modal } from 'ui'

import styles from './InfoModal.module.scss'
import { Typography } from 'antd'

interface Props {
  title: string
  content: string
  onClose: () => void
}

const InfoModal: React.FC<Props> = ({ title, content, onClose }) => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx('root')}>
      <Modal visible onCancel={onClose} width={800}>
        <div className={cx('content')}>
          <div className={cx('title')}>
            <Typography.Title level={2}>{title.replace(':', '')}</Typography.Title>
          </div>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </Modal>
    </div>
  )
}

export default InfoModal
