import React, { useCallback, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import { Button, Input, Select } from 'ui-v2'
import { allCountriesWithCodes } from 'constants/countries'

export interface HotelSearchParams {
  name?: string
  country?: string
  city?: string
  travel_port_property_id?: string
  sabre_property_id?: string
}

interface ParamsProps {
  onFiltersChange: (values: HotelSearchParams) => void
  isLoading?: boolean
}

const Params = ({ onFiltersChange, isLoading }: ParamsProps) => {
  const [areFiltersExpanded, setAreFiltersExpanded] = useState(false)
  const [name, setName] = useState<string>('')
  const [country, setCountry] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [sabreId, setSabreId] = useState<string>('')
  const [travelportId, setTravelportId] = useState<string>('')
  const [countrySelectOptions, setCountrySelectOptions] = useState<
    {
      value: string
      label: string
    }[]
  >(allCountriesWithCodes)

  const handleSearch = useCallback(() => {
    onFiltersChange({ name, country, city, travel_port_property_id: travelportId, sabre_property_id: sabreId })
  }, [onFiltersChange, name, country, city, travelportId, sabreId])

  useEffect(() => {
    const listener = (e: { code: string }) => e.code === 'Enter' && handleSearch()
    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [handleSearch])

  return (
    <div className="p-4 bg-bg-accent-7 flex flex-col gap-3 mb-2 md:px-7 lg:mb-0 lg:flex-row lg:items-start">
      <div className={twMerge('lg:hidden', areFiltersExpanded && 'mb-4')}>
        <div className="flex items-start justify-between gap-2 mb-2">
          <Input className="flex-1" label="Hotel name" value={name} onChange={(value) => setName(value || '')} />

          <Button
            isLoading={isLoading}
            className="w-10 rounded p-0 flex items-center justify-center"
            onClick={handleSearch}
            size="large"
            variant="primary"
            isDisabled={areFiltersExpanded}
          >
            <MagnifyingGlassIcon className={twMerge('text-white w-5', areFiltersExpanded && 'text-gray-400')} />
          </Button>

          <Button
            isLoading={isLoading}
            className="w-10 rounded p-0 flex items-center justify-center"
            onClick={handleSearch}
            size="large"
            variant="primary"
            isDisabled={areFiltersExpanded}
          >
            <MagnifyingGlassIcon className={twMerge('text-white w-5', areFiltersExpanded && 'text-gray-400')} />
          </Button>
        </div>

        <button
          className="bg-transparent lg:hidden border-none text-sm leading-4 font-medium text-blue-800"
          onClick={() => setAreFiltersExpanded((prevState) => !prevState)}
        >
          {areFiltersExpanded ? 'Less' : 'More'} filters
        </button>
      </div>

      <Input
        className="hidden lg:block lg:flex-1 lg:min-w-40"
        label="Hotel name"
        value={name}
        onChange={(value) => setName(value || '')}
      />

      <Select
        className={twMerge('lg:block lg:w-60', !areFiltersExpanded && 'hidden')}
        label="Country"
        value={country || undefined}
        options={countrySelectOptions}
        onChange={(value) => setCountry(value || '')}
        isSearchable
        isClearable
      />

      <Input
        className={twMerge('lg:block lg:w-44', !areFiltersExpanded && 'hidden')}
        value={city}
        onChange={(value) => setCity(value || '')}
        label="City"
      />

      <Input
        className={twMerge('lg:block lg:w-40', !areFiltersExpanded && 'hidden')}
        label="Sabre ID"
        value={sabreId}
        onChange={(value) => setSabreId(value || '')}
      />

      <Input
        className={twMerge('lg:block lg:w-40', !areFiltersExpanded && 'hidden')}
        label="Travelport ID"
        value={travelportId}
        onChange={(value) => setTravelportId(value || '')}
      />

      <Button
        className={twMerge('lg:block lg:w-auto', !areFiltersExpanded && 'hidden')}
        onClick={handleSearch}
        isLoading={isLoading}
        variant="primary"
        size="large"
      >
        Search
      </Button>
    </div>
  )
}

export default Params
