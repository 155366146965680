import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import useGetUser from 'api/users/getUser'
import { RootState } from 'store'
import { UserRoles } from 'modules/UsersModule/types'

const useIsTravelAgent = (): boolean => {
  const { profile } = useSelector((state: RootState) => state.auth)
  const { userId } = useParams<{ userId: string }>()
  const isAdmin = !!userId
  const { data: userData } = useGetUser(userId || '', { enabled: isAdmin })
  const isTravelAgent = useMemo(
    () => profile?.role === UserRoles.TravelAgent || (isAdmin && userData?.data.role === UserRoles.TravelAgent),
    [isAdmin, profile?.role, userData?.data.role],
  )

  return isTravelAgent
}

export default useIsTravelAgent
