import React from 'react'
import { twMerge } from 'tailwind-merge'

type ButtonProps = {
  className?: string
  onChange?: (isChecked: boolean) => void
  isChecked: boolean
  isDot?: boolean
  isError?: boolean
}

export const Checkbox = ({ className, onChange, isChecked, isDot, isError }: ButtonProps) => {
  return (
    <input
      type="checkbox"
      checked={isChecked}
      onChange={(event) => onChange?.(event.target.checked)}
      className={twMerge(
        'h-4 w-4 rounded border-gray-300 text-blue-800 relative flex justify-center items-center focus:ring-transparent',
        className,
        isDot && 'text-white border-blue-800 after:block after:h-2 after:w-2 after:rounded-full after:bg-blue-800',
        isError && 'border-validation-error',
      )}
    />
  )
}
