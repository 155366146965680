import React, { useEffect, useState } from 'react'
import { Moment } from 'moment'
import { DatePicker as AntdDatePicker } from 'antd'
import { getCxFromStyles } from 'helpers'

import useDisableNavigation from '../utils/useDisableNavigation'
import getDisabledDate from '../utils/getDisabledDate'
import useCloseMonthMode from '../utils/useCloseMonthMode'
import getNavIcons from '../utils/getNavIcons'

import styles from './DatePicker.module.scss'

interface Props {
  className?: string
  dropdownClassName?: string
  value?: Moment | null
  onChange?: (value: Moment | null) => void
  onOpenChange?: (opened: boolean) => void
  minDate?: Moment
  maxDate?: Moment
  isOpened?: boolean
  isError?: boolean
  disabled?: boolean
  placeholder?: string
  availableDates?: string[]
}

const DatePicker: React.FC<Props> = ({
  className,
  value,
  onChange,
  minDate,
  maxDate,
  dropdownClassName,
  onOpenChange,
  isOpened,
  isError,
  disabled,
  placeholder,
  availableDates = [],
}) => {
  const cx = getCxFromStyles(styles)

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { prevNavDisabled, nextNavDisabled, setNavDisabled } = useDisableNavigation()
  const { mode, setMode, renderCloseMonthButton } = useCloseMonthMode()
  const navIcons = getNavIcons(prevNavDisabled, nextNavDisabled)

  useEffect(() => {
    onOpenChange && onOpenChange(isOpen)
  }, [isOpen, onOpenChange])

  useEffect(() => {
    setIsOpen(!!isOpened)
  }, [isOpened])

  return (
    <AntdDatePicker
      className={cx(className, 'picker', { isOpen, disabled, isError })}
      dropdownClassName={dropdownClassName}
      allowClear={false}
      mode={mode}
      inputReadOnly
      value={value}
      onOpenChange={setIsOpen}
      open={isOpen}
      showToday={false}
      placeholder={placeholder}
      onChange={onChange}
      disabledDate={(date: Moment): boolean => {
        return getDisabledDate({ date, availableDates, minDate, maxDate })
      }}
      format="DD.MM.YYYY"
      disabled={disabled}
      onPanelChange={(navDate: Moment, panelMode: string) => {
        setMode(panelMode === 'month' ? 'month' : 'date')
        setNavDisabled({ navDate, minDate, maxDate, availableDates })
      }}
      renderExtraFooter={renderCloseMonthButton}
      {...navIcons}
    />
  )
}

export default DatePicker
