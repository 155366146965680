import React, { useCallback, useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { PencilIcon } from '@heroicons/react/24/solid'

import { useGetGdsCredentialsListResource } from 'api/gdsCredentials/getGdsCredentialsListResource'
import { useCreateGdsCredentials } from 'api/gdsCredentials/createGdsCredentials'
import { useUpdateGdsCredentials } from 'api/gdsCredentials/updateGdsCredentials'
import { useDeleteGdsCredentials } from 'api/gdsCredentials/deleteGdsCredentials'
import { SABRE_ID, SABRE_KEY, TRAVEL_PORT_ID, TRAVEL_PORT_KEY } from 'constants/gds'
import { GdsCredentials as GdsCredentialsType } from 'types/domain/GdsCredentials'
import { notification } from 'helpers'
import { getErrorMessage } from 'helpers/error'
import { ConfirmationModal, Button } from 'ui-v2'
import { SabreForm, TravelportForm } from './components'

const SECTIONS = {
  TRAVELPORT: TRAVEL_PORT_KEY,
  SABRE: SABRE_KEY,
}

interface GdsCrenetialsProps {
  userGds?: { id: number }[]
}

const GdsCredentials = ({ userGds }: GdsCrenetialsProps) => {
  const [editedSection, setEditedSection] = useState<string>()
  const [credentialsIdToDelete, setCredentialsIdToDelete] = useState<number | undefined>()
  const { data, isLoading: isLoadingCredentialsData, refetch: refetchList } = useGetGdsCredentialsListResource()
  const mutationOptions = {
    onSuccess: () => {
      notification.success({ message: 'Success.' })
      setEditedSection(undefined)
      setCredentialsIdToDelete(undefined)
      refetchList()
    },
    onError: (error: Error) => notification.error({ message: getErrorMessage(error) }),
  }
  const {
    mutate: createGdsCredentials,
    isLoading: isCreating,
    error: createError,
  } = useCreateGdsCredentials(mutationOptions)
  const {
    mutate: updateGdsCredentials,
    isLoading: isUpdating,
    error: updateError,
  } = useUpdateGdsCredentials(mutationOptions)
  const { mutate: deleteGdsCredentials, isLoading: isDeleting } = useDeleteGdsCredentials(mutationOptions)

  const isLoading = useMemo(
    () => isLoadingCredentialsData || isCreating || isUpdating,
    [isLoadingCredentialsData, isCreating, isUpdating],
  )

  const isUpdate = useMemo(
    () => data?.data?.find((credentialsData: GdsCredentialsType) => credentialsData.gds === editedSection),
    [data, editedSection],
  )

  const handleSubmit = useCallback(
    (values: { username: string; password: string; token?: string; pcc?: string; branch_code?: string }) => {
      const params = { ...values, gds: editedSection }
      try {
        if (isUpdate) {
          const id = data?.data.find((gds) => gds.gds === editedSection)?.id
          updateGdsCredentials({ id: id?.toString() || '', data: { ...params, id: id || 0 } })
        } else {
          createGdsCredentials({ ...params, id: 0 })
        }
        refetchList()
      } catch (error) {
        notification.error({ message: getErrorMessage(error) })
      }
    },
    [editedSection, isUpdate, data],
  )

  return (
    <div className="flex flex-col items-start w-full gap-2 md:gap-6 lg:flex-row">
      {userGds?.map(({ id }) => id).find((id) => id === TRAVEL_PORT_ID) && (
        <div
          className={twMerge(
            'p-2 bg-bg-light w-full rounded-md flex flex-col gap-1 md:p-6 lg:w-1/2',
            editedSection === SECTIONS.TRAVELPORT && 'border border-indigo-300 shadow-xl',
          )}
        >
          {editedSection !== SECTIONS.TRAVELPORT && (
            <div className="text-right">
              <Button onClick={() => setEditedSection(SECTIONS.TRAVELPORT)}>
                <span className="inline-flex items-center gap-2">
                  Edit <PencilIcon className="w-4" />
                </span>
              </Button>
            </div>
          )}

          <TravelportForm
            credentials={
              data
                ? data.data.find((credentialsData: GdsCredentialsType) => credentialsData.gds === TRAVEL_PORT_KEY)
                : undefined
            }
            isEdited={editedSection === SECTIONS.TRAVELPORT}
            onSubmit={handleSubmit}
            onCancel={() => setEditedSection(undefined)}
            onDelete={setCredentialsIdToDelete}
            isSubmitting={isLoading}
            apiErrors={isUpdate ? updateError : createError}
          />
        </div>
      )}

      {userGds?.map(({ id }) => id).find((id) => id === SABRE_ID) && (
        <div
          className={twMerge(
            'p-2 bg-bg-light w-full rounded-md flex flex-col gap-1 md:p-6 lg:w-1/2',
            editedSection === SECTIONS.SABRE && 'border border-indigo-300 shadow-xl',
          )}
        >
          {editedSection !== SECTIONS.SABRE && (
            <div className="text-right">
              <Button onClick={() => setEditedSection(SECTIONS.SABRE)}>
                <span className="inline-flex items-center gap-2">
                  Edit <PencilIcon className="w-4" />
                </span>
              </Button>
            </div>
          )}
          <SabreForm
            credentials={
              data
                ? data.data.find((credentialsData: GdsCredentialsType) => credentialsData.gds === SABRE_KEY)
                : undefined
            }
            isEdited={editedSection === SECTIONS.SABRE}
            onSubmit={handleSubmit}
            onCancel={() => setEditedSection(undefined)}
            onDelete={setCredentialsIdToDelete}
            isSubmitting={isLoading}
            apiErrors={isUpdate ? updateError : createError}
          />
        </div>
      )}

      {credentialsIdToDelete && (
        <ConfirmationModal
          title="Are you sure that you want to delete GDS credentials?"
          isLoading={isDeleting}
          onSuccess={() => deleteGdsCredentials({ id: String(credentialsIdToDelete) })}
          onClose={() => setCredentialsIdToDelete(undefined)}
        />
      )}
    </div>
  )
}

export default GdsCredentials
