import React, { Fragment, useCallback } from 'react'
import { twMerge } from 'tailwind-merge'

import { RateDetails } from 'types/domain/RateDetails'
import { rateStatusColor } from 'helpers'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/16/solid'

import { SortProps } from './List'

type Props = {
  rateList: Array<RateDetails>
  handleSelectRate: (value: string) => void
  selectedRate: Nullable<RateDetails>
  disabled: boolean
  sort: SortProps
  setSort: (sort: SortProps) => void
}

const DesktopCodeList = ({ rateList, handleSelectRate, selectedRate, disabled, sort, setSort }: Props) => {
  const renderRate = useCallback(
    (item: RateDetails) => (
      <div
        key={item.id}
        className={twMerge(
          'border border-gray-100 bg-white p-1 pl-2 rounded flex items-center justify-center cursor-pointer shadow-[0_6px_5px_0_rgba(114,117,138,0.16)]',
          item.rate_plan === selectedRate?.rate_plan && 'bg-indigo-100 border-indigo-300',
        )}
        onClick={() => (!disabled ? handleSelectRate(item.rate_plan) : null)}
      >
        <span className="text-base leading-6 font-normal text-gray-800 mr-auto">{item.rate_plan}</span>

        {item.details_available && <span className="text-xs text-gray-500 mx-1">Rules available</span>}

        <span
          style={{ backgroundColor: rateStatusColor(item.accuracy) }}
          className="rounded-sm w-[51px] h-8 flex items-center justify-center text-gray-800 text-base leading-6 font-normal"
        >
          {Math.round(item.accuracy)}%
        </span>
      </div>
    ),
    [disabled, handleSelectRate, selectedRate?.rate_plan],
  )

  const renderSortOption = useCallback(
    ({ order, label, close }: { order: SortProps; label: string; close: () => void }) => (
      <label
        className={twMerge(
          'w-full flex items-center gap-2 p-2 text-base leading-none font-normal text-gray-500',
          sort.by === order.by && sort.order === order.order && 'text-blue-800',
        )}
      >
        <input
          type="radio"
          checked={sort.by === order.by && sort.order === order.order}
          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 focus:ring-0"
          onChange={() => {
            setSort((prevState) => ({ ...prevState, order: order.order }))
            close()
          }}
        />
        {label}
      </label>
    ),
    [sort],
  )

  return (
    <div>
      <div className="flex justify-between mb-2 mt-4 pt-4 pb-2 border-b border-blue-100 text-gray-700 font-semibold text-md">
        <span>Booking code</span>
        <span>Accuracy</span>
      </div>
      <Menu as="div" className="relative">
        {({ open, close }) => (
          <>
            <Menu.Button className="p-0 h-auto border-none bg-transparent flex items-center justify-between w-full px-3 py-2 mb-2 text-blue-800">
              <span>
                {sort.order === 'asc' ? 'Worst to best' : sort.order === 'desc' ? 'Best to worst' : 'Default order'}
              </span>{' '}
              <ChevronDownIcon className={twMerge('w-4', open && 'rotate-180')} />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="p-3 flex flex-col text-gray-700 w-72 gap-3">
                  <div className="flex flex-col items-center gap-4">
                    {renderSortOption({ order: { by: 'accuracy', order: false }, label: 'Default order', close })}
                    {renderSortOption({
                      order: { by: 'accuracy', order: 'asc' },
                      label: 'Worst to best',
                      close,
                    })}
                    {renderSortOption({
                      order: { by: 'accuracy', order: 'desc' },
                      label: 'Best to worst',
                      close,
                    })}
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <div className="flex flex-col gap-2">{rateList.map(renderRate)}</div>
    </div>
  )
}

export default DesktopCodeList
