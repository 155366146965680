import React, { useCallback, useMemo, useState } from 'react'
import SidebarWrapperContext, { SidebarWrapperContextValue } from './SidebarWrapperContext'

const SidebarWrapperContextProvider: React.FC = (props) => {
  const [isCollapsed, setIsCollapsed] = useState<SidebarWrapperContextValue['isCollapsed']>(
    localStorage.getItem('menuExpanded') === 'false',
  )

  const toggleIsCollapsed = useCallback(() => {
    setIsCollapsed((prevState) => {
      localStorage.setItem('menuExpanded', String(prevState))

      return !prevState
    })
  }, [])

  const contextValue = useMemo(
    () => ({
      isCollapsed,
      toggleIsCollapsed,
    }),
    [isCollapsed, toggleIsCollapsed],
  )

  return <SidebarWrapperContext.Provider value={contextValue}>{props.children}</SidebarWrapperContext.Provider>
}

export default SidebarWrapperContextProvider
