import { QueryKey, useQuery, UseQueryOptions } from 'react-query'
import qs from 'qs'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { ApiResponseWithMeta } from 'api/types/ApiResponseWithMeta'
import { ListUser, UserListQueryParams } from 'types/domain/User'
import { RequestHeadersWithGdsScope } from 'api/types/RequestHeadersWithGdsScope'

import { USERS_ENDPOINT_KEY } from './constants'

type GetUserListResourceResponse = ApiResponseWithMeta<Array<ListUser>, unknown>

export const getUserListResource = ({
  headers,
  query,
}: {
  headers?: RequestHeadersWithGdsScope
  query?: UserListQueryParams
}): Promise<GetUserListResourceResponse> =>
  api.get(`/${USERS_ENDPOINT_KEY}?${qs.stringify(query, { skipNulls: true })}`, { headers })

export const useGetUserListResource = ({
  headers,
  query,
  queryOptions,
  customQueryKey,
}: {
  headers?: RequestHeadersWithGdsScope
  query?: UserListQueryParams
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getUserListResource>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || [USERS_ENDPOINT_KEY, ...Object.values(query || {}), ...Object.values(headers || {})],
    () => getUserListResource({ query, headers }),
    queryOptions,
  )
