import React from 'react'
import { useHistory } from 'react-router-dom'
import { Pagination } from 'antd'
import { round } from 'lodash'
import { twMerge } from 'tailwind-merge'

import { getCxFromStyles } from 'helpers'
import StatisticsItem from 'modules/StatisticsModule/types/StatisticsItem'
import { ListMeta, QueryParams } from 'hooks/useMeasurementsQuery'
import { useIsTablet } from 'hooks'
import { ListUser } from 'modules/UsersModule/types'
import { Column, Sorter } from 'ui/Table'
import { Table } from 'ui-v2'
import { Tiles } from 'ui'

import styles from './List.module.scss'

interface Props {
  data?: StatisticsItem[]
  meta?: ListMeta
  setPage: (page: number) => void
  setSorter: (sorter: Sorter<keyof StatisticsItem>) => void
  isOwnerList: boolean
  isTravelAgency: boolean
  params: QueryParams
}

const List: React.FC<Props> = ({ data, meta, params, setPage, setSorter, isOwnerList, isTravelAgency }) => {
  const cx = getCxFromStyles(styles)
  const history = useHistory()
  const isTablet = useIsTablet()
  const sorter: Sorter<keyof ListUser> = { by: params.sort_by, direction: params.sort_direction }

  const extraOwnerColumns: Column<StatisticsItem>[] = isOwnerList
    ? [
        {
          title: 'Plan',
          dataIndex: 'plan',
          sorter: true,
        },
      ]
    : []
  const extraHotelColumns: Column<StatisticsItem>[] = isOwnerList
    ? []
    : [
        {
          title: 'Period Average',
          dataIndex: 'gds_queries_daily_average',
          sorter: true,
        },
      ]

  const columns: Column<StatisticsItem>[] = isTravelAgency
    ? [
        {
          title: isOwnerList ? 'Hotel name' : 'Subscribers',
          dataIndex: 'name',
          sorter: true,
        },
        {
          title: 'Email',
          dataIndex: 'email',
          sorter: true,
        },
        {
          title: 'PDF Export',
          dataIndex: 'export_pdf',
          sorter: true,
        },
        {
          title: 'XLS Export',
          dataIndex: 'export_excel',
          sorter: true,
        },
        {
          title: 'Copied Links',
          dataIndex: 'copy_link',
          sorter: true,
        },
        {
          title: 'Issues',
          dataIndex: 'create_issue',
          sorter: true,
        },
        {
          title: 'Searches',
          dataIndex: 'gds_hotel_details',
          sorter: true,
        },
      ]
    : [
        {
          title: isOwnerList ? 'Hotel name' : 'Subscribers',
          dataIndex: 'name',
          sorter: true,
        },
        {
          title: 'Email',
          dataIndex: 'email',
          sorter: true,
        },
        ...extraOwnerColumns,
        {
          title: 'PDF Export',
          dataIndex: 'export_pdf',
          sorter: true,
        },
        {
          title: 'XLS Export',
          dataIndex: 'export_excel',
          sorter: true,
        },
        {
          title: 'RD Queries',
          dataIndex: 'gds_hotel_rate_details',
          sorter: true,
        },
        {
          title: 'RR Queries',
          dataIndex: 'gds_hotel_rate_rules',
          sorter: true,
        },
        ...extraHotelColumns,
        {
          title: 'GDS Queries',
          dataIndex: 'gds_queries',
          sorter: true,
        },
      ]

  return (
    <div className={cx('root')}>
      {isTablet ? (
        <Tiles<StatisticsItem>
          dataSource={data || []}
          fields={columns}
          pagination={{
            pageSize: meta?.per_page,
            page: meta?.current_page,
            totalItems: meta?.total,
            onChange: setPage,
          }}
        />
      ) : (
        <Table>
          <thead>
            <Table.Tr className="h-min-[46px]">
              <Table.Th sorter={sorter} setSorter={setSorter} name="name">
                {isOwnerList ? 'Hotel name' : 'Subscribers'}
              </Table.Th>
              <Table.Th sorter={sorter} setSorter={setSorter} name="email">
                Email
              </Table.Th>
              {isOwnerList && !isTravelAgency && (
                <Table.Th sorter={sorter} setSorter={setSorter} name="plan">
                  Plan
                </Table.Th>
              )}
              <Table.Th sorter={sorter} setSorter={setSorter} name="export_pdf">
                PDF Export
              </Table.Th>
              <Table.Th sorter={sorter} setSorter={setSorter} name="export_excel">
                XLS Export
              </Table.Th>
              {isTravelAgency ? (
                <>
                  <Table.Th sorter={sorter} setSorter={setSorter} name="copy_link">
                    Copied Links
                  </Table.Th>
                  <Table.Th sorter={sorter} setSorter={setSorter} name="create_issue">
                    Issues
                  </Table.Th>
                  <Table.Th sorter={sorter} setSorter={setSorter} name="gds_hotel_details">
                    Searches
                  </Table.Th>
                </>
              ) : (
                <>
                  <Table.Th sorter={sorter} setSorter={setSorter} name="gds_hotel_rate_details">
                    RD Queries
                  </Table.Th>
                  <Table.Th sorter={sorter} setSorter={setSorter} name="gds_hotel_rate_rules">
                    RR Queries
                  </Table.Th>
                  {isOwnerList && (
                    <Table.Th sorter={sorter} setSorter={setSorter} name="gds_queries_daily_average">
                      Period Average
                    </Table.Th>
                  )}
                  <Table.Th sorter={sorter} setSorter={setSorter} name="gds_queries">
                    GDS Queries
                  </Table.Th>
                </>
              )}
            </Table.Tr>
          </thead>
          <tbody>
            {(data || []).map((item, index) => (
              <Table.Tr
                key={index}
                className={twMerge(!isOwnerList && 'cursor-pointer')}
                onClick={
                  !isOwnerList
                    ? (): void => {
                        item.id && history.push(`/admin/statistics/${item.id}`)
                      }
                    : undefined
                }
              >
                <Table.Td>{item.name}</Table.Td>
                <Table.Td>{item.email}</Table.Td>
                {isOwnerList && !isTravelAgency && <Table.Td>{item.plan}</Table.Td>}
                <Table.Td>{item.export_pdf}</Table.Td>
                <Table.Td>{item.export_excel}</Table.Td>
                {isTravelAgency ? (
                  <>
                    <Table.Td>{item.copy_link}</Table.Td>
                    <Table.Td>{item.create_issue}</Table.Td>
                    <Table.Td>{item.gds_hotel_details}</Table.Td>
                  </>
                ) : (
                  <>
                    <Table.Td>{item.gds_hotel_rate_details}</Table.Td>
                    <Table.Td>{item.gds_hotel_rate_rules}</Table.Td>
                    {isOwnerList && <Table.Td>{round(item.gds_queries_daily_average, 2)}</Table.Td>}
                    <Table.Td>{item.gds_queries}</Table.Td>
                  </>
                )}
              </Table.Tr>
            ))}
          </tbody>
        </Table>
      )}
      <div className="flex items-center justify-center pt-5">
        <Pagination
          current={meta?.current_page || 1}
          total={meta?.total || 1}
          onChange={setPage}
          showSizeChanger={false}
          hideOnSinglePage
          pageSize={meta?.per_page || 10}
        />
      </div>
    </div>
  )
}

export default List
