import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { RateRules } from 'types/domain/RateRules'

import { RequestHeadersWithGdsScope } from '../types/RequestHeadersWithGdsScope'

type GetPreviewRulesRequestParams = {
  token: string
}

type GetPreviewRulesResponse = {
  data: RateRules
}

export const getPreviewRules = ({
  params,
  headers,
}: {
  params: GetPreviewRulesRequestParams
  headers?: RequestHeadersWithGdsScope
}): Promise<GetPreviewRulesResponse> => api.get(`/previews/${params.token}/rules`, { headers })

export const useGetPreviewRules = ({
  params,
  headers,
  queryOptions,
  customQueryKey,
}: {
  params: GetPreviewRulesRequestParams
  headers?: RequestHeadersWithGdsScope
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getPreviewRules>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || [
      `previews/${params.token}/rules`,
      ...Object.values(params || {}),
      ...Object.values(headers || {}),
    ],
    () => getPreviewRules({ params, headers }),
    queryOptions,
  )
