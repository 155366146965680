import { QueryKey, useQuery, UseQueryOptions } from 'react-query'
import qs from 'qs'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { ApiResponseWithMeta } from 'api/types/ApiResponseWithMeta'
import { HotelsImportListItem } from 'types/domain/HotelsImportListItem'
import { ApiPaginationQuery } from 'api/types/ApiPaginationQuery'

import { HOTELS_IMPORTS_ENDPOINT_KEY } from './constants'

type GetHotelsImportListResourceRequestQuery = ApiPaginationQuery & {
  gds_id?: string
}

type GetHotelsImportListResourceResponse = ApiResponseWithMeta<Array<HotelsImportListItem>>

export const getHotelsImportListResource = ({
  query,
}: {
  query?: GetHotelsImportListResourceRequestQuery
}): Promise<GetHotelsImportListResourceResponse> =>
  api.get(`/hotels-imports?${qs.stringify(query, { skipNulls: true })}`)

export const useGetHotelsImportListResource = ({
  query,
  queryOptions,
  customQueryKey,
}: {
  query?: GetHotelsImportListResourceRequestQuery
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getHotelsImportListResource>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || [HOTELS_IMPORTS_ENDPOINT_KEY, ...Object.values(query || {})],
    () => getHotelsImportListResource({ query }),
    queryOptions,
  )
