import React, { useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import type { Error } from 'api'
import { Button, Input } from 'ui-v2'
import { ArrowRightIcon, TrashIcon } from '@heroicons/react/16/solid'

type Props = {
  changePasswordError: Error | null
  changePassword: (data: { old_password: string; password: string; password_confirmation: string }) => void
  onCancel: () => void
  isEdited: boolean
  isSaving: boolean
}

const Password: React.FC<Props> = ({ changePasswordError, changePassword, onCancel, isEdited, isSaving }) => {
  const { values, setFieldValue, errors, setErrors, handleSubmit, resetForm } = useFormik({
    initialValues: {
      old_password: '',
      password: '',
      password_confirmation: '',
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: yup.object({
      old_password: yup.string().required('Current password is required'),
      password: yup.string().min(8, 'Password should has at least 8 chars').required('Password is required'),
      password_confirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Password confirmation is required'),
    }),
    onSubmit: (values) => {
      changePassword(values)
    },
  })

  useEffect(() => {
    !isEdited && resetForm()
  }, [isEdited])

  useEffect(() => {
    setErrors(changePasswordError?.errors || {})
  }, [changePasswordError?.errors])

  return (
    <div className="flex flex-col gap-2 md:gap-4">
      {!isEdited && <Input value="fake password" htmlType="password" label="Password" isDisabled />}

      {isEdited && (
        <>
          <Input
            value={values.old_password}
            label="Your current password"
            htmlType="password"
            onChange={(value) => setFieldValue('old_password', value)}
            isDisabled={!isEdited}
            error={errors.old_password}
          />

          <Input
            value={values.password}
            label="New password"
            htmlType="password"
            onChange={(value) => setFieldValue('password', value)}
            isDisabled={!isEdited}
            error={errors.password}
          />

          <Input
            value={values.password_confirmation}
            label="Repeat new password"
            htmlType="password"
            onChange={(value) => setFieldValue('password_confirmation', value)}
            isDisabled={!isEdited}
            error={errors.password_confirmation}
          />

          <div className="flex justify-end gap-2">
            <Button
              size="large"
              onClick={(): void => {
                resetForm()
                onCancel()
              }}
            >
              <span className="inline-flex gap-2">
                Cancel <TrashIcon className="w-4" />
              </span>
            </Button>

            <Button size="large" variant="primary" isLoading={isSaving} onClick={() => handleSubmit()}>
              <span className="inline-flex gap-2">
                Submit <ArrowRightIcon className="w-4" />
              </span>
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default Password
