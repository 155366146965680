import React, { useCallback, useMemo, useState } from 'react'
import orderBy from 'lodash/orderBy'
import { isNull } from 'lodash'

import { Select } from 'ui-v2'
import { RateDetails } from 'types/domain/RateDetails'
import DesktopCodeList from './DesktopCodeList'

export type Props = {
  selectedRate: Nullable<RateDetails>
  rateList: Array<RateDetails>
  onSelectRate: (rate: RateDetails) => void
  disabled: boolean
}

export type SortProps = { by: 'accuracy'; order: 'asc' | 'desc' | false }

const List = (props: Props) => {
  const { selectedRate, rateList, onSelectRate, disabled } = props

  const [sort, setSort] = useState<SortProps>({
    by: 'accuracy',
    order: false,
  })

  const handleSelectRate = useCallback(
    (value: string) => {
      const foundRate = rateList.find((rate) => rate.rate_plan === value)

      if (foundRate) {
        onSelectRate(foundRate)
      }
    },
    [onSelectRate, rateList],
  )

  const rates = useMemo(() => orderBy(rateList, [sort.by], [sort.order]), [sort, rateList])

  const ratesIncludesCorporateOrder = useMemo(() => {
    if (sort.order === false) {
      return rateList
    }

    const indexesToMoveUp = rates.map((rate, index) => (rate.corporate_code || rate.corporate_id ? index : null))

    indexesToMoveUp.length > 0 &&
      indexesToMoveUp
        .filter((index) => !isNull(index))
        .map((index) => {
          const corporateRate = rates.splice(index, 1)[0]
          rates.unshift(corporateRate)
        })

    return rates
  }, [rates])

  return (
    <>
      <Select
        options={ratesIncludesCorporateOrder.map((item) => ({
          value: item.rate_plan,
          label: item.rate_plan,
        }))}
        label="Booking code"
        value={selectedRate?.rate_plan}
        onChange={(value) => value && handleSelectRate(value)}
        className="m-4 md:mx-0 xl:hidden"
        isDisabled={disabled}
      />
      <div className="hidden xl:block xl:w-72">
        <DesktopCodeList
          rateList={ratesIncludesCorporateOrder}
          handleSelectRate={handleSelectRate}
          selectedRate={selectedRate}
          disabled={disabled}
          sort={sort}
          setSort={setSort}
        />
      </div>
    </>
  )
}

export default List
