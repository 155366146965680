import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'

const closeUnmatchedHotelsInImport = (hotel_import: number): Promise<void> =>
  api.delete(`/hotels-imports/${hotel_import}`)

export const useCloseUnmatchedHotelsInImport = (
  queryOptions?: UseMutationOptions<Awaited<ReturnType<typeof closeUnmatchedHotelsInImport>>, ApiError, number>,
): UseMutationResult<void, ApiError, number> =>
  useMutation((hotel_import) => closeUnmatchedHotelsInImport(hotel_import), queryOptions)
