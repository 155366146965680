import { useQuery, QueryKey, UseQueryOptions } from 'react-query'
import qs from 'qs'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { RequestHeadersWithGdsScope } from 'api/types/RequestHeadersWithGdsScope'
import { Dashboard } from 'types/domain/Dashboard'

import { DASHBOARD_ENDPOINT_KEY } from './constants'

type GetDashboardResourceRequestQuery = {
  user_id?: number
  hotel_id?: number
  start_date?: string
  end_date?: string
  page?: number
  booking_code?: string
}

type GetDashboardResourceResponse = {
  data: Dashboard
}

export const getDashboardResource = ({
  query,
  headers,
}: {
  query: GetDashboardResourceRequestQuery
  headers: RequestHeadersWithGdsScope
}): Promise<GetDashboardResourceResponse> =>
  api.get(`/dashboard?${qs.stringify(query, { skipNulls: true })}`, { headers })

export const useGetDashboardResource = ({
  query,
  headers,
  queryOptions,
  customQueryKey,
}: {
  query: GetDashboardResourceRequestQuery
  headers: RequestHeadersWithGdsScope
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getDashboardResource>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || [DASHBOARD_ENDPOINT_KEY, ...Object.values(query), ...Object.values(headers)],
    () => getDashboardResource({ query, headers }),
    queryOptions,
  )
