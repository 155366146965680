import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { Typography } from 'antd'

import { getCxFromStyles } from 'helpers'
import api from 'api'
import type { Error } from 'api'

import { Button, ConfirmationModal, Modal } from 'ui'
import { notification } from 'helpers'
import { RemoveRedEye } from '@material-ui/icons'

import styles from './RejectModal.module.scss'
import './RejectModal.scss'

type Props = {
  userId: number
  userName: string
  onClose: () => void
  onSuccess: () => void
}

const RejectModal: React.FC<Props> = ({ userId, userName, onClose, onSuccess }) => {
  const cx = getCxFromStyles(styles)
  const [isVisible, setIsVisible] = useState(true)
  const [isConfirmationModalVisible, setConfirmationModalVisible] = useState<boolean>(false)

  const { mutate: rejectUser, isLoading } = useMutation((): Promise<void> => api.delete(`/users/${userId}`), {
    onSuccess: () => {
      onSuccess()
    },
    onError: (error: Error) => {
      notification.error({ message: error.message })
    },
  })

  return (
    <>
      <Modal
        visible={isVisible}
        onCancel={onClose}
        bodyStyle={{
          padding: '32px',
        }}
        overlay={false}
        className="rejectUserModal"
      >
        <div className={cx('modalContent')}>
          <Typography.Title level={2}>
            Reject user account <div className={cx('line')} />
          </Typography.Title>

          <span className={cx('smallText')}>User is inactive:</span>
          <span className={cx('userName')}>{userName}</span>

          <div className={cx('actions')}>
            <Button
              large
              primary
              className={cx('rejectBtn')}
              loading={isLoading}
              onClick={() => {
                setIsVisible(false)
                setConfirmationModalVisible(true)
              }}
            >
              <span>Reject</span>
              <RemoveRedEye />
            </Button>
          </div>
        </div>
      </Modal>
      <ConfirmationModal
        title="Are you sure that you want to dismiss user?"
        isLoading={isLoading}
        onSuccess={rejectUser}
        onCancel={() => {
          setConfirmationModalVisible(false)
          onClose()
        }}
        visible={isConfirmationModalVisible}
      />
    </>
  )
}

export default RejectModal
