import { useMutation, UseMutationResult } from 'react-query'

import api from 'api/index'
import { GdsCredentials } from 'types/domain/GdsCredentials'

import { ApiError } from '../types/ApiError'
import { ApiResponseWithMeta } from '../types/ApiResponseWithMeta'
import { GDS_CREDENTIALS_ENDPOINT_KEY } from './constants'

type Options = {
  onSuccess?: () => void
  onError?: (error: Error) => void
}

export const deleteGdsCredentials = (id: string): Promise<ApiResponseWithMeta<GdsCredentials>> =>
  api.delete(`/${GDS_CREDENTIALS_ENDPOINT_KEY}/${id}`)

export const useDeleteGdsCredentials = (
  options: Options = {},
): UseMutationResult<ApiResponseWithMeta<GdsCredentials>, ApiError, { id: string }> =>
  useMutation(({ id }) => deleteGdsCredentials(id), options)
