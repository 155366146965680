import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { FormikHelpers } from 'formik'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { XMarkIcon } from '@heroicons/react/16/solid'

import api from 'api'
import { notification } from 'helpers'
import { useBillingQuery, useSubscriptionQuery } from 'hooks'
import { Billing as BillingType } from 'types/domain/Billing'
import { BillingPaymentFormType } from 'types/PaymentForm'
import { BillingCard, BillingContact } from './components'
import { EditForm } from './components/types'
import { Button, Header } from 'ui-v2'

type Props = {
  onClose: () => void
}

const stripe = loadStripe(import.meta.env.VITE_STRIPE_KEY)

const BillingSettings: React.FC<Props> = ({ onClose }) => {
  const [isEdit, setEdit] = useState<EditForm | null>(null)

  const [stripeClientSecret, setStripeClientSecret] = useState('')

  const { data: billing, refetch: reloadBilling } = useBillingQuery()
  const { data: subscription, refetch: reloadSubscription } = useSubscriptionQuery()

  useEffect(() => {
    subscription?.subscription?.active &&
      api.post('/billing/payment-method/client-secret').then((data) => {
        setStripeClientSecret(data.data.client_secret)
      })
  }, [subscription])

  const { mutate: saveBilling } = useMutation(async (data: BillingType): Promise<void> => api.post('/billing', data))

  const handleSubmitContact = (
    values: BillingPaymentFormType,
    formikHelpers: FormikHelpers<BillingPaymentFormType>,
  ): void => {
    saveBilling(
      {
        ...values,
        country: values.country.id,
        state: values.country.id === 'US' ? values.stateUs.id : values.state,
      },
      {
        onSuccess: async () => {
          notification.success({ message: 'Saved successfully.' })
          reloadBilling()
          onClose()
        },
        onError: (error: any) => {
          notification.error({ message: error.message })
          formikHelpers.setErrors(error.errors)
          formikHelpers.setSubmitting(false)
        },
      },
    )
  }

  return (
    <div>
      <Header>
        <div className="flex items-center justify-between w-full">
          <h1 className="text-xl !leading-7 font-medium text-gray-800 m-0">Payment Settings</h1>

          <Button
            variant="secondary"
            onClick={onClose}
            className="flex items-center justify-center p-0 rounded w-8 h-8"
          >
            <XMarkIcon className="w-5" />
          </Button>
        </div>
      </Header>

      <div className="mx-2 my-4 rounded-lg md:mx-7">
        <div className="relative p-2 bg-white md:p-6 w-full flex flex-col gap-2 md:gap-6 lg:flex-row lg:items-start lg:justify-between">
          <div className="w-full">
            {stripeClientSecret && billing && (
              <Elements
                options={{
                  clientSecret: stripeClientSecret,
                  locale: 'en',
                  appearance: {
                    theme: 'stripe',
                  },
                }}
                stripe={stripe}
              >
                <BillingCard
                  isEdit={isEdit}
                  setEdit={setEdit}
                  onClose={onClose}
                  reloadSubscription={reloadSubscription}
                  cardLastFour={subscription?.payment_method?.card_last_four}
                />
              </Elements>
            )}
          </div>

          <BillingContact isEdit={isEdit} setEdit={setEdit} handleSubmit={handleSubmitContact} data={billing} />
        </div>
      </div>
    </div>
  )
}

export default BillingSettings
