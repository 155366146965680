import React, { useCallback } from 'react'
import { Spin } from 'antd'
import { FormikHelpers, FormikProvider, useFormik } from 'formik'
import { LoadingOutlined } from '@ant-design/icons'

import { FormikCheckbox, Header } from 'ui'
import { useGetGdsListResource } from 'api/gds/getGdsListResource'
import { useUpdateUser } from 'api/users/updateUser'
import ActionButtonGroup from 'ui/ActionButtonGroup'
import useGetUser from 'api/users/getUser'
import { getGdsDisplayName } from 'modules/UsersModule/utils'
import { notification } from 'helpers'

import styles from './GdsTab.module.scss'

type Props = {
  userId: string
}

type GdsKeyValue = { [key: string]: boolean }

const GdsTab: React.FC<Props> = ({ userId }) => {
  const { data, isFetched, isFetching: gdsFetching } = useGetGdsListResource()
  const gdsList = data?.data || []
  const { mutate } = useUpdateUser({})

  const { data: getUserData, isFetching: userFetching } = useGetUser(userId)
  const userData = getUserData?.data

  const onSubmit = useCallback(
    (data: GdsKeyValue, formikHelpers: FormikHelpers<GdsKeyValue>) => {
      if (!userData) return

      const gds: { id: number }[] = []
      for (const [key, value] of Object.entries(data)) {
        if (value) {
          gds.push({ id: Number(key) })
        }
      }
      mutate(
        { id: userData.id, data: { gds, role: userData.role } },
        {
          onSuccess: () => {
            notification.success({ message: 'GDSs updated.' })
            formikHelpers.setSubmitting(false)
          },
          onError: (error) => {
            notification.error({ message: error.message })
            formikHelpers.setSubmitting(false)
          },
        },
      )
    },
    [mutate, userData],
  )

  const getInitialValues = () => {
    const initialValues: GdsKeyValue = {}
    gdsList.forEach(
      (gds) => (initialValues[`${gds.id}`] = !!userData?.gds.find((userGds) => `${userGds.id}` === `${gds.id}`)),
    )

    return initialValues
  }

  const formik = useFormik<GdsKeyValue>({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: getInitialValues(),
    onSubmit,
    enableReinitialize: true,
  })

  return (
    <Spin spinning={gdsFetching || userFetching} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}>
      <Header>Active GDSs</Header>
      <div className={styles.form}>
        {isFetched && (
          <FormikProvider value={formik}>
            {gdsList.map((gds) => (
              <FormikCheckbox key={gds.id} name={`${gds.id}`} label={getGdsDisplayName(gds.name)} />
            ))}
            <ActionButtonGroup
              classes={{ root: styles.actions }}
              submitButton={{ onClick: formik.handleSubmit, loading: formik.isSubmitting }}
            />
          </FormikProvider>
        )}
      </div>
    </Spin>
  )
}

export default GdsTab
