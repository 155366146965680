import React, { useState } from 'react'
import Highlighter from 'react-highlight-words'
import { twMerge } from 'tailwind-merge'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import isArray from 'lodash/isArray'

import { Button } from 'ui-v2'
import InfoModal from './components/InfoModal'

interface Field {
  icon: JSX.Element
  title: string
  info?: string
  values: (string | number | boolean | undefined)[]
  sublines?: ({ title: string; info?: string; values: (string | undefined)[]; highlightPhrases?: boolean } | null)[]
  highlightPhrases?: boolean
  isOptional?: boolean
}

interface Section {
  label: string
  fields: Array<Field | null>
}

interface Props {
  sections: Array<Section | null>
  showErrors?: boolean
  highlightedPhrases?: string[]
  className?: string
}

const List: React.FC<Props> = ({ sections, highlightedPhrases, className, showErrors = true }) => {
  const [modalInfoData, setModalInfoData] = useState<{ title?: string; content?: string }>({})

  const filteredSections = sections
    .filter((section: Section | null): section is Section => !!section)
    .map((section) => ({
      ...section,
      fields: section.fields
        .filter((field: Field | null): field is Field => !!field)
        .map((field) => ({
          ...field,
          values: isArray(field.values) ? field.values.filter((value) => value) : undefined,
          sublines: (field.sublines || [])
            .flatMap((value) => (value ? [value] : []))
            .map((subline) => ({
              ...subline,
              values: subline.values.filter((value) => value),
            })),
        })),
    }))

  return (
    <div className="flex flex-col gap-4 lg:mx-2">
      {filteredSections.map((section) => (
        <section key={section.label} className="max-w-4xl bg-bg-light rounded-md p-2 flex flex-col gap-2 lg:p-6">
          <h2 className="m-0 text-lg leading-8 tracking-wider uppercase text-gray-800 font-semibold">
            {section.label}
          </h2>

          {section.fields.map((field) => {
            const isFieldOptional = field.isOptional

            return (
              <React.Fragment key={`${section.label}-${field.title}`}>
                <div className="lg:flex lg:items-center lg:gap-4">
                  <div className="flex items-center gap-2 mb-0.5 lg:w-56">
                    <div
                      className={twMerge(
                        'text-gray-500 scale-75',
                        showErrors &&
                          isArray(field.values) &&
                          field.values.length === 0 &&
                          (field.sublines || []).length === 0 &&
                          !isFieldOptional &&
                          'text-validation-error',
                      )}
                    >
                      {field.icon}
                    </div>
                    <div className="text-base leading-none font-normal text-gray-700 flex items-center justify-between flex-1">
                      {field.title}
                    </div>
                  </div>

                  <div className="flex flex-wrap flex-1 items-center">
                    {isArray(field.values) &&
                      field.values.map((value) => (
                        <span
                          key={`${section.label}-${field.title}-${value}`}
                          className="inline-flex justify-center items-center bg-white border border-indigo-300 p-2 rounded text-base leading-6 font-normal text-gray-800 mb-1 mr-1"
                        >
                          {field.highlightPhrases && highlightedPhrases?.length ? (
                            <Highlighter
                              highlightStyle={{ backgroundColor: 'yellow' }}
                              searchWords={highlightedPhrases}
                              autoEscape={true}
                              textToHighlight={String(value)}
                            />
                          ) : (
                            <>{value}</>
                          )}
                        </span>
                      ))}

                    {showErrors &&
                      ((isArray(field.values) && field.values.length === 0 && (field.sublines || []).length === 0) ||
                        (isArray(field.values) && !field.values[0])) && (
                        <span
                          className={twMerge(
                            'inline-flex justify-center items-center bg-white border border-indigo-300 p-2 h-[40px] rounded text-base leading-6 font-normal text-gray-800 mb-1 mr-1',
                            showErrors &&
                              (field.values.length === 0 || (field.sublines || []).length === 0) &&
                              !isFieldOptional &&
                              'text-validation-error bg-validation-error-bg border-validation-error-outline',
                          )}
                        >
                          {isFieldOptional ? '-' : 'Missing'}
                        </span>
                      )}

                    {!!field.info && (
                      <Button
                        variant="secondary"
                        className="inline-flex rounded p-0 w-[34px] items-center justify-center lg:mt-1 mb-1 border-none bg-transparent"
                        onClick={(): void => setModalInfoData({ title: field.title, content: field.info })}
                      >
                        <InformationCircleIcon className="w-6" color="#4F46E5" />
                      </Button>
                    )}
                  </div>
                </div>

                {(field.sublines || []).length > 0 && (
                  <div className="pl-12">
                    {field.sublines?.map((subline) => (
                      <div key={`${section.label}-${field.title}-${subline.title}`} className="flex items-center">
                        <div className="text-base leading-none font-normal text-gray-700 w-32 lg:w-48">
                          {subline.title}
                        </div>
                        <div className="flex-1 flex flex-wrap lg:pl-4">
                          {subline.values.map((value) => (
                            <span
                              key={`${section.label}-${field.title}-${subline.title}-${value}`}
                              className="inline-flex justify-center items-center bg-white border border-indigo-300 p-2 rounded text-base leading-6 font-normal text-gray-800 mb-1 mr-1"
                            >
                              {subline.highlightPhrases && highlightedPhrases?.length ? (
                                <Highlighter
                                  highlightStyle={{ backgroundColor: 'yellow' }}
                                  searchWords={highlightedPhrases}
                                  autoEscape={true}
                                  textToHighlight={String(value)}
                                />
                              ) : (
                                <>{value}</>
                              )}
                            </span>
                          ))}
                          {showErrors && subline.values.length === 0 && (
                            <span className="inline-flex justify-center items-center text-validation-error bg-validation-error-bg border-validation-error-outline border p-2 h-[40px] rounded text-base leading-6 font-normal mb-1 mr-1">
                              Missing
                            </span>
                          )}

                          {!!subline.info && (
                            <Button
                              variant="secondary"
                              className="rounded p-0 w-[34px] bg-white flex items-center justify-center"
                              onClick={(): void => setModalInfoData({ title: subline.title, content: subline.info })}
                            >
                              <InformationCircleIcon className="w-5" />
                            </Button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </React.Fragment>
            )
          })}
        </section>
      ))}

      {!!modalInfoData.title && !!modalInfoData.content && (
        <InfoModal
          title={modalInfoData.title}
          content={modalInfoData.content}
          onClose={(): void => setModalInfoData({})}
        />
      )}
    </div>
  )
}

export default List
