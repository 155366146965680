import { useState, useEffect } from 'react'

interface Domensions {
  height: number
  width: number
}

const useDimensions = (): Domensions => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  useEffect((): (() => void) => {
    function handleResize(): void {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }

    window.addEventListener('resize', handleResize)

    return (): void => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return dimensions
}

export default useDimensions
