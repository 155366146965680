import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { formatDistanceToNowStrict } from 'date-fns'

import { Button, Table } from 'ui-v2'
import { ReportedIssue } from 'types/domain/ReportedIssue'
import StatusSelect from 'modules/ReportedIssuesModule/components/StatusSelect'
import { ISSUE_STATUS_KEYS } from 'constants/issuesStatuses'
import { ArrowLongRightIcon } from '@heroicons/react/24/outline'

interface IProps {
  data: ReportedIssue[]
  onIssueStatusChanged: () => void
}

const List = ({ data, onIssueStatusChanged }: IProps): JSX.Element => {
  const history = useHistory()
  const location = useLocation()
  const isAdmin = location.pathname.includes('admin')

  const renderActionButton = (issue: ReportedIssue) => (
    <Button
      variant="secondary"
      className="w-[30px] flex items-center justify-center"
      onClick={() => history.push(`${isAdmin ? '/admin' : ''}/reported-issues/${issue.id}`)}
    >
      <ArrowLongRightIcon className="w-5" />
    </Button>
  )

  return (
    <div>
      <div className="flex flex-col gap-2 md:gap-6 xl:hidden">
        {data.map((issue) => (
          <div
            className="flex flex-col gap-2 border border-indigo-100 rounded p-2 md:p-6 even:bg-bg-light"
            key={issue.id}
          >
            <div className="flex gap-6">
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">#</div>
                <div className="text-base leading-6 font-normal text-gray-700">{issue.id}</div>
              </div>
              <div className="w-1/2 flex justify-end">
                <StatusSelect
                  status={issue.status as ISSUE_STATUS_KEYS}
                  issueId={issue.id}
                  onStatusChanged={onIssueStatusChanged}
                />
              </div>
            </div>

            <div className="flex gap-2">
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">Property name</div>
                <div className="text-base leading-6 font-normal text-gray-700">{issue.property_name}</div>
              </div>
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">Property ID</div>
                <div className="text-base leading-6 font-normal text-gray-700">{issue.property_id}</div>
              </div>
            </div>

            <div className="flex gap-2">
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">GDS</div>
                <div className="text-base leading-6 font-normal text-gray-700">{issue.gds_name}</div>
              </div>
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">Email address</div>
                <div className="text-base leading-6 font-normal text-gray-700">{issue.email || '-'}</div>
              </div>
            </div>

            <div className="w-full">
              <div className="text-xs leading-none font-normal text-gray-500">Issue description</div>
              <div className="text-base leading-6 font-normal text-gray-700">{issue.description}</div>
            </div>

            <div className="w-full flex justify-between items-center gap-2">
              <div>
                <div className="text-base leading-6 font-normal text-gray-700">
                  {formatDistanceToNowStrict(new Date(issue.created_at))} ago
                </div>
                {isAdmin && `(${issue.requester_name})`}
              </div>
              {renderActionButton(issue)}
            </div>
          </div>
        ))}
      </div>

      <div className="hidden xl:block w-100">
        <Table className="table-fixed">
          <thead>
            <Table.Tr>
              <Table.Th className="w-[75px]">#</Table.Th>
              <Table.Th>Name</Table.Th>
              {isAdmin && <Table.Th>Requester</Table.Th>}
              <Table.Th>GDS</Table.Th>
              <Table.Th className="w-[90px]">ID</Table.Th>
              <Table.Th>Email address</Table.Th>
              <Table.Th>Issue description</Table.Th>
              <Table.Th>Reported</Table.Th>
              <Table.Th>Status</Table.Th>
              <Table.Th className="w-[62px]"></Table.Th>
            </Table.Tr>
          </thead>
          <tbody>
            {data.map((issue) => (
              <Table.Tr key={issue.id}>
                <Table.Td className="w-[75px]">{issue.id}</Table.Td>
                <Table.Td>{issue.property_name}</Table.Td>
                {isAdmin && <Table.Td>{issue.requester_name}</Table.Td>}
                <Table.Td>{issue.gds_name}</Table.Td>
                <Table.Td className="w-[90px]">{issue.property_id}</Table.Td>
                <Table.Td>{issue.email || '-'}</Table.Td>
                <Table.Td>
                  <div className="line-clamp-2">{issue.description || '-'}</div>
                </Table.Td>
                <Table.Td>{formatDistanceToNowStrict(new Date(issue.created_at))} ago</Table.Td>
                <Table.Td>
                  <StatusSelect
                    status={issue.status as ISSUE_STATUS_KEYS}
                    issueId={issue.id}
                    onStatusChanged={onIssueStatusChanged}
                  />
                </Table.Td>
                <Table.Td className="w-[62px] pt-2 px-4">{renderActionButton(issue)}</Table.Td>
              </Table.Tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default List
