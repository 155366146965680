import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PlusIcon } from '@heroicons/react/16/solid'

import { RejectModal, ActivationModal } from 'modules/UsersModule/components'
import { ApiError } from 'api/types/ApiError'
import { ListUser, ListUserMeta } from 'types/domain/User'
import { useGetUserListResource } from 'api/users/getUserListResource'
import List from 'modules/UsersModule/components/List'
import { useGetUserResource } from 'api/users/getUserResource'
import { ACCOUNT_TYPE_KEYS } from 'constants/accounts'
import { notification } from 'helpers'
import { Button } from 'ui-v2'

interface PropertyOwnersProps {
  accountType: ACCOUNT_TYPE_KEYS
  setParams: (params: { [key: string]: string | number | boolean }) => void
  params: { [key: string]: string | number | boolean }
  onError: (error: ApiError | null) => void
  setPage: (page: number) => void
}

const ListContainer: (props: PropertyOwnersProps) => JSX.Element = ({
  accountType,
  setParams,
  params,
  onError,
  setPage,
}: PropertyOwnersProps) => {
  const history = useHistory()

  const [meta, setMeta] = useState<Partial<ListUserMeta>>({
    current_page: 1,
  })
  const [data, setData] = useState<ListUser[]>([])
  const [activateUser, setActivateUser] = useState<ListUser>()
  const [rejectUser, setRejectUser] = useState<ListUser>()

  const { data: userToConfirmResource } = useGetUserResource({ params: { id: activateUser?.id || -1 } })

  const { refetch, isFetching } = useGetUserListResource({
    query: {
      account_type: accountType,
      ...params,
      ...params.filters,
    },
    queryOptions: {
      onSuccess: ({ data, meta }) => {
        setData(data)
        setMeta(meta)
        setRejectUser(undefined)
        setActivateUser(undefined)
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        // updateUsersCount(meta.total)
      },
      onError: (error: ApiError) => {
        onError(error)
        notification.error({ message: error.message })
      },
      onSettled: () => onError(null),
    },
  })

  const onActivationSuccess = (): void => {
    setActivateUser(undefined)
    void refetch()
  }

  if (userToConfirmResource) {
    return (
      <ActivationModal
        onClose={(): void => setActivateUser(undefined)}
        user={userToConfirmResource.data}
        onSuccess={onActivationSuccess}
      />
    )
  }

  return (
    <>
      <div className="pl-4 pb-4">
        <Button
          className="mr-auto"
          variant="secondary"
          onClick={() => history.push('/admin/users/invite-user', { role: accountType })}
        >
          <span className="inline-flex items-center gap-2">
            Invite user <PlusIcon className="w-5" />
          </span>
        </Button>
      </div>

      <List
        meta={meta}
        isLoading={isFetching}
        data={data}
        setPage={setPage}
        setSorter={(sorter): void =>
          setParams((old) => ({ ...old, ...{ sort_by: sorter.by, sort_direction: sorter.direction }, page: 1 }))
        }
        activateUser={setActivateUser}
        rejectUser={setRejectUser}
        params={params}
      />

      {!!rejectUser && (
        <RejectModal
          userId={rejectUser.id}
          userName={rejectUser.name}
          onClose={(): void => setRejectUser(undefined)}
          onSuccess={() => {
            setRejectUser(undefined)
            void refetch()
          }}
        />
      )}
    </>
  )
}

export default ListContainer
