import React, { useState } from 'react'

import { User } from 'modules/UsersModule/types'
import { usePlansQuery } from 'hooks'
import { useHistory } from 'react-router-dom'
import { Typography } from 'antd'

import { Select } from 'ui'
import { RejectModal } from 'modules/UsersModule/components'

import styles from './SubscriptionSelect.module.scss'

type Props = {
  user: User
  activationPlan?: string
  setActivationPlan: (planId: string) => void
}

const SubscriptionSelect: React.FC<Props> = ({ user, activationPlan, setActivationPlan }) => {
  const history = useHistory()
  const { data: plans } = usePlansQuery()

  const [rejectModal, setRejectModal] = useState(false)

  return (
    <div>
      <span>User is inactive:</span>

      <Typography.Title level={2} className={styles.title}>
        {user?.first_name} {user?.last_name}
        <br />
        {user?.email}
      </Typography.Title>

      <Select
        className={styles.select}
        placeholder="Select subscription plan"
        value={activationPlan}
        onChange={setActivationPlan}
        options={(plans || []).map((item) => ({
          value: item.id,
          label: item.name || item.price + '/' + item.billing_cycle,
        }))}
      />

      {rejectModal && (
        <RejectModal
          userId={user.id}
          userName={`${user.last_name} ${user.first_name}`}
          onClose={(): void => setRejectModal(false)}
          onSuccess={() => {
            history.push('/admin/users?refresh=1')
          }}
        />
      )}
    </div>
  )
}

export default SubscriptionSelect
