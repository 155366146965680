import { QueryKey, useMutation, UseMutationOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { Hotel } from 'types/domain/Hotel'

import { HOTELS_ENDPOINT_KEY } from './constants'

export type UpdateHotelRequestBody = {
  name: string
  country: string
  city: string
  address: string
  gds_hotels: Array<{
    id: Nullable<number>
    gds_id: number
    property_id: string
    chain_code: string
  }>
}

type UpdateHotelResponse = {
  data: Hotel
}

export const updateHotel = (body: UpdateHotelRequestBody): Promise<UpdateHotelResponse> => api.post(`/hotels`, body)

export const useAddHotel = ({
  queryOptions,
  customQueryKey,
}: {
  queryOptions?: UseMutationOptions<Awaited<ReturnType<typeof updateHotel>>, ApiError, UpdateHotelRequestBody>
  customQueryKey?: QueryKey
}) => useMutation(customQueryKey || HOTELS_ENDPOINT_KEY, (body) => updateHotel(body), queryOptions)
