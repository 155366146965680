import { useMutation, UseMutationResult, UseMutationOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from '../types/ApiError'
import { User } from '../../modules/UsersModule/types'

const updateUser = (id: number, data: Partial<User>): Promise<User> => api.patch(`/users/${id}`, data)

type RequestUser = {
  id: number
  data: Partial<User>
}

export const useUpdateUser = ({
  queryOptions,
}: {
  queryOptions?: UseMutationOptions<Awaited<ReturnType<typeof updateUser>>, ApiError, RequestUser>
}): UseMutationResult<User, ApiError, RequestUser> => useMutation(({ id, data }) => updateUser(id, data), queryOptions)
