import { useQuery, QueryKey, UseQueryOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { GdsCredentials } from 'types/domain/GdsCredentials'

import { GDS_CREDENTIALS_ENDPOINT_KEY } from './constants'

type GetGdsListResourceResponse = {
  data: Array<GdsCredentials>
}

export const getGdsCredentialsListResource = (): Promise<GetGdsListResourceResponse> =>
  api.get(`/${GDS_CREDENTIALS_ENDPOINT_KEY}`)

export const useGetGdsCredentialsListResource = ({
  queryOptions,
  customQueryKey,
}: {
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getGdsCredentialsListResource>>, ApiError>
  customQueryKey?: QueryKey
} = {}) => useQuery(customQueryKey || [GDS_CREDENTIALS_ENDPOINT_KEY], getGdsCredentialsListResource, queryOptions)
