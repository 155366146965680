import React, { FC, useMemo, useState } from 'react'
import { Add } from '@material-ui/icons'

import { GDS_LIST } from 'constants/gds'
import { notification } from 'helpers'
import { useCloseUnmatchedHotelsInImport } from 'api/hotelsImports/closeUnmatchedHotelsInImport'
import { useGetHotelsImportListResource } from 'api/hotelsImports/getHotelsImportListResource'
import { useAddHotelsImport } from 'api/hotelsImports/addHotelsImport'
import { HotelsImportListItem } from 'types/domain/HotelsImportListItem'
import { Button, Modal, Select, Table } from 'ui'

import styles from './Imports.module.scss'

interface Props {
  setCurrentImport: (item: HotelsImportListItem | null) => void
}

const Imports: FC<Props> = ({ setCurrentImport }) => {
  const [closeImportID, setCloseImportID] = useState<number | null>(null)
  const [newImportData, setNewImportData] = useState<{ gds_id?: string; file?: File } | null>(null)

  const { data: importsData, refetch: refetchImportsData } = useGetHotelsImportListResource({})
  const { mutate: closeUnmatched, isLoading: isCloseUnmatchedLoading } = useCloseUnmatchedHotelsInImport({
    onSuccess: () => {
      setCloseImportID(null)
      refetchImportsData()
    },
  })
  const { mutate: createHotelsImport, isLoading: isCreateHotelsImportLoading } = useAddHotelsImport({
    onSuccess: () => {
      refetchImportsData()
      setNewImportData(null)
    },
    onError: (error) => {
      notification.error({ message: Object.values(error.errors || {})[0] || error.message })
    },
  })

  const isOpenImport = useMemo(() => {
    return (importsData?.data.filter((item) => !item.resolved) || []).length > 0
  }, [importsData])

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h2>Imports</h2>

        <Button primary onClick={() => setNewImportData({})} disabled={isOpenImport}>
          <Add /> Add import
        </Button>
      </div>

      <Table<HotelsImportListItem>
        rowKey="id"
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
          },
          {
            title: 'GDS',
            dataIndex: 'gds_name',
          },
          {
            title: 'File',
            dataIndex: 'file_name',
          },
          {
            title: 'Total',
            dataIndex: 'total_count',
          },
          {
            title: 'Processed',
            dataIndex: 'proceeded_count',
          },
          {
            title: 'Auto Mapped',
            dataIndex: 'automatically_added_count',
          },
          {
            title: 'To Match',
            dataIndex: 'to_resolve_count',
          },
          {
            title: 'Mapped',
            dataIndex: 'resolved_count',
          },
          {
            title: 'Skipped',
            dataIndex: 'skipped_count',
          },
          {
            title: 'Created At',
            dataIndex: 'created_at',
          },
          {
            title: 'Status',
            dataIndex: 'ended_at',
            renderCell: (record) =>
              record.resolved ? 'Closed' : record.total_count === record.proceeded_count ? 'Processed' : 'Pending',
          },
          {
            title: '',
            key: 'actions',
            renderCell: (record) =>
              !record.resolved && (
                <button
                  className={styles.closeButton}
                  onClick={(event) => {
                    event.stopPropagation()
                    setCloseImportID(record.id)
                  }}
                >
                  Close
                </button>
              ),
          },
        ]}
        dataSource={importsData?.data || []}
        onRowClick={(record) => {
          setCurrentImport(record)
        }}
      />

      {closeImportID && (
        <Modal visible onCancel={() => setCloseImportID(null)}>
          <div className={styles.confirmation}>
            Are you sure?
            <div className={styles.actions}>
              <button onClick={() => setCloseImportID(null)} className={styles.cancel}>
                Cancel
              </button>
              <Button
                className={styles.red}
                onClick={() => {
                  closeUnmatched(closeImportID)
                }}
                loading={isCloseUnmatchedLoading}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {!!newImportData && (
        <Modal
          visible
          onCancel={() => setNewImportData(null)}
          bodyStyle={{
            background: 'white',
          }}
        >
          <div className={styles.newImportWrapper}>
            <Select
              placeholder="GDS"
              options={GDS_LIST.map((gds) => ({ value: String(gds.id), label: gds.label }))}
              value={newImportData.gds_id}
              onChange={(value) =>
                setNewImportData((prevState) => ({
                  ...prevState,
                  gds_id: value,
                }))
              }
            />

            <input
              type="file"
              className={styles.inputFile}
              onChange={(event) =>
                setNewImportData((prevState) => ({
                  ...prevState,
                  file: event.target.files?.[0],
                }))
              }
            />

            <Button
              primary
              disabled={!newImportData.file || !newImportData.gds_id}
              loading={isCreateHotelsImportLoading}
              onClick={() =>
                newImportData.file &&
                newImportData.gds_id &&
                createHotelsImport({
                  gds_id: Number(newImportData.gds_id),
                  file: newImportData.file,
                })
              }
            >
              Import
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default Imports
