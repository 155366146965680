import React, { useState } from 'react'
import moment from 'moment'

import { notification } from 'helpers'
import { Button, Input, Modal } from 'ui-v2'
import { Subscription } from 'types/domain/Subscription'
import { useCancelSubscriptionSelf } from 'api/subscription/cancelSubscriptionSelf'
import { useCancelSubscriptionUser } from 'api/subscription/cancelSubscriptionUser'
import { getErrorMessage } from 'helpers/error'

import { ArrowRightIcon, XMarkIcon } from '@heroicons/react/16/solid'

type Props = {
  onCancel: () => void
  onSuccess: () => void
  subscription: Subscription
  userId?: number
}

const ConfirmCancelSubscriptionModal = (props: Props) => {
  const { onCancel, onSuccess, subscription, userId } = props
  const [step, setStep] = useState(0)

  const cancelSubscriptionSelf = useCancelSubscriptionSelf()
  const cancelSubscriptionUser = useCancelSubscriptionUser({ params: { user_id: userId ?? -1 } })
  const cancelSubscription = userId ? cancelSubscriptionUser : cancelSubscriptionSelf

  const onConfirm = async () => {
    try {
      await cancelSubscription.mutateAsync()
      onSuccess()
    } catch (error) {
      notification.error({ message: getErrorMessage(error) })
    }
  }

  return (
    <Modal
      onClose={onCancel}
      title={step === 0 ? 'Are you sure you want to cancel your subscription?' : 'Subscription Cancellation'}
    >
      {step === 0 ? (
        <div className="text-sm leading-5 font-normal text-gray-500 mt-2">
          If you cancel your subscription, you will only be able to use RateCleaner until the end of your billing
          period.
          <div className="flex justify-end gap-2 mt-2">
            <Button
              size="large"
              onClick={(): void => {
                onCancel()
              }}
            >
              <span className="inline-flex gap-2">
                No <XMarkIcon className="w-5" />
              </span>
            </Button>

            <Button size="large" variant="primary" onClick={() => setStep((value) => value + 1)}>
              <span className="inline-flex gap-2">
                Yes <ArrowRightIcon className="w-4" />
              </span>
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-2 mt-2">
          <Input
            value={`${subscription.plan} for ${subscription.price}/${subscription.billing_cycle}`}
            label="Your plan:"
            readOnly
          />

          <Input
            value={moment(subscription.next_payment_date).format('DD MMMM YYYY')}
            label="End of your subscription:"
            readOnly
          />

          <div className="flex justify-end gap-2 mt-2">
            <Button
              size="large"
              onClick={(): void => {
                onCancel()
              }}
              isDisabled={cancelSubscription.isLoading}
            >
              <span className="inline-flex gap-2">
                Don't cancel <XMarkIcon className="w-5" />
              </span>
            </Button>

            <Button size="large" variant="primary" onClick={() => onConfirm()} isLoading={cancelSubscription.isLoading}>
              <span className="inline-flex gap-2">
                Cancel subscription <ArrowRightIcon className="w-4" />
              </span>
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default ConfirmCancelSubscriptionModal
