import React, { useCallback, useState } from 'react'
import { Close } from '@material-ui/icons'

import styles from './CloseMonthMode.module.scss'

const useCloseMonthMode = () => {
  const [mode, setMode] = useState<'date' | 'month'>('date')

  const renderCloseMonthButton = useCallback(() => {
    if (mode !== 'month') return null
    return (
      <div className={styles.closeMonthButton} onClick={() => setMode('date')}>
        <Close />
      </div>
    )
  }, [mode])

  return { mode, setMode, renderCloseMonthButton }
}

export default useCloseMonthMode
