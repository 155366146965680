import { QueryKey, useQuery, UseQueryOptions } from 'react-query'
import qs from 'qs'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { RequestHeadersWithGdsScope } from 'api/types/RequestHeadersWithGdsScope'

type GetCheckupsRequestParams = {
  hotel_id: string
  start_date: string
  end_date: string
}

type GetCheckupsResponse = {
  data: {
    id: number
    date: string
  }[]
}

export const getCheckups = ({
  headers,
  query,
}: {
  headers: RequestHeadersWithGdsScope
  query: GetCheckupsRequestParams
}): Promise<GetCheckupsResponse> => api.get(`/checkups?${qs.stringify(query, { skipNulls: true })}`, { headers })

export const useGetCheckups = (
  headers: RequestHeadersWithGdsScope,
  query: GetCheckupsRequestParams,
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getCheckups>>, ApiError>,
  customQueryKey?: QueryKey,
) =>
  useQuery(
    customQueryKey || ['useGetCheckups', ...Object.values(query), ...Object.values(headers)],
    () => getCheckups({ headers, query }),
    queryOptions,
  )
