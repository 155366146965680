import { UserRoles } from 'modules/UsersModule/types'

export enum ACCOUNT_TYPE_KEYS {
  PROPERTY_OWNER = 'owner',
  TRAVEL_AGENT = 'travel_agent',
}

export const ACCOUNT_TYPE_LIST = [
  { value: ACCOUNT_TYPE_KEYS.PROPERTY_OWNER, label: 'Hotel', userRole: UserRoles.Owner },
  { value: ACCOUNT_TYPE_KEYS.TRAVEL_AGENT, label: 'Travel Agency', userRole: UserRoles.TravelAgent },
]
