import React from 'react'
import classNames from 'classnames'
import moment, { Moment } from 'moment'

import ActionButton from 'ui/ActionButton'
import Select from 'ui/Select'
import ActionButtonGroup from 'ui/ActionButtonGroup'
import { Subscription } from 'types/domain/Subscription'
import { Returned as EditSubscriptionProps } from 'modules/UsersModule/pages/Profile/components/SubscriptionAndBillingTab/useEditSubscription'
import { Returned as EditTrialProps } from 'modules/UsersModule/pages/Profile/components/SubscriptionAndBillingTab/useEditTrial'
import { DatePicker } from 'ui'

import styles from './SubscriptionDetailsTiles.module.scss'

type Props = {
  isPreviewSubscription?: boolean
  subscription: Nullable<Subscription>
  editSubscription?: EditSubscriptionProps
  editTrial?: EditTrialProps
  trialEndsAtDate: Moment | null
  isTravelAgency: boolean
  isViewedByAdmin: boolean
  isShortVersion?: boolean
}

const SubscriptionDetailsTiles = (props: Props) => {
  const {
    subscription,
    isPreviewSubscription,
    editSubscription,
    editTrial,
    trialEndsAtDate,
    isViewedByAdmin,
    isShortVersion,
  } = props

  const isFlatFee = !!subscription?.flat_fee

  return (
    <div className="flex flex-col gap-2 md:gap-4">
      <div className="flex items-center justify-between flex-col gap-2 md:gap-4 2xl:flex-row">
        {editSubscription?.isActive ? (
          <div className={classNames(styles.tile, styles.editedTile)}>
            <div className={styles.editedTileContent}>
              <div className={styles.tileTitle}>Your plan</div>
              <span className={styles.selectLabel}>Subscription plan:</span>
              <Select
                disabled={editSubscription.isSaving}
                placeholder="Select plan"
                options={editSubscription.subscriptionPlanList?.map((plan) => ({
                  label: plan.name,
                  value: plan.id,
                }))}
                onChange={editSubscription.onSelect}
                value={editSubscription.selectedSubscriptionPlanId}
              />
              <ActionButtonGroup
                classes={{ root: styles.editedTileActions }}
                cancelButton={{ onClick: editSubscription.onCancel, disabled: editSubscription.isSaving }}
                submitButton={{
                  onClick: editSubscription.onSave,
                  loading: editSubscription.isSaving,
                  disabled: editSubscription.selectedSubscriptionPlanId === subscription?.plan_id,
                }}
              />
            </div>
          </div>
        ) : (
          <div className="w-full">
            {editSubscription?.canEdit && (
              <ActionButton size="small" color="white" onClick={() => editSubscription.onStart()}>
                Edit
              </ActionButton>
            )}

            <div>
              <div className="bg-white rounded border border-indigo-300 p-1 pl-2">
                <div className="text-gray-500 text-[10px] leading-none mb-1">Subscription plan:</div>
                <div className="text-base leading-none font-normal text-gray-800">
                  {subscription ? (
                    <>
                      {subscription?.plan}
                      <span className="text-gray-500 ml-1">
                        for {subscription.price}/{subscription.billing_cycle}
                      </span>
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              </div>

              {!isFlatFee && subscription && (
                <div className="text-[9px] text-gray-400 leading-none p-1 pl-2">
                  Limit: {subscription.requests_in_plan} queries/{subscription.billing_cycle}
                </div>
              )}
            </div>
          </div>
        )}

        {isFlatFee ? (
          <div className="w-full">
            <div className="bg-white rounded border border-indigo-300 p-1 pl-2">
              <div className="text-gray-500 text-[10px] leading-none mb-1">Billing amount:</div>
              <div className="text-base leading-none font-normal text-gray-800">
                {subscription ? subscription.next_payment_price ?? subscription.price : '-'}
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <div className="bg-white rounded border border-indigo-300 p-1 pl-2">
              <div className="text-gray-500 text-[10px] leading-none mb-1">
                {isPreviewSubscription || subscription?.requests_used === null ? 'Queries:' : 'Number of queries:'}
              </div>
              <div className="text-base leading-none font-normal text-gray-800">
                {subscription ? (
                  <span>
                    {isPreviewSubscription || subscription.requests_used === null ? (
                      subscription.requests_in_plan
                    ) : (
                      <>
                        <b>{subscription.requests_used}</b>
                        {subscription.requests_used && subscription.requests_used > subscription.requests_in_plan ? (
                          <span> ({subscription.requests_used - subscription.requests_in_plan} over limit)</span>
                        ) : null}
                      </>
                    )}
                  </span>
                ) : (
                  '-'
                )}
              </div>
            </div>
            {subscription && (
              <div className="text-[9px] text-gray-400 leading-none p-1 pl-2">
                {subscription.requests_price_above_threshold} for 100 queries over the limit
              </div>
            )}
          </div>
        )}
      </div>

      <div className="flex items-center justify-between flex-col gap-2 md:gap-4 2xl:flex-row">
        {!isShortVersion && (
          <div className="w-full">
            <div className="bg-white rounded border border-indigo-300 p-1 pl-2">
              <div className="text-gray-500 text-[10px] leading-none mb-1">Next billing date:</div>
              <div className="text-base leading-none font-normal text-gray-800">
                {subscription && subscription.active ? (
                  <>
                    {moment(subscription.next_payment_date).format('MMMM DD, YYYY')}

                    {subscription.ends_at && (
                      <span className="text-gray-500 ml-1">
                        Cancels {moment(subscription.ends_at).format('DD MMM YYYY')}
                      </span>
                    )}
                  </>
                ) : subscription?.ends_at ? (
                  <>Cancelled at {moment(subscription.ends_at).format('MMM DD, YYYY')}</>
                ) : (
                  '-'
                )}
              </div>
            </div>
          </div>
        )}

        {!isFlatFee && !isShortVersion && (
          <div className="w-full">
            <div className="bg-white rounded border border-indigo-300 p-1 pl-2">
              <div className="text-gray-500 text-[10px] leading-none mb-1">Current billing amount:</div>
              <div className="text-base leading-none font-normal text-gray-800">
                {subscription ? subscription.next_payment_price ?? subscription.price : '-'}
              </div>
            </div>
          </div>
        )}
      </div>

      {isViewedByAdmin && (
        <div className={styles.tilesRow}>
          <div className={styles.tile}>
            <div className={styles.tileTitle}>
              Trial:
              {editTrial?.canEdit && (
                <ActionButton size="small" color="white" onClick={() => editTrial.onStart()}>
                  Edit
                </ActionButton>
              )}
            </div>
            <div className={styles.tileValue}>
              {editTrial?.isActive ? (
                <div className={styles.editedTileContent}>
                  <DatePicker
                    placeholder="Set trial date"
                    value={editTrial.selectedEndsAtDate}
                    onChange={editTrial.onSelectDate}
                    className={styles.trialDatePicker}
                    minDate={moment()}
                  />
                  <ActionButtonGroup
                    classes={{ root: styles.editedTileActions }}
                    cancelButton={{ onClick: editTrial.onCancel, disabled: editTrial.isSaving }}
                    submitButton={{
                      onClick: editTrial.onSave,
                      loading: editTrial.isSaving,
                      disabled: !editTrial.selectedEndsAtDate,
                    }}
                    deleteButton={{
                      disabled: !trialEndsAtDate || editTrial.isSaving,
                      onClick: editTrial.onDelete,
                    }}
                  />
                </div>
              ) : (
                <span className={styles.tileValue}>
                  {trialEndsAtDate ? (
                    <b>Trial ends at {moment(trialEndsAtDate).format('MMMM DD, YYYY')}</b>
                  ) : (
                    <b>No trial</b>
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SubscriptionDetailsTiles
