export enum ISSUE_STATUS_KEYS {
  OPEN = 'open',
  IN_PROGRESS = 'in_progress',
  CLOSED = 'closed',
}

export const ISSUE_STATUSES = {
  [ISSUE_STATUS_KEYS.OPEN]: {
    label: 'Open',
    value: ISSUE_STATUS_KEYS.OPEN,
    className: 'red',
  },
  [ISSUE_STATUS_KEYS.IN_PROGRESS]: {
    label: 'In Progress',
    value: ISSUE_STATUS_KEYS.IN_PROGRESS,
    className: 'yellow',
  },
  [ISSUE_STATUS_KEYS.CLOSED]: {
    label: 'Closed',
    value: ISSUE_STATUS_KEYS.CLOSED,
    className: 'green',
  },
}
