import { ChartData } from 'chart.js/dist/types'

import { rateStatusColor } from './index'

const EMPTY_COLOR = '#F3F4F6'
const CHART_SPACING = 2
const LABEL_COLOR = '#1F2937'

export const getRateChartData = (rateValue: number): ChartData => {
  const values = [rateValue, 100 - rateValue]
  const colors = [rateStatusColor(rateValue), EMPTY_COLOR]

  return {
    datasets: [
      {
        data: values,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        borderWidth: 0,
        borderJoinStyle: 'round',
        cutout: '60%',
        spacing: rateValue === 0 || rateValue === 100 ? 0 : CHART_SPACING,
        rotation: 359,
      },
    ],
  }
}

export const getRateChartPlugins = (rateValue: number) => {
  return [
    {
      id: 'valueLabel',
      afterDraw: (chart) => {
        const width = chart.width,
          height = chart.height,
          ctx = chart.ctx,
          text = `${rateValue}%`

        ctx.restore()

        // Center percentage value
        const fontSize = (height / 83).toFixed(2)
        ctx.font = fontSize + 'em sans-serif'
        ctx.textBaseline = 'middle'
        ctx.fillStyle = LABEL_COLOR
        const textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2
        ctx.fillText(text, textX, textY)

        // Circle around center value
        const centerX = width / 2
        const centerY = height / 2
        const radius = Math.min(width, height) / 4
        const lineWidth = 1

        ctx.beginPath()
        ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI)
        ctx.lineWidth = lineWidth
        ctx.strokeStyle = EMPTY_COLOR
        ctx.stroke()

        ctx.save()
      },
    },
  ]
}
