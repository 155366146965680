import React, { useState } from 'react'
import { Dropdown, Menu } from 'antd'
import { MoreVert } from '@material-ui/icons'
import { twMerge } from 'tailwind-merge'

interface Props<T> {
  record: T
  menuItems: {
    key: string
    label: string
    onClick: (record: T) => void
    hide?: boolean
  }[]
}

export const TableDropdown = <T extends Record<string, any>>({ record, menuItems }: Props<T>): JSX.Element => {
  const [activeDropdown, setActiveDropdown] = useState<T | undefined>()

  return (
    <div className="flex text-right justify-center">
      <Dropdown
        overlayClassName="hrc-dropdown-bordered"
        overlay={
          <Menu>
            {menuItems
              .filter((item) => !item.hide)
              .map((item) => (
                <Menu.Item
                  key={item.key}
                  onClick={() => {
                    setActiveDropdown(undefined)
                    item.onClick && item.onClick(record)
                  }}
                >
                  {item.label}
                </Menu.Item>
              ))}
          </Menu>
        }
        placement="bottomRight"
        trigger={['click']}
        onVisibleChange={(visible): void => setActiveDropdown(visible ? record : undefined)}
        visible={activeDropdown === record}
      >
        <div
          className={twMerge(
            'h-9 w-9 inline-flex items-center justify-center text-blue-800 rounded-[17px] border-blue-800 bg-indigo-100 cursor-pointer hover:bg-blue-800 hover:text-white',
            activeDropdown === record && 'bg-blue-800 text-white',
          )}
        >
          <MoreVert />
        </div>
      </Dropdown>
    </div>
  )
}
