import React, { useEffect } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { ArrowRightIcon } from '@heroicons/react/16/solid'

import { Button, Input } from 'ui-v2'

interface Props {
  isSubmitting: boolean
  onSubmit: (values: { [key: string]: string }) => void
  apiErrors?: { [key: string]: string }
}

const ForgotPassword: React.FC<Props> = ({ onSubmit, isSubmitting, apiErrors }) => {
  const { values, errors, handleSubmit, setFieldValue, setErrors } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: yup.string().email('Incorrect e-mail address').required('Email is required'),
    }),
    validateOnChange: false,
    onSubmit: (values) => onSubmit(values),
  })

  useEffect(() => {
    setErrors(apiErrors || {})
  }, [apiErrors])

  return (
    <div className="w-full">
      <div className="pb-4 mb-4 border-b border-secondary">
        <h1 className="text-3xl leading-9 font-normal text-gray-800 m-0 mb-2">I forgot my password</h1>
        <div className="text-sm leading-5 font-normal text-gray-600">
          Enter your email. A new password activation link will be sent to you.
        </div>
      </div>

      <div className="flex flex-col items-center gap-4">
        <Input
          value={values.email}
          label="Email"
          onChange={(value) => setFieldValue('email', value)}
          error={errors.email}
          className="w-full"
        />

        <Button onClick={() => handleSubmit()} isLoading={isSubmitting} className="mt-8 w-64" variant="primary">
          <span className="inline-flex items-center gap-2">
            Send email with link <ArrowRightIcon className="w-3" />
          </span>
        </Button>
      </div>
    </div>
  )
}

export default ForgotPassword
