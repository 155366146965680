import React, { useEffect } from 'react'
import { ArrowRightIcon, TrashIcon, XMarkIcon } from '@heroicons/react/16/solid'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { Button, Input } from 'ui-v2'
import { GdsCredentialsFormProps } from '../types/GdsCredentialsForm'

const SabreForm = ({
  credentials,
  isEdited,
  onCancel,
  isSubmitting,
  onSubmit,
  apiErrors,
  onDelete,
}: GdsCredentialsFormProps) => {
  const { values, setFieldValue, errors, setErrors, handleSubmit, resetForm } = useFormik({
    initialValues: {
      username: credentials?.username || '',
      password: '',
      token: credentials?.token || '',
      pcc: credentials?.pcc || '',
    },
    enableReinitialize: true,
    validateOnChange: false,
    // validationSchema: yup.object({
    //   username: yup.string().required('Username is required'),
    //   password: yup.string().required('Password is required'),
    //   token: yup.string().required('API token is required'),
    //   pcc: yup.string().required('PCC is required'),
    // }),
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  useEffect(() => {
    !isEdited && resetForm()
  }, [isEdited])

  useEffect(() => {
    setErrors(apiErrors?.errors || {})
  }, [apiErrors?.errors])

  return (
    <div className="flex flex-col gap-2 md:gap-4">
      <div className="text-lg leading-8 tracking-wider uppercase font-semibold text-gray-800">
        Sabre <div className="text-xs leading-4 text-gray-400 normal-case">REST Hotel API</div>
      </div>

      <Input
        value={values.username}
        label="User name"
        onChange={(value) => setFieldValue('username', value)}
        isDisabled={!isEdited}
        error={errors.username}
      />

      <Input
        value={values.password}
        label="Password"
        htmlType="password"
        onChange={(value) => setFieldValue('password', value)}
        isDisabled={!isEdited}
        error={errors.password}
      />

      <Input
        value={values.token}
        label="REST API Token Credentials"
        onChange={(value) => setFieldValue('token', value)}
        isDisabled={!isEdited}
        error={errors.token}
      />

      <Input
        value={values.pcc}
        label="PCC"
        onChange={(value) => setFieldValue('pcc', value)}
        isDisabled={!isEdited}
        error={errors.pcc}
      />

      {isEdited && (
        <div className="flex justify-end gap-2">
          <Button size="large" onClick={() => onDelete(credentials?.id || -1)} className="mr-auto">
            <span className="inline-flex gap-2">
              Delete <TrashIcon className="w-4" />
            </span>
          </Button>

          <Button
            size="large"
            onClick={(): void => {
              resetForm()
              onCancel()
            }}
          >
            <span className="inline-flex gap-2">
              Cancel <XMarkIcon className="w-4" />
            </span>
          </Button>

          <Button size="large" variant="primary" isLoading={isSubmitting} onClick={() => handleSubmit()}>
            <span className="inline-flex gap-2">
              Submit <ArrowRightIcon className="w-4" />
            </span>
          </Button>
        </div>
      )}
    </div>
  )
}

export default SabreForm
