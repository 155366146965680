import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  Category,
  ChromeReaderMode,
  Face,
  Fastfood,
  Hotel,
  Image,
  Loop,
  Loyalty,
  MonetizationOn,
  More,
  QueryBuilder,
  SmokingRooms,
  Subtitles,
} from '@material-ui/icons'
import { Skeleton } from 'antd'
import { Moment } from 'moment'

import useGdsSelectContext from 'components/GdsSelect/useGdsSelectContext'
import List from 'modules/HotelsModule/pages/HotelPage/components/List'
import { RateDetails } from 'types/domain/RateDetails'
import { Checkup } from 'types/domain/Checkup'
import { parseCancellationDeadlineOffsetObjectToString } from 'helpers/cancellationDeadlineOffset'
import { RootState } from 'store'
import { UserRoles } from 'modules/UsersModule/types'
import useGetUser from 'api/users/getUser'
import { useGetHighlightedPhrasesResource } from 'api/highlightedPhrases/getHighlightedPhrasesResource'
import { OPTIONAL_RATE_FIELD_TITLES } from 'constants/optionalRateFields'

import { CheckupDetails } from '../CheckupDetails'

import styles from './RateDetailsTab.module.scss'

type Props = {
  rateDetails: RateDetails | null
  showCompareRatesModal?: () => void
  checkup?: Checkup
  onRefreshHotel?: () => void
  isLoading?: boolean
  selectedDate?: Moment
}

const RateDetailsTab = (props: Props) => {
  const { isSelectedSabre } = useGdsSelectContext()
  const { rateDetails, showCompareRatesModal, checkup, onRefreshHotel, isLoading, selectedDate } = props
  const { userId } = useParams<{ userId: string | undefined }>()
  const { profile } = useSelector((state: RootState) => state.auth)

  const { data: userData, isFetching: isUserFetching } = useGetUser(userId || '', { enabled: !!userId })
  const isTravelAgent = useMemo(
    () => userData?.data?.role === UserRoles.TravelAgent || profile?.role === UserRoles.TravelAgent,
    [userData],
  )

  const { data: highlightedPhrases } = useGetHighlightedPhrasesResource({})

  const getYesNoLabel = (value?: boolean | null) => {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No'
    }

    return undefined
  }

  const setOptionalConditioned = (field) => {
    if (
      field.title === 'Cancellation deadline:' &&
      !rateDetails?.refundable &&
      rateDetails?.guarantee_type !== 'Guarantee'
    ) {
      return true
    }

    return false
  }

  const rateFields =
    rateDetails?.corporate_code || rateDetails?.corporate_id
      ? [
          {
            icon: <Subtitles />,
            title: 'Negotiated rate name:',
            values: [rateDetails.corporate_code],
          },
          {
            icon: <Subtitles />,
            title: 'Corporate ID:',
            values: [rateDetails.corporate_id],
          },
        ]
      : []

  const sections = [
    {
      label: 'Rate',
      fields: [
        {
          icon: <Subtitles />,
          title: 'Booking code:',
          values: [rateDetails ? rateDetails.rate_plan : undefined],
          info: `
            <div>
              <p>The Booking Code uniquely identifies:</p>
              <ul>
                <li>Type of room provided</li>
                <li>Rate</li>
                <li>Rental policies (Rules)</li>
              </ul>

              <p>Rules for booking codes:</p>
              <ul>
                <li>A booking code can contain alphanumeric characters (<strong>A-Z</strong> and <strong>0-9</strong>).</li>
                <li>It cannot contain special symbols such as <strong>@</strong>, <strong>+</strong>, <strong>*</strong> or space.</li>
                <li>A booking code contains six or seven characters. Shorter or longer booking codes are not permitted. Any booking code can be created keeping these rules in mind.</li>
              </ul>

              <p>The chain determines the booking codes used at each of its properties.</p>

              <p>The following are all valid booking codes:</p>
              <ul>
                <li>A1KRAC</li>
                <li>B2DCOR</li>
                <li>B2QWKD1</li>
              </ul>
            </div>
          `,
        },
        ...rateFields,
        {
          icon: <Category />,
          title: 'Category:',
          values: [rateDetails ? rateDetails.rate_category?.value : undefined],
          info: `
            <p>Each Rate Code must have a Category Code assigned. The Category Code affects whether or not a rate is included in a general availability request range.</p>
            <p>The Rate Category Code is describing the primary condition required to qualify for the rate.</p>
            <p>Special booking codes are reserved for government employees, others correspond to weekend or special promotional rates. Remember that most OTAs, TMCs determine the display of rates by category.</p>
            <p>E.g.: TMC will look for <strong>AAA</strong>, <strong>GOV</strong> or <strong>MIL</strong>, or corporate to show them as preferred, while OTA will avoid these rates, filtering them by category, and looking for special or lowest <strong>LPR</strong> or <strong>PAR</strong> type of room provided.</p>
          `,
        },
        {
          icon: <Loyalty />,
          title: 'Name:',
          values: [rateDetails ? rateDetails.rate_name : undefined],
          info: `
            <p>Promote your rate using catchy or descriptive phrases, e.g.:</p>
            <ul>
              <li>Best Available Rate</li>
              <li>Corporate/Government/AAA Rate</li>
              <li>Save 20% Flex Rate</li>
              <li>Special Rate - Breakfast included</li>
              <li>Restricted Non-refundable Rate</li>
            </ul>
            <p>Describe the rate in the accurate way. Use words that alert the user about rate restrictions.</p>
          `,
          highlightPhrases: true,
        },
        rateDetails && rateDetails.rate_description
          ? {
              icon: <Loyalty />,
              title: 'Description:',
              values: [rateDetails.rate_description],
              highlightPhrases: true,
            }
          : null,
      ],
    },
    {
      label: 'Room',
      fields: [
        {
          icon: <ChromeReaderMode />,
          title: 'Description:',
          values: [rateDetails ? rateDetails.room_rate_description : undefined],
          info: `
            <p>Standardize your room description.</p>
            <p>Use the following formula:</p>
            <p><strong>[Room Name] [Bed Type(s)]</strong></p>
            <p><strong>[Min-Max Occupancy]</strong></p>
            <p><strong>[Main Room Facilities]</strong></p>
            <p><br /></p>
            <p>Example:</p>
            <p>1 Bedroom King Suite, 1 Extra-large double bed, 1 Sofa bed</p>
            <p>Occupancy min. 1 or 2 adults + 1 children (12y), max. 3 adults</p>
            <p>Room size 55 m², City view, Air conditioning, Private bathroom, Flat-screen TV, Soundproofing, Minibar, Free WiFi]</p>
          `,
          highlightPhrases: true,
        },
        isSelectedSabre
          ? null
          : {
              icon: <Face />,
              title: 'Adult count in room:',
              values: [rateDetails?.adults_count],
            },
        isSelectedSabre || checkup?.children === 0
          ? null
          : {
              icon: <Face />,
              title: 'Children count in room:',
              values: [rateDetails?.children_count],
            },
        {
          icon: <Hotel />,
          title: 'Bed types:',
          values: rateDetails
            ? !Array.isArray(rateDetails.beds) || rateDetails.beds.length === 0 || rateDetails.beds[0] === null
              ? []
              : rateDetails.beds.map((bed) => `${bed?.type} x ${bed?.quantity}`)
            : [],
          info: `
            <p>Specify type and number of beds, for instance: 1 double bed, 1 sofa bed.</p>
            <p>When <strong>Bedding type unknown or unspecified</strong> is returned, check why the Bed Type is missing. If you do not have the option to specify bed type, provide relevant data in the Room Description.</p>
          `,
        },
        {
          icon: <Fastfood />,
          title: 'Meal plans:',
          values: undefined,
          info: `
            <p>One of the key search and filtering parameters used by OTAs/TMCs.</p>
            <p>A status of <strong>Missing</strong> means that you have not provided any information. If not provided, your rate may not be displayed in the search results.</p>
            <p>Make sure you set the statistics accordingly: <strong>Yes</strong> for included, and <strong>No</strong> - for not included.</p>
          `,
          sublines: [
            {
              title: 'Breakfast:',
              values: [rateDetails ? getYesNoLabel(rateDetails.breakfast) : undefined],
            },
            {
              title: 'Lunch:',
              values: [rateDetails ? getYesNoLabel(rateDetails.lunch) : undefined],
            },
            {
              title: 'Dinner:',
              values: [rateDetails ? getYesNoLabel(rateDetails.dinner) : undefined],
            },
          ],
        },
        {
          icon: <SmokingRooms />,
          title: 'Smoking room:',
          values: [rateDetails ? getYesNoLabel(rateDetails.smoking) : undefined],
          info: `
            <p>
              A status of <strong>Missing</strong> means that you have not provided any information.<br />
              Choose one of the statuses: Yes or No.
            </p>
            <p>
              One of the key search and filtering parameters used by OTAs/TMCs.<br />
              A status of <strong>Missing</strong> means that you have not provided any information. If not provided, your rate may not be displayed in the search results.<br />
              Make sure you set the statistics accordingly: <strong>Yes</strong> for included, and <strong>No</strong> – for not included.
            </p>
          `,
        },
        {
          icon: <Image />,
          title: 'Room view:',
          values: [rateDetails ? rateDetails.room_view?.value : undefined],
          info: `
            <p>
              A status of <strong>Missing</strong> means that you have not provided any information.<br />
              Choose one of the descriptions, if relevant.
            </p>
          `,
        },
      ],
    },
    {
      label: 'Cancellation Policy',
      fields: [
        {
          icon: <Loop />,
          title: 'Refundable:',
          values: [rateDetails ? getYesNoLabel(rateDetails.refundable) : undefined],
          info: `
            <p>This is one of the crucial search attributes and filtering options used by OTAs/TMCs.</p>
            <p>A status of <strong>Missing</strong> means that you have not provided any information. If not provided, your rate may not be displayed in the search results or taken under consideration for selling.</p>
            <p>Make sure you set the status accordingly: <strong>Yes</strong> – refundable, <strong>No</strong> – non-refundable. If you have system limitations, add the <strong>Refundable</strong> status to the Rate description.</p>
          `,
        },
        {
          icon: <QueryBuilder />,
          title: 'Cancellation deadline:',
          values: [
            rateDetails
              ? rateDetails.cancellation_until ||
                (rateDetails.cancellation_deadline_offset
                  ? parseCancellationDeadlineOffsetObjectToString(rateDetails.cancellation_deadline_offset)
                  : undefined)
              : undefined,
          ],
          info: '<p>Set cancellation deadline. It is always required. If missing, OTA/TMC may display the rate improperly.</p>',
        },
        {
          icon: <More />,
          title: 'Rate guarantee type:',
          values: rateDetails ? [rateDetails.guarantee_type] : [],
          info: '<p>Required as form of payment.</p>',
        },
        isSelectedSabre
          ? null
          : {
              icon: <More />,
              title: 'Rate guarantee deadline:',
              values: rateDetails
                ? [
                    rateDetails.guarantee_deadline
                      ? rateDetails.guarantee_deadline
                      : rateDetails.guarantee_type === 'Prepaid'
                      ? '-'
                      : null,
                  ]
                : [],
              info: '<p>Required as form of payment.</p>',
            },
        {
          icon: <MonetizationOn />,
          title: 'Commission:',
          values: [
            rateDetails
              ? rateDetails.commission_type !== 'unknown' && rateDetails.commission_type !== null
                ? getYesNoLabel(
                    isSelectedSabre
                      ? rateDetails.commission_type !== 'None'
                      : rateDetails.commission_type === 'true'
                      ? true
                      : rateDetails.commission_type === 'false'
                      ? false
                      : null,
                  )
                : undefined
              : undefined,
          ],
          info: '<p>This is very important parameter usually used by the OTA/TMC for displaying/filtering rates.</p>',
          sublines: [
            {
              title: 'Commission value:',
              values: [
                rateDetails && rateDetails.commission_type !== null
                  ? (isSelectedSabre ? rateDetails.commission_type !== 'None' : rateDetails.commission_type === 'true')
                    ? rateDetails.commission_value && rateDetails.commission_value !== 'false'
                      ? rateDetails.commission_value
                      : '-'
                    : '-'
                  : undefined,
              ],
            },
          ],
        },
      ],
    },
    {
      label: 'Pricing',
      fields: [
        {
          icon: <MonetizationOn />,
          title: 'Rate base:',
          values: [rateDetails ? rateDetails.rate_base : undefined],
          info: '<p>Provide base rate (usually Total minus taxes).</p>',
        },
        {
          icon: <MonetizationOn />,
          title: 'Taxes:',
          values: [rateDetails ? rateDetails.taxes : undefined],
          info: '<p>Taxes.</p>',
        },
        isSelectedSabre
          ? null
          : {
              icon: <MonetizationOn />,
              title: 'Surcharges:',
              values: [rateDetails ? rateDetails.surcharges : undefined],
              info: '<p>Additional surcharges e.g. local taxes.</p>',
            },
        isSelectedSabre
          ? null
          : {
              icon: <MonetizationOn />,
              title: 'Other fees:',
              values: [rateDetails ? getYesNoLabel(rateDetails.other_fees) : undefined],
              info: '<p>Additional taxes, if relevant.</p>',
            },
        isSelectedSabre
          ? {
              icon: <MonetizationOn />,
              title: 'Fees:',
              values: [rateDetails ? rateDetails.total_fees : undefined],
              info: '<p>Additional fees</p>',
              sublines: rateDetails?.fees?.map((fee) => ({ title: fee.name + ':', values: [fee.amount] })),
            }
          : null,
        {
          icon: <MonetizationOn />,
          title: 'Total:',
          values: [rateDetails ? rateDetails.total : undefined],
          info: `
            <p>
              Total: base rate with all unknown taxes, surcharges and fees.<br />
              Check is the rate the same as presented on the hotel web site.
            </p>
          `,
        },
        isSelectedSabre
          ? null
          : {
              icon: <MonetizationOn />,
              title: 'Rate change indicator:',
              values: [rateDetails ? getYesNoLabel(rateDetails.rate_change_indicator) : undefined],
              info: '<p>Required when the rate is changing during the stay.</p>',
            },
      ],
    },
    {
      label: 'Extra Charges',
      fields: [
        {
          icon: <MonetizationOn />,
          title: 'Extra adult amount:',
          values: [rateDetails ? rateDetails.extra_adult_amount : undefined],
          info: '<p>This parameter displays number of adults sent in search request.</p>',
        },
        {
          icon: <MonetizationOn />,
          title: 'Adult rollaway charge:',
          values: [rateDetails ? rateDetails.extra_adult_rollaway_amount : undefined],
          info: '<p>Set charges if relevant for Adult Rollaway (<strong>Yes</strong> or <strong>No</strong>).</p>',
        },
        isSelectedSabre
          ? null
          : {
              icon: <MonetizationOn />,
              title: 'Resort fee:',
              values: [rateDetails ? rateDetails.extra_resort_fee : undefined],
              info: '<p>Provide Resort fees if required. If missing, OTA/TMC may not select your rate when searching for leisure or bleisure properties.</p>',
            },
      ],
    },
  ].map((section) => ({
    ...section,
    fields: section.fields.map((field) =>
      field
        ? {
            ...field,
            isOptional:
              OPTIONAL_RATE_FIELD_TITLES.includes(field.title.substring(0, field.title.length - 1)) ||
              setOptionalConditioned(field),
          }
        : null,
    ),
  }))

  if (isLoading || isUserFetching) {
    return (
      <div className={styles.skeletonWrapper}>
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </div>
    )
  }

  return (
    <>
      <CheckupDetails checkup={checkup} onRefreshHotel={onRefreshHotel} selectedDate={selectedDate} />
      <List sections={sections} highlightedPhrases={highlightedPhrases?.data} />
    </>
  )
}

export default RateDetailsTab
