import React, { createContext, FC, useCallback, useContext, useState } from 'react'
import noop from 'lodash/noop'
import moment from 'moment'

import { DateRange } from 'modules/DashboardModule/components/DateRangePicker/DateRangePicker'
import { stringifyMomentDate } from 'helpers/date'

const SESSION_STORAGE_KEY = 'DashboardRangeDatePickerRange'

interface IDashboardRangeDatePickerContext {
  selectedDateRange: DateRange
  setSelectedDateRange: (dateRange: DateRange) => void
  getDateRangeForApi: () => {
    start_date: string
    end_date: string
  }
}

const DashboardRangeDatePickerContext = createContext<IDashboardRangeDatePickerContext>({
  selectedDateRange: [null, null],
  setSelectedDateRange: noop,
  getDateRangeForApi: () => ({
    start_date: '',
    end_date: '',
  }),
})

const getInitialSelectedDateRange = (): DateRange => {
  const savedDateRange = sessionStorage.getItem(SESSION_STORAGE_KEY)

  if (savedDateRange) {
    return JSON.parse(`${savedDateRange.replaceAll(`'`, `"`)}`).map((date: string) => (date ? moment(date) : null))
  }

  return [moment(), moment().add(2, 'months')]
}

const DashboardRangeDatePickerProvider: FC = (props) => {
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(getInitialSelectedDateRange())

  const startDate = selectedDateRange[0]
  const endDate = selectedDateRange[1]

  const getDateRangeForApi = useCallback(
    () => ({
      start_date: startDate ? stringifyMomentDate(startDate) : '',
      end_date: endDate ? stringifyMomentDate(endDate) : '',
    }),
    [endDate, startDate],
  )

  const setDate = useCallback((dateRange: DateRange) => {
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(dateRange))
    setSelectedDateRange(dateRange)
  }, [])

  return (
    <DashboardRangeDatePickerContext.Provider
      value={{ selectedDateRange, setSelectedDateRange: setDate, getDateRangeForApi }}
    >
      {props.children}
    </DashboardRangeDatePickerContext.Provider>
  )
}

const useDashboardRangeDatePickerContext = () => useContext(DashboardRangeDatePickerContext)

export { DashboardRangeDatePickerProvider, useDashboardRangeDatePickerContext }
