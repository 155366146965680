import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getApiError } from 'helpers/error'
import { login } from '../../slices/auth'

import Login from './Login'
import { useLogin } from 'api/login'

const LoginContainer: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    mutate: onSubmit,
    error,
    isLoading,
  } = useLogin({
    onSuccess: ({ data: { token } }) => {
      dispatch(login(token))
      history.push('/')
    },
  })

  return (
    <Login
      onSubmit={(values) => onSubmit({ email: values.email, password: values.password })}
      isSubmitting={isLoading}
      apiErrors={getApiError(error)}
    />
  )
}

export default LoginContainer
