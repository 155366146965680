import React from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { Pagination } from 'antd'

import { useGetTrialInvitations } from 'api/users/getTrialInvitations'
import { Table } from 'ui-v2'
import { ListUser, UserRoles } from 'modules/UsersModule/types'
import { ApiError } from 'api/types/ApiError'
import { Sorter } from 'ui/Table'

type TrialInvitationsProps = {
  params: { [key: string]: string | number | boolean }
  onError: (error: ApiError | null) => void
  setParams: (params: { [key: string]: string | number | boolean }) => void
  setPage: (page: number) => void
}

const TrialInvitations = ({ params, setParams, setPage, onError }: TrialInvitationsProps) => {
  const sorter: Sorter<keyof ListUser> = { by: params.sort_by, direction: params.sort_direction }

  const { data, isLoading } = useGetTrialInvitations({
    query: { ...params, ...params.filters },
    queryOptions: {
      onError,
      onSettled: () => onError(null),
    },
  })

  const setSorter = (sorter): void =>
    setParams((old) => ({ ...old, ...{ sort_by: sorter.by, sort_direction: sorter.direction }, page: 1 }))

  return (
    <div className="flex flex-col gap-4">
      <span className="text-base leading-none font-normal text-gray-500">
        Number of invitations: {data?.meta.total}
      </span>

      <div className="flex flex-col gap-2 lg:hidden">
        {(data?.data || []).map((invitation) => (
          <div
            className="flex flex-col gap-2 border border-indigo-100 rounded p-2 even:bg-bg-light"
            key={invitation.id}
          >
            <div className="flex gap-2">
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">Date</div>
                <div className="text-base leading-6 font-normal text-gray-700">
                  {moment(invitation.created_at).format('DD.MM.YYYY')}
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">Email</div>
                <div className="text-base leading-6 font-normal text-gray-700 break-words">{invitation.email}</div>
              </div>
            </div>

            <div className="flex gap-2">
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">First name</div>
                <div className="text-base leading-6 font-normal text-gray-700">{invitation.first_name || '-'}</div>
              </div>
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">Last name</div>
                <div className="text-base leading-6 font-normal text-gray-700">{invitation.last_name || '-'}</div>
              </div>
            </div>

            <div className="flex gap-2">
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">Trial ends at</div>
                <div className="text-base leading-6 font-normal text-gray-700">
                  {moment(invitation.trial_ends_at).format('DD.MM.YYYY')}
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">Used at</div>
                <div className="text-base leading-6 font-normal text-gray-700">
                  {invitation.used_at ? moment(invitation.used_at).format('DD.MM.YYYY') : '-'}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="hidden lg:block">
        <Table>
          <thead>
            <Table.Tr>
              <Table.Th>Date</Table.Th>
              <Table.Th>Email</Table.Th>
              <Table.Th sorter={sorter} setSorter={setSorter} name="first_name">
                First name
              </Table.Th>
              <Table.Th sorter={sorter} setSorter={setSorter} name="last_name">
                Last name
              </Table.Th>
              <Table.Th>Account type</Table.Th>
              <Table.Th sorter={sorter} setSorter={setSorter} name="trial_ends_at">
                Trial ends at
              </Table.Th>
              <Table.Th>Used at</Table.Th>
            </Table.Tr>
          </thead>
          <tbody>
            {(data?.data || []).map((invitation) => (
              <Table.Tr key={invitation.id}>
                <Table.Td>{moment(invitation.created_at).format('DD.MM.YYYY')}</Table.Td>
                <Table.Td className="break-words">{invitation.email}</Table.Td>
                <Table.Td>{invitation.first_name || '-'}</Table.Td>
                <Table.Td>{invitation.last_name || '-'}</Table.Td>
                <Table.Td>{invitation.role === UserRoles.TravelAgent ? 'Travel Agent' : 'Hotel'}</Table.Td>
                <Table.Td>{moment(invitation.trial_ends_at).format('DD.MM.YYYY')}</Table.Td>
                <Table.Td>{invitation.used_at ? moment(invitation.used_at).format('DD.MM.YYYY') : '-'}</Table.Td>
              </Table.Tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div className="flex items-center justify-center pt-5">
        <Pagination
          current={data?.meta.current_page || 1}
          total={data?.meta.total || 1}
          onChange={setPage}
          showSizeChanger={false}
          hideOnSinglePage
          pageSize={data?.meta.per_page || 10}
        />
      </div>

      {data && data.data.length === 0 && <div className="text-center text-base text-gray-500 p-5">No Invitations</div>}
    </div>
  )
}

export default TrialInvitations
