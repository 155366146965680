import { useQuery, QueryKey, UseQueryOptions } from 'react-query'
import qs from 'qs'

import api from 'api/index'
import type { ApiError } from 'api/types/ApiError'
import { RequestHeadersWithGdsScope } from 'api/types/RequestHeadersWithGdsScope'
import { ReportedIssue as Issue } from 'types/domain/ReportedIssue'
import { ApiMeta } from 'api/types/ApiMeta'
import { ApiPaginationQuery } from 'api/types/ApiPaginationQuery'

import { ISSUES_ENDPOINT_KEY } from './constants'
import { ReportedIssueCounters } from 'modules/ReportedIssuesModule/pages/ReportedIssuesList/components/Counters/Counters'

export type GetIssueListResourceRequestParams = ApiPaginationQuery & {
  id?: number
  gds_id?: number
  requester_name?: string
  property_id?: string
  property_name?: string
  status?: number
}

export type GetIssueListResourceResponse = {
  data: Array<Issue>
  counters: ReportedIssueCounters
  meta: ApiMeta
}

export const getIssueListResource = ({
  query,
  headers,
}: {
  headers: RequestHeadersWithGdsScope
  query: GetIssueListResourceRequestParams
}): Promise<GetIssueListResourceResponse> =>
  api.get(`/${ISSUES_ENDPOINT_KEY}?${qs.stringify(query, { skipNulls: true })}`, { headers })

export const useGetIssueListResource = ({
  headers,
  query,
  queryOptions,
  customQueryKey,
}: {
  headers: RequestHeadersWithGdsScope
  query: GetIssueListResourceRequestParams
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getIssueListResource>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || [ISSUES_ENDPOINT_KEY, ...Object.values(query), ...Object.values(headers)],
    () => getIssueListResource({ query, headers }),
    queryOptions,
  )
