import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ArrowRightIcon, PlusIcon } from '@heroicons/react/16/solid'
import { MinusIcon, EyeIcon } from '@heroicons/react/24/solid'

import { Table, Button, Checkbox } from 'ui-v2'
import { HotelListItem } from 'types/domain/HotelListItem'
import { User } from 'types/domain/User'
import { SABRE_ID, TRAVEL_PORT_ID } from 'constants/gds'

type ListProps = {
  hotels: HotelListItem[]
  userHotels: User['hotels']
  isEditable?: boolean
  checkedHotels: number[]
  toggleAllHotel: () => void
  toggleHotel: (id: number) => void
  noDataMessage?: string
  assignHotel: (id: number) => void
  unassignHotel: (id: number) => void
}

const List = ({
  hotels,
  noDataMessage,
  isEditable,
  toggleHotel,
  toggleAllHotel,
  checkedHotels,
  assignHotel,
  unassignHotel,
  userHotels,
}: ListProps) => {
  const { userId } = useParams<{ userId: string | undefined }>()
  const history = useHistory()
  const isAdmin = !!userId

  const renderSabreId = (hotel: HotelListItem) =>
    hotel.gds_properties.find((gdsRecord) => gdsRecord.gds_id === SABRE_ID)?.property_id || '-'
  const renderTravelportID = (hotel: HotelListItem) =>
    hotel.gds_properties.find((gdsRecord) => gdsRecord.gds_id === TRAVEL_PORT_ID)?.property_id || '-'

  const renderActions = (hotel: HotelListItem) =>
    isEditable ? (
      userHotels.some((item) => hotel.id === item.id) ? (
        <div className="flex align-middle items-center gap-2">
          <Button
            className="p-0 w-[34px] inline-flex items-center justify-center border-none"
            variant="secondary"
            onClick={() =>
              history.push(`${isAdmin ? `/admin/users/${userId}` : ''}/hotel/details?hotel_id=${hotel.id}`)
            }
          >
            <EyeIcon className="w-4" />
          </Button>

          <Button
            className="p-0 w-[34px] inline-flex items-center justify-center bg-red-100 border-none"
            variant="danger"
            onClick={() => unassignHotel(hotel.id)}
          >
            <MinusIcon className="w-4 fill-[#DC2626]" />
          </Button>
        </div>
      ) : (
        <Button
          className="p-0 w-[34px] inline-flex items-center justify-center border-none"
          variant="secondary"
          onClick={() => assignHotel(hotel.id)}
        >
          <PlusIcon className="w-4" />
        </Button>
      )
    ) : (
      <Button
        className="p-0 w-[34px] inline-flex items-center justify-center border-none"
        variant="secondary"
        onClick={() => history.push(`${isAdmin ? `/admin/users/${userId}` : ''}/hotel/details?hotel_id=${hotel.id}`)}
      >
        <ArrowRightIcon className="w-4" />
      </Button>
    )

  return (
    <div>
      <div className="flex flex-col gap-2 lg:hidden">
        {hotels.map((hotel) => (
          <div className="flex flex-col gap-2 border border-indigo-100 rounded p-2 even:bg-bg-light" key={hotel.id}>
            <div className="flex gap-2">
              <div className="w-1/2">
                {isEditable && (
                  <Checkbox isChecked={checkedHotels.includes(hotel.id)} onChange={() => toggleHotel(hotel.id)} />
                )}
              </div>
              <div className="w-1/2 flex items-center justify-end gap-2">{renderActions(hotel)}</div>
            </div>

            <div className="w-full">
              <div className="text-xs leading-none font-normal text-gray-500">Name</div>
              <div className="text-base leading-6 font-normal text-gray-700">{hotel.name}</div>
            </div>

            <div className="flex gap-2">
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">Country</div>
                <div className="text-base leading-6 font-normal text-gray-700">{hotel.country}</div>
              </div>
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">City</div>
                <div className="text-base leading-6 font-normal text-gray-700">{hotel.city}</div>
              </div>
            </div>

            <div className="flex gap-2">
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">Sabre ID</div>
                <div className="text-base leading-6 font-normal text-gray-700">{renderSabreId(hotel)}</div>
              </div>
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">Travelport ID</div>
                <div className="text-base leading-6 font-normal text-gray-700">{renderTravelportID(hotel)}</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="hidden lg:block">
        <Table>
          <thead>
            <Table.Tr>
              {isEditable && (
                <Table.Th className="w-10">
                  <Checkbox
                    isChecked={checkedHotels.length === hotels.length}
                    onChange={() => toggleAllHotel()}
                    isDot={checkedHotels.length > 0 && checkedHotels.length !== hotels.length}
                  />
                </Table.Th>
              )}
              <Table.Th>Name</Table.Th>
              <Table.Th className="w-[115px]">Country</Table.Th>
              <Table.Th>City</Table.Th>
              <Table.Th>ID</Table.Th>
              <Table.Th className="w-20"></Table.Th>
            </Table.Tr>
          </thead>
          <tbody>
            {hotels.map((hotel) => (
              <Table.Tr key={hotel.id}>
                {isEditable && (
                  <Table.Td>
                    <Checkbox isChecked={checkedHotels.includes(hotel.id)} onChange={() => toggleHotel(hotel.id)} />
                  </Table.Td>
                )}
                <Table.Td>{hotel.name}</Table.Td>
                <Table.Td>{hotel.country}</Table.Td>
                <Table.Td>{hotel.city}</Table.Td>
                <Table.Td>
                  <div className="flex gap-2">
                    <div className="w-1/2">
                      <div className="text-base leading-6 font-normal text-gray-700">{renderSabreId(hotel)}</div>
                      <div className="text-xs leading-none font-normal text-gray-500">Sabre</div>
                    </div>
                    <div className="w-1/2">
                      <div className="text-base leading-6 font-normal text-gray-700">{renderTravelportID(hotel)}</div>
                      <div className="text-xs leading-none font-normal text-gray-500">Travelport</div>
                    </div>
                  </div>
                </Table.Td>
                <Table.Td className="text-center p-1 align-middle">{renderActions(hotel)}</Table.Td>
              </Table.Tr>
            ))}
          </tbody>
        </Table>
      </div>

      {hotels.length === 0 && <div className="text-center text-base text-gray-500 p-5">{noDataMessage}</div>}
    </div>
  )
}

export default List
