import React from 'react'
import { useParams } from 'react-router-dom'
import { ArrowRightIcon } from '@heroicons/react/16/solid'

import HotelSearchListItem from 'modules/HotelsModule/types/HotelSearchListItem'
import { Table, Button } from 'ui-v2'

interface ListProps {
  hotels: HotelSearchListItem[]
  isSabreAvailable: boolean
  isTravelPortAvailable: boolean
  noDataMessage?: string
  onSelectHotel: (hotel: HotelSearchListItem, redirectUrl: string) => void
}

const List = ({ hotels, isSabreAvailable, isTravelPortAvailable, noDataMessage, onSelectHotel }: ListProps) => {
  const { userId } = useParams<{ userId: string | undefined }>()
  const isAdmin = !!userId

  const renderSabreLink = (hotel: HotelSearchListItem) => {
    return hotel.property_ids.sabre_id ? (
      <button
        className="text-base leading-none font-normal border-none bg-transparent p-0 h-auto text-blue-800"
        onClick={(): void =>
          onSelectHotel(
            hotel,
            `${isAdmin ? `/admin/users/${userId}` : ''}/hotel/details?hotel_id=${hotel.id}&sabre_id=${
              hotel.property_ids.sabre_id
            }`,
          )
        }
      >
        {hotel.property_ids.sabre_id}
      </button>
    ) : (
      '-'
    )
  }
  const renderTravelportLink = (hotel: HotelSearchListItem) => {
    return hotel.property_ids.travelport_id ? (
      <button
        className="text-base leading-none font-normal border-none bg-transparent p-0 h-auto text-blue-800"
        onClick={(): void =>
          onSelectHotel(
            hotel,
            `${isAdmin ? `/admin/users/${userId}` : ''}/hotel/details?hotel_id=${hotel.id}&travelport_id=${
              hotel.property_ids.travelport_id
            }`,
          )
        }
      >
        {hotel.property_ids.travelport_id}
      </button>
    ) : (
      '-'
    )
  }

  return (
    <div>
      <div className="flex flex-col gap-2 lg:hidden">
        {hotels.map((hotel) => (
          <Button
            className="h-auto border border-indigo-100 rounded p-2 even:bg-bg-light text-left"
            childrenClassName="flex flex-col w-full gap-2"
            onClick={(): void =>
              onSelectHotel(hotel, `${isAdmin ? `/admin/users/${userId}` : ''}/hotel/details?hotel_id=${hotel.id}`)
            }
            key={hotel.id}
          >
            <div className="w-full">
              <div className="text-xs leading-none font-normal text-gray-500">Name</div>
              <div className="text-base leading-normal font-normal text-gray-700">{hotel.name}</div>
            </div>

            <div className="flex gap-2">
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">Country</div>
                <div className="text-base leading-normal font-normal text-gray-700">{hotel.country}</div>
              </div>
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">City</div>
                <div className="text-base leading-normal font-normal text-gray-700">{hotel.city}</div>
              </div>
            </div>

            <div className="flex gap-2">
              {isSabreAvailable && (
                <div className="w-1/2">
                  <div className="text-xs leading-tight font-normal text-gray-500">Sabre ID</div>
                  <div className="text-base leading-normal font-normal text-gray-700">{renderSabreLink(hotel)}</div>
                </div>
              )}
              {isTravelPortAvailable && (
                <div className="w-1/2">
                  <div className="text-xs leading-tight font-normal text-gray-500">Travelport ID</div>
                  <div className="text-base leading-normal font-normal text-gray-700">
                    {renderTravelportLink(hotel)}
                  </div>
                </div>
              )}
            </div>
          </Button>
        ))}
      </div>

      <div className="hidden lg:block">
        <Table>
          <thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>City</Table.Th>
              <Table.Th className="w-[115px]">Country</Table.Th>
              <Table.Th>ID</Table.Th>
              <Table.Th>Chain code</Table.Th>
              <Table.Th className="w-14"></Table.Th>
            </Table.Tr>
          </thead>
          <tbody>
            {hotels.map((hotel) => (
              <Table.Tr key={hotel.id}>
                <Table.Td>{hotel.name}</Table.Td>
                <Table.Td>{hotel.city}</Table.Td>
                <Table.Td>{hotel.country}</Table.Td>
                <Table.Td>
                  <div className="flex gap-2">
                    {isSabreAvailable && (
                      <div className="w-1/2">
                        <div className="text-sm leading-tight font-normal text-gray-700">{renderSabreLink(hotel)}</div>
                        <div className="text-xs leading-none font-normal text-gray-400">Sabre</div>
                      </div>
                    )}
                    {isTravelPortAvailable && (
                      <div className="w-1/2">
                        <div className="text-sm leading-tight font-normal text-gray-700">
                          {renderTravelportLink(hotel)}
                        </div>
                        <div className="text-xs leading-none font-normal text-gray-400">Travelport</div>
                      </div>
                    )}
                  </div>
                </Table.Td>
                <Table.Td className="text-center p-1 align-middle">
                  <div className="flex gap-2">
                    {isSabreAvailable && (
                      <div className="w-1/2">
                        <div className="text-sm leading-tight font-normal text-gray-700">
                          {hotel.chain_codes.sabre_chain_code}
                        </div>
                        <div className="text-xs leading-none font-normal text-gray-400">Sabre</div>
                      </div>
                    )}
                    {isTravelPortAvailable && (
                      <div className="w-1/2">
                        <div className="text-sm leading-tight font-normal text-gray-700">
                          {hotel.chain_codes.travelport_chain_code}
                        </div>
                        <div className="text-xs leading-none font-normal text-gray-400">Travelport</div>
                      </div>
                    )}
                  </div>
                </Table.Td>
                <Table.Td className="text-center p-1 align-middle">
                  <Button
                    className="p-0 w-[34px] inline-flex items-center justify-center border-none"
                    variant="secondary"
                    onClick={(): void =>
                      onSelectHotel(
                        hotel,
                        `${isAdmin ? `/admin/users/${userId}` : ''}/hotel/details?hotel_id=${hotel.id}`,
                      )
                    }
                    isDisabled={
                      !(
                        (isTravelPortAvailable && hotel.property_ids.travelport_id) ||
                        (isSabreAvailable && hotel.property_ids.sabre_id)
                      )
                    }
                  >
                    <ArrowRightIcon className="w-4" />
                  </Button>
                </Table.Td>
              </Table.Tr>
            ))}
          </tbody>
        </Table>
      </div>

      {hotels.length === 0 && <div className="text-center text-base text-gray-500 p-5">{noDataMessage}</div>}
    </div>
  )
}

export default List
