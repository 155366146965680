import { useContext } from 'react'
import SidebarWrapperContext from './SidebarWrapperContext'

const useSidebarWrapperContext = () => {
  const { isCollapsed, toggleIsCollapsed } = useContext(SidebarWrapperContext)

  return {
    isSidebarCollapsed: isCollapsed,
    toggleSidebarCollapsed: toggleIsCollapsed,
  }
}

export default useSidebarWrapperContext
