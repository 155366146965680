import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { EyeIcon, XMarkIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'

import { DashboardHotel } from 'types/domain/DashboardHotel'
import { MainLayoutContext } from 'layouts/Main/MainContext'
import { Button } from 'ui-v2'

interface Props {
  hotel: DashboardHotel | null
  handleClose: () => void
}

const HotelHero: React.FC<Props> = ({ hotel, handleClose }) => {
  const history = useHistory()
  const mainLayoutContext = useContext(MainLayoutContext)

  return (
    <div className="p-4 rounded-lg bg-white border border-indigo-100 relative">
      <Button
        variant="secondary"
        onClick={handleClose}
        className="absolute top-1 right-1 rounded w-[34px] p-0 inline-flex items-center justify-center border-none"
      >
        <XMarkIcon className="w-6" />
      </Button>

      {hotel && (
        <div className="flex flex-col gap-4 md:flex-row md:flex-wrap">
          <div>
            <h1 className="text-lg leading-8 font-medium text-gray-800 m-0">{hotel.name}</h1>
            <span className="text-sm leading-none font-normal text-gray-500">Property ID: {hotel.property_id}</span>
          </div>

          <div className="flex items-center justify-center gap-4 md:gap-2">
            {/*<Button onClick={(): void => history.push(`${mainLayoutContext.linkPrefix}/hotel/${hotel.id}/card`)}>*/}
            {/*  <span className="inline-flex items-center justify-center gap-2">*/}
            {/*    Hotel Card <EyeIcon className="w-4" />*/}
            {/*  </span>*/}
            {/*</Button>*/}
            <Button onClick={(): void => history.push(`${mainLayoutContext.linkPrefix}/hotel?hotel_id=${hotel.id}`)}>
              <span className="inline-flex items-center justify-center gap-2">
                Rate Check
                <ArrowLongRightIcon className="w-4" />
              </span>
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default HotelHero
