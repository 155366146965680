import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'

const useReturnToMainDashboard = (): (() => void) => {
  const { userId } = useParams<{ userId: string | undefined }>()
  const { push: routerPush, location: routerLocation } = useHistory()

  return useCallback(() => {
    const isAdmin = !!userId

    routerPush(
      isAdmin ? `/admin/users/${userId}/dashboard${routerLocation.search}` : `/dashboard${routerLocation.search}`,
    )
  }, [routerPush, routerLocation, userId])
}

export default useReturnToMainDashboard
