import { createContext } from 'react'
import noop from 'lodash/noop'

export type GdsSelectContextValue = {
  value: string
  disabled: boolean
  setDisabled: (nextValue: boolean) => void
  setValue: (nextValue: string) => void
}

export default createContext<GdsSelectContextValue>({
  value: '',
  disabled: false,
  setDisabled: noop,
  setValue: noop,
})
