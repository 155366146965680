import React from 'react'
import { Checkbox, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { useGetGdsListResource } from 'api/gds/getGdsListResource'
import { getGdsDisplayName } from 'modules/UsersModule/utils'

import styles from './Gds.module.scss'

interface Props {
  selectedGds: { id: number }[]
  onChange: (data: { id: number }[]) => void
}

const Gds: React.FC<Props> = ({ selectedGds, onChange }) => {
  const { data, isFetching: gdsFetching } = useGetGdsListResource()
  const gdsList = data?.data || []

  const options = gdsList.map((gds) => ({ label: getGdsDisplayName(gds.name), value: gds.id }))

  const defaultValue = options
    .filter((option) => selectedGds.find((gds) => +gds.id === option.value))
    .map((option) => option.value)

  return (
    <Spin spinning={gdsFetching} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}>
      <Checkbox.Group
        className={styles.checkboxGroup}
        options={options}
        onChange={(values) => onChange(values.map((value) => ({ id: Number(value) })))}
        defaultValue={defaultValue}
      />
    </Spin>
  )
}

export default Gds
