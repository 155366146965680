import { Route } from 'routes/types/Route'

import { PropertyAddForm, PropertyForm } from './pages/PropertyForm'

const routes: Route[] = [
  { path: '/admin/properties/add', component: PropertyAddForm, layout: 'admin' },
  { path: '/admin/properties/:hotelId', component: PropertyForm, layout: 'admin' },
]

export default routes
