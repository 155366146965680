import { useQuery, QueryKey, UseQueryOptions } from 'react-query'
import qs from 'qs'

import api from 'api/index'
import type { ApiError } from 'api/types/ApiError'
import { RequestHeadersWithGdsScope } from 'api/types/RequestHeadersWithGdsScope'
import { BookingCode } from 'types/domain/BookingCode'

import { BOOKING_CODES_ENDPOINT_KEY } from './constants'
import { ApiMeta } from 'api/types/ApiMeta'

type GetBookingCodesListResourceRequestParams = {
  rate_plan: string
  hotel_id: number
  user_id?: number
  start_date: string
  end_date: string
  page: number
}

export type GetBookingCodesListResourceResponse = {
  data: Array<BookingCode>
  meta: ApiMeta
}

export const getBookingCodesListResource = ({
  query,
  headers,
}: {
  headers: RequestHeadersWithGdsScope
  query: GetBookingCodesListResourceRequestParams
}): Promise<GetBookingCodesListResourceResponse> =>
  api.get(`/${BOOKING_CODES_ENDPOINT_KEY}?${qs.stringify(query, { skipNulls: true })}`, { headers })

export const useGetBookingCodesListResource = ({
  headers,
  query,
  queryOptions,
  customQueryKey,
}: {
  headers: RequestHeadersWithGdsScope
  query: GetBookingCodesListResourceRequestParams
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getBookingCodesListResource>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || [BOOKING_CODES_ENDPOINT_KEY, ...Object.values(query), ...Object.values(headers)],
    () => getBookingCodesListResource({ query, headers }),
    queryOptions,
  )
