import { countries } from 'countries-list'

export const allCountries = Object.entries(countries).reduce<
  {
    value: string
    label: string
  }[]
>(
  (acc, [code, country]) => [
    ...acc,
    {
      value: code,
      label: country.name,
    },
  ],
  [],
)

export const allCountriesWithCodes = Object.entries(countries).reduce<
  {
    value: string
    label: string
  }[]
>(
  (acc, [code, country]) => [
    ...acc,
    {
      value: code,
      label: `${country.name} (${code})`,
    },
  ],
  [],
)
