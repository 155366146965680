import React from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { useIsTablet } from 'hooks'
import logo from 'assets/logo.svg'

const Default: React.FC = ({ children }) => {
  const isTablet = useIsTablet()

  return (
    <div
      className={twMerge(
        `bg-[url('assets/bg-login.svg')] bg-no-repeat bg-center bg-dark-blue bg-cover min-h-screen px-4 py-8 flex items-center justify-start flex-col gap-8 md:gap-20 md:p-8 md:justify-center`,
        isTablet && "bg-right bg-auto bg-[url('assets/bg-login-mobile.svg')]",
      )}
    >
      <Link to="/">
        <img className="w-[222px]" src={logo} alt="logo" />
      </Link>
      <div className="rounded-[14px] bg-gradient-to-b from-[#2A2E4E80] -rotate-3 w-full max-w-[544px]">
        <div className="rounded-[14px] bg-gradient-to-b from-[#2A2E4E] rotate-3 p-3.5">
          <div className="bg-bg-light rounded-lg p-4 md:p-12">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Default
