import { useMutation, UseMutationResult } from 'react-query'

import api from 'api/index'
import { ApiError } from '../types/ApiError'
import { ApiResponseWithMeta } from '../types/ApiResponseWithMeta'
import { GDS_CREDENTIALS_ENDPOINT_KEY } from './constants'
import { GdsCredentials } from 'types/domain/GdsCredentials'

type Options = {
  onSuccess?: () => void
  onError?: (error: Error) => void
}

export const createGdsCredentials = (data: GdsCredentials): Promise<ApiResponseWithMeta<GdsCredentials>> => api.post(`/${GDS_CREDENTIALS_ENDPOINT_KEY}`, data)

export const useCreateGdsCredentials = (options: Options = {}): UseMutationResult<ApiResponseWithMeta<GdsCredentials>, ApiError, GdsCredentials> =>
  useMutation(createGdsCredentials, options)
