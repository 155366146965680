import { twMerge } from 'tailwind-merge'

type InputProps = {
  value: string
  className?: string
  onChange?: (value?: string) => void
  label: string
  error?: string
  readOnly?: boolean
}

export const Textarea = ({ className, value, onChange, label, error, readOnly }: InputProps) => {
  return (
    <div
      className={twMerge(
        'relative border border-gray-100 bg-white rounded h-32',
        className,
        error && 'border-validation-error-outline mb-4',
      )}
    >
      <div
        className={twMerge(
          'absolute top-0.5 leading-none font-normal text-gray-500 text-xs left-2',
          error && 'text-validation-error',
        )}
      >
        {label}:
      </div>

      <textarea
        className={twMerge(
          'h-full pt-4 pb-0 px-2 w-full leading-none font-normal text-base text-gray-800 outline-none border-none rounded focus:ring-0',
        )}
        placeholder="-"
        onChange={(event) => onChange?.(event.target.value)}
        readOnly={readOnly}
        value={value || ''}
      />

      {error && (
        <span className="text-validation-error absolute -bottom-4 left-0 text-xs whitespace-nowrap overflow-ellipsis w-full overflow-hidden">
          {error}
        </span>
      )}
    </div>
  )
}
