import { useState } from 'react'
import qs from 'qs'
import { useQuery } from 'react-query'

import api, { Error, ListQueryParams, Meta } from 'api'
import { notification } from 'helpers'

import { Sorter } from '../ui/Table'
import { HotelListItem } from '../types/domain/HotelListItem'

type QueryParams = ListQueryParams & {
  sort_by: keyof HotelListItem
  user_id?: number
  only_active?: boolean
}

type Options = {
  enabled?: boolean
}

type ReturnType = {
  data: HotelListItem[]
  meta: Meta
  isFetching: boolean
  setPage: (page: number) => void
  sorter: Sorter<keyof HotelListItem>
  setSorter: (sorter: Sorter<keyof HotelListItem>) => void
  setFilters: (filters: { [key: string]: string | number | boolean }) => void
  error: null | Error
}

const useHotelListQuery = (initialParams: Partial<QueryParams> = {}, options: Options = {}): ReturnType => {
  const [params, setParams] = useState<QueryParams>({
    page: 1,
    length: 25,
    sort_by: 'name',
    sort_direction: 'asc',
    filters: {},
    ...initialParams,
  })
  const [data, setData] = useState<HotelListItem[]>([])
  const [meta, setMeta] = useState<Meta>({
    current_page: 1,
    from: 0,
    last_page: 0,
    per_page: 25,
    to: 0,
    total: 0,
  })

  const setPage = (page: number): void => setParams({ ...params, page })
  const setSorter = (sorter: Sorter): void =>
    setParams((old) => ({
      ...old,
      ...{ sort_by: sorter.by as keyof HotelListItem, sort_direction: sorter.direction },
      page: 1,
    }))
  const setFilters = (filters: { [key: string]: string | number | boolean }): void =>
    setParams((old) => ({ ...old, ...filters, page: 1 }))

  const fetchProperties = (key: string, params: QueryParams): Promise<{ data: HotelListItem[]; meta: Meta }> =>
    api.get(
      `/hotels?${qs.stringify(
        {
          ...params,
          ...params.filters,
          filters: null,
        },
        { skipNulls: true },
      )}`,
    )

  const { isFetching, error } = useQuery(['hotels', params], () => fetchProperties('hotels', params), {
    onSuccess: ({ data, meta }) => {
      setData(data)
      setMeta(meta)
    },
    onError: (error: Error) => {
      notification.error({ message: error.message })
    },
    ...options,
  })

  return {
    data,
    meta,
    error,
    isFetching,
    setPage,
    sorter: { by: params.sort_by, direction: params.sort_direction },
    setFilters,
    setSorter,
  }
}

export default useHotelListQuery
