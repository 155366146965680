import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UseMutateFunction } from 'react-query'
import qs from 'qs'
import moment from 'moment'
import { Pagination, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { notification } from 'helpers'
import { useIsTablet } from 'hooks'
import { ApiError } from 'api/types/ApiError'
import { useDeactivateDemo } from 'api/demoLinks/deactivateDemo'
import { Meta } from 'api/types/Meta'
import { Sorter } from 'ui/Table'
import { ConfirmationModal, Tiles } from 'ui'
import { TableDropdown, Table } from 'ui-v2'
import { useDeleteDemo } from 'api/demoLinks/deleteDemo'
import { DemoListItem } from 'types/domain/DemoListItem'

import { DEMO_LINKS_URL, DEMO_LIST_TAB_KEYS } from '../const'

interface Props {
  meta: Partial<Meta>
  data: DemoListItem[]
  isFetching: boolean
  setPage: (page: number) => void
  sorter: Sorter<keyof DemoListItem>
  setSorter: (sorter: Sorter<keyof DemoListItem>) => void
  setFilters: (filters: { [key: string]: string }) => void
  refetch: () => void
  selectedTabKey: string
}

interface DemoActionState {
  demo: DemoListItem | null
  callback: UseMutateFunction<void, ApiError, string, unknown> | null
  actionName: string
}

const enum ACTION_NAMES {
  DELETE = 'delete',
  DEACTIVATE = 'deactivate',
}

const DEMO_LINK = `${import.meta.env.VITE_HRC_URL}/login`
const DEMO_ACTION_INITIAL_STATE = { demo: null, callback: null, actionName: '' }

const DemoList: React.FC<Props> = ({ meta, data, isFetching, setPage, sorter, setSorter, refetch, selectedTabKey }) => {
  const [demoAction, setDemoAction] = useState<DemoActionState>(DEMO_ACTION_INITIAL_STATE)
  const { mutate: deleteDemo, isLoading: isDeletionLoading } = useDeleteDemo()
  const { mutate: deactivateDemo, isLoading: isDeactivationLoading } = useDeactivateDemo()
  const isTablet = useIsTablet()

  const { push: routerPush } = useHistory()

  const onActionConfirmed = useCallback(() => {
    if (demoAction.demo?.id && demoAction.callback) {
      demoAction.callback(`${demoAction.demo.id}`, {
        onSuccess: () => {
          notification.success({ message: `Demo ${demoAction.actionName}d successfully.` })
          setDemoAction(DEMO_ACTION_INITIAL_STATE)
          refetch()
        },
        onError: (error) => {
          notification.error({ message: error.message })
        },
      })
    }
  }, [demoAction])

  return (
    <Spin
      spinning={isFetching}
      indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
      wrapperClassName="bg-white px-4 pr-[30px]"
    >
      {isTablet ? (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Tiles<DemoListItem> fields={columns} dataSource={data} />
      ) : (
        <Table className="px-4">
          <thead>
            <Table.Tr>
              <Table.Th>#</Table.Th>
              <Table.Th>Hotel name</Table.Th>
              <Table.Th sorter={sorter} setSorter={setSorter} name="name">
                Name
              </Table.Th>
              <Table.Th sorter={sorter} setSorter={setSorter} name="updated_at">
                Last updated
              </Table.Th>
              <Table.Th>Days left</Table.Th>
              <Table.Th sorter={sorter} setSorter={setSorter} name="queries">
                Queries
              </Table.Th>
              <Table.Th className="w-14"></Table.Th>
            </Table.Tr>
          </thead>
          <tbody>
            {data.map((demo) => (
              <Table.Tr key={demo.id}>
                <Table.Td>{demo.id}</Table.Td>
                <Table.Td>{demo.hotel?.name || '-'}</Table.Td>
                <Table.Td>{demo.name}</Table.Td>
                <Table.Td>{moment(demo.updated_at).format('DD.MM.YYYY')}</Table.Td>
                <Table.Td>{demo.days_left}</Table.Td>
                <Table.Td>{`${demo.queries_used} / ${demo.queries}`}</Table.Td>
                <Table.Td>
                  <TableDropdown<DemoListItem>
                    record={demo}
                    menuItems={[
                      {
                        key: 'copy',
                        label: 'Copy link',
                        onClick: (r) => {
                          navigator.clipboard.writeText(`${DEMO_LINK}/${r.token}`).then(() => {
                            notification.success({
                              message: 'Demo link has been copied to the clipboard.',
                            })
                          })
                        },
                      },
                      {
                        key: 'edit',
                        label: 'Edit',
                        onClick: (demo) => {
                          routerPush(
                            `${DEMO_LINKS_URL}/edit?${qs.stringify({
                              id: demo.id,
                              name: demo.name,
                              hotel_id: demo.hotel?.id,
                              hotel_name: demo.hotel?.name,
                              deadline_at: moment(demo.deadline_at).format('YYYY-MM-DD'),
                              queries: demo.queries,
                              gds_ids: demo.gds_ids,
                            })}`,
                          )
                        },
                      },
                      {
                        key: 'deactivate',
                        label: 'Deactivate',
                        hide: selectedTabKey === DEMO_LIST_TAB_KEYS.NOT_ACTIVE,
                        onClick: (demo) =>
                          setDemoAction({
                            demo,
                            callback: deactivateDemo,
                            actionName: ACTION_NAMES.DEACTIVATE,
                          }),
                      },
                      {
                        key: 'delete',
                        label: 'Delete',
                        onClick: (demo) =>
                          setDemoAction({ demo, callback: deleteDemo, actionName: ACTION_NAMES.DELETE }),
                      },
                    ]}
                  />
                </Table.Td>
              </Table.Tr>
            ))}
          </tbody>
        </Table>
      )}
      <div className="flex items-center justify-center pt-5">
        <Pagination
          current={meta.current_page || 1}
          total={meta.total || 1}
          onChange={setPage}
          showSizeChanger={false}
          hideOnSinglePage
          pageSize={meta.per_page || 10}
        />
      </div>
      {data && data.length === 0 && <div className="text-center text-base text-gray-500 p-5">No Demos</div>}
      <ConfirmationModal
        onCancel={() => setDemoAction(DEMO_ACTION_INITIAL_STATE)}
        onSuccess={onActionConfirmed}
        title={`Demo ${demoAction.demo?.name} will be ${demoAction.actionName}d. Are you sure?`}
        visible={!!demoAction.demo}
        isLoading={isDeactivationLoading || isDeletionLoading}
      />
    </Spin>
  )
}

export default DemoList
