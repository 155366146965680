import React, { FC } from 'react'
import { ChevronDownIcon } from '@heroicons/react/16/solid'

import { RateDetails } from 'types/domain/RateDetails'
import List from './components/List'

interface Props {
  selectedRate: Nullable<RateDetails>
  rateList: RateDetails[]
  onSelectRate: (rd: RateDetails) => void
  disabled: boolean
}

const BookingCodeList: FC<Props> = ({ selectedRate, rateList, onSelectRate, disabled }) => {
  return (
    <div>
      {rateList.length > 0 ? (
        <List onSelectRate={onSelectRate} rateList={rateList} selectedRate={selectedRate} disabled={disabled} />
      ) : (
        <div className="hidden xl:block xl:w-72">
          <div className="flex items-center justify-between px-3 py-2 mb-2 text-blue-800">
            <span>Worst to best accuracy</span>
            <ChevronDownIcon className="w-4" />
          </div>

          <div className="flex flex-col gap-2">
            <div className="border border-gray-100 bg-white p-2 h-10 rounded flex items-center justify-between gap-2">
              <div className="bg-blue-100 h-[5px] w-20" />
              <div className="bg-gray-100 h-[3px] w-12" />
            </div>
            <div className="border border-gray-100 bg-white p-2 h-10 rounded flex items-center justify-between gap-2">
              <div className="bg-blue-100 h-[5px] w-20" />
              <div className="bg-gray-100 h-[3px] w-12" />
            </div>
            <div className="border border-gray-100 bg-white p-2 h-10 rounded flex items-center justify-between gap-2">
              <div className="bg-blue-100 h-[5px] w-20" />
              <div className="bg-gray-100 h-[3px] w-12" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default BookingCodeList
