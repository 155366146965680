import { useState } from 'react'

import { ListQueryParams, Meta } from 'api'
import { notification } from 'helpers'
import { Sorter } from '../ui/Table'
import { GetDemoRequestParams, useGetDemos } from '../api/demoLinks/getDemos'
import { DemoListItem } from '../types/domain/DemoListItem'
import { RequestHeadersWithGdsScope } from '../api/types/RequestHeadersWithGdsScope'
import { ApiError } from '../api/types/ApiError'

type QueryParams = ListQueryParams &
  GetDemoRequestParams & {
    sort_by: keyof DemoListItem
  }

type ReturnType = {
  data: DemoListItem[]
  meta: Meta
  isFetching: boolean
  setPage: (page: number) => void
  sorter: Sorter<keyof DemoListItem>
  setSorter: (sorter: Sorter<keyof DemoListItem>) => void
  setFilters: (filters: { [key: string]: string | number | boolean }) => void
  refetch: () => void
  error: null | ApiError
}

// todo: refactoring with useHotelsQuery
const useDemosQuery = ({ headers }: { headers: RequestHeadersWithGdsScope }): ReturnType => {
  const [params, setParams] = useState<QueryParams>({
    page: 1,
    length: 25,
    sort_by: 'name',
    sort_direction: 'asc',
    filters: {},
    active: true,
    hotel: '',
    name: '',
  })
  const [data, setData] = useState<DemoListItem[]>([])
  const [meta, setMeta] = useState<Meta>({
    current_page: 1,
    from: 0,
    last_page: 0,
    per_page: 25,
    to: 0,
    total: 0,
  })

  const setPage = (page: number): void => setParams({ ...params, page })
  const setSorter = (sorter: Sorter): void =>
    setParams((old) => ({
      ...old,
      ...{ sort_by: sorter.by as keyof DemoListItem, sort_direction: sorter.direction },
      page: 1,
    }))
  const setFilters = (filters: { [key: string]: string | number | boolean }): void =>
    setParams((old) => ({ ...old, ...filters, page: 1 }))

  const { isFetching, refetch, error } = useGetDemos(headers, params, {
    onSuccess: ({ data, meta }) => {
      setData(data)
      setMeta(meta)
    },
    onError: (error) => {
      notification.error({ message: error.message })
    },
  })

  return {
    data,
    meta,
    isFetching,
    setPage,
    sorter: { by: params.sort_by, direction: params.sort_direction },
    setFilters,
    setSorter,
    refetch,
    error,
  }
}

export default useDemosQuery
