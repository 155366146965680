import { GdsListItem } from 'types/domain/GdsListItem'
import { SABRE_KEY, TRAVEL_PORT_KEY } from 'constants/gds'
import {GdsCredentials} from "../types/domain/GdsCredentials";

// TODO: create literal type for gds names based on constants/gds file
type Returned = {
  travel_port: GdsListItem
  sabre: GdsListItem
}

export const parseGdsListToObject = (gdsListResource: Array<GdsListItem>): Returned => {
  const travel_port = gdsListResource.find((gds) => gds.name === TRAVEL_PORT_KEY)
  const sabre = gdsListResource.find((gds) => gds.name === SABRE_KEY)

  if (!travel_port || !sabre) {
    throw new Error('Travel Port or Sabre not configured')
  }

  return {
    travel_port,
    sabre,
  }
}

export const validateGdsCredentials = (gdsCredentialsList: Array<GdsCredentials> | undefined, gdsKey: string) => {
  const credentials = gdsCredentialsList?.find((gdsCredentials) => gdsCredentials.gds === gdsKey)
  return credentials ? !Object.values(credentials).includes('') : false
}