import React, { ReactNode } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { getCxFromStyles } from 'helpers'
import styles from './Button.module.scss'

interface Props {
  children?: ReactNode
  loading?: boolean
  disabled?: boolean
  onClick?: () => void
  large?: boolean
  primary?: boolean
  secondary?: boolean
  danger?: boolean
  success?: boolean
  block?: boolean
  htmlType?: 'button' | 'submit' | 'reset'
  className?: string
}

const Button: React.FC<Props> = ({
  children,
  loading,
  disabled,
  onClick,
  large,
  primary,
  htmlType,
  secondary,
  danger,
  success,
  className,
  block,
}) => {
  const cx = getCxFromStyles(styles)

  return (
    <button
      className={cx(className, 'button', { primary, large, secondary, block, danger, success })}
      disabled={disabled || loading}
      onClick={onClick}
      type={htmlType || 'button'}
    >
      {loading ? (
        <Spin indicator={<LoadingOutlined style={{ color: primary ? '#fff' : '#0c145a' }} spin />} />
      ) : (
        children
      )}
    </button>
  )
}

export default Button
