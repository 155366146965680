import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import useGdsSelectContext from 'components/GdsSelect/useGdsSelectContext'
import { DashboardHotel } from 'types/domain/DashboardHotel'
import { useDashboardRangeDatePickerContext } from 'context/DashboardRangeDatePickerContext'
import { useGetDashboardResource } from 'api/dashboard/getDashboardResource'
import { useGetBookingCodesListResource } from 'api/bookingCodes/getBookingCodesListResource'
import { BookingCode } from 'types/domain/BookingCode'
import { ApiMeta } from 'api/types/ApiMeta'

const useGetActiveHotel = (filters?: {
  page?: number
  bookingCode?: string
}): {
  hotel: DashboardHotel | null
  bookingCodes: Array<BookingCode>
  bookingCodesMeta?: ApiMeta
  isFetching: boolean
  isLoading: boolean
} => {
  const { headers } = useGdsSelectContext({ disabled: true })
  const { userId, hotelId } = useParams<{ userId: string; hotelId: string; bookingCode: string }>()
  const dateRangePicker = useDashboardRangeDatePickerContext()

  const { data, isLoading, isFetching } = useGetDashboardResource({
    query: {
      user_id: userId ? parseInt(userId, 10) : undefined,
      hotel_id: parseInt(hotelId, 10),
      ...dateRangePicker.getDateRangeForApi(),
    },
    headers,
    queryOptions: {
      enabled: !!headers['Gds-Name'],
    },
  })

  const {
    data: bookingCodes,
    isLoading: isBookingCodesLoading,
    isFetching: isBookingCodesFetching,
  } = useGetBookingCodesListResource({
    query: {
      user_id: userId ? parseInt(userId, 10) : undefined,
      hotel_id: parseInt(hotelId, 10),
      page: filters?.page || 1,
      rate_plan: filters?.bookingCode || '',
      ...dateRangePicker.getDateRangeForApi(),
    },
    headers,
    queryOptions: {
      enabled: !!headers['Gds-Name'],
    },
  })

  const hotel = useMemo(() => {
    const hotels = data?.data.hotels

    if (hotels?.length !== 1) {
      return null
    }

    return hotels[0]
  }, [data])

  return {
    hotel,
    bookingCodes: bookingCodes?.data || [],
    bookingCodesMeta: bookingCodes?.meta,
    isFetching: isFetching || isBookingCodesFetching,
    isLoading: isLoading || isBookingCodesLoading,
  }
}

export default useGetActiveHotel
