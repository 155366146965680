import {Route} from 'routes/types/Route'

import ReportedIssuesListContainer from "./pages/ReportedIssuesList"
import ReportedIssueDetails from "./pages/ReportedIssueDetails"

const routes: Route[] = [
  {path: '/admin/reported-issues/:issueId', component: ReportedIssueDetails, layout: 'admin'},
  {path: '/reported-issues/:issueId', component: ReportedIssueDetails, layout: 'main'},
  {path: '/admin/reported-issues', component: ReportedIssuesListContainer, layout: 'admin'},
  {path: '/reported-issues', component: ReportedIssuesListContainer, layout: 'main'}
]

export default routes
