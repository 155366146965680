import { useQuery, QueryKey, UseQueryOptions } from 'react-query'
import qs from 'qs'

import api, { Meta } from 'api/index'
import { RequestHeadersWithGdsScope } from 'api/types/RequestHeadersWithGdsScope'
import { DemoListItem } from 'types/domain/DemoListItem'
import { ApiError } from '../types/ApiError'

export type GetDemoRequestParams = {
  active: boolean
  hotel: string
  name: string
}

export type GetDemosResponse = {
  data: DemoListItem[]
  meta: Meta
}

export const getDemos = ({
  headers,
  query,
}: {
  headers: RequestHeadersWithGdsScope
  query: GetDemoRequestParams
}): Promise<GetDemosResponse> => api.get(`/demos?${qs.stringify(query, { skipNulls: true })}`, { headers })

export const useGetDemos = (
  headers: RequestHeadersWithGdsScope,
  query: GetDemoRequestParams,
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getDemos>>, ApiError>,
  customQueryKey?: QueryKey,
) =>
  useQuery(
    customQueryKey || ['demos', ...Object.values(query), ...Object.values(headers)],
    () => getDemos({ headers, query }),
    queryOptions,
  )
