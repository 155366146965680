import React, { FC, useState } from 'react'
import { ArrowForward, Close, Search } from '@material-ui/icons'
import { format } from 'date-fns'

import { HotelsImportListItem } from 'types/domain/HotelsImportListItem'
import {
  useGetHotelsImportResource,
  GetHotelsImportResourceRequestQuery,
} from 'api/hotelsImports/getHotelsImportResource'
import { HotelsImportItemListItem } from 'types/domain/HotelsImportItemListItem'
import { Table } from 'ui'

import Match from '../Match'

import styles from './ImportHotels.module.scss'

interface Props {
  importData: HotelsImportListItem
  onClose: () => void
}

const ImportHotels: FC<Props> = ({ importData, onClose }) => {
  const [chosenCandidateId, setChosenCandidateId] = useState<number | null>(null)

  const [importHotelsQuery, setImportHotelsQuery] = useState<GetHotelsImportResourceRequestQuery>({
    page: 1,
    length: 20,
  })

  const [filters, setFilters] = useState({
    query: '',
    chain_code: '',
    similarity_from: '',
    similarity_to: '',
  })

  const {
    data: importHotelsData,
    isLoading: isImportHotelsLoading,
    refetch: refetchImportHotelsData,
  } = useGetHotelsImportResource({
    params: { hotel_import: importData.id },
    query: importHotelsQuery,
  })

  if (chosenCandidateId) {
    return (
      <Match
        importData={importData}
        listFilters={{
          similarity_from: importHotelsQuery.similarity_from,
          similarity_to: importHotelsQuery.similarity_to,
          chain_code: importHotelsQuery.chain_code,
          query: importHotelsQuery.query,
        }}
        initialCandidateId={chosenCandidateId}
        onClose={() => {
          setChosenCandidateId(null)
          refetchImportHotelsData()
        }}
      />
    )
  }

  const applyFilters = () =>
    setImportHotelsQuery((prevState) => ({
      ...prevState,
      similarity_from: filters.similarity_from ? Number(filters.similarity_from) : undefined,
      similarity_to: filters.similarity_to ? Number(filters.similarity_to) : undefined,
      chain_code: filters.chain_code ?? undefined,
      query: filters.query,
      page: 1,
    }))

  return (
    <div className={styles.root}>
      <h2>
        Hotels ({importData.file_name}){' '}
        <button onClick={onClose}>
          <Close />
        </button>
      </h2>

      <div className={styles.filters}>
        <span className={styles.searchWrapper}>
          <span className={styles.search}>
            <input
              value={filters.query}
              onChange={(event) => setFilters((prevState) => ({ ...prevState, query: event.target.value }))}
              onKeyDown={(event) => event.key === 'Enter' && applyFilters()}
              placeholder="Search..."
            />

            <Search />
          </span>

          <span className={styles.search}>
            <input
              value={filters.chain_code}
              onChange={(event) => setFilters((prevState) => ({ ...prevState, chain_code: event.target.value }))}
              onKeyDown={(event) => event.key === 'Enter' && applyFilters()}
              placeholder="Search chain code"
            />
          </span>

          <button onClick={applyFilters}>Search</button>
        </span>
        Similarity:
        <span>
          <input
            value={filters.similarity_from}
            onChange={(event) => setFilters((prevState) => ({ ...prevState, similarity_from: event.target.value }))}
            onKeyDown={(event) => event.key === 'Enter' && applyFilters()}
          />
        </span>
        :
        <span>
          <input
            value={filters.similarity_to}
            onChange={(event) => setFilters((prevState) => ({ ...prevState, similarity_to: event.target.value }))}
            onKeyDown={(event) => event.key === 'Enter' && applyFilters()}
          />
        </span>
        <button onClick={applyFilters}>
          <ArrowForward />
        </button>
      </div>

      <Table<HotelsImportItemListItem>
        rowKey="id"
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
          },
          {
            title: 'Property ID',
            dataIndex: 'property_id',
          },
          {
            title: 'Name',
            dataIndex: 'name',
          },
          {
            title: 'Chain code',
            dataIndex: 'chain_code',
          },
          {
            title: 'Status',
            dataIndex: 'status',
            renderCell: (record) => <span className={styles.center}>{record.status}</span>,
          },
          {
            title: 'Address',
            dataIndex: 'address',
          },
          {
            title: 'City',
            dataIndex: 'city',
          },
          {
            title: 'Country',
            dataIndex: 'country',
          },
          {
            title: 'Postal Code',
            dataIndex: 'post_code',
          },
          {
            title: 'Matches',
            dataIndex: 'matches_count',
          },
          {
            title: 'Created At',
            dataIndex: 'created_at',
            renderCell: (record) => format(new Date(record.created_at), 'yyyy-MM-dd HH:ii'),
          },
          {
            title: 'Best Similarity',
            dataIndex: 'best_match',
            renderCell: (record) => (record.best_match ? `${record.best_match}%` : '-'),
          },
        ]}
        dataSource={importHotelsData?.data || []}
        loading={isImportHotelsLoading}
        pagination={{
          page: importHotelsData?.meta.current_page,
          onChange: (page) => setImportHotelsQuery((prevState) => ({ ...prevState, page })),
          pageSize: importHotelsData?.meta.per_page,
          totalItems: importHotelsData?.meta.total,
        }}
        onRowClick={(record) => {
          setChosenCandidateId(record.id)
        }}
      />
    </div>
  )
}

export default ImportHotels
