import React from 'react'
import { getCxFromStyles } from 'helpers'
import styles from './Tabs.module.scss'

interface Tab {
  key: string
  label: string
  disabled?: boolean
}

interface Props {
  className?: string
  onChange?: (name: string, tab: Tab) => void
  selectedTabKey: string
  tabs: Tab[]
  small?: boolean
  AdditionalComponent?: JSX.Element
}

// TODO: hook as helper
// wartosc i jego zmiana
// ustawianie wartosci na podstawie klucza z domyslna wartoscia jezeli klucz nie pasuje do tabow
// sprawdzanie czy wskazany klucz jest wlasnie wybrany
// zmiany na adresie dodajac lub odczytujac z adresu dana wartosc

const Tabs: React.FC<Props> = ({ tabs, className, onChange, selectedTabKey, small, AdditionalComponent }) => {
  const cx = getCxFromStyles(styles)

  const handleSelect = (tabKey: string, tab: Tab) => {
    if (typeof onChange === 'function' && selectedTabKey !== tabKey) {
      onChange(tabKey, tab)
    }
  }

  return (
    <div className={cx('tabs', className, { small })}>
      {tabs.map((tab) => (
        <div
          key={tab.label}
          className={cx('tab', { active: tab.key === selectedTabKey, disabled: tab.disabled })}
          onClick={() => handleSelect(tab.key, tab)}
        >
          <span>{tab.label}</span>
        </div>
      ))}
      {AdditionalComponent && AdditionalComponent}
    </div>
  )
}

export default Tabs
