import { useContext, useEffect } from 'react'
import HotelSelectContext from './HotelSelectContext'

type Props = {
  shouldVisibleButton?: boolean
}

const useHotelSelectContext = (props: Props = {}) => {
  const { shouldVisibleButton = true } = props
  const { isShownModal, setIsShownModal, setIsShownButton, isShownButton } = useContext(HotelSelectContext)

  useEffect(() => {
    setIsShownButton(shouldVisibleButton)

    return () => {
      setIsShownButton(false)
    }
  }, [setIsShownButton, shouldVisibleButton])

  return {
    isShownModal,
    isShownButton,
    setIsShownButton,
    setIsShownModal
  }
}

export default useHotelSelectContext
