import React, { Fragment, useCallback, useContext, useMemo } from 'react'
import { AttachMoney, Close, Description, MeetingRoom, RestaurantMenu } from '@material-ui/icons'
import { format } from 'date-fns'
import { twMerge } from 'tailwind-merge'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'

import { Button, Table } from 'ui-v2'
import { rateStatusColor } from 'helpers'
import { HotelSummary } from 'types/domain/HotelSummary'
import { Column } from 'ui/Table'
import { MainLayoutContext } from 'layouts/Main/MainContext'
import { NO_GDS_DATA_AVAILABLE_MESSAGE } from '../consts'

interface Item extends Omit<HotelSummary, 'all'> {
  id: string | number
  key: string | number
  name: string
  created_at?: string
  checkup_date?: string
  from_date?: string
  to_date?: string
  rac?: string
  adults?: number
  summary: HotelSummary
}

export interface Props {
  type: 'hotels' | 'rates' | 'bookingCodes'
  items: Item[]
  handleSelect: (item: number | string) => void
  isFetching?: boolean
  hotelId?: number
}

const AccuracyTable: React.FC<Props> = ({ type, items, handleSelect, isFetching, hotelId }) => {
  const mainLayoutContext = useContext(MainLayoutContext)

  const renderPercentCell = (record: Item, key: keyof Item): JSX.Element => {
    const value = record[key] as number | null | undefined
    return (
      <div
        className="flex h-8 rounded items-center justify-center text-base leading-6 font-medium text-gray-800"
        style={{
          backgroundColor: rateStatusColor(value),
        }}
      >
        {typeof value === 'number' && `${Math.floor(value)}%`}
      </div>
    )
  }

  const renderActionCell = useCallback(
    (record: Item) => {
      const isEmpty = Object.values(record.summary).every((x) => x === null)

      if (isEmpty || type === 'bookingCodes') return <div />

      return (
        <Menu as="div" className="relative inline-block align-center">
          <>
            <Menu.Button className="p-0 h-auto border-none bg-transparent">
              <Button
                variant="secondary"
                className="border-none w-[34px] p-0 rounded-full flex items-center justify-center gap-1"
              >
                <EllipsisVerticalIcon className="w-5" />
              </Button>
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-1 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="p-3 flex flex-col text-gray-700">
                  <Menu.Item>
                    <a
                      onClick={() => handleSelect(record.id)}
                      className={twMerge(
                        'inline-flex items-center gap-2 px-5 py-4 text-base leading-none font-normal text-blue-800 hover:text-blue-800 hover:bg-blue-50 whitespace-nowrap',
                      )}
                    >
                      {type === 'hotels' ? 'Booking codes' : 'Previous searches'}
                    </a>
                  </Menu.Item>

                  <Menu.Item>
                    <a
                      href={`${mainLayoutContext.linkPrefix}/hotel/details?hotel_id=${
                        type === 'hotels' ? record.id : hotelId
                      }${type !== 'hotels' ? `&checkup_rate_id=${record.id}` : ''}`}
                      className={twMerge(
                        'inline-flex items-center gap-2 px-5 py-4 text-base leading-none font-normal text-blue-800 hover:text-blue-800 hover:bg-blue-50 whitespace-nowrap',
                      )}
                    >
                      Check rates
                    </a>
                  </Menu.Item>

                  <Menu.Item>
                    <a
                      href={`${mainLayoutContext.linkPrefix}/hotel/ota-view?hotel_id=${
                        type === 'hotels' ? record.id : hotelId
                      }${type !== 'hotels' ? `&checkup_rate_id=${record.id}` : ''}`}
                      className={twMerge(
                        'inline-flex items-center gap-2 px-5 py-4 text-base leading-none font-normal text-blue-800 hover:text-blue-800 hover:bg-blue-50 whitespace-nowrap',
                      )}
                    >
                      OTA View
                    </a>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        </Menu>
      )
    },
    [handleSelect, hotelId],
  )

  const getColumns = useCallback(
    (type: Props['type']): Column<Item>[] => {
      let columns: Column<Item>[] = []

      if (type === 'rates') {
        columns = columns.concat([
          {
            title: 'Booking Code',
            renderCell: (record) => record.name,
          },
        ])
      } else if (type === 'hotels') {
        columns = columns.concat([
          {
            title: 'Hotel Name',
            renderCell: (record) => record.name,
          },
        ])
      } else {
        columns = columns.concat([
          {
            title: 'Request Date',
            headerClassName: 'hidden lg:table-cell',
            className: 'hidden lg:table-cell',
            renderCell: (record) => (
              <span className="whitespace-nowrap">
                {format(record.checkup_date ? new Date(record.checkup_date) : new Date(), 'yyyy-MM-dd')}
              </span>
            ),
          },
          {
            title: 'Check-in',
            renderCell: (record) => <span className="whitespace-nowrap">{record.from_date}</span>,
          },
          {
            title: 'Check-out',
            renderCell: (record) => <span className="whitespace-nowrap">{record.to_date}</span>,
          },
          {
            title: 'Adults',
            headerClassName: 'hidden xl:table-cell',
            className: 'hidden xl:table-cell',
            renderCell: (record) => record.adults,
          },
          {
            title: 'RAC',
            headerClassName: 'hidden 2xl:table-cell',
            className: 'hidden 2xl:table-cell',
            renderCell: (record) => record.rac,
          },
        ])
      }

      columns = columns.concat([
        {
          title: 'Rate Description',
          icon: <Description className="scale-50" />,
          className: 'w-[12%]',
          renderCell: (r) => renderPercentCell(r, 'rate_description'),
        },
        {
          title: 'Room Description',
          icon: <MeetingRoom className="scale-50" />,
          className: 'w-[12%]',
          renderCell: (r) => renderPercentCell(r, 'room_description'),
        },
        {
          title: 'Meal Plans',
          icon: <RestaurantMenu className="scale-50" />,
          className: 'w-[12%]',
          renderCell: (r) => renderPercentCell(r, 'meal_plans'),
        },
        {
          title: 'Cancellation Policy',
          icon: <Close className="scale-50" />,
          className: 'w-[12%]',
          renderCell: (r) => renderPercentCell(r, 'cancellation_policy'),
        },
        {
          title: 'Pricing',
          icon: <AttachMoney className="scale-50" />,
          className: 'w-[12%]',
          renderCell: (r) => renderPercentCell(r, 'pricing'),
        },
      ])

      if (type !== 'bookingCodes') {
        columns = columns.concat([
          {
            title: '',
            key: 'actions',
            className: 'w-8 p-0 align-middle',
            renderCell: renderActionCell,
          },
        ])
      }

      return columns
    },
    [renderActionCell],
  )

  const columns = useMemo(() => getColumns(type), [getColumns, type])

  return (
    <Table>
      <thead>
        <Table.Tr>
          {columns.map((column) => (
            <Table.Th className={twMerge('bg-white border-r-0 border-l-0 p-2', column.headerClassName)}>
              <span
                className={twMerge(
                  'flex items-center gap-1 text-sm leading-none font-normal normal-case text-gray-500 whitespace-nowrap',
                  column.icon && 'justify-center',
                  type === 'bookingCodes' ? '2xl:justify-start' : 'xl:justify-start',
                )}
              >
                {column.icon && (
                  <div className="w-6 h-6 bg-gray-100 text-gray-700 rounded-full flex items-center justify-center">
                    {column.icon}
                  </div>
                )}
                <span
                  className={twMerge(
                    column.icon && 'hidden',
                    type === 'bookingCodes' ? '2xl:inline-block' : 'xl:inline-block',
                  )}
                >
                  {column.title}
                </span>
              </span>
            </Table.Th>
          ))}
        </Table.Tr>
      </thead>

      <tbody>
        {items.map((item, index) => (
          <Table.Tr key={index} className="even:bg-white">
            {columns.map((column) => (
              <Table.Td
                className={twMerge('text-base leading-6 font-normal text-gray-800 p-2 border-none', column.className)}
              >
                {column.renderCell?.(item, index)}
              </Table.Td>
            ))}
          </Table.Tr>
        ))}

        {!items.length && !isFetching && (
          <Table.Tr className="even:bg-white">
            <Table.Td
              className={twMerge('text-base leading-6 font-normal text-gray-400 text-center')}
              colSpan={columns.length}
            >
              {NO_GDS_DATA_AVAILABLE_MESSAGE}
            </Table.Td>
          </Table.Tr>
        )}
      </tbody>
    </Table>
  )
}

export default AccuracyTable
