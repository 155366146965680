import React, { ReactNode } from 'react'
import { Close } from '@material-ui/icons'
import { Modal as AntdModal } from 'antd'
import { useDimensions } from 'hooks'
import { getCxFromStyles } from 'helpers'
import styles from './Modal.module.scss'

interface Props {
  visible: boolean
  onCancel: () => void
  children: ReactNode
  width?: number
  bodyStyle?: { [key: string]: string | number }
  closeButtonClassName?: string
  closable?: boolean
  className?: string
  overlay?: boolean
  overlayClosable?: boolean
}

const Modal: React.FC<Props> = ({
  visible,
  onCancel,
  children,
  width,
  bodyStyle,
  closeButtonClassName,
  closable,
  className,
  overlay,
  overlayClosable,
}) => {
  const cx = getCxFromStyles(styles)
  const dimensions = useDimensions()

  return (
    <AntdModal
      mask={overlay}
      className={className}
      visible={visible}
      onCancel={onCancel}
      width={width}
      bodyStyle={{
        padding: '55px 0 0',
        ...bodyStyle,
      }}
      footer={null}
      closeIcon={
        <div className={cx('close', closeButtonClassName)}>
          <Close />
        </div>
      }
      style={dimensions.width <= 768 ? { top: 16 } : undefined}
      closable={closable}
      maskClosable={overlayClosable}
    >
      {children}
    </AntdModal>
  )
}

export default Modal
