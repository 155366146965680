import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { Input } from 'antd'
import { Menu, Transition } from '@headlessui/react'
import { FlagIcon, LinkIcon } from '@heroicons/react/24/outline'
import { twMerge } from 'tailwind-merge'

import { Tooltip } from 'ui'
import { Button } from 'ui-v2'
import { useAddPreview } from 'api/previews/addPreview'
import { ApiError } from 'api/types/ApiError'
import { notification } from 'helpers'
import useOutsideClick from 'hooks/useOutsideClick'

import styles from './NotifyIssueActionButtons.module.scss'

interface NotifyIssueActionButtonProps {
  showReportIssueModal: () => void
  rateId: number
}
const NotifyIssueActionButtons = ({ showReportIssueModal, rateId }: NotifyIssueActionButtonProps) => {
  const [link, setLink] = useState<string>('')
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  useOutsideClick(ref, () => setIsTooltipOpen(false))

  useEffect(() => {
    setIsTooltipOpen(false)
    setLink('')
  }, [rateId])

  const copyToClipboard = useCallback(async (link: string) => {
    try {
      await navigator.clipboard.writeText(link)
      setIsTooltipOpen(false)
      notification.success({ message: 'Copied' })
    } catch (e) {
      setIsTooltipOpen(true)
    }
  }, [])

  const { mutate: generatePreview, isLoading } = useAddPreview({
    queryOptions: {
      onSuccess: async (data) => {
        const token = data.data.token
        const link = `${window.location.origin.toString()}/booking-code-preview/${token}`
        await setLink(link)
        await copyToClipboard(link)
      },
      onError: (error: ApiError) => notification.error({ message: error.message }),
    },
  })

  const handleCopyLink = () => {
    if (link) {
      copyToClipboard(link)
    } else {
      generatePreview({ checkup_rate_id: rateId })
    }
  }

  const CopyLinkTooltipContent = (
    <div className={styles.linkInputContainer}>
      <Input.TextArea className={styles.copylinkInput} value={link} disabled autoSize />
      <Button onClick={() => copyToClipboard(link)} variant="primary" className={styles.copyLinkBtn}>
        Copy
      </Button>
    </div>
  )

  return (
    <div ref={ref}>
      <div className="lg:hidden">
        <Tooltip trigger={[]} text={CopyLinkTooltipContent} visible={isTooltipOpen} placement="bottom">
          <Menu as="div" className="relative inline-block">
            <>
              <Menu.Button className="p-0 h-auto border-none bg-transparent">
                <Button
                  variant="secondary"
                  isLoading={isLoading}
                  className="border-none w-[34px] p-0 rounded flex items-center justify-center gap-1"
                >
                  <div className="w-1 h-1 bg-blue-800 rounded-full" />
                  <div className="w-1 h-1 bg-blue-800 rounded-full my-0.5" />
                  <div className="w-1 h-1 bg-blue-800 rounded-full" />
                </Button>
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-1 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="p-3 flex flex-col text-gray-700">
                    <Menu.Item>
                      <a
                        onClick={showReportIssueModal}
                        className={twMerge(
                          'inline-flex items-center gap-2 px-5 py-4 text-base leading-none font-normal text-blue-800 hover:text-blue-800 hover:bg-blue-50 whitespace-nowrap',
                        )}
                      >
                        <FlagIcon className="w-4" /> Notify hotel
                      </a>
                    </Menu.Item>

                    <Menu.Item>
                      <a
                        onClick={handleCopyLink}
                        className={twMerge(
                          'inline-flex items-center gap-2 px-5 py-4 text-base leading-none font-normal text-blue-800 hover:text-blue-800 hover:bg-blue-50 whitespace-nowrap',
                        )}
                      >
                        <LinkIcon className="w-4" /> Copy link
                      </a>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          </Menu>
        </Tooltip>
      </div>
      <div className="hidden lg:flex items-center gap-2">
        <Button onClick={showReportIssueModal}>
          <span className="inline-flex items-center gap-2">
            Notify hotel <FlagIcon className="w-4" />
          </span>
        </Button>
        <Tooltip trigger={[]} text={CopyLinkTooltipContent} visible={isTooltipOpen} placement="bottom">
          <Button onClick={handleCopyLink} isLoading={isLoading}>
            <span className="inline-flex items-center gap-2">
              Copy link <LinkIcon className="w-4" />
            </span>
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

export default NotifyIssueActionButtons
