import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import api from 'api'
import type { Error } from 'api'
import { notification } from 'helpers'
import EmailVerification from './EmailVerification'
import { login } from '../../slices/auth'

const EmailVerificationContainer: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { hash } = useParams<{ hash?: string }>()

  const { isLoading: isVerifying } = useQuery(['email-verification', hash], () => api.get(`/verification/${hash}`), {
    enabled: !!hash,
    onSuccess: ({ data: { token } }) => {
      notification.success({
        message: 'Email has been verified',
      })
      dispatch(login(token))
      history.push('/')
    },
    onError: (error: Error) => {
      notification.error({ message: error.message })
    },
  })

  const {
    mutate: onSubmit,
    isLoading: isResending,
    error,
  } = useMutation(async (data: { [key: string]: string }): Promise<void> => api.post('/verification-resend', data), {
    onSuccess: () => {
      notification.success({
        message: 'Email has been sent',
      })
      history.push('/')
    },
    onError: (error: Error) => {
      notification.error({ message: error.message })
    },
  })

  return (
    <EmailVerification
      onSubmit={onSubmit}
      isSubmitting={isResending}
      apiErrors={error?.errors}
      isVerifying={isVerifying}
    />
  )
}

export default EmailVerificationContainer
