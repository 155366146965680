import { QueryKey, useMutation, UseMutationOptions } from 'react-query'
import api from 'api'
import { ApiError } from 'api/types/ApiError'
import { ReportedIssue as Issue } from 'types/domain/ReportedIssue'

import { ISSUES_ENDPOINT_KEY } from './constants'

type SendIssueParams = {
  id: number
  email?: string
}

type SendIssueResponse = {
  data: Issue
}

export const sendIssue = ({ params }: { params: SendIssueParams }): Promise<SendIssueResponse> =>
  api.post(`/${ISSUES_ENDPOINT_KEY}/${params.id}/send`, { email: params.email ? params.email : undefined })

export const useSendIssue = ({
  queryOptions,
  customQueryKey,
}: {
  params?: SendIssueParams
  queryOptions?: UseMutationOptions<Awaited<ReturnType<typeof sendIssue>>, ApiError, SendIssueParams>
  customQueryKey?: QueryKey
}) => useMutation(customQueryKey || ISSUES_ENDPOINT_KEY, (params) => sendIssue({ params }), queryOptions)
