import React from 'react'
import moment from 'moment'

import { getCxFromStyles } from 'helpers'
import Hotel from 'modules/HotelsModule/types/Hotel'

import { Alarm, Autorenew, DirectionsBus, LibraryBooks, LocationOn, PhoneInTalk } from '@material-ui/icons'
import { Button, GoogleMap } from 'ui'

import styles from './GeneralInfo.module.scss'

interface Props {
  data?: Hotel
  refreshData: (mode?: string) => void
}

const GeneralInfo: React.FC<Props> = ({ data, refreshData }) => {
  const cx = getCxFromStyles(styles)

  const updatedAt = typeof data?.details?.created_at === 'string' && moment(data?.details.created_at)
  const canRefresh = (data?.checkup?.from_date && moment().isAfter(moment(data?.checkup?.from_date))) || false

  return (
    <div className={cx('root')}>
      <div className={cx('topBar')}>
        Last updated:
        <span className={cx('date')}>{!!updatedAt && updatedAt.format('DD.MM.YYYY')}</span>
        <Button secondary onClick={(): void => refreshData('hotel_details_only')} disabled={canRefresh}>
          <Autorenew fontSize="small" />
        </Button>
      </div>

      <div className={cx('data')}>
        <div className={cx('info')}>
          <div className={cx('block')}>
            <div className={cx('line')}>
              <span className={cx('textWithIco')}>
                <LocationOn /> Street:
              </span>
              <span className={cx('outlinedText', { missing: !data?.address })}>{data?.address || 'missing'}</span>
            </div>
            <div className={cx('line')}>
              <span className={cx('textWithIco')}>
                <LocationOn /> City & Postal Code:
              </span>
              <span className={cx('outlinedText', { missing: !data?.city || !data?.post_code })}>
                {data?.city || data?.post_code ? (
                  <>
                    {data?.post_code} {data?.city}
                  </>
                ) : (
                  'missing'
                )}
              </span>
            </div>
            <div className={cx('line')}>
              <span className={cx('textWithIco')}>
                <LocationOn /> Country:
              </span>
              <span className={cx('outlinedText', { missing: !data?.country })}>{data?.country || 'missing'}</span>
            </div>
          </div>

          <div className={cx('block')}>
            <div className={cx('line')}>
              <span className={cx('textWithIco')}>
                <PhoneInTalk /> Phone:
              </span>
              <span className={cx('outlinedText', { missing: !data?.details?.phone })}>
                {data?.details?.phone || 'missing'}
              </span>
            </div>
            <div className={cx('line')}>
              <span className={cx('textWithIco')}>
                <LibraryBooks /> Fax:
              </span>
              <span className={cx('outlinedText', { missing: !data?.details?.fax })}>
                {data?.details?.fax || 'missing'}
              </span>
            </div>
          </div>

          <div className={cx('block')}>
            <div className={cx('line')}>
              <span className={cx('textWithIco')}>
                <LocationOn /> Distance from Airport:
              </span>
              <span className={cx('outlinedText', { missing: !data?.details?.distance_from_airport })}>
                {data?.details?.distance_from_airport || 'missing'}
              </span>
            </div>
            <div className={cx('line')}>
              <span className={cx('textWithIco')}>
                <DirectionsBus /> Hotel transportation:
              </span>
              <span className={cx('outlinedText', { missing: !data?.details?.transportation?.value })}>
                {data?.details?.transportation?.value || 'missing'}
              </span>
            </div>
          </div>

          <div className={cx('block')}>
            <div className={cx('line')}>
              <span className={cx('textWithIco')}>
                <Alarm /> Check-in Time:
              </span>
              <span className={cx('outlinedText', { missing: !data?.details?.check_in })}>
                {data?.details?.check_in || 'missing'}
              </span>
            </div>
            <div className={cx('line')}>
              <span className={cx('textWithIco')}>
                <Alarm /> Check-out Time:
              </span>
              <span className={cx('outlinedText', { missing: !data?.details?.check_out })}>
                {data?.details?.check_out || 'missing'}
              </span>
            </div>
          </div>
        </div>

        <div className={cx('map')}>
          <div className={cx('mapWrapper')}>
            <GoogleMap marker={{ latitude: data?.details?.latitude, longitude: data?.details?.longitude }} />
          </div>

          <div className={cx('coords')}>
            <div className={cx('lat')}>
              Latitude:
              <span className={cx('outlinedText', { missing: !data?.details?.latitude })}>
                {data?.details?.latitude || 'missing'}
              </span>
            </div>
            <div className={cx('lng')}>
              Longitude:
              <span className={cx('outlinedText', { missing: !data?.details?.longitude })}>
                {data?.details?.longitude || 'missing'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralInfo
