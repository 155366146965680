import { Route } from 'routes/types/Route'

import { DashboardBookingCodeDetailsPage, DashboardHotelDetailsPage, DashboardPage } from './pages'

const routes: Route[] = [
  {
    path: '/dashboard/hotels/:hotelId/booking-codes/:bookingCode',
    component: DashboardBookingCodeDetailsPage,
    layout: 'main',
  },
  {
    path: '/admin/users/:userId/dashboard/hotels/:hotelId/booking-codes/:bookingCode',
    component: DashboardBookingCodeDetailsPage,
    layout: 'main',
  },
  {
    path: '/dashboard/hotels/:hotelId',
    component: DashboardHotelDetailsPage,
    layout: 'main',
  },
  {
    path: '/admin/users/:userId/dashboard/hotels/:hotelId',
    component: DashboardHotelDetailsPage,
    layout: 'main',
  },
  { path: '/dashboard', component: DashboardPage, layout: 'main' },
  {
    path: '/admin/users/:userId/dashboard',
    component: DashboardPage,
    layout: 'main',
  },
]

export default routes
