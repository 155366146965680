import { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import { useGetPlanListResource } from 'api/plans/getPlanListResource'
import { useUpdateSubscriptionUser } from 'api/subscription/updateSubscriptionUser'
import { SUBSCRIPTION_ENDPOINT_KEY } from 'api/subscription/constants'
import { Subscription } from 'types/domain/Subscription'
import { SubscriptionPlanListItem } from 'types/domain/SubscriptionPlanListItem'

type Props = {
  userId?: number
  subscription: Subscription | undefined
  isViewedByAdmin: boolean
}

export type Returned = {
  onStart: () => void
  isActive: boolean
  isSaving: boolean
  subscriptionPlanList: SubscriptionPlanListItem[]
  onSelect: (planId: string) => void
  onCancel: () => void
  onSave: () => void
  canEdit: boolean
  selectedSubscriptionPlanId: string | undefined
}

const useEditSubscription = (props: Props): Returned => {
  const { userId, subscription, isViewedByAdmin } = props
  const queryClient = useQueryClient()

  const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>()
  const [isActiveEdit, setIsActiveEdit] = useState(false)
  const canEdit = isViewedByAdmin && !subscription?.active

  const planListResource = useGetPlanListResource({ queryOptions: { enabled: canEdit } })
  const updateSubscriptionUser = useUpdateSubscriptionUser({ params: { user_id: userId ?? -1 } })

  const onSave = useCallback(async () => {
    if (selectedPlanId) {
      await updateSubscriptionUser.mutateAsync({ plan: selectedPlanId })
      setIsActiveEdit(false)
      await queryClient.invalidateQueries(SUBSCRIPTION_ENDPOINT_KEY)
    }
  }, [selectedPlanId, updateSubscriptionUser, queryClient])

  const onCancel = useCallback(() => {
    setIsActiveEdit(false)
    setSelectedPlanId(subscription?.plan_id)
  }, [subscription?.plan_id])

  const onStart = useCallback(() => setIsActiveEdit(true), [])

  useEffect(() => {
    if (subscription?.plan_id) {
      setSelectedPlanId(subscription?.plan_id)
    }
  }, [subscription?.plan_id])

  return {
    onStart,
    onSave,
    onCancel,
    onSelect: setSelectedPlanId,
    isActive: isActiveEdit,
    isSaving: updateSubscriptionUser.isLoading,
    subscriptionPlanList: planListResource.data?.data || [],
    selectedSubscriptionPlanId: selectedPlanId,
    canEdit,
  }
}

export default useEditSubscription
