import React from 'react'
import classNames from 'classnames'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

import styles from './NavIcons.module.scss'

export const getNavIcons = (prevNavDisabled: boolean, nextNavDisabled: boolean) => {
  const prevIcon = (
    <div
      onClick={(e) => prevNavDisabled && e.stopPropagation()}
      className={classNames(styles.nav, { [styles.disabled]: prevNavDisabled })}
    >
      <ChevronLeft />
    </div>
  )
  const superPrevIcon = (
    <div
      onClick={(e) => prevNavDisabled && e.stopPropagation()}
      className={classNames(styles.prevSuperNav, { [styles.disabled]: prevNavDisabled })}
    >
      <ChevronLeft />
      <ChevronLeft />
    </div>
  )
  const nextIcon = (
    <div
      onClick={(e) => nextNavDisabled && e.stopPropagation()}
      className={classNames(styles.nav, { [styles.disabled]: nextNavDisabled })}
    >
      <ChevronRight />
    </div>
  )
  const superNextIcon = (
    <div
      onClick={(e) => nextNavDisabled && e.stopPropagation()}
      className={classNames(styles.nextSuperNav, { [styles.disabled]: nextNavDisabled })}
    >
      <ChevronRight />
      <ChevronRight />
    </div>
  )

  return {
    nextIcon,
    superNextIcon,
    prevIcon,
    superPrevIcon,
  }
}
