export default (percent?: number | null): string => {
  if (typeof percent !== 'number') {
    return '#fff'
  }

  if (percent >= 100) {
    return '#D5F199'
  }

  if (percent > 80) {
    return '#EAFBC7'
  }

  if (percent > 70) {
    return '#DAE5FF'
  }

  if (percent > 60) {
    return '#EEF3FF'
  }

  if (percent > 40) {
    return '#F8CDCD'
  }

  if (percent > 20) {
    return '#FFA69D'
  }

  if (percent > 0) {
    return '#FF7C6E'
  }

  if (percent <= 0) {
    return '#DF5042'
  }

  return '#fff'
}
