import { QueryKey, useQuery, UseQueryOptions } from 'react-query'
import User from 'modules/UsersModule/types/User'
import { ApiError } from '../types/ApiError'
import api from '../index'

const getUserById = (userId: string): Promise<{ data: User }> => api.get(`/users/${userId}`)

const useGetUser = (
  userId: string,
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getUserById>>, ApiError>,
  customQueryKey?: QueryKey,
) => useQuery(customQueryKey || ['user', userId].join('.'), () => getUserById(userId), queryOptions)

export default useGetUser
