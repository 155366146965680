import { QueryObserverResult, useQuery } from 'react-query'

import api from 'api'
import type { Error } from 'api'
import { notification } from 'helpers'
import { Billing as BillingType } from 'types/domain/Billing'

type ReturnType = {
  data?: BillingType
  isLoading: boolean
  isFetching: boolean
  refetch: () => Promise<QueryObserverResult<{ data: BillingType } | undefined>>
}

type Options = {
  enabled?: boolean
}

const useBillingQuery = (options: Options = {}): ReturnType => {
  const fetchBilling = (): Promise<{ data: BillingType }> => api.get('/billing')
  const { data, isFetching, isLoading, refetch } = useQuery(['billing'], fetchBilling, {
    onError: (error: Error) => {
      notification.error({ message: error.message })
    },
    ...options,
  })

  return {
    data: data?.data,
    isFetching,
    isLoading,
    refetch,
  }
}

export default useBillingQuery
