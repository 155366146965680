// TODO: refactor with new styles
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import moment from 'moment/moment'
import { LoadingOutlined } from '@ant-design/icons'
import { Input, Pagination, Spin } from 'antd'
import classNames from 'classnames'
import { ExpandLess, ExpandMore, FilterList } from '@material-ui/icons'

import Button from '../Button'
import Select from '../Select'
import DatePicker from '../DatePicker/DatePicker'
import { NORMAL_PC_BREAK_POINT } from '../../constants/breakpoints'
import { Tooltip } from '../index'
import { useDimensions } from '../../hooks'
import { getCxFromStyles } from 'helpers'

import styles from './Table.module.scss'
import { TablePagination } from './types'

export interface Sorter<T = unknown> {
  by: T
  direction: 'asc' | 'desc'
}

export interface Column<T> {
  title: ReactNode
  key?: string | number
  dataIndex?: keyof T
  className?: string
  headerClassName?: string
  icon?: ReactNode
  renderCell?: (record: T, index: number) => ReactNode
  renderExpandedContent?: (record: T, index: number) => ReactNode
  sorter?: boolean | string
  filter?:
    | boolean
    | {
        type?: 'input' | 'select' | 'datepicker'
        name?: string
        placeholder?: string
        isOpened?: boolean
        onOpenChange?: (isOpen: boolean) => void
        options?: {
          label: string
          value: string
        }[]
        cellProps?: { [key: string]: unknown }
      }
}

type Props<T> = {
  rowKey: keyof T
  loading?: boolean
  className?: string
  columns: Column<T>[]
  renderRowClassname?: (record: T) => string
  onFiltersChange?: (filters: { [key: string]: string }) => void
  dataSource?: T[]
  sorter?: Sorter<keyof T>
  onSortChange?: (sorter: Sorter<keyof T>) => void
  pagination?: TablePagination
  onRowClick?: (record: T) => void
  filtersExpanded?: boolean
  noData?: ReactNode
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Table = <T extends Record<string, any>>({
  loading,
  className,
  columns,
  rowKey,
  dataSource,
  onSortChange,
  sorter,
  pagination,
  onFiltersChange,
  renderRowClassname,
  onRowClick,
  filtersExpanded = false,
  noData,
}: Props<T>): JSX.Element => {
  const cx = getCxFromStyles(styles)
  const [areFiltersExpanded, setAreFiltersExpanded] = useState(filtersExpanded)
  const [filters, setFilters] = useState<{ [key: string]: string }>({})
  const [expandedRows, setExpandedRows] = useState<number[]>([])

  const { width } = useDimensions()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const emitOnFiltersChange = useCallback(
    debounce((filters) => {
      setExpandedRows([])
      onFiltersChange && onFiltersChange(filters)
    }, 500),
    [],
  )

  const toggleExpanded = (index: number): void => {
    setExpandedRows((oldIndexes) =>
      oldIndexes.includes(index) ? oldIndexes.filter((item) => item !== index) : [...oldIndexes, index],
    )
  }

  useEffect(() => {
    emitOnFiltersChange(filters)

    return emitOnFiltersChange.cancel
  }, [filters, emitOnFiltersChange])

  const onHeaderCellClick = (fieldName: keyof T): void => {
    if (onSortChange) {
      setExpandedRows([])
      onSortChange({
        by: fieldName,
        direction: sorter?.by === fieldName && sorter?.direction === 'asc' ? 'desc' : 'asc',
      })
    }
  }

  const updateFilters = (name: string, value: string): void => {
    setFilters((old) => ({ ...old, [name]: value }))
  }

  const isFilter = columns.some((item) => item.filter)

  const renderTitleIcon = (title: ReactNode, icon: ReactNode): JSX.Element => {
    if (width < NORMAL_PC_BREAK_POINT && typeof title === 'string')
      return (
        <Tooltip text={title as string}>
          <div className={styles.iconWrapper}>{icon}</div>
        </Tooltip>
      )

    return <div className={styles.iconWrapper}>{icon}</div>
  }

  return (
    <Spin spinning={!!loading} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}>
      <table className={cx(className, 'table')}>
        <thead>
          <tr className={cx('headerTitles', { withFilters: areFiltersExpanded })}>
            {columns.map((column) => {
              const props = typeof column.filter === 'object' ? column.filter.cellProps : undefined
              return (
                <th key={(column.dataIndex as string) || column.key} className={column.className} {...props}>
                  <div
                    className={cx('headerCell', { hasSorter: !!column.sorter })}
                    onClick={(): void => {
                      typeof column.dataIndex !== 'undefined' &&
                        !!column.sorter &&
                        onHeaderCellClick(typeof column.sorter === 'string' ? column.sorter : column.dataIndex)
                    }}
                  >
                    {column.icon && renderTitleIcon(column.title, column.icon)}
                    <span className={classNames('', { [styles.withIcon]: !!column.icon })}>{column.title}</span>
                    {!!column.sorter && (
                      <div
                        className={cx('sorter', {
                          active: sorter?.by === (typeof column.sorter === 'string' ? column.sorter : column.dataIndex),
                        })}
                      >
                        {sorter?.by === column.dataIndex && sorter?.direction === 'asc' ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </div>
                    )}
                  </div>
                </th>
              )
            })}

            {isFilter && (
              <th className={cx('filtersButtonCell')}>
                <Button
                  secondary
                  onClick={(): void => setAreFiltersExpanded((old) => !old)}
                  className={cx('showFilterButton', { active: areFiltersExpanded })}
                >
                  <FilterList fontSize="small" />
                </Button>
              </th>
            )}
          </tr>

          {areFiltersExpanded && (
            <tr className={cx('headerFilters')}>
              {columns.map((column) => {
                const filterName = String(
                  (typeof column.filter !== 'boolean' && column.filter?.name) ||
                    (column.dataIndex as string) ||
                    column.key,
                )
                const props = typeof column.filter === 'object' ? column.filter.cellProps : undefined

                return (
                  <th key={filterName} className={classNames(column.className, styles.filter)} {...props}>
                    <div className={cx('headerCell')}>
                      {column.filter === true && (
                        <Input
                          size="large"
                          placeholder={typeof column.title === 'string' ? column.title : undefined}
                          value={filters[filterName]}
                          onChange={(event): void => updateFilters(filterName, event.target.value)}
                        />
                      )}
                      {typeof column.filter === 'object' && (
                        <>
                          {(!column.filter.type || column.filter.type === 'input') && (
                            <Input
                              size="large"
                              placeholder={
                                column.filter.placeholder ||
                                (typeof column.title === 'string' ? column.title : undefined)
                              }
                              value={filters[filterName]}
                              onChange={(event): void => updateFilters(filterName, event.target.value)}
                            />
                          )}

                          {column.filter.type === 'select' && (
                            <Select
                              className={cx('select')}
                              placeholder={
                                column.filter.placeholder ||
                                (typeof column.title === 'string' ? column.title : undefined)
                              }
                              options={column.filter.options}
                              value={filters[filterName]}
                              onChange={(value): void => updateFilters(filterName, value)}
                              allowClear
                              alternative
                            />
                          )}

                          {column.filter.type === 'datepicker' && (
                            <DatePicker
                              onChange={(date) => updateFilters(filterName, date ? date.format('YYYY-MM-DD') : '')}
                              onOpenChange={column.filter.onOpenChange}
                              value={moment(filters[filterName])}
                              isOpened={column.filter.isOpened}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </th>
                )
              })}
            </tr>
          )}
        </thead>
        <tbody>
          {dataSource?.map((item, rowIndex) => {
            const isExpandable = columns.some(
              (column) => !!column.renderExpandedContent && !!column.renderExpandedContent(item, rowIndex),
            )

            return (
              <React.Fragment key={item[rowKey]}>
                <tr
                  className={cx(
                    'row',
                    { expandable: isExpandable, withBackground: rowIndex % 2 === 0, clickable: !!onRowClick },
                    renderRowClassname && renderRowClassname(item),
                  )}
                  onClick={(): void => {
                    isExpandable ? toggleExpanded(rowIndex) : onRowClick && onRowClick(item)
                  }}
                >
                  {columns.map((column, index) => {
                    const props = typeof column.filter === 'object' ? column.filter.cellProps : undefined

                    return (
                      <td
                        key={(column.dataIndex as string) || column.key}
                        colSpan={isFilter && columns.length === index + 1 ? 2 : 1}
                        className={column.className}
                        {...props}
                      >
                        <div className={cx('cell', { expandIndicator: isExpandable && index === 0 })}>
                          {isExpandable &&
                            index === 0 &&
                            (expandedRows.includes(rowIndex) ? <ExpandLess /> : <ExpandMore />)}
                          {column.renderCell
                            ? column.renderCell(item, rowIndex)
                            : column.dataIndex && item[column.dataIndex]}
                        </div>
                      </td>
                    )
                  })}
                </tr>

                {isExpandable && expandedRows.includes(rowIndex) && (
                  <tr className={cx('row', 'expanded')} onClick={(): void => toggleExpanded(rowIndex)}>
                    {columns.map((column, index) => (
                      <td
                        key={(column.dataIndex as string) || column.key}
                        colSpan={isFilter && columns.length === index + 1 ? 2 : 1}
                      >
                        <div className={cx('cell')}>
                          {!!column.renderExpandedContent && column.renderExpandedContent(item, rowIndex)}
                        </div>
                      </td>
                    ))}
                  </tr>
                )}
              </React.Fragment>
            )
          })}
        </tbody>
      </table>

      {noData || null}

      {!!pagination && (
        <div className={cx('pagination')}>
          <Pagination
            current={pagination.page}
            total={pagination.totalItems}
            onChange={pagination.onChange}
            showSizeChanger={false}
            hideOnSinglePage
            pageSize={pagination.pageSize || 10}
          />
        </div>
      )}
    </Spin>
  )
}

export default Table
