import React from 'react'
import moment, { Moment } from 'moment'
import { DatePicker } from 'antd'
import en_GB from 'antd/lib/date-picker/locale/en_GB'
import { twMerge } from 'tailwind-merge'
import { ArrowRightIcon } from '@heroicons/react/16/solid'

import { useIsTablet } from 'hooks'
import { getDisabledDate, useDisableNavigation, getNavIcons } from '../utils'

import './DateRangePicker.scss'

interface Props {
  className?: string
  labels: [string, string]
  value?: [Moment | null, Moment | null] | null
  onChange?: (dates?: [Moment | null, Moment | null] | null) => void
  minDate?: Moment
  maxDate?: Moment
  availableDates?: string[]
  isDisabled?: boolean
  isClearable?: boolean
  defaultPickerDates?: [Moment, Moment]
  onOpenChange?: (isOpen: boolean) => void
  onCalendarChange?: (dates?: [Moment | null, Moment | null] | null) => void
}

moment.locale('en', {
  week: {
    dow: 1,
  },
})

export const DateRangePicker: React.FC<Props> = ({
  value,
  onChange,
  minDate,
  maxDate,
  availableDates = [],
  className,
  labels,
  isDisabled,
  isClearable,
  defaultPickerDates,
  onOpenChange,
  onCalendarChange,
}) => {
  const {} = useIsTablet()
  const { prevNavDisabled, nextNavDisabled, setNavDisabled } = useDisableNavigation()
  const { nextIcon, superNextIcon, prevIcon, superPrevIcon } = getNavIcons(prevNavDisabled, nextNavDisabled)

  return (
    <div
      className={twMerge(
        'relative border border-gray-100 bg-white rounded h-[42px]',
        className,
        isDisabled && 'pointer-events-none border-gray-100',
      )}
    >
      <div
        className={twMerge(
          'absolute top-0.5 left-2 text-gray-500 leading-none font-normal text-xs',
          isDisabled && 'text-gray-300',
        )}
      >
        {labels[0]}:
      </div>
      <div
        className={twMerge(
          'absolute top-0.5 left-[calc(50%+8px)] text-gray-500 leading-none font-normal text-xs',
          isDisabled && 'text-gray-300',
        )}
      >
        {labels[1]}:
      </div>
      <DatePicker.RangePicker
        className="dateRangePickerHRC"
        dropdownClassName="dateRangePickerDropdownHRC hrc-date-range-picker-dropdown"
        locale={en_GB}
        inputReadOnly
        value={value}
        placeholder={['-', '-']}
        onCalendarChange={onCalendarChange}
        onChange={onChange}
        onOpenChange={onOpenChange}
        onPanelChange={(date) => {
          const navDate = date && date[0]
          setNavDisabled({ navDate, minDate, maxDate, availableDates })
        }}
        disabledDate={(date: Moment): boolean => {
          return getDisabledDate({ date, availableDates, minDate, maxDate })
        }}
        format="DD.MM.YYYY"
        nextIcon={nextIcon}
        superNextIcon={superNextIcon}
        prevIcon={prevIcon}
        superPrevIcon={superPrevIcon}
        disabled={isDisabled}
        allowClear={isClearable}
        separator={<ArrowRightIcon />}
        defaultPickerValue={defaultPickerDates}
      />
    </div>
  )
}
