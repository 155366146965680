// TODO: full refactor
import React from 'react'
import moment, { Moment } from 'moment'
import { Spin, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ArrowRightAlt, Close, ErrorOutline } from '@material-ui/icons'

import { Button, DatePicker, Select } from 'ui'
import { getCxFromStyles } from 'helpers'
import { useExportFile } from 'hooks'
import { HotelListItem } from 'types/domain/HotelListItem'
import { RateDetails } from 'types/domain/RateDetails'
import useGdsSelectContext from 'components/GdsSelect/useGdsSelectContext'

import { Section } from '../types'
import styles from './CompareRatesTable.module.scss'

interface Props {
  onClose: () => void
  currentDate: Moment | null
  currentRate: RateDetails | null
  isLoading: boolean
  selectedDate?: Moment
  setSelectedDate: (date: Moment) => void
  hotel: HotelListItem
  rate?: RateDetails
  rates: RateDetails[]
  onRateChange: (value: string) => void
  sections: Section[]
  showErrors: boolean
}

const CompareRatesTable: React.FC<Props> = ({
  onClose,
  currentDate,
  currentRate,
  isLoading,
  selectedDate,
  setSelectedDate,
  hotel,
  rate,
  rates,
  onRateChange,
  sections,
  showErrors,
}) => {
  const cx = getCxFromStyles(styles)
  const { isLoading: isExporting, saveFile } = useExportFile()
  const { headers, label: gdsName } = useGdsSelectContext()

  const renderValues = (values: string[], keyPrefix: string, withErrors: boolean): JSX.Element => (
    <>
      {values.map((value) => (
        <span key={`${keyPrefix}-${value}`} className={cx('value')}>
          {value}
        </span>
      ))}

      {withErrors && values.length === 0 && (
        <div className={cx('errorWrapper')}>
          <span className={cx('value')}>missing</span>
          <ErrorOutline />
        </div>
      )}
    </>
  )

  return (
    <div className={cx('root')}>
      <div className={cx('header')}>
        <Typography.Title level={2}>Compare Rate Details</Typography.Title>

        <Button
          primary
          large
          className={cx('exportButton')}
          disabled={!rate}
          loading={isExporting}
          onClick={(): Promise<void> =>
            saveFile(
              '/print',
              `${hotel.name} - ${gdsName} - ${currentRate?.rate_plan} ${currentDate?.format('YYYYMMDD')} vs ${
                rate?.rate_plan
              } ${selectedDate?.format('YYYYMMDD')}.pdf`,
              {
                type: 'compare-rate',
                rate_id: currentRate?.id,
                compare_rate_id: rate?.id,
              },
              headers,
            )
          }
        >
          <span>Export</span>
          <ArrowRightAlt fontSize="small" />
        </Button>

        <div className={cx('close')} onClick={onClose}>
          <Close />
        </div>
      </div>

      <table className={cx('table')}>
        <tbody>
          <tr className={cx('selectors')}>
            <td />
            <td className={cx('values')}>
              <div className={cx('row')}>
                <span className={cx('title')}>Date:</span>
                <DatePicker value={currentDate} className={cx('selector')} disabled />
              </div>
              <div className={cx('row')}>
                <span className={cx('title')}>Booking code:</span>
                <Select value={currentRate?.rate_plan} className={cx('selector')} disabled />
              </div>
            </td>
            <td className={cx('values')}>
              <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}>
                <div className={cx('row')}>
                  <span className={cx('title')}>Date:</span>
                  <DatePicker
                    minDate={moment()}
                    value={selectedDate}
                    onChange={(date): void => setSelectedDate(date as Moment)}
                    className={cx('selector')}
                  />
                </div>
                <div className={cx('row')}>
                  <span className={cx('title')}>Booking code:</span>
                  <div className={cx({ error: !!selectedDate && !isLoading && !rate })}>
                    <Select
                      options={rates.map((item) => ({
                        value: item.rate_plan,
                        label: item.rate_plan,
                      }))}
                      placeholder="--"
                      value={rate?.rate_plan}
                      onChange={onRateChange}
                      className={cx('selector')}
                      disabled={!selectedDate}
                      isError={!!selectedDate && !isLoading && !rate}
                    />
                    {!!selectedDate && !isLoading && !rate && (
                      <span className={cx('errorMessage')}>Not available for selected date</span>
                    )}
                  </div>
                </div>
              </Spin>
            </td>
          </tr>

          {sections.map((section) => (
            <React.Fragment key={section.label}>
              <tr key={section.label} className={cx('sectionTitle')}>
                <td>
                  <Typography.Title level={2}>
                    <span>{section.label}</span>
                  </Typography.Title>
                </td>
                <td />
                <td />
              </tr>

              {section.fields.map((field) => (
                <React.Fragment key={`${section.label}-${field.title}`}>
                  <tr
                    className={cx({
                      danger:
                        showErrors &&
                        (field.values1.length === 0 || field.values2.length === 0) &&
                        (field.sublines || []).length === 0,
                      hasSublines: (field.sublines || []).length !== 0,
                    })}
                  >
                    <td>
                      <div className={cx('fieldTitle')}>
                        <div className={cx('icoWrapper')}>{field.icon}</div>
                        <div className={cx('title')}>{field.title}</div>
                      </div>
                    </td>
                    <td className={cx('values')}>
                      {renderValues(
                        field.values1 as string[],
                        `${section.label}-${field.title}`,
                        field.sublines.length === 0,
                      )}
                    </td>
                    <td className={cx('values')}>
                      {renderValues(
                        field.values2 as string[],
                        `${section.label}-${field.title}`,
                        showErrors && field.sublines.length === 0,
                      )}
                    </td>
                  </tr>

                  {(field.sublines || []).length > 0 &&
                    field.sublines?.map((subline, index) => (
                      <tr
                        key={`${section.label}-${field.title}-${subline?.title}`}
                        className={cx('subline', { last: index + 1 === (field.sublines || []).length })}
                      >
                        <td className={cx('fieldTitle')}>{subline?.title}</td>
                        <td className={cx('values')}>
                          {renderValues(
                            subline?.values1 as string[],
                            `${section.label}-${field.title}-${subline?.title}`,
                            true,
                          )}
                        </td>
                        <td className={cx('values')}>
                          {renderValues(
                            subline?.values2 as string[],
                            `${section.label}-${field.title}-${subline?.title}`,
                            showErrors,
                          )}
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CompareRatesTable
