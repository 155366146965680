import React, { useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useMutation } from 'react-query'
import { twMerge } from 'tailwind-merge'
import { ArrowRightIcon, TrashIcon } from '@heroicons/react/16/solid'
import { PencilIcon } from '@heroicons/react/24/solid'
import { InformationCircleIcon } from '@heroicons/react/20/solid'

import api from 'api'
import { notification } from 'helpers'
import { Button, Input } from 'ui-v2'
import { EditForm } from '../types'

type Props = {
  cardLastFour?: string
  isEdit: EditForm | null
  setEdit: (edit: EditForm | null) => void
  onClose: () => void
  reloadSubscription: () => void
}

const BillingCard: React.FC<Props> = ({ cardLastFour, isEdit, setEdit, reloadSubscription, onClose }) => {
  const editable = isEdit === EditForm.BILLING_CARD
  const [isLoading, setIsLoading] = useState(false)

  const stripe = useStripe()
  const elements = useElements()

  const { mutate: saveCC } = useMutation(
    async (data: { payment_method_id: string }): Promise<void> => api.post('/billing/payment-method', data),
  )

  const handleSubmit = async () => {
    setIsLoading(true)

    if (!stripe || !elements) {
      notification.error({ message: 'Stripe integration error' })
      return
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: document.location.href,
      },
      redirect: 'if_required',
    })

    if (error) {
      notification.error({ message: error.message })
      setIsLoading(false)
    } else {
      saveCC(
        { payment_method_id: setupIntent.payment_method as string },
        {
          onSuccess: async () => {
            notification.success({ message: 'Saved successfully.' })
            reloadSubscription()
            onClose()
          },
          onError: (error: any) => {
            notification.error({ message: error.message })
            setIsLoading(false)
          },
        },
      )
    }
  }

  return (
    <div
      className={twMerge(
        'p-2 bg-bg-light rounded-md flex flex-col gap-2 md:gap-4 md:p-6 w-full',
        editable && 'border border-indigo-300 shadow-xl',
      )}
    >
      <div className="flex items-center justify-between gap-2 text-lg leading-8 tracking-wider uppercase text-gray-800 font-semibold">
        Billing card
        {!editable && (
          <Button onClick={() => setEdit(EditForm.BILLING_CARD)}>
            <span className="inline-flex items-center gap-2">
              Edit <PencilIcon className="w-4" />
            </span>
          </Button>
        )}
      </div>

      <Input value={cardLastFour ? `**** **** **** ${cardLastFour}` : ''} label="Card ending in:" isDisabled />

      {editable && (
        <>
          <PaymentElement />

          <div className="flex items-start justify-start gap-3 p-4 rounded-md bg-blue-50 text-sm leading-5 font-medium text-blue-800">
            <InformationCircleIcon className="w-6 text-blue-400 mt-0.5" />
            Your card will not be charged at this time, but will be set as the default for future payments.
          </div>

          <div className="flex justify-end gap-2">
            <Button size="large" onClick={() => setEdit(null)} isDisabled={isLoading}>
              <span className="inline-flex gap-2">
                Cancel <TrashIcon className="w-4" />
              </span>
            </Button>

            <Button size="large" variant="primary" isLoading={isLoading} onClick={() => handleSubmit()}>
              <span className="inline-flex gap-2">
                Submit <ArrowRightIcon className="w-4" />
              </span>
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default BillingCard
