import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ArrowRightIcon, TrashIcon } from '@heroicons/react/16/solid'

import type { Error } from 'api'
import User from 'modules/UsersModule/types/User'
import { Button, Input } from 'ui-v2'

type Props = {
  saveUserError: Error | null
  onSubmit: (data: { [key: string]: string }) => void
  user?: User
  onCancel: () => void
  isEdited: boolean
  isSaving?: boolean
}

const Email: React.FC<Props> = ({ saveUserError, onSubmit, user, onCancel, isEdited, isSaving }) => {
  const { values, setFieldValue, errors, setErrors, handleSubmit, resetForm } = useFormik({
    initialValues: {
      email: user?.email || '',
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: yup.object({
      email: yup.string().email('Incorrect e-mail address').required('Email is required'),
    }),
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  useEffect(() => {
    !isEdited && resetForm()
  }, [isEdited])

  useEffect(() => {
    setErrors(saveUserError?.errors || {})
  }, [saveUserError?.errors])

  return (
    <div className="flex flex-col gap-2 md:gap-4">
      <Input
        value={values.email}
        label="E-mail"
        onChange={(value) => setFieldValue('email', value)}
        isDisabled={!isEdited}
        error={errors.email}
      />

      {isEdited && (
        <div className="flex justify-end gap-2">
          <Button
            size="large"
            onClick={(): void => {
              resetForm()
              onCancel()
            }}
          >
            <span className="inline-flex gap-2">
              Cancel <TrashIcon className="w-4" />
            </span>
          </Button>

          <Button size="large" variant="primary" isLoading={isSaving} onClick={() => handleSubmit()}>
            <span className="inline-flex gap-2">
              Submit <ArrowRightIcon className="w-4" />
            </span>
          </Button>
        </div>
      )}
    </div>
  )
}

export default Email
