import React from 'react'
import unavailableImage from 'assets/unavailable.svg'

type Props = {
  title?: string
  children?: JSX.Element
  image?: string
}

const Unavailable: React.FC<Props> = ({ title, children, image }) => {
  return (
    <div className="flex flex-col items-center gap-12 rounded-lg bg-white p-4 md:pt-12 md:pb-24">
      <img src={image || unavailableImage} alt="image" />

      <div className="text-center">
        <h1 className="text-xl lg:text-3xl leading-9 font-semibold text-blue-950 mb-2">{title || 'Unavailable'}</h1>
        <div className="text-sm lg:text-base leading-none font-normal text-gray-500">
          {children || 'Select different date to load rates rules.'}
        </div>
      </div>
    </div>
  )
}

export default Unavailable
