import React, { useState } from 'react'
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/solid'

import { Button } from 'ui-v2'
import { ContactUsModal } from 'layouts/components'

type Props = {
  title: string
  info: string
  isButton: boolean
  image: string
}

const UpgradeRequired: React.FC<Props> = ({ info, title, isButton, image }) => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)

  return (
    <div className="flex flex-col items-center gap-12 mx-2 my-4 rounded-lg bg-white p-4 md:mx-7 md:pt-12 md:pb-24">
      <img src={image} alt="image" />

      <div className="text-center">
        <h1 className="text-3xl leading-9 font-semibold text-gray-800">{title}</h1>
        <div className="text-base leading-none font-normal text-gray-500">{info}</div>
      </div>

      {isButton && (
        <Button variant="primary" size="x-large" onClick={() => setIsContactModalOpen(true)}>
          <span className="inline-flex items-center gap-2">
            Contact us <ChatBubbleLeftRightIcon className="w-5" />
          </span>
        </Button>
      )}

      {isContactModalOpen && <ContactUsModal close={(): void => setIsContactModalOpen(false)} />}
    </div>
  )
}

export default UpgradeRequired
