import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'

import { RequestHeadersWithGdsScope } from '../types/RequestHeadersWithGdsScope'
import { BookingCodePreview } from 'types/domain/BookingCode'

type GetPreviewByTokenRequestParams = {
  token: string
}

type GetPreviewByTokenResponse = {
  data: BookingCodePreview
}

export const getPreviewByToken = ({
  params,
  headers,
}: {
  params: GetPreviewByTokenRequestParams
  headers?: RequestHeadersWithGdsScope
}): Promise<GetPreviewByTokenResponse> => api.get(`/previews/${params.token}/token`, { headers })

export const useGetPreviewByToken = ({
  params,
  headers,
  queryOptions,
  customQueryKey,
}: {
  params: GetPreviewByTokenRequestParams
  headers?: RequestHeadersWithGdsScope
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getPreviewByToken>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || ['previews', ...Object.values(params || {}), ...Object.values(headers || {})],
    () => getPreviewByToken({ params, headers }),
    queryOptions,
  )
