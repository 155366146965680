import React, { FC, useCallback, useState } from 'react'
import { Close, Search } from '@material-ui/icons'

import { useGetHotelSearchListResource } from 'api/hotels/getHotelSearchListResource'
import { HotelSearchListResponseItem } from 'types/domain/HotelSearchListResponseItem'
import { HotelCandidate } from 'types/domain/HotelCandidate'
import { Table } from 'ui'

import styles from './CustomHotels.module.scss'

interface Props {
  currentCandidate: HotelCandidate
  updateHotelFromCandidate: (hotel_id: number) => void
  onClose: () => void
}

const CustomHotels: FC<Props> = ({ updateHotelFromCandidate, onClose, currentCandidate }) => {
  const [customHotelsQuery, setCustomHotelsQuery] = useState({
    page: 1,
    length: 20,
    name: '',
  })
  const [customHotelsSearch, setCustomHotelsSearch] = useState('')

  const { data: customHotelsData, isLoading: isCustomHotelsLoading } = useGetHotelSearchListResource({
    query: customHotelsQuery,
    queryOptions: {
      enabled: !!customHotelsQuery.name,
    },
  })

  const setHotelQuery = useCallback(
    () =>
      setCustomHotelsQuery((prevState) => ({
        ...prevState,
        name: customHotelsSearch.length >= 3 ? customHotelsSearch : '',
        page: 1,
      })),
    [customHotelsSearch],
  )

  return (
    <div className={styles.root}>
      <h2>
        Map with searched hotel{' '}
        <button onClick={onClose}>
          <Close />
        </button>
      </h2>

      <div className={styles.searchWrapper}>
        <div className={styles.search}>
          <input
            value={customHotelsSearch}
            onChange={(event) => setCustomHotelsSearch(event.target.value)}
            onKeyDown={(event) => {
              event.code === 'Enter' && setHotelQuery()
            }}
            placeholder="Search... (min 3 chars)"
          />

          <Search />
        </div>
        <button onClick={setHotelQuery}>Search</button>
      </div>

      <Table<HotelSearchListResponseItem>
        rowKey="id"
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            renderCell: (record, index) => index > 0 && record.id,
          },
          {
            title: 'Property Id',
            key: 'property-ids',
            renderCell: (record, index) =>
              index > 0 ? (
                <div>
                  Sabre: {record.property_ids.sabre_id}
                  <br />
                  Travelport: {record.property_ids.travelport_id}
                </div>
              ) : (
                currentCandidate.property_id
              ),
          },
          {
            title: 'Name',
            dataIndex: 'name',
          },
          {
            title: 'Address',
            dataIndex: 'address',
          },
          {
            title: 'City',
            dataIndex: 'city',
          },
          {
            title: 'Country',
            dataIndex: 'country',
          },
          {
            title: 'Post Code',
            dataIndex: 'post_code',
          },
          {
            title: '',
            key: 'action',
            renderCell: (record, index) =>
              index > 0 && (
                <button className={styles.button} onClick={() => updateHotelFromCandidate(record.id)}>
                  Match
                </button>
              ),
          },
        ]}
        dataSource={[
          {
            id: currentCandidate.id,
            name: currentCandidate.name,
            city: currentCandidate.city,
            address: currentCandidate.address,
            post_code: currentCandidate.post_code,
            country: currentCandidate.country,
            property_ids: {
              travelport_id: '',
              sabre_id: '',
            },
          },
          ...(customHotelsData?.data || []),
        ]}
        loading={isCustomHotelsLoading}
        pagination={{
          page: customHotelsData?.meta.current_page,
          onChange: (page) => setCustomHotelsQuery((prevState) => ({ ...prevState, page })),
          pageSize: customHotelsData?.meta.per_page,
          totalItems: customHotelsData?.meta.total,
        }}
      />
    </div>
  )
}

export default CustomHotels
