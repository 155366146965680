import { useContext, useEffect, useMemo } from 'react'
import { GDS_LIST, SABRE_KEY, TRAVEL_PORT_KEY } from 'constants/gds'
import GdsNameHeaderKey from 'api/constants/GdsNameHeaderKey'
import GdsSelectContext from './GdsSelectContext'

type Props = {
  disabled?: boolean
  onChange?: (value: string) => void
}

const useGdsSelectContext = (props: Props = {}) => {
  const { disabled = false, onChange } = props
  const { value, setValue, setDisabled } = useContext(GdsSelectContext)

  useEffect(() => {
    setDisabled(disabled)

    return () => {
      setDisabled(false)
    }
  }, [setDisabled, disabled])

  const headers = useMemo(
    () => ({
      [GdsNameHeaderKey]: value,
    }),
    [value],
  )

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(value)
    }
  }, [onChange, value])

  const isSelectedSabre = useMemo(() => value === SABRE_KEY, [value])
  const isSelectedTravelport = useMemo(() => value === TRAVEL_PORT_KEY, [value])
  const selectedGds = useMemo(() => GDS_LIST.find((gds) => gds.name === value), [value])

  return {
    id: selectedGds?.id,
    label: selectedGds?.label,
    value,
    setValue,
    headers,
    isSelectedSabre,
    isSelectedTravelport,
    setDisabled,
  }
}

export default useGdsSelectContext
