import { useMutation, UseMutationResult, UseMutationOptions } from 'react-query'

import api from 'api/index'
import { UserRoles } from 'modules/UsersModule/types'

import { ApiError } from '../types/ApiError'

const inviteUser = (data: InviteUserRequest): Promise<void> => api.post(`/trial-invitations`, data)

type InviteUserRequest = {
  email: string
  first_name?: string
  last_name?: string
  gds_ids: number[]
  trial_ends_at: string
  hotel_ids: number[]
  account_type: [UserRoles.Owner, UserRoles.TravelAgent]
}

export const useInviteUser = (
  queryOptions?: UseMutationOptions<void, ApiError, InviteUserRequest>,
): UseMutationResult<void, ApiError, InviteUserRequest> => useMutation((data) => inviteUser(data), queryOptions)
