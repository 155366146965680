import { createContext } from 'react'
import noop from 'lodash/noop'

export type SidebarWrapperContextValue = {
  isCollapsed: boolean
  toggleIsCollapsed: () => void
}

export default createContext<SidebarWrapperContextValue>({
  isCollapsed: false,
  toggleIsCollapsed: noop,
})
