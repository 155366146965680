import { useMutation, UseMutationResult } from 'react-query'

import api from 'api/index'
import { Demo } from 'types/domain/Demo'
import { ApiError } from '../types/ApiError'
import { ApiResponseWithMeta } from '../types/ApiResponseWithMeta'
import { mapDataToBackend } from './utils'

const updateDemo = (id: string, data: Demo): Promise<ApiResponseWithMeta<Demo>> =>
  api.patch(`/demos/${id}`, mapDataToBackend(data))

export const useUpdateDemo = (): UseMutationResult<ApiResponseWithMeta<Demo>, ApiError, { id: string; data: Demo }> =>
  useMutation(({ id, data }) => updateDemo(id, data))
