import React, { useEffect } from 'react'
import { ArrowRightIcon, TrashIcon } from '@heroicons/react/16/solid'
import { useFormik } from 'formik'
import * as yup from 'yup'

import type { Error } from 'api'
import { Button, Input, PhoneInput } from 'ui-v2'
import User from 'modules/UsersModule/types/User'

type Props = {
  saveUserError: Error | null
  onSubmit: (data: { [key: string]: string }) => void
  user?: User
  onCancel: () => void
  isEdited: boolean
  isSaving?: boolean
}

const General: React.FC<Props> = ({ saveUserError, onSubmit, user, onCancel, isEdited, isSaving }) => {
  const { values, setFieldValue, errors, setErrors, handleSubmit, resetForm } = useFormik({
    initialValues: {
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      phone: user?.phone || '',
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: yup.object({
      first_name: yup.string().required('First name is required'),
      last_name: yup.string().required('Last name is required'),
      phone: yup.string().required('Phone is required'),
    }),
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  useEffect(() => {
    !isEdited && resetForm()
  }, [isEdited])

  useEffect(() => {
    setErrors(saveUserError?.errors || {})
  }, [saveUserError?.errors])

  return (
    <div className="flex flex-col gap-2 md:gap-4">
      <Input
        value={values.first_name}
        label="First name"
        onChange={(value) => setFieldValue('first_name', value)}
        isDisabled={!isEdited}
        error={errors.first_name}
      />

      <Input
        value={values.last_name}
        label="Last name"
        onChange={(value) => setFieldValue('last_name', value)}
        isDisabled={!isEdited}
        error={errors.last_name}
      />

      <PhoneInput
        value={values.phone}
        onChange={(phone) => setFieldValue('phone', phone)}
        label="Phone number"
        isDisabled={!isEdited}
        error={errors.phone}
      />

      {isEdited && (
        <div className="flex justify-end gap-2">
          <Button
            size="large"
            onClick={(): void => {
              resetForm()
              onCancel()
            }}
          >
            <span className="inline-flex gap-2">
              Cancel <TrashIcon className="w-4" />
            </span>
          </Button>

          <Button size="large" variant="primary" isLoading={isSaving} onClick={() => handleSubmit()}>
            <span className="inline-flex gap-2">
              Submit <ArrowRightIcon className="w-4" />
            </span>
          </Button>
        </div>
      )}
    </div>
  )
}

export default General
