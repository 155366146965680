import { useState } from 'react'
import { saveAs } from 'file-saver'

import api from 'api'
import { notification } from 'helpers'
import { getErrorMessage } from '../helpers/error'
import { RequestHeadersWithGdsScope } from '../api/types/RequestHeadersWithGdsScope'

type RequestParams = {
  type:
    | 'hotel-rate'
    | 'hotel-card'
    | 'compare-rate'
    | 'hotel-ota'
    | 'hotel-rate-rules'
    | 'rate-details'
    | 'measurements'
  hotel_id?: number
  rate_id?: number
  compare_rate_id?: number
  date?: string
  owner_id?: number
  date_from?: string
  date_to?: string
  gds_id?: number
}

type ReturnType = {
  isLoading: boolean
  saveFile: (url: string, fileName: string, params: RequestParams, headers: RequestHeadersWithGdsScope) => Promise<void>
}

const useExportFile = (): ReturnType => {
  const [isLoading, setIsLoading] = useState(false)
  const saveFile = async (
    url: string,
    fileName: string,
    params: RequestParams,
    headers: RequestHeadersWithGdsScope,
  ): Promise<void> => {
    setIsLoading(true)

    try {
      const response: Blob = await api.post(url, params, {
        responseType: 'blob',
        headers,
      })

      saveAs(response, fileName)
    } catch (error) {
      notification.error({ message: getErrorMessage(error) })
    }

    setIsLoading(false)
  }

  return {
    isLoading,
    saveFile,
  }
}

export default useExportFile
