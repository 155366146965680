import React, { FC, useEffect, useState } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Close } from '@material-ui/icons'

import { useGetHotelCandidateResource } from 'api/hotelCandidate/getHotelCandidateResource'
import { useGetHotelCandidateNextResource } from 'api/hotelCandidate/getHotelCandidateNextResource'
import { useDeleteHotelCandidate } from 'api/hotelCandidate/deleteHotelCandidate'
import { useCreateHotelFromCandidate } from 'api/hotelCandidate/createHotelFromCandidate'
import { useUpdateHotelFromCandidate } from 'api/hotelCandidate/updateHotelFromCandidate'
import { HotelCandidate } from 'types/domain/HotelCandidate'
import { HotelsImportListItem } from 'types/domain/HotelsImportListItem'
import { notification } from 'helpers'

import CustomHotels from '../CustomHotels'

import styles from './Match.module.scss'

interface Props {
  importData: HotelsImportListItem
  listFilters: {
    similarity_from?: number
    similarity_to?: number
    chain_code?: string
    query?: string
  }
  initialCandidateId: number
  onClose: () => void
}

const Match: FC<Props> = ({ listFilters, initialCandidateId, onClose, importData }) => {
  const [isCustomHotelChoosing, setIsCustomHotelChoosing] = useState(false)
  const [currentCandidate, setCurrentCandidate] = useState<HotelCandidate | null>(null)
  const [nextCandidateParams, setNextCandidateParams] = useState<{
    direction: 'asc' | 'desc'
  } | null>(null)

  const { isLoading: isHotelCandidateLoading } = useGetHotelCandidateResource({
    params: { hotel_candidate: initialCandidateId },
    queryOptions: {
      onSuccess: (data) => setCurrentCandidate(data.data),
    },
  })
  const { isLoading: isHotelCandidateNextLoading, refetch: refetchHotelCandidateNextData } =
    useGetHotelCandidateNextResource({
      params: { hotel_candidate: currentCandidate?.id || 0 },
      query: {
        direction: nextCandidateParams?.direction || 'desc',
        ...listFilters,
      },
      queryOptions: {
        enabled: false,
        onError: (error) => {
          if (error.status === 404) {
            onClose()
          }
        },
        onSuccess: (data) => setCurrentCandidate(data.data),
      },
    })

  const { mutate: deleteCandidate, isLoading: isDeleteCandidateLoading } = useDeleteHotelCandidate({
    onSuccess: () => {
      setNextCandidateParams({ direction: 'desc' })
    },
  })

  const { mutate: createHotelFromCandidate, isLoading: isCreateHotelFromCandidateLoading } =
    useCreateHotelFromCandidate({
      params: { hotel_candidate: currentCandidate?.id || 0 },
      queryOptions: {
        onSuccess: () => {
          setNextCandidateParams({ direction: 'desc' })
        },
      },
    })
  const { mutate: updateHotelFromCandidate, isLoading: isUpdateHotelFromCandidateLoading } =
    useUpdateHotelFromCandidate({
      params: { hotel_candidate: currentCandidate?.id || 0 },
      queryOptions: {
        onSuccess: () => {
          setIsCustomHotelChoosing(false)
          setNextCandidateParams({ direction: 'desc' })
        },
        onError: (error) => {
          notification.error({ message: error.message })
        },
      },
    })

  useEffect(() => {
    nextCandidateParams && refetchHotelCandidateNextData()
  }, [nextCandidateParams, refetchHotelCandidateNextData])

  return (
    <div className={styles.root}>
      {isCustomHotelChoosing && currentCandidate ? (
        <CustomHotels
          updateHotelFromCandidate={(hotel_id) => updateHotelFromCandidate({ hotel_id })}
          onClose={() => setIsCustomHotelChoosing(false)}
          currentCandidate={currentCandidate}
        />
      ) : (
        <>
          <h2>
            Hotel Matching{' '}
            <button onClick={onClose}>
              <Close />
            </button>
          </h2>

          <Spin
            spinning={
              isHotelCandidateLoading ||
              isHotelCandidateNextLoading ||
              isCreateHotelFromCandidateLoading ||
              isUpdateHotelFromCandidateLoading ||
              isDeleteCandidateLoading
            }
            indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
          >
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Mapped Hotel</th>
                  {currentCandidate?.matches?.map((item, index) => (
                    <th key={item.id}>Match {index + 1}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="w-[120px]">Name</td>
                  <td>
                    <strong>
                      {currentCandidate?.name}{' '}
                      {currentCandidate?.status && (
                        <span className={styles.hotelStatus}>({currentCandidate.status})</span>
                      )}
                    </strong>
                  </td>
                  {currentCandidate?.matches?.map((item) => (
                    <td key={item.id}>{item.name}</td>
                  ))}
                </tr>
                <tr>
                  <td className="w-[120px]">Property ID</td>
                  <td>
                    <strong>{currentCandidate?.property_id.replaceAll('-Galileo', '')}</strong>
                  </td>
                  {currentCandidate?.matches?.map((item) => (
                    <td key={item.id}>{item.properties.join(', ').replaceAll('-Galileo', '')}</td>
                  ))}
                </tr>
                <tr>
                  <td className="w-[120px]">Address</td>
                  <td>
                    <strong>{currentCandidate?.address}</strong>
                  </td>
                  {currentCandidate?.matches?.map((item) => (
                    <td key={item.id}>{item.address}</td>
                  ))}
                </tr>
                <tr>
                  <td className="w-[120px]">Postal Code</td>
                  <td>
                    <strong>{currentCandidate?.post_code}</strong>
                  </td>
                  {currentCandidate?.matches?.map((item) => (
                    <td key={item.id}>{item.post_code}</td>
                  ))}
                </tr>
                <tr>
                  <td className="w-[120px]">City</td>
                  <td>
                    <strong>{currentCandidate?.city}</strong>
                  </td>
                  {currentCandidate?.matches?.map((item) => (
                    <td key={item.id}>{item.city}</td>
                  ))}
                </tr>
                <tr>
                  <td className="w-[120px]">Country</td>
                  <td>
                    <strong>{currentCandidate?.country}</strong>
                  </td>
                  {currentCandidate?.matches?.map((item) => (
                    <td key={item.id}>{item.country}</td>
                  ))}
                </tr>
                <tr>
                  <td className="w-[120px]">Similarity</td>
                  <td>
                    <strong>-</strong>
                  </td>
                  {currentCandidate?.matches?.map((item) => (
                    <td key={item.id}>{item.similarity_index.toFixed(1)}%</td>
                  ))}
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <button onClick={() => createHotelFromCandidate()}>Create</button>
                    <button
                      className={styles.redGhost}
                      onClick={() => currentCandidate && deleteCandidate(currentCandidate?.id)}
                    >
                      Reject
                    </button>
                  </td>
                  {currentCandidate?.matches?.map((item) => (
                    <td key={item.id}>
                      <button
                        onClick={() => updateHotelFromCandidate({ hotel_id: item.id, force: true })}
                        className={
                          item.properties.some((item) => item.includes(importData.gds_name)) ? styles.red : styles.green
                        }
                      >
                        Match
                      </button>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
            <div className={styles.bottomActions}>
              <button
                className={styles.skip}
                onClick={() => {
                  setNextCandidateParams({ direction: 'asc' })
                }}
              >
                Prev
              </button>
              <button onClick={() => setIsCustomHotelChoosing(true)}>Map with another hotel</button>
              <button
                className={styles.skip}
                onClick={() => {
                  setNextCandidateParams({ direction: 'desc' })
                }}
              >
                Next
              </button>
            </div>
          </Spin>
        </>
      )}
    </div>
  )
}

export default Match
