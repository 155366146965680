import { combineReducers, configureStore } from '@reduxjs/toolkit'

import authReducer from 'modules/AuthModule/slices/auth'

const combinedReducer = combineReducers({
  auth: authReducer,
})

// eslint-disable-next-line
const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/logout/fulfilled' || action.type === 'auth/logout/rejected') {
    localStorage.clear()
    state = {
      auth: {
        isAuthenticated: false,
      },
    }
  }
  return combinedReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>

export default store
