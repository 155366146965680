import React, { useContext, useEffect, useMemo } from 'react'
import { Select } from 'ui-v2'
import { useSelector } from 'react-redux'

import GdsSelectContext from 'components/GdsSelect/GdsSelectContext'
import { GDS_LIST } from 'constants/gds'
import { RootState } from 'store'
import { useGetGdsCredentialsListResource } from 'api/gdsCredentials/getGdsCredentialsListResource'
import { useIsTravelAgent } from 'hooks'
import { validateGdsCredentials } from 'helpers/gds'

type GdsSelectProps = {
  isHiddenSelect?: boolean
}

const GdsSelect: React.FC<GdsSelectProps> = ({ isHiddenSelect }) => {
  const { disabled: contextDisabled, value, setValue: selectGds } = useContext(GdsSelectContext)
  const { profile } = useSelector((state: RootState) => state.auth)
  const isDemoUser = useSelector((state: RootState) => state.auth.isDemoUser)
  const isTravelAgent = useIsTravelAgent()
  const { data: gdsCredentialsData } = useGetGdsCredentialsListResource({
    queryOptions: {
      enabled: isTravelAgent,
    },
  })

  const allGdsEnabled = profile?.is_admin || isDemoUser

  const options = useMemo(() => {
    const gdsOptions = GDS_LIST.filter((gds) => {
      if (allGdsEnabled) return true
      return profile?.gds.find((userGdsId) => userGdsId.id.toString() === gds.id.toString())
    }).map((gds) => ({
      value: gds.name,
      label: gds.label,
    }))

    if (isTravelAgent) {
      return gdsOptions.filter((gdsOption) => validateGdsCredentials(gdsCredentialsData?.data, gdsOption.value))
    }

    return gdsOptions
  }, [allGdsEnabled, profile?.gds, gdsCredentialsData, isTravelAgent])

  useEffect(() => {
    if (!value && options && options.length > 0) {
      const lastSelectedGds = localStorage.getItem('selected-gds')

      selectGds(
        lastSelectedGds && options.find((o) => o.value === lastSelectedGds) ? lastSelectedGds : options[0].value,
      )
    }
  }, [options, selectGds, value])

  const disabled = options.length < 2 || contextDisabled

  if (isHiddenSelect) {
    return null
  }

  return (
    <Select
      className="w-full border-2 md:w-48 md:mr-auto"
      options={options}
      value={value}
      onChange={(value) => value && selectGds(value)}
      isDisabled={disabled}
      label="GDS"
      size="small"
    />
  )
}

export default GdsSelect
