import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import moment from 'moment'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'

import api from 'api'
import type { Error } from 'api'
import Hotel from 'modules/HotelsModule/types/Hotel'
import { notification } from 'helpers'

import HotelCard from './HotelCard'
import useGdsSelectContext from 'components/GdsSelect/useGdsSelectContext'

const HotelContainer: React.FC = () => {
  const { hotelId } = useParams<{ hotelId: string }>()
  const { headers } = useGdsSelectContext()

  const [data, setData] = useState<Hotel>()

  const fetchHotel = (key: string, hotelId: number): Promise<{ data: Hotel }> =>
    api.get(`/hotels/${hotelId}`, { headers })
  const { isLoading: isHotelLoading, data: hotelData } = useQuery(
    ['hotel', hotelId, headers['Gds-Name']],
    () => fetchHotel(['hotel', hotelId].join(','), parseInt(hotelId, 10)),
    {
      onError: (error: Error) => {
        notification.error({ message: error.message })
      },
    },
  )
  useEffect(() => {
    !!hotelData && setData(hotelData.data)
  }, [hotelData])

  const { mutate: fetchData, isLoading: isHotelRefreshing } = useMutation(
    async (data: { start_date: string; end_date: string; mode: string; hotel_id?: number }): Promise<{ data: Hotel }> =>
      api.post('/checkups', data),
    {
      onSuccess: ({ data }) => {
        setData(data)
      },
      onError: (error: Error) => {
        notification.error({ message: error.message })
      },
    },
  )

  const refreshData = (mode = 'full'): void => {
    fetchData({
      start_date: data?.checkup?.from_date || moment().format('YYYY-MM-DD'),
      end_date: data?.checkup?.to_date || moment().add(1, 'day').format('YYYY-MM-DD'),
      hotel_id: data?.id,
      mode,
    })
  }

  return (
    <Spin spinning={isHotelLoading || isHotelRefreshing} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}>
      <HotelCard data={data} refreshData={refreshData} />
    </Spin>
  )
}

export default HotelContainer
