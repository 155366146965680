import React, { useCallback, useMemo, useState } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { DocumentChartBarIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/16/solid'
import { twMerge } from 'tailwind-merge'

import { CodeDetails, GeneralInfo, Rules } from './components'
import { Button, Header } from 'ui-v2'
import { Rate } from 'ui'
import { Hotel } from 'types/domain/Hotel'
import { RateDetails } from 'types/domain/RateDetails'
import NoData from '../NoData'
import { useExportFile } from 'hooks'
import useGdsSelectContext from 'components/GdsSelect/useGdsSelectContext'

type Props = {
  userId?: number
  // TODO: split this
  hotel: Hotel
  onNewBookingCode: (code: string) => void
  selectedRate: Nullable<RateDetails>
}

const OTAView = (props: Props) => {
  const history = useHistory()
  const { userId, hotel, selectedRate, onNewBookingCode } = props
  const { isLoading, saveFile } = useExportFile()
  const { headers, label: gdsName } = useGdsSelectContext()

  const [bookingCode, setBookingCode] = useState(selectedRate?.rate_plan)
  const [isShowRateRules, setIsShowRateRules] = useState(false)

  const rateList = useMemo(() => hotel.rates || [], [hotel])
  const rateDetails = useMemo(() => rateList.find((rate) => rate.rate_plan === bookingCode), [bookingCode, rateList])
  const startDate = useMemo(() => moment(hotel.checkup?.from_date), [hotel.checkup?.from_date])
  const endDate = useMemo(() => moment(hotel.checkup?.to_date), [hotel.checkup?.to_date])
  const isUnavailable = startDate.isBefore(moment().startOf('day')) && !rateDetails?.details_available

  const onSelectBookingCode = useCallback(
    (newBookingCode: string) => {
      setIsShowRateRules(false)
      setBookingCode(newBookingCode)
      onNewBookingCode(newBookingCode)
    },
    [onNewBookingCode],
  )
  const toggleShowRateRules = useCallback(() => setIsShowRateRules((value) => !value), [])

  if (!rateDetails || !bookingCode) {
    return <NoData title={'No data to display.'} />
  }

  return (
    <div>
      <Header>
        <div className="w-full">
          <h1 className="text-3xl !leading-9 font-semibold text-gray-800 m-0 flex items-start justify-between">
            {hotel.details?.name}
            <Button
              onClick={() => history.push('/hotel/details')}
              variant="secondary"
              className="p-0 flex items-center justify-center rounded w-[34px]"
            >
              <XMarkIcon className="w-6" />
            </Button>
          </h1>
          <div className="flex items-center gap-2 text-base leading-none font-normal text-gray-500">
            AAA Rating:
            {typeof hotel.details?.rating_aaa === 'number' ? (
              <span className="mb-1">
                <Rate value={hotel.details.rating_aaa} disabled />
              </span>
            ) : (
              <span className="text-validation-error">Missing</span>
            )}
          </div>
        </div>
      </Header>

      <div className="px-6 bg-white flex items-center justify-end gap-1 md:flex-row md:items-start md:gap-3 md:px-7">
        <Button
          size="large"
          variant="secondary"
          isLoading={isLoading}
          className="rounded-none border-t-0 border-b-0"
          onClick={(): Promise<void> =>
            saveFile(
              '/print',
              `${hotel.name} - ${gdsName || 'unknown'} - OTA page - ${moment().format('YYYYMMDD')}.pdf`,
              {
                type: 'hotel-ota',
                hotel_id: hotel.id,
                rate_id: selectedRate?.id,
              },
              headers,
            )
          }
        >
          <span className="flex items-center gap-2">
            Export to PDF <DocumentChartBarIcon className="w-5" />
          </span>
        </Button>
      </div>

      <div className="bg-bg-light py-4 px-2 flex flex-col gap-4 md:px-7">
        <GeneralInfo hotelDetails={hotel.details} />

        <CodeDetails
          rateList={rateList}
          rate={rateDetails}
          onSelectBookingCode={onSelectBookingCode}
          adultsCount={hotel.checkup?.adults || 1}
          childrenCount={hotel.checkup?.children || 0}
        />

        <div className="bg-white p-4 flex flex-col gap-4 rounded-lg">
          <div className="bg-bg-accent-7 px-4 py-3 rounded flex items-center gap-6 text-lg leading-8 tracking-wider uppercase text-gray-800 font-semibold">
            Rate rules
            <Button onClick={toggleShowRateRules} variant="secondary">
              <span className="flex gap-2">
                {isShowRateRules ? 'Hide' : 'Show'}
                <ChevronDownIcon className={twMerge('w-4', isShowRateRules && 'rotate-180')} />
              </span>
            </Button>
          </div>

          {isShowRateRules && (
            <Rules
              isUnavailable={isUnavailable}
              hotelId={hotel.id}
              bookingCode={bookingCode}
              startDate={startDate}
              endDate={endDate}
              userId={userId}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default OTAView
