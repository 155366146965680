import React from 'react'

import styles from './HeaderWithActions.module.scss'
import { Header } from '../index'
import { Close } from '@material-ui/icons'

type Props = {
  withoutLine?: boolean
  title: string
  onClose?: () => void
}

const HeaderWithActions: React.FC<Props> = ({ children, onClose, title, withoutLine }) => (
  <div className={styles.root}>
    <Header withoutLine={withoutLine}>{title}</Header>
    <div className={styles.actions}>
      {children}
      {onClose && (
        <div className={styles.close} onClick={onClose}>
          <Close />
        </div>
      )}
    </div>
  </div>
)

export default HeaderWithActions
