import React from 'react'
import classNames from 'classnames'
import ccIcon from 'payment-icons/min/flat/default.svg'
import amexIcon from 'payment-icons/min/flat/amex.svg'
import jcbIcon from 'payment-icons/min/flat/jcb.svg'
import maestroIcon from 'payment-icons/min/flat/maestro.svg'
import mastercardIcon from 'payment-icons/min/flat/mastercard.svg'
import visaIcon from 'payment-icons/min/flat/visa.svg'

import styles from './CardProviderIcon.module.scss'

type Props = {
  cardProviderName: string
  className?: string
}

const CardProviderIcon = ({ cardProviderName, className }: Props) => (
  <img
    className={classNames(styles.root, className)}
    src={
      cardProviderName === 'amex'
        ? amexIcon
        : cardProviderName === 'jcb'
        ? jcbIcon
        : cardProviderName === 'maestro'
        ? maestroIcon
        : cardProviderName === 'mastercard'
        ? mastercardIcon
        : cardProviderName === 'visa'
        ? visaIcon
        : ccIcon
    }
    alt="CC"
  />
)


export default CardProviderIcon
