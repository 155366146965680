import React from 'react'
import classNames from 'classnames'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { Column as Field, TablePagination } from 'ui/Table'

import styles from './Tiles.module.scss'

interface ITiles<T> {
  fields: Field<T>[]
  dataSource: T[]
  pagination?: TablePagination
  className?: string
  isLoading?: boolean
}

const Tiles = <T extends Record<string, any>>({ dataSource, fields, isLoading = false, className }: ITiles<T>) => {
  return (
    <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}>
      <div className={classNames(styles.tiles, className)}>
        {dataSource.map((item, index) => (
          <div className={styles.tile} key={String(item.id)}>
            {fields.map((field, subIndex) => (
              <div
                className={classNames(styles.fieldWrapper, field.className, {
                  [styles.buttonWrapper]: field.key === 'actions',
                })}
                key={String(subIndex)}
              >
                <div className={styles.title}>{field.title}</div>
                {field.renderCell ? field.renderCell(item, index) : item[field.dataIndex as string]}
              </div>
            ))}
          </div>
        ))}
      </div>
    </Spin>
  )
}

export default Tiles
