import { useQuery, QueryKey, UseQueryOptions } from 'react-query'
import qs from 'qs'

import api from 'api/index'
import type { ApiError } from 'api/types/ApiError'
import { BookingCodeListItem } from 'types/domain/BookingCode'
import { RequestHeadersWithGdsScope } from 'api/types/RequestHeadersWithGdsScope'
import { ApiPaginationQuery } from 'api/types/ApiPaginationQuery'
import { ApiResponseWithMeta } from 'api/types/ApiResponseWithMeta'

import { BOOKING_CODES_ENDPOINT_KEY } from './constants'

type GetBookingCodeDetailsListResourceRequestQuery = ApiPaginationQuery & {
  hotel_id: number
  user_id?: number
  rate_plan?: number
  start_date?: string
  end_date?: string
  adults?: number
  rac?: string
}

type GetBookingCodeDetailsListResourceRequestParams = {
  booking_code: string
}

export type GetBookingCodeDetailsListResourceResponse = ApiResponseWithMeta<Array<BookingCodeListItem>, unknown>

export const getBookingCodeDetailsListResource = ({
  params,
  query,
  headers,
}: {
  params: GetBookingCodeDetailsListResourceRequestParams
  headers: RequestHeadersWithGdsScope
  query: GetBookingCodeDetailsListResourceRequestQuery
}): Promise<GetBookingCodeDetailsListResourceResponse> =>
  api.get(`/${BOOKING_CODES_ENDPOINT_KEY}/${params.booking_code}?${qs.stringify(query, { skipNulls: true })}`, {
    headers,
  })

export const useGetBookingCodeDetailsListResource = ({
  params,
  headers,
  query,
  queryOptions,
  customQueryKey,
}: {
  params: GetBookingCodeDetailsListResourceRequestParams
  headers: RequestHeadersWithGdsScope
  query: GetBookingCodeDetailsListResourceRequestQuery
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getBookingCodeDetailsListResource>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || [BOOKING_CODES_ENDPOINT_KEY, ...Object.values(query), ...Object.values(headers)],
    () => getBookingCodeDetailsListResource({ params, query, headers }),
    queryOptions,
  )
