import React, { ReactNode, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, Redirect, useLocation, useParams, matchPath } from 'react-router-dom'
import moment from 'moment'
import { Layout } from 'antd'
import { Assignment, Forum, Home, Computer, Search, Flag, Description } from '@material-ui/icons'
import { twMerge } from 'tailwind-merge'

import { RootState } from 'store'
import { UserRoles } from 'modules/UsersModule/types'
import { Tooltip } from 'ui'
import useSidebarWrapperContext from 'components/SidebarWrapper/useSidebarWrapperContext'
import { MainLayoutContext } from './MainContext'
import { getMenuDisabled } from '../utils'
import { SidebarWrapper, TopBar, ContactUsModal } from '../components'

const Main: React.FC = ({ children }) => {
  const { pathname } = useLocation()
  const { userId } = useParams<{ userId: string | undefined }>()
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)
  const { isAuthenticated, profile } = useSelector((state: RootState) => state.auth)

  const { isSidebarCollapsed, toggleSidebarCollapsed } = useSidebarWrapperContext()

  const isTravelAgent = useMemo(() => profile?.role === UserRoles.TravelAgent, [profile])
  const isDemoUser = Boolean(localStorage.getItem('isDemoUser'))
  const hideGdsSelect = useMemo(
    () =>
      !!matchPath(pathname, { path: `/profile` }) ||
      !!matchPath(pathname, { path: `/search/hotel` }) ||
      (profile?.gds || []).length < 2,
    [pathname, profile],
  )

  if (!isAuthenticated) {
    return <Redirect to="/" />
  }

  if (userId && !profile?.is_admin) {
    return <Redirect to="/" />
  }

  const linkPrefix = userId ? `/admin/users/${userId}` : ''

  const layoutContext = {
    linkPrefix,
  }

  const disabledNavigation = getMenuDisabled(pathname, profile)

  const renderLink = (to: string, children: ReactNode): ReactNode => {
    if (disabledNavigation) return children

    return (
      <Link to={to} className="w-full">
        {children}
      </Link>
    )
  }

  const itemClasses = twMerge(
    'w-[52px] h-[52px] flex items-center justify-center text-white border-y-[6px] border-transparent cursor-pointer md:w-9 md:h-9 md:border-0 text-base leading-6 font-normal',
    !isSidebarCollapsed && 'md:w-full md:gap-3 md:justify-start md:px-2',
    disabledNavigation && 'pointer-events-none',
  )

  const itemActiveClasses = 'border-b-blue-800 md:bg-blue-800 md:rounded'

  return (
    <Layout>
      <div className="fixed bg-dark-blue p-1 z-10 w-full bottom-0 duration-300 md:w-auto md:top-0 md:overflow-auto md:py-4 md:px-2 md:shrink-0">
        <SidebarWrapper isExpanded={!isSidebarCollapsed} toggleExpand={toggleSidebarCollapsed}>
          {!isTravelAgent &&
            renderLink(
              `${linkPrefix}/dashboard`,
              <div
                className={twMerge(
                  itemClasses,
                  (matchPath(pathname, {
                    path: `${linkPrefix}/dashboard`,
                    exact: false,
                  }) ||
                    pathname === `${linkPrefix}/hotel/card`) &&
                    itemActiveClasses,
                )}
              >
                <Tooltip text={isSidebarCollapsed ? 'Dashboard' : ''} placement="right">
                  <Home className="!w-5 !h-5" />
                </Tooltip>
                <span className={twMerge('hidden', !isSidebarCollapsed && 'md:inline')}>Dashboard</span>
              </div>,
            )}
          {isTravelAgent &&
            renderLink(
              `${linkPrefix}/search/hotel`,
              <div
                className={twMerge(
                  itemClasses,
                  matchPath(pathname, {
                    path: `${linkPrefix}/search/hotel`,
                    exact: false,
                  }) && itemActiveClasses,
                )}
              >
                <Tooltip text={isSidebarCollapsed ? 'Hotel Search' : ''} placement="right">
                  <Search className="!w-5 !h-5" />
                </Tooltip>
                <span className={twMerge('hidden', !isSidebarCollapsed && 'md:inline')}>Hotel Search</span>
              </div>,
            )}
          {renderLink(
            `${linkPrefix}/hotel/details`,
            <div
              className={twMerge(
                itemClasses,
                (matchPath(pathname, {
                  path: `${linkPrefix}/hotel/details`,
                  exact: false,
                }) ||
                  matchPath(pathname, {
                    path: `${linkPrefix}/hotel/rules`,
                    exact: false,
                  })) &&
                  itemActiveClasses,
              )}
            >
              <Tooltip text={isSidebarCollapsed ? 'Rate Check' : ''} placement="right">
                <Assignment className="!w-5 !h-5" />
              </Tooltip>
              <span className={twMerge('hidden', !isSidebarCollapsed && 'md:inline')}>Rate Check</span>
            </div>,
          )}
          {!isTravelAgent &&
            renderLink(
              `${linkPrefix}/hotel/ota-view`,
              <div
                className={twMerge(
                  itemClasses,
                  matchPath(pathname, {
                    path: `${linkPrefix}/hotel/ota-view`,
                    exact: false,
                  }) && itemActiveClasses,
                )}
              >
                <Tooltip text={isSidebarCollapsed ? 'OTA View' : ''} placement="right">
                  <Computer className="!w-5 !h-5" />
                </Tooltip>
                <span className={twMerge('hidden', !isSidebarCollapsed && 'md:inline')}>OTA View</span>
              </div>,
            )}
          {isTravelAgent &&
            renderLink(
              `${linkPrefix}/reported-issues`,
              <div
                className={twMerge(
                  itemClasses,
                  matchPath(pathname, {
                    path: `${linkPrefix}/reported-issues`,
                    exact: false,
                  }) && itemActiveClasses,
                )}
              >
                <Tooltip text={isSidebarCollapsed ? 'Issues' : ''} placement="right">
                  <Flag className="!w-5 !h-5" />
                </Tooltip>
                <span className={twMerge('hidden', !isSidebarCollapsed && 'md:inline')}>Issues</span>
              </div>,
            )}
          {isTravelAgent &&
            renderLink(
              `${linkPrefix}/hotel-emails`,
              <div
                className={twMerge(
                  itemClasses,
                  matchPath(pathname, {
                    path: `${linkPrefix}/hotel-emails`,
                    exact: false,
                  }) && itemActiveClasses,
                )}
              >
                <Tooltip text={isSidebarCollapsed ? 'Mailbook' : ''} placement="right">
                  <Description className="!w-5 !h-5" />
                </Tooltip>
                <span className={twMerge('hidden', !isSidebarCollapsed && 'md:inline')}>Mailbook</span>
              </div>,
            )}
          <div className="border-l border-gray-600 pl-2 ml-2 md:ml-0 md:mt-auto md:pl-0 md:pt-3 md:border-l-0 md:border-t md:w-full">
            <div
              className={twMerge(itemClasses, 'pointer-events-auto')}
              onClick={(): void => setIsContactModalOpen(true)}
            >
              <Tooltip text={isSidebarCollapsed ? 'Contact' : ''} placement="right">
                <Forum className="!w-5 !h-5" />
              </Tooltip>
              <span className={twMerge('hidden', !isSidebarCollapsed && 'md:inline')}>Contact</span>
            </div>
          </div>
        </SidebarWrapper>
      </div>

      <Layout className={twMerge('mb-[60px] md:mb-0 md:ml-[52px]', !isSidebarCollapsed && 'md:ml-[220px]')}>
        <TopBar hideGdsSelect={hideGdsSelect} hideProfileMenuOptions={isDemoUser} />
        <Layout.Content className="bg-bg-light">
          <MainLayoutContext.Provider value={layoutContext}>{children}</MainLayoutContext.Provider>
        </Layout.Content>
        <Layout.Footer className="!py-6 !px-4 md:!px-8 md:!py-2.5 !bg-bg-light !text-xs md:text-right">
          <div className="divide-x-2 divide-gray-400">
            <a
              className="pr-3"
              href={`${import.meta.env.VITE_LANDING_URL}/privacy-policy`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </a>
            <a
              className="px-3"
              href={`${import.meta.env.VITE_LANDING_URL}/cookies-policy`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookies Policy
            </a>
            <a
              className="pl-3"
              href={`${import.meta.env.VITE_LANDING_URL}/terms`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>
          </div>
          © {moment().format('YYYY')} Travel Minds sp. z o.o. All rights reserved.
        </Layout.Footer>
      </Layout>

      {isContactModalOpen && <ContactUsModal close={(): void => setIsContactModalOpen(false)} />}
    </Layout>
  )
}

export default Main
