import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import moment, { Moment } from 'moment'
import { useQuery } from 'react-query'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { Close } from '@material-ui/icons'

import { useExportFile } from 'hooks'
import { notification } from 'helpers'
import useMeasurementsQuery, { QueryParams } from 'hooks/useMeasurementsQuery'
import useGdsSelectContext from 'components/GdsSelect/useGdsSelectContext'
import { User } from 'modules/UsersModule/types'
import api from 'api'
import { Button, Tabs, Header } from 'ui-v2'
import { GDS_LIST } from 'constants/gds'

import { Tiles, List, Params } from './components'
import styles from './Statistics.module.scss'

const GDS_SELECT_OPTIONS = [
  {
    id: 0,
    value: 'all',
    label: 'All',
  },
  ...[...GDS_LIST]
    .sort((a, b) => (a.name < b.name ? -1 : 1))
    .map((gds) => ({ value: gds.name, label: gds.label, id: gds.id })),
]

const accountTypeTabs = [
  {
    key: 'hotel',
    label: 'Hotels',
  },
  {
    key: 'travel_agency',
    label: 'Travel Agencies',
  },
]

const Statistics: React.FC = () => {
  const history = useHistory()
  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null]>([moment().subtract(30, 'days'), moment()])
  const { ownerId } = useParams<{ ownerId?: string }>()
  const [selectedGds, setSelectedGds] = useState<{ id: number; label: string; value: string }>(GDS_SELECT_OPTIONS[0])
  const [accountType, setAccountType] = useState<QueryParams['account_type']>(
    accountTypeTabs[0].key as QueryParams['account_type'],
  )
  const { isLoading: isExporting, saveFile } = useExportFile()

  const { headers } = useGdsSelectContext({ disabled: true })

  const initialParams: QueryParams = useMemo(
    () => ({
      page: 1,
      length: 25,
      from: dateRange[0]?.format('YYYY-MM-DD') || '',
      to: dateRange[1]?.format('YYYY-MM-DD') || '',
      owner_id: ownerId ? parseInt(ownerId, 10) : undefined,
      account_type: !ownerId ? (accountType as QueryParams['account_type']) : undefined,
    }),
    [dateRange, ownerId, accountType],
  )

  const getUserById = (key: string, userId: number): Promise<{ data: User }> => api.get(`/users/${userId}`)

  const [params, setParams] = useState<QueryParams>(initialParams)

  const handleSearch = useCallback(() => {
    setParams((old) => ({
      ...old,
      from: dateRange[0]?.format('YYYY-MM-DD') || '',
      to: dateRange[1]?.format('YYYY-MM-DD') || '',
      owner_id: ownerId ? parseInt(ownerId, 10) : undefined,
      account_type: !ownerId ? (accountType as QueryParams['account_type']) : undefined,
    }))
  }, [dateRange, ownerId, accountType])

  const { isLoading: isUserLoading, data: userData } = useQuery(
    ['user', ownerId],
    () => getUserById(['user', ownerId].join(','), ownerId ? parseInt(ownerId, 10) : -1),
    {
      enabled: !!ownerId,
      onError: () => {
        notification.error({ message: 'Fetch user error' })
        history.push('/admin/statistics')
      },
      onSuccess: handleSearch,
    },
  )

  const { data, meta, isLoading, isFetched, isRefetching } = useMeasurementsQuery(
    params,
    selectedGds.value === 'all' ? undefined : { 'Gds-Name': selectedGds.value },
  )

  useEffect(() => {
    if (!ownerId) {
      setParams((old) => ({
        ...old,
        owner_id: undefined,
        account_type: accountType,
      }))
    }
  }, [ownerId, accountType])

  return (
    <Spin
      spinning={isLoading || isUserLoading || isRefetching || !isFetched}
      indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
    >
      <div className="flex justify-between items-center">
        <Header title={userData ? `${userData.data.first_name} ${userData.data.last_name}` : 'Statistics'} size="big" />
        {ownerId && (
          <div className={styles.close}>
            <Close
              fontSize="small"
              onClick={() => {
                history.push('/admin/statistics')
                setAccountType(accountType)
                setParams((old) => ({ ...old, owner_id: undefined, account_type: accountType }))
              }}
            />
          </div>
        )}
      </div>
      <Params
        handleSearch={handleSearch}
        dates={dateRange}
        onDatesChange={setDateRange}
        selectedGds={selectedGds}
        setSelectedGds={setSelectedGds}
        gdsSelectOptions={GDS_SELECT_OPTIONS}
        isLoading={isLoading}
      />
      <div className="pl-[30px] pb-6">
        {!ownerId && (
          <Tabs
            items={accountTypeTabs}
            selectedKey={accountType}
            onChange={(key) => {
              setAccountType(key as QueryParams['account_type'])
              setParams((old) => ({ ...old, account_type: key as QueryParams['account_type'], page: 1 }))
              history.push('/admin/statistics')
            }}
          />
        )}

        <div className="relative p-2 bg-white md:p-6">
          {!!ownerId && (
            <div className="ml-auto mr-1 text-right">
              <Button
                variant="primary"
                size="large"
                onClick={() =>
                  saveFile(
                    '/exports/measurements',
                    `${userData?.data.first_name} ${userData?.data.last_name} [${dateRange[0]?.format(
                      'YYYY-MM-DD',
                    )} - ${dateRange[1]?.format('YYYY-MM-DD')}].xlsx`,
                    {
                      type: 'measurements',
                      owner_id: parseInt(ownerId),
                      date_from: dateRange[0]?.format('YYYY-MM-DD'),
                      date_to: dateRange[1]?.format('YYYY-MM-DD'),
                      gds_id: selectedGds.id ? selectedGds.id : undefined,
                    },
                    headers,
                  )
                }
                isLoading={isExporting}
              >
                Export
              </Button>
            </div>
          )}
          <Tiles data={meta?.tiles} isOwnerList={!!ownerId} isTravelAgency={accountType === 'travel_agency'} />
          <List
            data={data}
            meta={meta}
            setPage={(page): void => setParams((old) => ({ ...old, page }))}
            isOwnerList={!!ownerId}
            isTravelAgency={accountType === 'travel_agency'}
            params={params}
            setSorter={(sorter): void =>
              setParams((old) => ({ ...old, ...{ sort_by: sorter.by, sort_direction: sorter.direction }, page: 1 }))
            }
          />
        </div>
      </div>
    </Spin>
  )
}

export default Statistics
