import { Column } from '../../ui/Table'
import { HotelListItem } from '../../types/domain/HotelListItem'
import { GDS_LIST, TRAVEL_PORT_KEY } from '../../constants/gds'
import React from 'react'

const getGdsColumns = (withChainCodeColumns?: boolean) => {
  const cols: Column<HotelListItem>[] = []

  GDS_LIST.forEach((gds) => {
    cols.push({
      title: `${gds.name === TRAVEL_PORT_KEY ? 'Travelport' : gds.label} ID`,
      key: `${gds.name}_property_id`,
      renderCell: (record) => (
        <div>{record.gds_properties.find((gdsRecord) => gdsRecord.gds_id === gds.id)?.property_id}</div>
      ),
      filter: true,
    })
    withChainCodeColumns &&
      cols.push({
        title: `${gds.name === TRAVEL_PORT_KEY ? 'Travelport' : gds.label} Chain`,
        key: `${gds.name}_chain_code`,
        renderCell: (record) => (
          <div>{record.gds_properties.find((gdsRecord) => gdsRecord.gds_id === gds.id)?.chain_code}</div>
        ),
        filter: true,
      })
  })

  return cols
}

export default getGdsColumns
