import React from 'react'
import { Modal } from './Modal'

import styles from './ConfirmationModal.module.scss'
import { Button } from './Button'
import { ArrowRightIcon, XMarkIcon } from '@heroicons/react/16/solid'

interface Props {
  onClose: () => void
  onSuccess: () => void
  title: string
  isLoading?: boolean
}

export const ConfirmationModal: React.FC<Props> = ({ title, onSuccess, onClose, isLoading }) => {
  return (
    <Modal onClose={onClose} title={title}>
      <div className="flex justify-end gap-2 mt-5">
        <Button
          size="large"
          onClick={(): void => {
            onClose()
          }}
          isDisabled={isLoading}
        >
          <span className="inline-flex gap-2">
            No <XMarkIcon className="w-4" />
          </span>
        </Button>

        <Button size="large" variant="primary" isLoading={isLoading} onClick={() => onSuccess()}>
          <span className="inline-flex gap-2">
            Yes <ArrowRightIcon className="w-4" />
          </span>
        </Button>
      </div>
    </Modal>
  )
}
