import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Sorter } from 'ui-v2/Table/Table'
import chevron from 'assets/chevronGray.svg'

type ThProps = {
  name?: string
  children?: ReactNode
  className?: string
  colSpan?: number
  sorter?: Sorter<string>
  setSorter?: (sorter: Sorter<string>) => void
}
export const Th = ({ name, children, className, colSpan, sorter, setSorter }: ThProps) => {
  const [isHeaderSticky, setIsHeaderSticky] = useState(false)

  const wrapperRef = useRef()
  const checkHeaderStickiness = useCallback(() => {
    if (!wrapperRef.current) {
      return
    }

    const { y } = wrapperRef.current.getBoundingClientRect()
    if (y < 0 && !isHeaderSticky) {
      setIsHeaderSticky(true)
    } else if (y > 0 && isHeaderSticky) {
      setIsHeaderSticky(false)
    }
  }, [wrapperRef])

  useEffect(() => {
    window.addEventListener('scroll', checkHeaderStickiness, { passive: true })
    return () => window.removeEventListener('scroll', checkHeaderStickiness)
  }, [checkHeaderStickiness])

  return (
    <th
      ref={wrapperRef}
      colSpan={colSpan}
      className={twMerge(
        'bg-gray-50 px-6 py-3 text-left border border-gray-200 border-t-transparent first:border-l-transparent last:border-r-transparent text-xs leading-4 font-medium tracking-wider uppercase text-gray-500 text-nowrap',
        className,
        isHeaderSticky && 'sticky top-0',
      )}
    >
      <div>
        {children}
        {sorter && setSorter && name && (
          <button
            onClick={() => setSorter({ by: name, direction: sorter.direction === 'asc' ? 'desc' : 'asc' })}
            className={twMerge(
              'h-auto bg-transparent border-none p-0 ml-1 cursor-pointer align-text-bottom',
              sorter.direction === 'asc' && 'rotate-180',
            )}
          >
            <img src={chevron} alt="sort" />
          </button>
        )}
      </div>
    </th>
  )
}
