import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'

import styles from './PageLoader.module.scss'

const PageLoader = () => {
  return (
    <div className={styles.root}>
      <LoadingOutlined style={{ fontSize: 40 }} spin />
    </div>
  )
}

export default PageLoader
