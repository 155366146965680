import React from 'react'
import { useSelector } from 'react-redux'
import { Link, matchPath, Redirect, useLocation } from 'react-router-dom'
import { Layout } from 'antd'
import { Description, SupervisedUserCircle, TrendingUp, AccessTime, CompareArrows, Flag } from '@material-ui/icons'
import { twMerge } from 'tailwind-merge'

import { RootState } from 'store'
import { Tooltip } from 'ui'
import { useIsTablet } from 'hooks'
import useSidebarWrapperContext from 'components/SidebarWrapper/useSidebarWrapperContext'
import { DEMO_LINKS_URL } from 'modules/DemosModule/const'
import { SidebarWrapper, TopBar } from '../components'

const Admin: React.FC = ({ children }) => {
  const { pathname } = useLocation()
  const { isAuthenticated, profile } = useSelector((state: RootState) => state.auth)

  const isTablet = useIsTablet()
  const { isSidebarCollapsed, toggleSidebarCollapsed } = useSidebarWrapperContext()

  if (!isAuthenticated || !profile?.is_admin) {
    return <Redirect to="/" />
  }

  const itemClasses = twMerge(
    'w-[52px] h-[52px] flex items-center justify-center text-white border-y-[6px] border-transparent cursor-pointer md:w-9 md:h-9 md:border-0 text-base leading-6 font-normal',
    !isSidebarCollapsed && 'md:w-full md:gap-3 md:justify-start md:px-2',
  )

  const itemActiveClasses = 'border-b-blue-800 md:bg-blue-800 md:rounded'

  return (
    <Layout>
      <div className="fixed bg-dark-blue p-1 z-10 w-full bottom-0 duration-300 md:w-auto md:top-0 md:overflow-auto md:py-4 md:px-2 md:shrink-0">
        <SidebarWrapper isExpanded={!isSidebarCollapsed} toggleExpand={toggleSidebarCollapsed}>
          <Link to="/admin/users" className="w-full">
            <div className={twMerge(itemClasses, pathname === '/admin/users' && itemActiveClasses)}>
              <Tooltip text={isSidebarCollapsed ? 'User Management' : ''} placement="right">
                <SupervisedUserCircle className="!w-5 !h-5" />
              </Tooltip>
              <span className={twMerge('hidden', !isSidebarCollapsed && 'md:inline')}>User Management</span>
            </div>
          </Link>

          <Link to="/admin/properties" className="w-full">
            <div className={twMerge(itemClasses, pathname === '/admin/properties' && itemActiveClasses)}>
              <Tooltip text={isSidebarCollapsed ? 'Properties' : ''} placement="right">
                <Description className="!w-5 !h-5" />
              </Tooltip>
              <span className={twMerge('hidden', !isSidebarCollapsed && 'md:inline')}>Properties</span>
            </div>
          </Link>

          <Link to="/admin/statistics" className="w-full">
            <div className={twMerge(itemClasses, pathname === '/admin/statistics' && itemActiveClasses)}>
              <Tooltip text={isSidebarCollapsed ? 'Statistics' : ''} placement="right">
                <TrendingUp className="!w-5 !h-5" />
              </Tooltip>
              <span className={twMerge('hidden', !isSidebarCollapsed && 'md:inline')}>Statistics</span>
            </div>
          </Link>

          <Link to={DEMO_LINKS_URL} className="w-full">
            <div
              className={twMerge(
                itemClasses,
                matchPath(pathname, {
                  path: DEMO_LINKS_URL,
                  exact: false,
                }) && itemActiveClasses,
              )}
            >
              <Tooltip text={isSidebarCollapsed ? 'Demo Links' : ''} placement="right">
                <AccessTime className="!w-5 !h-5" />
              </Tooltip>
              <span className={twMerge('hidden', !isSidebarCollapsed && 'md:inline')}>Demo Links</span>
            </div>
          </Link>

          <Link to="/admin/reported-issues" className="w-full">
            <div
              className={twMerge(
                itemClasses,
                !!matchPath(pathname, { path: '/admin/reported-issues' }) && itemActiveClasses,
              )}
            >
              <Tooltip text={isSidebarCollapsed ? 'Issues' : ''} placement="right">
                <Flag className="!w-5 !h-5" />
              </Tooltip>
              <span className={twMerge('hidden', !isSidebarCollapsed && 'md:inline')}>Issues</span>
            </div>
          </Link>

          {!isTablet && (
            <Link to="/admin/gds-match" className="w-full">
              <div className={twMerge(itemClasses, pathname === '/admin/gds-match' && itemActiveClasses)}>
                <Tooltip text={isSidebarCollapsed ? 'Hotel Mapping' : ''} placement="right">
                  <CompareArrows className="!w-5 !h-5" />
                </Tooltip>
                <span className={twMerge('hidden', !isSidebarCollapsed && 'md:inline')}>Hotel Mapping</span>
              </div>
            </Link>
          )}

          <div className="mt-auto" />
        </SidebarWrapper>
      </div>

      <Layout className={twMerge('mb-[60px] md:mb-0 md:ml-[52px]', !isSidebarCollapsed && 'md:ml-[220px]')}>
        <TopBar hideGdsSelect />

        <Layout.Content className="bg-bg-light">{children}</Layout.Content>
      </Layout>
    </Layout>
  )
}

export default Admin
