import React, { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Input as InputA } from 'antd'
import { RootState } from 'store'
import { ArrowRightAlt, Delete } from '@material-ui/icons'
import { format } from 'date-fns'
import { PlusIcon, XMarkIcon } from '@heroicons/react/16/solid'

import { useGetIssueResource } from 'api/issues/getIssueResource'
import { Form } from 'ui'
import { Header, Button, Input, Textarea } from 'ui-v2'
import { FormItem } from 'ui/Form'
import { useUpdateHotel } from 'api/hotels/updateHotel'
import { useSendIssue } from 'api/issues/sendIssue'
import { ISSUE_STATUS_KEYS } from 'constants/issuesStatuses'
import StatusSelect from 'modules/ReportedIssuesModule/components/StatusSelect'
import { notification } from 'helpers'

import styles from './ReportedIssueDetails.module.scss'

const ReportedIssueDetails = (): JSX.Element => {
  const { issueId } = useParams<{ issueId?: string }>()
  const history = useHistory()

  const { profile } = useSelector((state: RootState) => state.auth)
  const isAdmin = useMemo(() => profile?.is_admin, [profile])

  const { data, isLoading, refetch } = useGetIssueResource({ params: { id: Number(issueId) } })
  const { mutate, isLoading: isHotelUpdating } = useUpdateHotel({
    params: { id: data?.data?.hotel_id || -1 },
    queryOptions: {
      onSuccess: () => {
        notification.success({
          message: 'Email has been updated',
        })
        refetch()
      },
      onError: (error) =>
        notification.error({
          message: error.message,
        }),
    },
  })
  const reportedIssue = useMemo(() => data?.data, [data])
  const { mutate: sendIssue, isLoading: isIssueSending } = useSendIssue({
    queryOptions: {
      onSuccess: () => {
        notification.success({
          message: 'Email has been sent',
        })
        refetch()
      },
      onError: (error) =>
        notification.error({
          message: error.message,
        }),
    },
  })

  const handleAddEmail = useCallback((values: { [key: string]: string }) => {
    mutate({ email: values.hotel_email })
  }, [])

  return (
    <div className="flex flex-col justify-between gap-4 bg-bg-light">
      <Header>
        <div className="flex justify-between w-full gap-3 md:flex-row md:items-center">
          <h1 className="text-3xl !leading-9 font-semibold text-gray-800 my-2 lg:m-0 flex flex-wrap items-center gap-3">
            <span>
              Issue #{issueId}
              {reportedIssue && (
                <span className="text-gray-400">, {format(new Date(reportedIssue.created_at), 'dd.LL.y')}</span>
              )}
            </span>

            {reportedIssue && (
              <StatusSelect
                status={reportedIssue.status as ISSUE_STATUS_KEYS}
                issueId={reportedIssue.id}
                onStatusChanged={refetch}
              />
            )}
          </h1>

          <Button
            variant="secondary"
            className="p-1 w-[34px] rounded flex items-center justify-center"
            onClick={() => history.push(`${isAdmin ? '/admin' : ''}/reported-issues`)}
          >
            <XMarkIcon className="w-5" />
          </Button>
        </div>
      </Header>

      <div className="mx-2 p-2 bg-white rounded-lg md:p-6 md:mx-7 relative flex flex-col gap-2 lg:gap-6">
        {isLoading && (
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-50 opacity-35">
            <span className="absolute top-1/2 left-1/2 w-12 h-12 inline-flex items-center justify-center -mt-6 -ml-6">
              <svg
                aria-hidden="true"
                className="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-800"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </span>
          </div>
        )}

        <div className="flex flex-col gap-2 lg:flex-row md:gap-6">
          <div className="w-full flex flex-col gap-2 md:gap-6">
            <div className="flex flex-col gap-2 rounded p-2 md:p-6 bg-bg-light">
              <div className="text-lg leading-8 tracking-wider uppercase text-gray-800 font-semibold">Hotel</div>
              <Input value={reportedIssue?.property_name || ''} label="Property name" readOnly />
              <Input value={reportedIssue?.booking_code || ''} label="Booking Code" readOnly />
              <Input value={reportedIssue?.gds_name || ''} label="GDS" readOnly />
              <Input value={reportedIssue?.property_id || ''} label="Property ID" readOnly />
            </div>
            <div className="flex flex-col gap-2 rounded p-2 md:p-6 bg-bg-light">
              <div className="text-lg leading-8 tracking-wider uppercase text-gray-800 font-semibold">Email</div>
              <Input value={reportedIssue?.email || ''} label="Email" readOnly />
            </div>
          </div>
          <div className="w-full flex flex-col gap-2 md:gap-6">
            <div className="flex flex-col gap-2 rounded p-2 md:p-6 bg-bg-light">
              <div className="text-lg leading-8 tracking-wider uppercase text-gray-800 font-semibold">Issue</div>
              <Textarea value={reportedIssue?.description || ''} label="Description" className="h-44" readOnly />
            </div>
          </div>
        </div>
      </div>

      {isAdmin && reportedIssue && (
        <Form initialValues={{ hotel_email: reportedIssue.hotel_email || '' }} onSubmit={handleAddEmail}>
          {({ form, errors }): JSX.Element => (
            <div className="mx-2 p-2 bg-white rounded-lg md:p-6 md:mx-7 relative flex flex-col gap-2 lg:gap-6">
              <div className="flex flex-col gap-2 lg:flex-row md:gap-6">
                <div className="w-1/2 flex pr-2 flex-col gap-2 md:gap-6">
                  <div className="flex flex-col gap-2 rounded p-2 md:p-6 bg-bg-light">
                    <div className="text-lg leading-8 tracking-wider uppercase text-gray-800 font-semibold">
                      Hotel email
                    </div>

                    <div className={styles.addEmailFormItemWrapper}>
                      <FormItem
                        name="hotel_email"
                        label={!form.getFieldValue('hotel_email') ? 'Hotel e-mail' : ' '}
                        validateStatus={errors.hotel_email ? 'error' : ''}
                        rules={[
                          { required: true, message: 'E-mail is required' },
                          { type: 'email', message: 'Incorrect e-mail address' },
                        ]}
                        help={errors.hotel_email || ''}
                        className={styles.addEmailFormItem}
                      >
                        <InputA size="large" placeholder="Hotel e-mail" className={styles.addEmailInput} />
                      </FormItem>
                      {!reportedIssue.hotel_email ||
                      (reportedIssue.hotel_email &&
                        form.isFieldTouched('hotel_email') &&
                        form.getFieldValue('hotel_email') &&
                        form.getFieldValue('hotel_email') !== reportedIssue.hotel_email) ? (
                        <Button
                          onClick={form.submit}
                          isLoading={isHotelUpdating}
                          isDisabled={isIssueSending}
                          variant="secondary"
                        >
                          <span className="inline-flex items-center gap-2">
                            Add <PlusIcon className="w-5" />
                          </span>
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            form.setFieldsValue({ hotel_email: '' })
                            handleAddEmail({ hotel_email: '' })
                          }}
                          isLoading={isHotelUpdating}
                          isDisabled={isIssueSending}
                          variant="danger"
                        >
                          <span className="inline-flex items-center gap-2">
                            Delete <Delete className={styles.deleteIcon} />
                          </span>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[140px] flex flex-col ml-auto">
                <Button
                  isDisabled={
                    !reportedIssue.hotel_email ||
                    isLoading ||
                    isHotelUpdating ||
                    reportedIssue.status === ISSUE_STATUS_KEYS.CLOSED
                  }
                  variant="primary"
                  isLoading={isIssueSending}
                  onClick={() =>
                    sendIssue({
                      id: parseInt(issueId || '', 10),
                      email: form.getFieldValue('hotel_email'),
                    })
                  }
                >
                  Send email
                  <ArrowRightAlt className={styles.arrowIcon} fontSize="small" />
                </Button>
              </div>
            </div>
          )}
        </Form>
      )}
    </div>
  )
}

export default ReportedIssueDetails
