import { Moment } from 'moment'

const getDisabledDate = ({
  date,
  minDate,
  maxDate,
  availableDates,
}: {
  date: Moment
  minDate?: Moment
  maxDate?: Moment
  availableDates: string[]
}): boolean => {
  const isBeforeMinDate = minDate ? !date || date.isBefore(minDate) : false
  const isAfterMaxDate = maxDate ? !date || date.isAfter(maxDate) : false
  const isDateAllowed = availableDates ? !availableDates.includes(date.format('YYYY-MM-DD')) : false

  return (isBeforeMinDate || isAfterMaxDate) && isDateAllowed
}

export default getDisabledDate
