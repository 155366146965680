import { useMutation, UseMutationResult } from 'react-query'

import api from 'api/index'
import { Demo } from 'types/domain/Demo'
import { ApiError } from '../types/ApiError'
import { ApiResponseWithMeta } from '../types/ApiResponseWithMeta'
import { mapDataToBackend } from './utils'

export const createDemo = (data: Demo): Promise<ApiResponseWithMeta<Demo>> => api.post('/demos', mapDataToBackend(data))

export const useCreateDemo = (): UseMutationResult<ApiResponseWithMeta<Demo>, ApiError, Demo> =>
  useMutation((data) => createDemo(data))
