import React from 'react'
import { useField, useFormikContext } from 'formik'
import MaskedInput, { Mask } from 'react-text-mask'

import Input, { Props as InputProps } from './Input'

type Props = {
  name: string
  classes?: {
    input?: string
    root?: string
    label?: string
  }
  mask?: Mask
} & Omit<InputProps, 'onBlur' | 'onChange' | 'value' | 'error' | 'name'>

const FormikInput = (props: Props) => {
  const { name, mask, ...restProps } = props
  const [field, meta] = useField<string | undefined>(name)
  const { isSubmitting } = useFormikContext()

  if (mask)
    return (
      <MaskedInput
        {...restProps}
        mask={mask}
        name={name}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        disabled={isSubmitting || restProps.disabled}
        render={(ref, props) => (
          <Input
            {...restProps}
            {...props}
            value={props.defaultValue}
            inputRef={(input: HTMLInputElement) => ref(input)}
            error={meta.touched ? meta.error : undefined}
            disabled={isSubmitting || restProps.disabled}
          />
        )}
      />
    )

  return (
    <Input
      {...restProps}
      name={name}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      error={meta.touched ? meta.error : undefined}
      disabled={isSubmitting || restProps.disabled}
    />
  )
}

export default FormikInput
