import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PlusIcon } from '@heroicons/react/16/solid'

import { Tabs, Button, Header } from 'ui-v2'
import useGdsSelectContext from 'components/GdsSelect/useGdsSelectContext'
import useDemosQuery from 'hooks/useDemosQuery'
import { DEMO_LINKS_URL, DEMO_LIST_TAB_KEYS } from '../../const'
import DemoList from '../../components/DemoList'

import Params from './components'

export const DEMO_LIST_TABS = {
  [DEMO_LIST_TAB_KEYS.ACTIVE]: {
    key: 'active',
    label: 'Active',
  },
  [DEMO_LIST_TAB_KEYS.NOT_ACTIVE]: {
    key: 'notActive',
    label: 'Not active',
  },
}

const DemoListContainer: React.FC = () => {
  const [selectedTabKey, setSelectedTabKey] = useState(DEMO_LIST_TABS[DEMO_LIST_TAB_KEYS.ACTIVE].key)

  const { headers } = useGdsSelectContext()

  const history = useHistory()

  const { data, meta, error, setPage, isFetching, setSorter, setFilters, sorter, refetch } = useDemosQuery({
    headers,
  })

  return (
    <div>
      <Header size="big" title="Demo" />
      <Params error={error} onFiltersChange={setFilters} />
      <div className="mx-2 my-4 rounded-lg md:mx-7">
        <div className="flex items-center justify-between">
          <Tabs
            items={Object.values(DEMO_LIST_TABS)}
            selectedKey={selectedTabKey}
            onChange={(tabKey) => {
              setSelectedTabKey(tabKey)
              setFilters({ active: tabKey === DEMO_LIST_TABS[DEMO_LIST_TAB_KEYS.ACTIVE].key })
            }}
          />
        </div>
        <div className="relative p-2 bg-white md:px-6 md:pb-6 md:pt-4">
          <div className="mt-2 mb-4">
            <Button className="mr-auto" variant="secondary" onClick={(): void => history.push(`${DEMO_LINKS_URL}/add`)}>
              <span className="inline-flex items-center gap-2">
                Add demo <PlusIcon className="w-5" />
              </span>
            </Button>
          </div>
          <DemoList
            data={data}
            meta={meta}
            setPage={setPage}
            isFetching={isFetching}
            setSorter={setSorter}
            setFilters={setFilters}
            sorter={sorter}
            refetch={refetch}
            selectedTabKey={selectedTabKey}
          />
        </div>
      </div>
    </div>
  )
}

export default DemoListContainer
