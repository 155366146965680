import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from 'store'
import { UserRoles } from 'modules/UsersModule/types'
import ContactUsModal from 'layouts/components/ContactUsModal'
import UpgradeRequired from './UpgradeRequired'
import supportImage from 'assets/support.svg'
import accessDeniedImage from 'assets/accessDenied.svg'

const UpgradeRequiredContainer: React.FC = () => {
  const [isOpenContactModal, setIsOpenContactModal] = useState(false)

  const { profile } = useSelector((state: RootState) => state.auth)

  const isOwner = profile?.role === UserRoles.Owner
  const isTravelAgent = useMemo(() => profile?.role === UserRoles.TravelAgent, [profile])

  // TODO for TravelAgency employee:
  // Access denied
  // Please contact the Owner of your company accounts to get access to your account.

  const title = isTravelAgent ? 'Access denied' : isOwner ? 'Support action needed' : 'Access denied'
  const infoText = isTravelAgent
    ? 'To activate your account, please contact us.'
    : isOwner
    ? 'Contact support to solve this problem'
    : 'Inform the owner and take full advantage of RateCleaner.'
  const image = isOwner ? supportImage : accessDeniedImage

  return (
    <>
      <UpgradeRequired info={infoText} title={title} image={image} isButton={isTravelAgent || isOwner} />
      {isOpenContactModal && <ContactUsModal close={() => setIsOpenContactModal(false)} />}
    </>
  )
}

export default UpgradeRequiredContainer
