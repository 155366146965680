import React, { useMemo } from 'react'
import { AttachMoney, Business, Close, Description, MeetingRoom, RestaurantMenu } from '@material-ui/icons'

import { HotelSummary } from 'types/domain/HotelSummary'
import { Tile } from './AccuracySummary'

type Props = {
  summary?: HotelSummary
  type: 'hotels' | 'hotel'
}

type UseAccuracySummary = {
  items: Array<Tile>
  enabled: boolean
}

const useAccuracySummary = (props: Props): UseAccuracySummary => {
  const { summary, type } = props

  const roundPercent = (value: number | null): number | null => (typeof value === 'number' ? Math.round(value) : null)

  const items = useMemo(
    () =>
      summary
        ? [
            {
              title: 'Rate<br />Description',
              icon: <Description style={{ fontSize: 16 }} />,
              percent: roundPercent(summary.rate_description),
            },
            {
              title: 'Room<br />Description',
              icon: <MeetingRoom style={{ fontSize: 16 }} />,
              percent: roundPercent(summary.room_description),
            },
            {
              title: 'Meal<br />Plans',
              icon: <RestaurantMenu style={{ fontSize: 16 }} />,
              percent: roundPercent(summary.meal_plans),
            },
            {
              title: 'Cancellation<br />Policy',
              icon: <Close style={{ fontSize: 16 }} />,
              percent: roundPercent(summary.cancellation_policy),
            },
            {
              title: 'Pricing<br />Description',
              icon: <AttachMoney style={{ fontSize: 16 }} />,
              percent: roundPercent(summary.pricing),
            },
            {
              title: `${type === 'hotels' ? 'Hotels' : 'Hotel'}<br />Accuracy`,
              icon: <Business style={{ fontSize: 16 }} />,
              percent: roundPercent(summary.all),
            },
          ]
        : [],
    [summary, type],
  )

  return {
    items,
    enabled: !!summary,
  }
}

export default useAccuracySummary
