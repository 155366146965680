import { useMutation, UseMutationResult } from 'react-query'

import api from 'api/index'
import { GdsCredentials } from 'types/domain/GdsCredentials'

import { ApiError } from '../types/ApiError'
import { ApiResponseWithMeta } from '../types/ApiResponseWithMeta'
import { GDS_CREDENTIALS_ENDPOINT_KEY } from './constants'

type Options = {
  onSuccess?: () => void
  onError?: (error: Error) => void
}

export const updateGdsCredentials = (
  id: string,
  data: GdsCredentials,
): Promise<ApiResponseWithMeta<GdsCredentials>> => api.patch(`/${GDS_CREDENTIALS_ENDPOINT_KEY}/${id}`, data)

export const useUpdateGdsCredentials = (options: Options = {}): UseMutationResult<
  ApiResponseWithMeta<GdsCredentials>,
  ApiError,
  { id: string, data: GdsCredentials}
> => useMutation(({ id, data }) => updateGdsCredentials(id, data), options)
