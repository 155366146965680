// TODO: refactor
import React from 'react'
import moment, { Moment } from 'moment'

import { getCxFromStyles } from 'helpers'
import { RateDetails} from 'types/domain/RateDetails'

import { Button, DatePicker, Select } from 'ui'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import styles from './RatesSettingsForm.module.scss'
import { ArrowRightAlt } from '@material-ui/icons'

interface Props {
  compare: () => void
  isLoading: boolean
  currentDate: Moment | null
  currentRate: RateDetails | null
  selectedDate?: Moment
  setSelectedDate: (date: Moment) => void
  rate?: RateDetails
  rates: RateDetails[]
  onRateChange: (value: string) => void
}

const RatesSettingsForm: React.FC<Props> = ({
  compare,
  currentDate,
  currentRate,
  isLoading,
  selectedDate,
  setSelectedDate,
  rate,
  rates,
  onRateChange,
}) => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx('root')}>
      <div className={cx('row')}>
        <span className={cx('title')}>Date:</span>
        <DatePicker value={currentDate} className={cx('selector')} disabled />
      </div>

      <div className={cx('row')}>
        <span className={cx('title')}>Booking code:</span>
        <Select value={currentRate?.rate_plan} className={cx('selector')} disabled />
      </div>

      <div className={cx('row', 'separator')}>vs</div>

      <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}>
        <div className={cx('row')}>
          <span className={cx('title')}>Date:</span>
          <DatePicker
            minDate={moment()}
            value={selectedDate}
            onChange={(date): void => setSelectedDate(date as Moment)}
            className={cx('selector')}
          />
        </div>

        <div className={cx('row')}>
          <span className={cx('title')}>Booking code:</span>
          <div className={cx({ error: !!selectedDate && !isLoading && !rate })}>
            <Select
              options={rates.map((item) => ({
                value: item.rate_plan,
                label: item.rate_plan,
              }))}
              placeholder="--"
              value={rate?.rate_plan}
              onChange={onRateChange}
              className={cx('selector')}
              disabled={!selectedDate}
              isError={!!selectedDate && !isLoading && !rate}
            />
            {!!selectedDate && !isLoading && !rate && (
              <span className={cx('errorMessage')}>Not available for selected date</span>
            )}
          </div>
        </div>
      </Spin>

      <Button onClick={compare} primary large disabled={!selectedDate || !rate}>
        <span>Compare Rate</span>
        <ArrowRightAlt fontSize="small" />
      </Button>
    </div>
  )
}

export default RatesSettingsForm
