import React, { ReactNode, useEffect, useState } from 'react'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { FormInstance } from 'antd/lib/form'
import { Form as AntdForm } from 'antd'
import { isEqual } from 'lodash'

interface Props {
  children: (props: { errors: { [key: string]: string }; form: FormInstance }) => ReactNode
  errors?: { [key: string]: string }
  onSubmit?: (values: { [key: string]: string }) => void
  initialValues?: { [key: string]: string }
  className?: string
  reinitialize?: boolean
}

const Form: React.FC<Props> = ({ children, errors, onSubmit, initialValues, className, reinitialize }) => {
  const [form] = AntdForm.useForm()

  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
  const [currentInitialValues, setCurrentInitialValues] = useState<typeof initialValues>(initialValues)

  useEffect(() => {
    if (!isEqual(currentInitialValues, initialValues)) {
      setCurrentInitialValues(initialValues)
      reinitialize && form.resetFields()
    }
  }, [reinitialize, initialValues, form]) // eslint-disable-line

  useEffect(() => {
    setFormErrors(errors || {})
  }, [errors])

  const applyErrors = (data: ValidateErrorEntity<{ [key: string]: string }>): void => {
    const { errorFields } = data
    setFormErrors(errorFields.reduce((acc, { name, errors }) => ({ ...acc, [name[0]]: errors[0] }), {}))
  }

  const removeErrors = (changedFields: { [key: string]: string }): void => {
    const field = Object.keys(changedFields)[0]
    setFormErrors((oldFormErrors) => ({
      ...oldFormErrors,
      [field]: '',
    }))
  }

  return (
    <AntdForm
      onFinish={onSubmit}
      onFinishFailed={applyErrors}
      form={form}
      layout="vertical"
      requiredMark={false}
      validateTrigger="onSubmit"
      initialValues={initialValues}
      onValuesChange={removeErrors}
      className={className}
    >
      {children({ form, errors: formErrors })}
    </AntdForm>
  )
}

export const FormItem = AntdForm.Item
export default Form
