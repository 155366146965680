import React, { useState } from 'react'

import { getCxFromStyles } from 'helpers'
import { Keyword } from 'modules/HotelsModule/types/Hotel'

import { ExpandMore } from '@material-ui/icons'

import styles from './Keywords.module.scss'
import { Typography } from 'antd'

interface Props {
  keywords?: Keyword[]
}

const Keywords: React.FC<Props> = ({ keywords }) => {
  const cx = getCxFromStyles(styles)

  const [openedKeywords, setOpenedKeywords] = useState<string[]>([])

  const toggleKeyword = (keyword: string): void => {
    setOpenedKeywords((oldValue) =>
      oldValue.includes(keyword) ? oldValue.filter((item) => item !== keyword) : [...oldValue, keyword],
    )
  }

  return (
    <div className={cx('root')}>
      <div className={cx('topBar')}>
        <Typography.Title level={2}>GDS property description</Typography.Title>
      </div>

      <div className={cx('keywords')}>
        {keywords?.map((keyword) => (
          <div
            key={keyword.keyword}
            className={cx('keyword', {
              opened: openedKeywords.includes(keyword.keyword),
              error: !keyword.value,
            })}
          >
            <div className={cx('name')} onClick={(): void => toggleKeyword(keyword.keyword)}>
              {keyword.description}
              <div className={cx('arrow')}>
                <ExpandMore style={{ fontSize: 16 }} />
              </div>
            </div>
            <div className={cx('value')}>{keyword.value}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Keywords
