import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { TrashIcon } from '@heroicons/react/16/solid'
import { ArrowLongRightIcon } from '@heroicons/react/24/outline'

import { Button, Select, Textarea } from 'ui-v2'

interface Props {
  onCancel: () => void
  onSubmit: (data: { topic: string; message: string }) => void
  isSending: boolean
}

const ContactUsForm: React.FC<Props> = ({ onCancel, onSubmit, isSending }) => {
  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      topic: 'general',
      message: '',
    },
    validationSchema: yup.object({
      topic: yup.string().required('Topic is required'),
      message: yup.string().required('Message is required'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  return (
    <div className="flex flex-col gap-4 mt-2">
      <div className="text-base text-gray-500">Send us a message – We are here to help.</div>

      <Select
        label="Topic"
        options={[
          { label: 'General', value: 'general' },
          { label: 'Payment', value: 'payment' },
        ]}
        value={values.topic}
        onChange={(value) => setFieldValue('topic', value)}
        error={errors.topic}
      />

      <Textarea
        value={values.message}
        label="Message"
        onChange={(value) => setFieldValue('message', value)}
        error={errors.message}
      />

      <div className="flex justify-end gap-2">
        <Button onClick={onCancel}>
          <span className="inline-flex items-center gap-2">
            Cancel <TrashIcon className="w-4" />
          </span>
        </Button>
        <Button variant="primary" isLoading={isSending} onClick={handleSubmit}>
          <span className="inline-flex items-center gap-2">
            Send <ArrowLongRightIcon className="w-5" />
          </span>
        </Button>
      </div>
    </div>
  )
}

export default ContactUsForm
