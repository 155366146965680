import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Layout } from 'antd'
import { format } from 'date-fns'

import { getCxFromStyles } from 'helpers'
import { MainLayoutContext } from './PreviewContext'
import { ContactUsModal } from '../components'

import styles from './Preview.module.scss'

const Preview: React.FC = ({ children }) => {
  const cx = getCxFromStyles(styles)
  const { userId } = useParams<{ userId: string | undefined }>()

  const [isContactModalOpen, setIsContactModalOpen] = useState(false)

  const linkPrefix = `/admin/users/${userId}`

  const layoutContext = {
    linkPrefix,
  }

  return (
    <Layout>
      <Layout className={cx('content')}>
        <Layout.Content>
          <MainLayoutContext.Provider value={layoutContext}>{children}</MainLayoutContext.Provider>
        </Layout.Content>
        <Layout.Footer className={cx('footer')}>
          <div className={cx('links')}>
            Data generated by Rate Cleaner. <a href="https://ratecleaner.com/">Learn more</a> about this application.
          </div>
          © {format(new Date(), 'yyyy')} Travel Minds sp. z o.o. All rights reserved.
        </Layout.Footer>
      </Layout>

      {isContactModalOpen && <ContactUsModal close={(): void => setIsContactModalOpen(false)} />}
    </Layout>
  )
}

export default Preview
