import React, { useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ArrowRightIcon, TrashIcon } from '@heroicons/react/16/solid'

import type { Error } from 'api'
import User from 'modules/UsersModule/types/User'
import { ACCOUNT_TYPE_KEYS, ACCOUNT_TYPE_LIST } from 'constants/accounts'
import { Button, Input } from 'ui-v2'

type Props = {
  saveUserError: Error | null
  onSubmit: (data: { [key: string]: string }) => void
  user?: User
  onCancel: () => void
  isEdited: boolean
  isSaving?: boolean
}

const About: React.FC<Props> = ({ saveUserError, onSubmit, user, onCancel, isEdited, isSaving }) => {
  const isNotTravelAgent = useMemo(
    () =>
      user?.role !==
      ACCOUNT_TYPE_LIST.find((accountType) => accountType.value === ACCOUNT_TYPE_KEYS.TRAVEL_AGENT)?.userRole,
    [user?.role],
  )

  const { values, setFieldValue, errors, setErrors, handleSubmit, resetForm } = useFormik({
    initialValues: {
      responsibility: user?.about?.responsibility || '',
      hotel: user?.about?.hotel || '',
      city: user?.about?.city || '',
      travel_agency: user?.about?.travel_agency || '',
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: yup.object({
      hotel: isNotTravelAgent ? yup.string().required('Hotel is required') : yup.string(),
      travel_agency: !isNotTravelAgent ? yup.string().required('Travel agency is required') : yup.string(),
    }),
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  useEffect(() => {
    !isEdited && resetForm()
  }, [isEdited])

  useEffect(() => {
    setErrors(saveUserError?.errors || {})
  }, [saveUserError?.errors])

  return (
    <div className="flex flex-col gap-2 md:gap-4">
      {isNotTravelAgent && (
        <Input
          value={values.responsibility}
          label="Responsibility"
          onChange={(value) => setFieldValue('responsibility', value)}
          isDisabled={!isEdited}
          error={errors.responsibility}
        />
      )}

      {isNotTravelAgent ? (
        <Input
          value={values.hotel}
          label="Hotel"
          onChange={(value) => setFieldValue('hotel', value)}
          isDisabled={!isEdited}
          error={errors.hotel}
        />
      ) : (
        <Input
          value={values.travel_agency}
          label="Agency name"
          onChange={(value) => setFieldValue('travel_agency', value)}
          isDisabled={!isEdited}
          error={errors.travel_agency}
        />
      )}

      <Input
        value={values.city}
        label="City"
        onChange={(value) => setFieldValue('city', value)}
        isDisabled={!isEdited}
        error={errors.city}
      />

      {isEdited && (
        <div className="flex justify-end gap-2">
          <Button
            size="large"
            onClick={(): void => {
              resetForm()
              onCancel()
            }}
          >
            <span className="inline-flex gap-2">
              Cancel <TrashIcon className="w-4" />
            </span>
          </Button>

          <Button size="large" variant="primary" isLoading={isSaving} onClick={() => handleSubmit()}>
            <span className="inline-flex gap-2">
              Submit <ArrowRightIcon className="w-4" />
            </span>
          </Button>
        </div>
      )}
    </div>
  )
}

export default About
