import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/solid'

import { RootState } from 'store'
import { ContactUsModal } from 'layouts/components'
import { UserStatus } from 'modules/UsersModule/types'
import { Button } from 'ui-v2'
import image from 'assets/activation.svg'

const AccountActivation: React.FC = () => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)
  const { profile } = useSelector((state: RootState) => state.auth)
  const history = useHistory()

  if (profile?.status === UserStatus.Active) history.push('/')

  return (
    <div className="flex flex-col items-center gap-12 mx-2 my-4 rounded-lg bg-white p-4 md:mx-7 md:pt-12 md:pb-24">
      <img src={image} alt="image" />

      <div className="text-center">
        <h1 className="text-3xl leading-9 font-semibold text-gray-800">Please wait for account activation</h1>
        <div className="text-base leading-none font-normal text-gray-500">
          We will reach out to you shortly to complete the process of activating your account.
          <br />
          No further action is required on your end at this time.
          <br />
          In case of any questions, contact our Support.
        </div>
      </div>

      <Button variant="primary" size="large" onClick={() => setIsContactModalOpen(true)}>
        <span className="inline-flex items-center gap-2">
          Contact us <ChatBubbleLeftRightIcon className="w-5" />
        </span>
      </Button>

      {isContactModalOpen && <ContactUsModal close={(): void => setIsContactModalOpen(false)} />}
    </div>
  )
}

export default AccountActivation
