import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'

import { HIGHLIGHTED_PHRASES_ENDPOINT_KEY } from './constants'
import { RequestHeadersWithGdsScope } from '../types/RequestHeadersWithGdsScope'

type GetHighlightedPhrasesResourceResponse = {
  data: string[]
}

export const getHighlightedPhrasesResource = ({
  headers,
}: {
  headers?: RequestHeadersWithGdsScope
}): Promise<GetHighlightedPhrasesResourceResponse> => api.get(HIGHLIGHTED_PHRASES_ENDPOINT_KEY, { headers })

export const useGetHighlightedPhrasesResource = ({
  headers,
  queryOptions,
  customQueryKey,
}: {
  headers?: RequestHeadersWithGdsScope
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getHighlightedPhrasesResource>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || [HIGHLIGHTED_PHRASES_ENDPOINT_KEY, ...Object.values(headers || {})],
    () => getHighlightedPhrasesResource({ headers }),
    queryOptions,
  )
