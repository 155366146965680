import React, { useState } from 'react'
import qs from 'qs'
import { useMutation, useQuery } from 'react-query'

import { ListUser, Invitation, User } from 'modules/UsersModule/types'
import api from 'api'
import type { Error, Meta } from 'api'
import { notification } from 'helpers'
import Employees from './Employees'

type Props = {
  user: User
  selectEmployee?: (employeeId: number, tab?: 'hotels' | 'employees' | 'user-role') => void
}

type ListMeta = Meta & { invitations?: Invitation[] }

const EmployeesContainer: React.FC<Props> = ({ selectEmployee, user }) => {
  const [invitations, setInvitations] = useState<Invitation[]>([])
  const [employees, setEmployees] = useState<ListUser[]>([])
  const [isAddModal, setIsAddModal] = useState(false)

  const fetchUsers = (userId: number): Promise<{ data: ListUser[]; meta: ListMeta }> =>
    api.get(
      `/users?${qs.stringify(
        {
          length: 999,
          sort_by: 'name',
          sort_direction: 'asc',
          user_id: userId,
        },
        { skipNulls: true },
      )}`,
    )
  const { isFetching, refetch: refreshList } = useQuery(['users', user.id], () => fetchUsers(user.id), {
    onSuccess: ({ data, meta }) => {
      setEmployees(data)
      setInvitations(meta.invitations || [])
    },
    onError: (error: Error) => {
      notification.error({ message: error.message })
    },
  })

  const { mutate: invite, isLoading: isInviting } = useMutation(
    async (data: { emails: string[]; invited_to?: number }): Promise<void> => api.post('/invitations', data),
    {
      onSuccess: () => {
        setIsAddModal(false)
        notification.success({ message: 'Email(s) has been sent' })
      },
      onError: (error: Error) => {
        notification.error({ message: error.message })
      },
      onSettled: () => refreshList(),
    },
  )

  const { mutate: removeInvitation } = useMutation(
    async (id: string): Promise<void> => api.delete(`/invitations/${id}`),
    {
      onError: (error: Error) => {
        notification.error({ message: error.message })
      },
      onSettled: () => refreshList(),
    },
  )

  return (
    <Employees
      selectEmployee={selectEmployee}
      employees={employees}
      invitations={invitations}
      isLoading={isFetching}
      user={user}
      invite={invite}
      isInviting={isInviting}
      isAddModal={isAddModal}
      setIsAddModal={setIsAddModal}
      removeInvitation={removeInvitation}
    />
  )
}

export default EmployeesContainer
