import React from 'react'
import { Star } from '@material-ui/icons'
import { Rate as AntdRate } from 'antd'

interface Props {
  value: number
  disabled?: boolean
}

const Rate: React.FC<Props> = ({ value, disabled }) => {
  return <AntdRate character={<Star />} allowHalf value={value} disabled={disabled} />
}

export default Rate
