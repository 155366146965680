import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Routes from 'routes/Routes'
import { RootState } from 'store'
import { fetchProfile } from 'modules/AuthModule/slices/auth'
import { LoadingOutlined } from '@ant-design/icons'

const App: React.FC = () => {
  const { isAuthenticated, profile, isDemoUser } = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isAuthenticated && !isDemoUser) {
      dispatch(fetchProfile())
    }
  }, [isAuthenticated, dispatch, isDemoUser])

  if (isAuthenticated && !(profile || isDemoUser)) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <LoadingOutlined style={{ fontSize: 40 }} spin />
      </div>
    )
  }

  return <Routes />
}

export default App
