import { useMutation, UseMutationOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'

type HotelEmail = {
  id: number
  hotel_id: number
  user_id: number
  email: string
  first_name?: string
  last_name?: string
}

type AddHotelAgentEmailsParams = {
  hotelId: number
}

type AddHotelAgentEmailsBody = {
  email: string
}

type AddHotelAgentEmailsResponse = {
  data: HotelEmail
}

export const addHotelAgentEmail = ({
  params,
  body,
}: {
  params: AddHotelAgentEmailsParams
  body: AddHotelAgentEmailsBody
}): Promise<AddHotelAgentEmailsResponse> => api.post(`/hotels/${params.hotelId}/agent-emails`, body)

export const useAddHotelAgentEmail = ({
  params,
  queryOptions,
}: {
  params: AddHotelAgentEmailsParams
  queryOptions?: UseMutationOptions<Awaited<ReturnType<typeof addHotelAgentEmail>>, ApiError, AddHotelAgentEmailsBody>
}) => useMutation((body) => addHotelAgentEmail({ params, body }), queryOptions)
