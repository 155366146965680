import React, { useCallback, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import { Button, Input } from 'ui-v2'
import { ApiError } from 'api/types/ApiError'

export interface UserSearchParams {
  name?: string
}

interface ParamsProps {
  onFiltersChange: (values: UserSearchParams) => void
  error: ApiError | null
  isLoading?: boolean
}

const Params = ({ error, onFiltersChange, isLoading }: ParamsProps) => {
  const [name, setName] = useState<string>('')

  const handleSearch = useCallback(() => {
    onFiltersChange({ name })
  }, [onFiltersChange, name])

  useEffect(() => {
    const listener = (e: { code: string }) => e.code === 'Enter' && handleSearch()
    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [handleSearch])

  return (
    <>
      <div className="p-4 bg-bg-accent-7 flex flex-col gap-3 md:px-7 lg:flex-row lg:items-start">
        <div className="lg:hidden">
          <div className="flex items-start justify-between gap-2 mb-2">
            <Input
              className="flex-grow"
              label="Hotel name"
              value={name}
              onChange={(value) => setName(value || '')}
              error={error?.errors?.name}
            />

            <Button
              isLoading={isLoading}
              className="w-10 rounded p-0 flex items-center justify-center"
              onClick={handleSearch}
              size="large"
              variant="primary"
            >
              <MagnifyingGlassIcon className="text-white w-5" />
            </Button>
          </div>
        </div>

        <Input
          className="hidden lg:block lg:flex-grow"
          label="Hotel name"
          value={name}
          onChange={(value) => setName(value || '')}
          error={error?.errors?.name}
        />

        <Button
          className={twMerge('lg:block lg:w-auto')}
          onClick={handleSearch}
          isLoading={isLoading}
          variant="primary"
          size="large"
        >
          Search
        </Button>
      </div>
    </>
  )
}

export default Params
