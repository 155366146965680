import React, { useCallback } from 'react'
import { FormikHelpers, useFormik } from 'formik'
import { Checkbox } from 'antd'
import * as yup from 'yup'
import moment from 'moment/moment'
import { ArrowRightIcon, TrashIcon } from '@heroicons/react/16/solid'

import { notification } from 'helpers'
import { Demo as TDemo } from 'types/domain/Demo'
import { useCreateDemo } from 'api/demoLinks/createDemo'
import { useUpdateDemo } from 'api/demoLinks/updateDemo'
import { getApiError } from 'helpers/error'
import { Button, Input } from 'ui-v2'
import { DatePicker, Select } from 'ui'
import { GDS_LIST } from 'constants/gds'

import styles from './Demo.module.scss'

interface Props {
  hotels: { id: number; name: string }[]
  onHotelSearch: (value: string) => void
  onCancel: () => void
  onSuccess: () => void
  initialData: Partial<TDemo> | null
  isHotelListLoading: boolean
}

const Demo: React.FC<Props> = ({ hotels, onHotelSearch, initialData, onCancel, onSuccess, isHotelListLoading }) => {
  const { mutate: create } = useCreateDemo()
  const { mutate: update } = useUpdateDemo()

  const submitOptions = (formikHelpers: FormikHelpers<TDemo>) => ({
    onSuccess: async () => {
      formikHelpers.setSubmitting(false)
      notification.success({ message: 'Success!' })
      onSuccess()
    },
    onError: (error: any) => {
      formikHelpers.setSubmitting(false)
      const apiErrors = getApiError(error)
      formikHelpers.setErrors(apiErrors)
    },
  })

  const onSubmit = useCallback(
    (data: TDemo, formikHelpers: FormikHelpers<TDemo>) => {
      formikHelpers.setSubmitting(true)

      if (initialData?.id) {
        update({ id: `${initialData.id}`, data }, submitOptions(formikHelpers))
      } else {
        create(data, submitOptions(formikHelpers))
      }
    },
    [create, initialData?.id, submitOptions, update],
  )

  const { values, errors, setFieldValue, handleSubmit, isSubmitting } = useFormik<TDemo>({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: {
      name: initialData?.name || '',
      hotel: { id: initialData?.hotel?.id || '', name: initialData?.hotel?.name || '' },
      deadline_at: initialData?.deadline_at || moment().add(1, 'month').format('YYYY-MM-DD'),
      queries: initialData?.queries || 500,
      gds_ids: initialData?.gds_ids.map((gdsId) => Number(gdsId)) || [],
    },
    validationSchema: yup.object({
      name: yup.string().required('Name is required'),
      hotel: yup.object({
        id: yup.number().required('Hotel is required'),
      }),
      deadline_at: yup.string().required('Date is required'),
      queries: yup.string().required('No of queries is required'),
      gds_ids: yup.array(yup.string()).min(1, 'Al least one GDS must be selected'),
    }),
    onSubmit,
  })

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const customHotelError = errors['hotels.0.id'] ? 'The selected hotel is invalid.' : ''

  return (
    <div className="bg-white p-4 rounded-md">
      <div className={styles.form}>
        <DatePicker
          value={moment(values.deadline_at || moment().add(1, 'month'), 'YYYY-MM-DD')}
          onChange={(value) => setFieldValue('deadline_at', value?.format('YYYY-MM-DD'))}
          isError={!!errors.deadline_at}
        />
        {!!errors.deadline_at && (
          <span className="block absolute mt-12 text-xs text-[#ff2e00]">{errors.deadline_at}</span>
        )}
        <Input
          label="Queries"
          value={values.queries as unknown as string}
          error={errors.queries}
          onChange={(value) => setFieldValue('queries', value)}
        />
        <Input
          label="name"
          value={values.name}
          error={errors.name}
          onChange={(value) => setFieldValue('name', value)}
        />
        <Select
          placeholder="Hotel name"
          options={hotels.map((h) => ({ value: `${h.id}`, label: h.name }))}
          onSearchChange={onHotelSearch}
          isLoading={isHotelListLoading}
          initialSearchValue={initialData?.hotel.name}
          value={values.hotel.name}
          isError={!!errors.hotel}
          error={errors?.hotel?.id || customHotelError}
          className="bg-white !border-none"
          onChange={(hotelId) =>
            setFieldValue(
              'hotel',
              hotels.find((hotel) => hotel.id === Number(hotelId)),
            )
          }
        />
        <div className="w-auto">
          {GDS_LIST.map((gds) => (
            <Checkbox
              key={gds.id}
              checked={values.gds_ids.includes(gds.id)}
              onChange={(event) =>
                setFieldValue('gds_ids', [
                  ...values.gds_ids.filter((item) => item !== gds.id),
                  ...(event.target.checked ? [gds.id] : []),
                ])
              }
            >
              {gds.label}
            </Checkbox>
          ))}
          <div className="text-[12px] height-[15px] text-[#ff2e00] mt-1">{errors?.gds_ids}</div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-2">
        <Button onClick={onCancel}>
          <span className="inline-flex items-center gap-2">
            Cancel <TrashIcon className="w-4" />
          </span>
        </Button>

        <Button isDisabled={isSubmitting} isLoading={isSubmitting} onClick={handleSubmit} variant="primary">
          <span className="flex">
            {initialData ? 'Update' : 'Save'}
            <ArrowRightIcon className="w-3 ml-3" />
          </span>
        </Button>
      </div>
    </div>
  )
}

export default Demo
