// TODO: refactor
import React, { useMemo, useState } from 'react'
import { Moment } from 'moment'

import { getCxFromStyles } from 'helpers'
import { RateDetails } from 'types/domain/RateDetails'

import { Button, Select } from 'ui'

import styles from './RatesList.module.scss'

interface SectionField {
  title: string
  icon: JSX.Element
  values1: Array<string | undefined>
  values2: Array<string | undefined>
  sublines: Array<
    | {
        title: string
        values1: Array<string | undefined>
        values2: Array<string | undefined>
      }
    | undefined
  >
}

interface Section {
  label: string
  fields: SectionField[]
}

interface Props {
  showSettings: () => void
  currentDate: Moment | null
  currentRate: RateDetails | null
  selectedDate?: Moment
  rate?: RateDetails
  sections: Section[]
}

const RatesList: React.FC<Props> = ({ showSettings, currentRate, currentDate, rate, selectedDate, sections }) => {
  const cx = getCxFromStyles(styles)
  const [category, setCategory] = useState(sections[0].label)

  const currentSectionFields = useMemo((): Section['fields'] | undefined => {
    const section = sections.find((item) => item.label === category)

    return section?.fields
  }, [category, sections])

  const renderValues = (field: SectionField): JSX.Element => {
    const renderValue = (
      values: Array<string | undefined>,
      sublines?: Array<
        | {
            title?: string
            values?: Array<string | undefined>
          }
        | undefined
      >,
    ): JSX.Element => (
      <div className={cx('value')}>
        <div className={cx('outlinedWrapper')}>
          {values.length > 0
            ? values.map((value, index) => (
                <div className={cx('outlinedText')} key={index}>
                  {value}
                </div>
              ))
            : !sublines?.length && <div className={cx('outlinedText', 'danger')}>Missing</div>}
        </div>

        {sublines?.map((subline, sublineIndex) => (
          <div key={sublineIndex} className={cx('subline')}>
            <div className={cx('title')}>{subline?.title}</div>
            <div className={cx('outlinedWrapper')}>
              {!!subline?.values && subline.values.length > 0 ? (
                subline?.values?.map((value, index) => (
                  <div className={cx('outlinedText')} key={index}>
                    {value}
                  </div>
                ))
              ) : (
                <div className={cx('outlinedText', 'danger')}>Missing</div>
              )}
            </div>
          </div>
        ))}
      </div>
    )

    return (
      <div className={cx('values')}>
        <div className={cx('code')}>{currentRate?.rate_plan}</div>
        {renderValue(
          field.values1,
          field.sublines.map((subline) => ({ title: subline?.title, values: subline?.values1 })),
        )}
        <div className={cx('code')}>{rate?.rate_plan}</div>
        {renderValue(
          field.values2,
          field.sublines.map((subline) => ({ title: subline?.title, values: subline?.values2 })),
        )}
      </div>
    )
  }

  return (
    <div className={cx('root')}>
      <div className={cx('params')}>
        <div className={cx('buttonWrapper')}>
          <Button onClick={showSettings}>Edit</Button>
        </div>

        <div className={cx('param')}>
          <span className={cx('title')}>Date:</span>
          {currentDate?.format('DD.MM.YYYY')}
        </div>

        <div className={cx('param')}>
          <span className={cx('title')}>Date:</span>
          {selectedDate?.format('DD.MM.YYYY')}
        </div>

        <div className={cx('param')}>
          <span className={cx('title')}>Booking code 1:</span>
          {currentRate?.rate_plan}
        </div>

        <div className={cx('param')}>
          <span className={cx('title')}>Booking code 2:</span>
          {rate?.rate_plan}
        </div>
      </div>

      <div className={cx('sectionSelector')}>
        <span className={cx('title')}>Category:</span>
        <Select
          options={sections.map((item) => ({
            value: item.label,
            label: item.label,
          }))}
          placeholder="Category"
          value={category}
          onChange={setCategory}
          className={cx('select')}
        />
      </div>

      <div className={cx('list')}>
        {currentSectionFields?.map((item) => (
          <div className={cx('item')} key={item.title}>
            <div className={cx('title')}>
              {item.icon}
              {item.title}
            </div>

            {renderValues(item)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default RatesList
