import { useMutation, UseMutationOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'

type UpdateHotelFromCandidateRequestBody = {
  hotel_id: number
  force?: boolean
}

type UpdateHotelFromCandidateRequestParams = {
  hotel_candidate: number
}

const updateHotelFromCandidate = ({
  params,
  body,
}: {
  params: UpdateHotelFromCandidateRequestParams
  body: UpdateHotelFromCandidateRequestBody
}): Promise<never> => api.patch(`/hotel-candidate/${params.hotel_candidate}`, body)

export const useUpdateHotelFromCandidate = ({
  params,
  queryOptions,
}: {
  params: UpdateHotelFromCandidateRequestParams
  queryOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof updateHotelFromCandidate>>,
    ApiError,
    UpdateHotelFromCandidateRequestBody
  >
}) =>
  useMutation((body: UpdateHotelFromCandidateRequestBody) => updateHotelFromCandidate({ params, body }), queryOptions)
