import React from 'react'
import { Tooltip as AntdTooltip } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip'

interface Props {
  text: string | JSX.Element
  placement?: TooltipPlacement
  delay?: number
  visible?: boolean
  trigger?: Array<string>
  color?: string
  overlayClassName?: string
}

const Tooltip: React.FC<Props> = ({
  children,
  text,
  placement = 'top',
  delay = 0.1,
  visible,
  trigger = ['hover'],
  color,
  overlayClassName,
}) => {
  return (
    <AntdTooltip
      title={text}
      placement={placement}
      mouseEnterDelay={delay}
      visible={visible}
      trigger={trigger}
      color={color || '#2A2E4E'}
      overlayClassName={overlayClassName}
      arrowPointAtCenter
    >
      {children}
    </AntdTooltip>
  )
}

export default Tooltip
