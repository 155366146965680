import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useMutation } from 'react-query'
import uniqBy from 'lodash/uniqBy'

import { useHotelsQuery } from 'hooks'
import { UserRoles } from 'modules/UsersModule/types'
import User, { Hotel } from 'modules/UsersModule/types/User'
import { RootState } from 'store'
import api from 'api'
import type { Error } from 'api'
import { notification } from 'helpers'

import Hotels from './Hotels'

type Props = {
  user: User
  setUser?: (user: User) => void
  setUserHotels?: (hotels: Hotel[]) => void
}

const HotelContainer: React.FC<Props> = ({ user, setUser, setUserHotels }) => {
  const { profile } = useSelector((state: RootState) => state.auth)
  const isPagination = user.role === UserRoles.Owner || !!profile?.is_admin
  const isEditable = user.id !== profile?.id

  const userId = isEditable && !profile?.is_admin ? user.user_id || undefined : undefined

  const {
    data: hotels,
    meta,
    setPage,
    isFetching,
    setFilters,
  } = useHotelsQuery(
    {
      length: isPagination ? 50 : 999,
      user_id: userId,
    },
    { enabled: isEditable },
  )

  useEffect(() => {
    !!userId && setFilters({ user_id: userId })
  }, [userId]) // eslint-disable-line

  const { mutate: updateUser, isLoading: isUserUpdating } = useMutation(
    async (data: { attach?: number[]; detach?: number[] }) =>
      api.patch(`/users/${user?.id}`, { hotels: data, role: user.role }),
    {
      onSuccess: ({ data }) => {
        setUser && setUser(data)
      },
      onError: (error: Error) => {
        notification.error({ message: error.message })
      },
    },
  )

  const updateUserWithoutSave = (data: { attach?: number[]; detach?: number[] }): void => {
    const newHotels = uniqBy(
      [
        ...user.hotels.filter((item) => !(data.detach || []).includes(item.id)),
        ...hotels
          .filter((item) => (data.attach || []).includes(item.id))
          .map((item) => ({
            id: item.id,
            name: item.name,
            city: item.city,
            country: item.country,
            gds_properties: item.gds_properties,
          })),
      ],
      'id',
    )
    setUserHotels && setUserHotels(newHotels)
  }

  return (
    <Hotels
      hotels={hotels}
      userHotels={user.hotels}
      isEditable={isEditable}
      isPagination={isPagination}
      meta={meta}
      setPage={setPage}
      isLoading={isFetching || isUserUpdating}
      setFilters={setFilters}
      updateUser={setUserHotels ? updateUserWithoutSave : updateUser}
    />
  )
}

export default HotelContainer
