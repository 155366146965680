import { twMerge } from 'tailwind-merge'

export type InputProps = {
  value: string
  className?: string
  onChange?: (value?: string) => void
  isDisabled?: boolean
  readOnly?: boolean
  label: string
  error?: string
  htmlType?: 'text' | 'password'
  inputRef?: any
}

export const Input = ({
  className,
  value,
  onChange,
  isDisabled,
  label,
  readOnly,
  error,
  htmlType = 'text',
  inputRef,
}: InputProps) => {
  return (
    <div
      className={twMerge(
        'relative bg-white rounded',
        className,
        isDisabled && 'border-gray-100',
        error && 'border-validation-error-outline mb-4',
      )}
    >
      <div
        className={twMerge(
          'absolute top-0.5 leading-none font-normal text-gray-500 text-xs left-2',
          isDisabled && 'text-gray-300',
          error && 'text-validation-error',
        )}
      >
        {label}:
      </div>

      <input
        className={twMerge(
          'h-10 pt-3 pb-0 px-2 w-full leading-none font-normal text-base text-gray-800 outline-none border-none rounded focus:ring-0',
          isDisabled && 'text-gray-500',
        )}
        type={htmlType}
        placeholder="-"
        value={value || ''}
        onChange={(event) => onChange?.(event.target.value)}
        readOnly={readOnly}
        disabled={isDisabled}
        ref={inputRef}
      />

      {error && (
        <span className="text-validation-error absolute -bottom-4 left-0 text-xs whitespace-nowrap overflow-ellipsis w-full overflow-hidden">
          {error}
        </span>
      )}
    </div>
  )
}
