import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { ArrowRightAlt, Close } from '@material-ui/icons'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useHistory, useLocation } from 'react-router-dom'

import { DatePicker } from 'ui'
import { Button, Header, Input, Checkbox } from 'ui-v2'
import { Hotels } from './components'
import { HotelListItem } from 'types/domain/HotelListItem'
import { useInviteUser } from 'api/users/inviteUser'
import { notification } from 'helpers'
import { GDS_LIST } from 'constants/gds'
import { UserRoles } from 'modules/UsersModule/types'

import styles from './InviteUserContainer.module.scss'

const InviteUserContainer: React.FC = () => {
  const history = useHistory()
  const location = useLocation<{ role: UserRoles.Owner | UserRoles.TravelAgent }>()
  const accountType = location.state.role
  const [assignedHotels, setAssignedHotels] = useState<HotelListItem[]>([])

  const { isLoading, mutate: inviteUser } = useInviteUser({
    onSuccess: () => {
      notification.success({ message: 'Invitation sent' })
      history.push('/admin/users?tab=trial_invitations')
    },
    onError: (error) => {
      notification.error({ message: error.message })
      setErrors(error?.errors || {})
    },
  })

  const { values, errors, setFieldValue, handleSubmit, setErrors } = useFormik<{
    email: string
    first_name: string
    last_name: string
    gds_ids: number[]
    trial_ends_at: string
    hotel_ids: number[]
    account_type: [UserRoles.Owner, UserRoles.TravelAgent]
  }>({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      gds_ids: [],
      trial_ends_at: moment().add(30, 'days').format('YYYY-MM-DD'),
      hotel_ids: [],
      account_type: accountType,
    },
    validationSchema: yup.object({
      email: yup.string().email('Incorrect e-mail').required('E-mail is required'),
      gds_ids: yup.array(yup.string()).min(1, 'Al least one GDS must be selected'),
      trial_ends_at: yup.string().required('Date is required'),
      hotel_ids:
        accountType === UserRoles.Owner
          ? yup.array(yup.number()).min(1, 'Al least one hotel must be assigned')
          : yup.array(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      inviteUser(values)
    },
  })

  useEffect(() => {
    setFieldValue(
      'hotel_ids',
      assignedHotels.map((hotel) => hotel.id),
    )
  }, [assignedHotels])

  return (
    <>
      <div className="px-[30px] py-6 flex justify-between mb-8 border-b border-indigo-100 bg-white">
        <Header size="big" title="Invite user" />
        <div className={styles.close} onClick={() => history.push('/admin/users?tab=trial_invitations')}>
          <Close />
        </div>
      </div>

      <div className="px-[30px] py-4">
        <div className="flex flex-col w-full bg-white p-4 rounded-md gap-4">
          <div className="flex flex-row gap-4">
            <div className="w-1/2 p-6 bg-slate-50 rounded-lg gap-4">
              <div className="text-gray-800 text-lg font-semibold mb-4">Profile</div>

              <div className="flex flex-col gap-4">
                <Input
                  label="E-mail"
                  value={values.email}
                  error={errors.email}
                  onChange={(value) => setFieldValue('email', value)}
                />
                <Input
                  label="First name"
                  value={values.first_name}
                  error={errors.first_name}
                  onChange={(value) => setFieldValue('first_name', value)}
                />
                <Input
                  label="Last name"
                  value={values.last_name}
                  error={errors.last_name}
                  onChange={(value) => setFieldValue('last_name', value)}
                />
              </div>
            </div>

            <div className="flex flex-col w-1/2 gap-4">
              <div className="p-6 bg-slate-50 rounded-lg gap-4">
                <div className="text-gray-800 text-lg font-semibold mb-4">GDS</div>

                <div className="flex gap-[20px]">
                  {GDS_LIST.map((gds) => (
                    <div className="flex items-center">
                      <Checkbox
                        key={gds.id}
                        isChecked={values.gds_ids.includes(gds.id)}
                        onChange={(value) =>
                          setFieldValue('gds_ids', [
                            ...values.gds_ids.filter((item) => item !== gds.id),
                            ...(value ? [gds.id] : []),
                          ])
                        }
                      />
                      <span className="ml-[22px] text-gray-700 text-sm font-medium leading-tight">{gds.label}</span>
                    </div>
                  ))}
                </div>

                {errors.gds_ids && <div className="mt-4 text-[13px] h-[15px] text-red-800">{errors.gds_ids}</div>}
              </div>

              <div className="p-6 bg-slate-50 rounded-lg gap-4">
                <div className="text-gray-800 text-lg font-semibold mb-4">Trial</div>

                <DatePicker
                  className="w-full"
                  placeholder="Trial date"
                  value={moment(values.trial_ends_at, 'YYYY-MM-DD')}
                  onChange={(value) => setFieldValue('trial_ends_at', value?.format('YYYY-MM-DD'))}
                />
              </div>
            </div>
          </div>

          {accountType === UserRoles.Owner && (
            <div>
              {errors.hotel_ids && (
                <div className="mt-4 mb-2 text-[13px] h-[15px] text-red-800">{errors.hotel_ids}</div>
              )}
              <Hotels assignedHotels={assignedHotels} setAssignedHotels={setAssignedHotels} />
            </div>
          )}

          <div className="text-right my-6 mx-4">
            <Button size="large" className="w-[115px]" onClick={() => history.push('/admin/users')}>
              <span>Cancel</span>
              <Close fontSize="small" />
            </Button>

            <Button
              variant="primary"
              size="large"
              loading={isLoading}
              className="w-[150px] ml-6"
              onClick={handleSubmit}
            >
              <span>Save & Send</span>
              <ArrowRightAlt fontSize="small" />
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default InviteUserContainer
