import React from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { ChevronLeft } from '@material-ui/icons'

import logoSmall from 'assets/logo-small.svg'
import logo from 'assets/logo.svg'

interface Props {
  isExpanded: boolean
  toggleExpand: () => void
}

const SidebarWrapper: React.FC<Props> = ({ children, isExpanded, toggleExpand }) => {
  return (
    <div className={twMerge('flex w-full items-center md:h-full md:flex-col', isExpanded && 'md:w-[204px]')}>
      <div className="hidden w-full pb-3 mb-3 border-b border-gray-600 md:block">
        <Link className="flex items-center w-full gap-2.5" to={'/'}>
          <img className="h-8" src={isExpanded ? logo : logoSmall} alt="logo" />
        </Link>
        <button
          onClick={toggleExpand}
          className={twMerge(
            'fixed left-10 rotate-180 z-20 top-[50px] h-5 w-5 p-0 m-0 flex items-center justify-center rounded border border-gray-600 bg-dark-blue text-gray-600',
            isExpanded && 'left-[210px] rotate-0',
          )}
        >
          <ChevronLeft className="!w-4 !h-4 text-white" />
        </button>
      </div>

      <div className="flex items-center justify-between flex-1 md:flex-col md:h-full md:w-full md:gap-4">
        {children}
      </div>
    </div>
  )
}

export default SidebarWrapper
