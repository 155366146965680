import React from 'react'
import classNames from 'classnames'
import styles from './AlertBar.module.scss'

export type AlertBarProps = {
  color: 'warning' | 'error' | 'success'
  size?: 'small' | 'normal'
  classes?: {
    root?: string
  }
}

const AlertBar: React.FC<AlertBarProps> = ({ classes, color, children, size = 'small' }) => (
  <div className={classNames(styles.root, styles[size], styles[color], classes?.root)}>{children}</div>
)

export default AlertBar
