import React from 'react'

import { getCxFromStyles } from 'helpers'
import { ListMeta } from 'hooks/useMeasurementsQuery'

import styles from './Tiles.module.scss'

interface Props {
  data?: ListMeta['tiles']
  isOwnerList: boolean
  isTravelAgency: boolean
}

const Tiles: React.FC<Props> = ({ data, isOwnerList, isTravelAgency }) => {
  const cx = getCxFromStyles(styles)

  const tiles = isTravelAgency
    ? [
        {
          counter: data?.hotel_searches,
          description: 'Searches',
        },
        {
          counter: data?.rd_queries,
          description: 'RD Queries',
        },
        {
          counter: data?.rr_queries,
          description: 'RR Queries',
        },
        {
          counter: data?.copied_links,
          description: 'Copied Links',
        },
        {
          counter: data?.issues,
          description: 'Issues',
        },
      ]
    : [
        {
          counter: data?.hotel_searches,
          description: 'Searches',
        },
        {
          counter: data?.gds_queries,
          description: 'GDS Queries',
        },
        ...(isOwnerList
          ? [
              {
                counter: data?.gds_queries_per_hotel,
                description: 'GDS Queries / Hotel',
              },
            ]
          : [
              {
                counter: data?.gds_queries_per_user,
                description: 'GDS Queries / User',
              },
            ]),
        {
          counter: data?.rd_queries,
          description: 'RD Queries',
        },
        {
          counter: data?.rr_queries,
          description: 'RR Queries',
        },
      ]

  return (
    <div className={cx('root', { fourItems: !isOwnerList && !isTravelAgency })}>
      {tiles.map((tile) => (
        <div key={tile.description} className={cx('tile', {})}>
          <span className={cx('counter')}>{tile.counter}</span>
          <span className={cx('description')}>{tile.description}</span>
        </div>
      ))}
    </div>
  )
}

export default Tiles
