import React from 'react'
import classNames from 'classnames'

import styles from './Header.module.scss'

type Props = {
  withoutLine?: boolean,
  classes?: {
    root?: string
    header?: string
    line?: string
  }
}

const Header: React.FC<Props> = ({ children, classes, withoutLine }) => (
  <div className={classNames(styles.root, classes?.root)}>
    <h1 className={classNames(styles.header, classes?.header)}>{children}</h1>
    {!withoutLine && <div className={classNames(styles.line, classes?.line)} />}
  </div>
)

export default Header
