import React from 'react'
import {
  Accessible,
  AcUnit,
  Business,
  Casino,
  CheckCircle,
  ChildCare,
  ChildFriendly,
  DirectionsBus,
  FitnessCenter,
  GolfCourse,
  LocalBar,
  LocalLaundryService,
  LocalParking,
  MeetingRoom,
  NaturePeople,
  Pets,
  Pool,
  RestaurantMenu,
  RoomService,
  RvHookup,
  Search,
  SmokeFree,
  Spa,
  Wifi,
} from '@material-ui/icons'
import hairdresserIcon from 'assets/hairdresser.svg'

const iconColor = '#6B7280'

export default [
  { id: 1, name: '24-hour front desk' },
  { id: 5, name: 'Air conditioning', icon: <AcUnit htmlColor={iconColor} /> },
  { id: 6, name: 'Airline desk' },
  { id: 8, name: 'Baby sitting', icon: <ChildFriendly htmlColor={iconColor} /> },
  { id: 14, name: 'Business library' },
  { id: 15, name: 'Car rental desk', icon: <DirectionsBus htmlColor={iconColor} /> },
  { id: 16, name: 'Casino', icon: <Casino htmlColor={iconColor} /> },
  { id: 20, name: 'Coffee shop' },
  { id: 22, name: 'Concierge desk' },
  { id: 23, name: 'Concierge floor' },
  { id: 26, name: 'Currency exchange' },
  { id: 33, name: 'Elevators' },
  { id: 34, name: 'Executive floor' },
  { id: 38, name: 'Family plan' },
  { id: 41, name: 'Free airport shuttle', icon: <RvHookup htmlColor={iconColor} /> },
  { id: 42, name: 'Free parking' },
  { id: 43, name: 'Free transportation' },
  { id: 44, name: 'Game room' },
  { id: 45, name: 'Gift/News stand' },
  { id: 46, name: 'Hairdresser/barber', icon: <img src={hairdresserIcon} alt="ico" /> },
  { id: 47, name: 'Accessible facilities' },
  { id: 48, name: 'Health club' },
  { id: 53, name: 'Indoor parking' },
  { id: 54, name: 'Indoor pool' },
  { id: 55, name: 'Jacuzzi' },
  { id: 56, name: 'Jogging track' },
  { id: 57, name: 'Kennels' },
  { id: 58, name: 'Laundry/Valet service' },
  { id: 60, name: 'Live entertainment' },
  { id: 61, name: 'Massage services' },
  { id: 65, name: 'Outdoor parking' },
  { id: 66, name: 'Outdoor pool', icon: <Pool htmlColor={iconColor} /> },
  { id: 68, name: 'Parking', icon: <LocalParking htmlColor={iconColor} /> },
  { id: 69, name: 'Photocopy center' },
  { id: 71, name: 'Pool', icon: <Pool /> },
  { id: 72, name: 'Poolside snack bar' },
  { id: 76, name: 'Restaurant', icon: <RestaurantMenu htmlColor={iconColor} /> },
  { id: 77, name: 'Room Service' },
  { id: 78, name: 'Safe deposit box' },
  { id: 79, name: 'Sauna' },
  { id: 81, name: 'Shoe shine stand' },
  { id: 82, name: 'Shopping mall' },
  { id: 83, name: 'Solarium' },
  { id: 84, name: 'Spa', icon: <Spa /> },
  { id: 86, name: 'Steam bath' },
  { id: 91, name: 'Tour/sightseeing desk' },
  { id: 96, name: 'Dry cleaning', icon: <LocalLaundryService htmlColor={iconColor} /> },
  { id: 97, name: 'Valet parking' },
  { id: 101, name: 'Wheelchair access', icon: <Accessible htmlColor={iconColor} /> },
  { id: 103, name: 'Multilingual staff' },
  { id: 104, name: 'Wedding services' },
  { id: 105, name: 'Banquet facilities' },
  { id: 106, name: 'Bell staff/porter' },
  { id: 107, name: 'Beauty shop/salon' },
  { id: 113, name: '120 AC' },
  { id: 114, name: '120 DC' },
  { id: 115, name: '220 AC' },
  { id: 117, name: '220DC' },
  { id: 118, name: 'Barbeque grills' },
  { id: 154, name: 'Medical Facilities Service' },
  { id: 157, name: 'Buffet breakfast' },
  { id: 159, name: 'Continental breakfast' },
  { id: 161, name: 'Full american breakfast' },
  { id: 162, name: 'Meal plan available' },
  { id: 165, name: 'Lounges/bars', icon: <LocalBar htmlColor={iconColor} /> },
  { id: 174, name: 'Lunch served in restaurant' },
  { id: 175, name: 'Dinner served in restaurant' },
  { id: 193, name: "Children's play area" },
  { id: 195, name: 'Disco' },
  { id: 199, name: 'Train access' },
  { id: 202, name: 'Bicycle rentals' },
  { id: 223, name: 'Internet services', icon: <Wifi htmlColor={iconColor} /> },
  { id: 224, name: 'Pets allowed', icon: <Pets /> },
  { id: 227, name: 'Complimentary breakfast' },
  { id: 228, name: 'Business center', icon: <Business htmlColor={iconColor} /> },
  { id: 229, name: 'Business services', icon: <RoomService htmlColor={iconColor} /> },
  { id: 230, name: 'Secured parking' },
  { id: 231, name: 'Racquetball' },
  { id: 233, name: 'Tennis court', icon: <Search htmlColor={iconColor} /> },
  { id: 236, name: 'Golf', icon: <GolfCourse htmlColor={iconColor} /> },
  { id: 237, name: 'Horseback riding' },
  { id: 238, name: 'Oceanfront' },
  { id: 239, name: 'Beachfront' },
  { id: 240, name: 'Hair dryer' },
  { id: 241, name: 'Ironing board' },
  { id: 255, name: 'Data port' },
  { id: 256, name: 'Exterior corridors' },
  { id: 257, name: 'Gulf view' },
  { id: 259, name: 'High speed internet access' },
  { id: 260, name: 'Interior corridors' },
  { id: 261, name: 'High speed wireless' },
  { id: 264, name: 'Fire safety compliant' },
  { id: 268, name: 'All public areas non-smoking', icon: <SmokeFree htmlColor={iconColor} /> },
  { id: 269, name: 'Meeting rooms', icon: <MeetingRoom htmlColor={iconColor} /> },
  { id: 270, name: 'Movies in room' },
  { id: 271, name: 'Secretarial service' },
  { id: 272, name: 'Snow skiing' },
  { id: 273, name: 'Water skiing' },
  { id: 274, name: 'Fax service' },
  { id: 282, name: 'Airport shuttle service' },
  { id: 283, name: 'Luggage service' },
  { id: 289, name: 'Children programs' },
  { id: 292, name: 'Newspaper' },
  { id: 327, name: 'Events ticket service' },
  { id: 345, name: 'Fitness center', icon: <FitnessCenter htmlColor={iconColor} /> },
  { id: 900, name: 'HIGH SPEED WIRELESS FEE' },
  { id: 901, name: 'HIGH SPEED INT ACC FEE' },
  { id: 902, name: 'MOUNTAIN VIEW' },
  { id: 903, name: 'OCEAN VIEW' },
  { id: 904, name: 'WHEELCHAIR ACCESS ELEV', icon: <CheckCircle htmlColor={iconColor} /> },
  { id: 905, name: 'Air Conditioning - adjustable' },
  { id: 906, name: 'Hammam(Turkish bath)' },
  { id: 907, name: 'Bar in the Middle Pool' },
  { id: 908, name: 'Parking Fee Required' },
  { id: 909, name: 'Fitness Center Nearby' },
  { id: 910, name: 'Wi/Fi Wireless LAN', icon: <Wifi htmlColor={iconColor} /> },
  { id: 911, name: 'Allergy-Free Room Available' },
  { id: 912, name: 'Family Rooms', icon: <NaturePeople htmlColor={iconColor} /> },
  { id: 913, name: 'Children Stay Free', icon: <ChildCare htmlColor={iconColor} /> },
  { id: 914, name: 'Skiing' },
  { id: 915, name: 'Soundproofed Rooms' },
  { id: 916, name: 'Chapel/Shrine nearby' },
  { id: 917, name: 'Heating' },
  { id: 918, name: 'Design/Boutique Hotel' },
  { id: 919, name: 'Express Check in/Check out' },
  { id: 920, name: 'Garden' },
  { id: 921, name: 'Gay Friendly' },
  { id: 922, name: 'Ski Storage' },
  { id: 923, name: 'Billiards' },
  { id: 924, name: 'Bowling' },
  { id: 925, name: 'Canoeing' },
  { id: 926, name: 'Mini Golf' },
  { id: 927, name: 'Karaoke' },
  { id: 928, name: 'Squash Courts' },
  { id: 929, name: 'Table Tennis' },
  { id: 930, name: 'Golf Course (within 3 km)' },
  { id: 931, name: 'Diving' },
  { id: 932, name: 'Fishing' },
  { id: 933, name: 'Snorkelling' },
  { id: 934, name: 'Ski School' },
  { id: 935, name: 'Windsurfing' },
  { id: 936, name: 'Walking' },
  { id: 937, name: 'Cycling' },
  { id: 938, name: 'Darts' },
  { id: 939, name: 'Biking' },
  { id: 940, name: 'Very Important Person (VIP) Room Facilities' },
  { id: 941, name: 'Room service Breakfast' },
  { id: 942, name: 'Shops in Hotel' },
  { id: 943, name: 'Lunch to go/packed lunch' },
  { id: 944, name: 'Bridal Suite' },
  { id: 945, name: 'Old Town' },
  { id: 946, name: 'University' },
  { id: 947, name: 'Forest' },
  { id: 948, name: 'City Centre' },
  { id: 949, name: 'Mountains' },
  { id: 950, name: 'Museum Area' },
  { id: 951, name: 'River' },
  { id: 952, name: 'Park Recreation' },
  { id: 953, name: 'Beach' },
  { id: 954, name: 'Panoramic View' },
  { id: 955, name: 'Near Highway' },
  { id: 956, name: 'Near Airport' },
  { id: 957, name: 'Countryside' },
  { id: 958, name: 'Lake' },
  { id: 959, name: 'Subway near' },
  { id: 960, name: 'Near Exhibition Centre' },
  { id: 961, name: 'Harbour' },
  { id: 962, name: 'Fitnesscenters in der Nahe (Kostenlos)' },
  { id: 963, name: 'Safety Deposit Box' },
  { id: 964, name: 'Valet Parking' },
  { id: 967, name: 'Train Station Nearby' },
  { id: 968, name: 'Covered Parking' },
  { id: 969, name: 'Airport Shuttle Surcharge' },
]
