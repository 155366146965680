import React from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowRightIcon } from '@heroicons/react/16/solid'

import { Button } from 'ui-v2'
import image from 'assets/404.svg'

const Error404: React.FC = () => {
  const history = useHistory()

  return (
    <div className="flex flex-col items-center gap-12 mx-2 my-4 rounded-lg bg-white p-4 md:mx-7 md:pt-12 md:pb-24">
      <img src={image} alt="image" />

      <div className="text-center">
        <h1 className="text-3xl leading-9 font-semibold text-gray-800">Sorry, page not found.</h1>
        <div className="text-base leading-none font-normal text-gray-500">Go back to the home page.</div>
      </div>

      <Button variant="primary" size="large" onClick={() => history.push('/')}>
        <span className="inline-flex items-center gap-2">
          Home page <ArrowRightIcon className="w-3" />
        </span>
      </Button>
    </div>
  )
}

export default Error404
