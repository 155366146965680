import { QueryKey, useMutation, UseMutationOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'

import { SUBSCRIPTION_ENDPOINT_KEY } from './constants'

type CancelSubscriptionUserRequestParams = {
  user_id: number
}

export const cancelSubscriptionUser = ({ params }: { params: CancelSubscriptionUserRequestParams }): Promise<null> =>
  api.post(`/subscription/${params.user_id}/cancel`)

export const useCancelSubscriptionUser = ({
  params,
  queryOptions,
  customQueryKey,
}: {
  params: CancelSubscriptionUserRequestParams
  queryOptions?: UseMutationOptions<Awaited<ReturnType<typeof cancelSubscriptionUser>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useMutation(
    customQueryKey || [SUBSCRIPTION_ENDPOINT_KEY, ...Object.values(params)],
    () => cancelSubscriptionUser({ params }),
    queryOptions,
  )
