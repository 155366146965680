import { QueryKey, useMutation, UseMutationOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { ReportedPreview as Preview } from 'types/domain/ReportedPreview'

export type AddPreviewRequestBody = {
  checkup_rate_id: number
}

type AddPreviewResponse = {
  data: Preview
}

export const addPreview = (body: AddPreviewRequestBody): Promise<AddPreviewResponse> => api.post(`/previews`, body)

export const useAddPreview = ({
  queryOptions,
  customQueryKey,
}: {
  queryOptions?: UseMutationOptions<Awaited<ReturnType<typeof addPreview>>, ApiError, AddPreviewRequestBody>
  customQueryKey?: QueryKey
}) => useMutation(customQueryKey || 'preview', (body) => addPreview(body), queryOptions)
