import React, { Fragment, useMemo, useState } from 'react'
import moment from 'moment'
import { capitalize } from 'lodash'
import { PlusIcon } from '@heroicons/react/16/solid'
import { twMerge } from 'tailwind-merge'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { Menu, Transition } from '@headlessui/react'

import { Invitation, ListUser, User, UserRoles, UserStatus } from 'modules/UsersModule/types'
import { Button, Input, Table } from 'ui-v2'
import { AddModal } from './components'

type Props = {
  selectEmployee?: (employeeId: number, tab?: 'hotels' | 'employees' | 'user-role') => void
  employees: ListUser[]
  invitations: Invitation[]
  invite: (data: { emails: string[]; invited_to?: number }) => void
  removeInvitation: (id: string) => void
  isInviting: boolean
  isLoading: boolean
  user: User
  isAddModal: boolean
  setIsAddModal: (visible: boolean) => void
}

type Employee = {
  id: number | string
  name: string
  status?: UserStatus
  hotels: { id: number; name: string }[]
  role?: UserRoles
  created_at: string
  isInvitation?: boolean
  invited_to?: number
}

const Employees: React.FC<Props> = ({
  selectEmployee,
  employees,
  invitations,
  isLoading,
  user,
  invite,
  removeInvitation,
  isInviting,
  isAddModal,
  setIsAddModal,
}) => {
  const [userName, setUserName] = useState('')
  const [filters, setFilters] = useState<{ [key: string]: string }>({})

  const list: Employee[] = useMemo(
    () => [
      ...employees,
      ...invitations.map((invitation) => ({
        id: invitation.id,
        name: invitation.email,
        hotels: [],
        created_at: invitation.created_at,
        isInvitation: true,
        invited_to: invitation.invited_to,
      })),
    ],
    [employees, invitations],
  )

  const dataSource = useMemo(
    () =>
      list.filter((item) => {
        const filterEntries = Object.entries(filters)

        return (
          filterEntries.length === 0 ||
          filterEntries.filter(([field, value]) => {
            return new RegExp(value, 'i').test(
              typeof item[field as keyof Employee] === 'string' ? (item[field as keyof Employee] as string) : '',
            )
          }).length > 0
        )
      }),
    [list, filters],
  )

  const managers = useMemo(
    () => [
      ...(user.role === UserRoles.Owner ? [{ id: user.id, name: `${user.first_name} ${user.last_name} (owner)` }] : []),
      ...employees.filter((employee) => employee.role === UserRoles.Manager).map(({ id, name }) => ({ id, name })),
    ],
    [employees, user],
  )

  const renderActionsButton = (record: Employee) => (
    <Menu as="div" className="relative inline-block">
      <>
        <Menu.Button className="p-0 h-auto border-none bg-transparent">
          <Button
            variant="secondary"
            className="border-none w-[34px] p-0 rounded-full flex items-center justify-center gap-1"
          >
            <EllipsisVerticalIcon className="w-5" />
          </Button>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-1 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="p-3 flex flex-col text-gray-700">
              {record.isInvitation ? (
                <>
                  <Menu.Item>
                    <a
                      onClick={() => invite({ emails: [record.name], invited_to: record.invited_to })}
                      className={twMerge(
                        'inline-flex items-center gap-2 px-5 py-4 text-base leading-none font-normal text-blue-800 hover:text-blue-800 hover:bg-blue-50 whitespace-nowrap',
                      )}
                    >
                      Resend
                    </a>
                  </Menu.Item>

                  <Menu.Item>
                    <a
                      onClick={() => typeof record.id === 'string' && removeInvitation(record.id)}
                      className={twMerge(
                        'inline-flex items-center gap-2 px-5 py-4 text-base leading-none font-normal text-blue-800 hover:text-blue-800 hover:bg-blue-50 whitespace-nowrap',
                      )}
                    >
                      Delete invitation
                    </a>
                  </Menu.Item>
                </>
              ) : (
                <>
                  <Menu.Item>
                    <a
                      onClick={() => typeof record.id === 'number' && !!selectEmployee && selectEmployee(record.id)}
                      className={twMerge(
                        'inline-flex items-center gap-2 px-5 py-4 text-base leading-none font-normal text-blue-800 hover:text-blue-800 hover:bg-blue-50 whitespace-nowrap',
                      )}
                    >
                      Manage
                    </a>
                  </Menu.Item>

                  {record.role === UserRoles.Manager && (
                    <Menu.Item>
                      <a
                        onClick={() =>
                          typeof record.id === 'number' && !!selectEmployee && selectEmployee(record.id, 'employees')
                        }
                        className={twMerge(
                          'inline-flex items-center gap-2 px-5 py-4 text-base leading-none font-normal text-blue-800 hover:text-blue-800 hover:bg-blue-50 whitespace-nowrap',
                        )}
                      >
                        Show employees
                      </a>
                    </Menu.Item>
                  )}

                  <Menu.Item>
                    <a
                      onClick={() =>
                        typeof record.id === 'number' && !!selectEmployee && selectEmployee(record.id, 'user-role')
                      }
                      className={twMerge(
                        'inline-flex items-center gap-2 px-5 py-4 text-base leading-none font-normal text-blue-800 hover:text-blue-800 hover:bg-blue-50 whitespace-nowrap',
                      )}
                    >
                      Change status
                    </a>
                  </Menu.Item>
                </>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </>
    </Menu>
  )

  const renderStatus = (record: Employee) => (
    <span
      className={twMerge(
        'rounded-full bg-gray-100 py-0.5 px-2.5 text-gray-800 text-xs leading-4 font-medium',
        record.status === UserStatus.Active && 'bg-green-100 text-green-800',
        record.status === UserStatus.Inactive && 'bg-red-100 text-red-800',
      )}
    >
      {capitalize(record.status || 'invitation sent')}
    </span>
  )

  const renderRole = (record: Employee) => (
    <span
      className={twMerge(
        'rounded-full bg-blue-100 py-0.5 px-2.5 text-blue-800 text-xs leading-4 font-medium',
        record.role === 'manager' && 'bg-purple-100 text-purple-800',
      )}
    >
      {capitalize(record.role)}
    </span>
  )

  return (
    <div>
      {isLoading && (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-50 opacity-75 z-10">
          <span className="absolute top-1/2 left-1/2 w-12 h-12 inline-flex items-center justify-center -mt-6 -ml-6">
            <svg
              aria-hidden="true"
              className="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-800"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </span>
        </div>
      )}

      <Button onClick={(): void => setIsAddModal(true)} variant="secondary" className="mb-4">
        <span className="inline-flex items-center gap-2">
          Add Employees <PlusIcon className="w-5" />
        </span>
      </Button>

      <div className="p-2 rounded-t bg-bg-accent-7 flex items-center justify-between gap-2 mb-2 md:px-4 md:py-3 lg:mb-0">
        <Input
          label="User name"
          onChange={(value) => setUserName(value || '')}
          value={userName}
          className="flex-1 lg:max-w-[520px]"
        />

        <Button variant="primary" onClick={() => setFilters({ name: userName })} size="large">
          Search
        </Button>
      </div>

      <div className="lg:hidden flex flex-col gap-2">
        {dataSource.map((employee) => (
          <div className="flex flex-col gap-2 border border-indigo-100 rounded p-2 even:bg-bg-light" key={employee.id}>
            <div className="flex gap-2">
              <div className="flex-1">
                <div className="text-xs leading-none font-normal text-gray-500">Name</div>
                <div
                  className={twMerge('text-base leading-6 font-normal text-gray-700', isInviting && 'text-gray-500')}
                >
                  {employee.name}
                </div>
              </div>
              <div>{(employee.isInvitation || !!selectEmployee) && renderActionsButton(employee)}</div>
            </div>
            <div className="flex gap-2">
              <div className="flex-1">
                <div className="text-xs leading-none font-normal text-gray-500">Property name</div>
                <div className={twMerge('text-base leading-6 font-normal text-gray-700')}>
                  {employee.hotels.length > 1 ? `${employee.hotels.length} hotels` : employee.hotels[0]?.name || '-'}
                </div>
              </div>
            </div>

            <div className="flex gap-2">
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">Role</div>
                <div className="text-base leading-6 font-normal text-gray-700">
                  {employee.role && renderRole(employee)}
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">Status</div>
                <div className="text-base leading-6 font-normal text-gray-700">{renderStatus(employee)}</div>
              </div>
            </div>

            <div className="flex gap-2">
              <div className="w-full">
                <div className="text-xs leading-none font-normal text-gray-500">Date Added</div>
                <div className="text-base leading-6 font-normal text-gray-700">
                  {moment(employee.created_at).format('DD.MM.YYYY')}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="hidden lg:block">
        <Table>
          <thead>
            <Table.Tr>
              <Table.Th>#</Table.Th>
              <Table.Th>User name</Table.Th>
              <Table.Th>Property name</Table.Th>
              <Table.Th>Role</Table.Th>
              <Table.Th>Status</Table.Th>
              <Table.Th>Date added</Table.Th>
              <Table.Th className="w-14"></Table.Th>
            </Table.Tr>
          </thead>
          <tbody>
            {dataSource.map((employee, index) => (
              <Table.Tr key={employee.id}>
                <Table.Td>{index + 1}</Table.Td>
                <Table.Td className={twMerge(isInviting && 'text-gray-500')}>{employee.name}</Table.Td>
                <Table.Td>
                  {employee.hotels.length > 1 ? `${employee.hotels.length} hotels` : employee.hotels[0]?.name}
                </Table.Td>
                <Table.Td>{renderRole(employee)}</Table.Td>
                <Table.Td>{renderStatus(employee)}</Table.Td>
                <Table.Td>{moment(employee.created_at).format('DD.MM.YYYY')}</Table.Td>
                <Table.Td className="text-center p-1">
                  {(employee.isInvitation || !!selectEmployee) && renderActionsButton(employee)}
                </Table.Td>
              </Table.Tr>
            ))}
          </tbody>
        </Table>
      </div>

      {dataSource.length === 0 && <div className="text-center text-base text-gray-500 p-5">Add employees first</div>}

      {isAddModal && (
        <AddModal
          onCancel={(): void => setIsAddModal(false)}
          managers={managers}
          isInviting={isInviting}
          invite={invite}
        />
      )}
    </div>
  )
}

export default Employees
