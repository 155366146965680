import { QueryKey, useMutation, UseMutationOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'

export type AddHotelsImportRequestBody = {
  gds_id: number
  file: File
}

const addHotelsImport = (body: AddHotelsImportRequestBody): Promise<void> => {
  const formData = new FormData()
  formData.append('gds_id', String(body.gds_id))
  formData.append('file', body.file)

  return api.post(`/hotels-imports`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const useAddHotelsImport = (
  queryOptions?: UseMutationOptions<Awaited<ReturnType<typeof addHotelsImport>>, ApiError, AddHotelsImportRequestBody>,
  customQueryKey?: QueryKey,
) => useMutation(customQueryKey || 'add-hotels-import', (body) => addHotelsImport(body), queryOptions)
