import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ArrowRightIcon } from '@heroicons/react/16/solid'

import { Button, Input } from 'ui-v2'

interface Props {
  isSubmitting: boolean
  onSubmit: (values: { [key: string]: string }) => void
  apiErrors?: { [key: string]: string }
}

const ResetPassword: React.FC<Props> = ({ onSubmit, isSubmitting, apiErrors }) => {
  const { values, errors, handleSubmit, setFieldValue, setErrors } = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema: yup.object({
      password: yup.string().min(8, 'Password should has at least 8 chars').required('Password is required'),
      password_confirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Password confirmation is required'),
    }),
    validateOnChange: false,
    onSubmit: (values) => onSubmit(values),
  })

  useEffect(() => {
    setErrors(apiErrors || {})
  }, [apiErrors])

  return (
    <div className="w-full">
      <div className="pb-4 mb-4 border-b border-secondary">
        <h1 className="text-3xl leading-9 font-normal text-gray-800 m-0 mb-2">Enter new password</h1>
      </div>

      <div className="flex flex-col items-center gap-4">
        <Input
          value={values.password}
          label="New password"
          htmlType="password"
          onChange={(value) => setFieldValue('password', value)}
          error={errors.password}
          className="w-full"
        />

        <Input
          value={values.password_confirmation}
          label="Repeat new password"
          htmlType="password"
          onChange={(value) => setFieldValue('password_confirmation', value)}
          error={errors.password_confirmation}
          className="w-full"
        />

        <Button onClick={() => handleSubmit()} isLoading={isSubmitting} className="mt-8 w-64" variant="primary">
          <span className="inline-flex items-center gap-2">
            Save <ArrowRightIcon className="w-3" />
          </span>
        </Button>
      </div>
    </div>
  )
}

export default ResetPassword
