import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { Tr } from './Tr'
import { Td } from './Td'
import { Th } from './Th'

export interface Sorter<T = unknown> {
  by: T
  direction: 'asc' | 'desc'
}

type TableProps = {
  children?: ReactNode
  className?: string
}

export const Table = ({ children, className }: TableProps) => {
  return <table className={twMerge('table-auto w-full', className)}>{children}</table>
}

Table.Tr = Tr
Table.Td = Td
Table.Th = Th
