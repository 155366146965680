import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowRightAlt, Close, RemoveRedEye } from '@material-ui/icons'
import { Typography } from 'antd'
import moment from 'moment'

import { getCxFromStyles } from 'helpers'
import { MainLayoutContext } from 'layouts/Main/MainContext'
import { useExportFile, useIsTablet } from 'hooks'
import useGdsSelectContext from 'components/GdsSelect/useGdsSelectContext'
import { Button, Rate } from 'ui'

import styles from './Header.module.scss'

interface Props {
  hotelId?: number
  name?: string
  propertyId?: string
  ratingAAA?: number
  ratingNTM?: number
}

const Header: React.FC<Props> = ({ name, ratingAAA, ratingNTM, hotelId, propertyId }) => {
  const cx = getCxFromStyles(styles)
  const history = useHistory()
  const { headers, label: gdsName } = useGdsSelectContext()
  const mainLayoutContext = useContext(MainLayoutContext)
  const isTablet = useIsTablet()
  const { isLoading, saveFile } = useExportFile()

  return (
    <div className={cx('root')}>
      <div className={cx('close')} onClick={(): void => history.push('/dashboard')}>
        <Close />
      </div>

      <div className={cx('titleWrapper')}>
        <Typography.Title level={2}>{name}</Typography.Title>
        <Typography.Text className={styles.propertySubtitle}>Property ID: {propertyId}</Typography.Text>
        <div className={cx('rates')}>
          <div className={cx('rateWrapper')}>
            <span className={cx('rateTitle')}>Hotel AAA Rating:</span>
            {typeof ratingAAA === 'number' ? (
              <Rate value={ratingAAA} disabled />
            ) : (
              <span className={cx('ratePlaceholder')}>missing</span>
            )}
          </div>
          <div className={cx('rateWrapper')}>
            <span className={cx('rateTitle')}>NTM Rating:</span>
            {typeof ratingNTM === 'number' ? (
              <Rate value={ratingNTM} disabled />
            ) : (
              <span className={cx('ratePlaceholder')}>missing</span>
            )}
          </div>
        </div>
      </div>

      <div className={cx('buttons')}>
        <Button large onClick={(): void => history.push(`${mainLayoutContext.linkPrefix}/hotel/details?hotel_id=${hotelId}`)}>
          <span>Check Rate Details</span> {!isTablet && <RemoveRedEye style={{ fontSize: 12 }} />}
        </Button>
        <Button
          large
          primary
          loading={isLoading}
          onClick={(): Promise<void> =>
            saveFile(
              '/print',
              `${name} - ${gdsName} - hotel card - ${moment().format('YYYYMMDD')}.pdf`,
              {
                type: 'hotel-card',
                hotel_id: hotelId,
              },
              headers,
            )
          }
        >
          <span>Export to PDF</span> <ArrowRightAlt fontSize="small" />
        </Button>
      </div>
    </div>
  )
}

export default Header
