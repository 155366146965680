import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type TdProps = {
  children?: ReactNode
  className?: string
  colSpan?: number
}
export const Td = ({ children, className, colSpan }: TdProps) => {
  return (
    <td
      className={twMerge(
        '!table-cell max-w-[280px] align-baseline px-6 py-2 text-left text-sm leading-5 font-medium text-gray-900 border border-gray-100 border-t-transparent first:border-l-transparent last:border-r-transparent overflow-ellipsis',
        className,
      )}
      colSpan={colSpan}
    >
      {children}
    </td>
  )
}
