import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Checkbox, Pagination, Spin } from 'antd'
import uniqBy from 'lodash/uniqBy'
import { Add, Remove, RemoveRedEye } from '@material-ui/icons'
import { PlusIcon } from '@heroicons/react/16/solid'
import { MinusIcon } from '@heroicons/react/24/solid'
import { LoadingOutlined } from '@ant-design/icons'

import { getCxFromStyles } from 'helpers'
import { useDimensions } from 'hooks'
import { HotelListItem } from 'types/domain/HotelListItem'
import { Meta } from 'api'
import { List } from 'modules/UsersModule/components/Hotels/components'

import { Button } from 'ui-v2'

import styles from './Hotels.module.scss'
import Params from './components/Params'

type Props = {
  hotels: HotelListItem[]
  assignedHotels: HotelListItem[]
  setAssignedHotels: (hotels: HotelListItem[]) => void
  meta: Meta
  setPage: (page: number) => void
  isLoading: boolean
  setFilters: (filters: { [key: string]: string }) => void
  errors: { [key: string]: string }
}

const Hotels: React.FC<Props> = ({
  hotels,
  meta,
  setPage,
  isLoading,
  setFilters,
  assignedHotels,
  setAssignedHotels,
  errors,
}) => {
  const cx = getCxFromStyles(styles)
  const { width } = useDimensions()
  const history = useHistory()
  const [checkedHotels, setCheckedHotels] = useState<number[]>([])

  const toggleCheckedHotel = (id: number): void =>
    setCheckedHotels((old) => (old.some((item) => item === id) ? old.filter((item) => item !== id) : [...old, id]))

  const onCheckAllHotels = (): void => {
    setCheckedHotels(hotels.length === checkedHotels.length ? [] : hotels.map((item) => item.id))
  }

  const assignHotels = (ids?: number[]): void => {
    setAssignedHotels(
      uniqBy([...assignedHotels, ...hotels.filter((item) => (ids || checkedHotels).includes(item.id))], 'id'),
    )
    setCheckedHotels([])
  }

  const unassignHotels = (ids?: number[]): void => {
    setAssignedHotels(uniqBy([...assignedHotels.filter((item) => !(ids || checkedHotels).includes(item.id))], 'id'))
    setCheckedHotels([])
  }

  const list: HotelListItem[] = useMemo(
    () => [...assignedHotels, ...hotels.filter((hotel) => !assignedHotels.some((item) => item.id === hotel.id))],
    [hotels, assignedHotels],
  )

  const renderViewButton = (hotelId: number): JSX.Element => (
    <Button secondary onClick={(): void => history.push(`/hotel/details?hotel_id=${hotelId}`)}>
      <RemoveRedEye style={{ fontSize: 14 }} />
    </Button>
  )

  return (
    <Spin spinning={!!isLoading} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}>
      {width > 1024 ? (
        <>
          <Params onFiltersChange={setFilters} error={errors} />
          <List
            hotels={list}
            checkedHotels={checkedHotels}
            toggleAllHotel={onCheckAllHotels}
            toggleHotel={toggleCheckedHotel}
            unassignHotel={(id) => unassignHotels([id])}
            assignHotel={(id) => assignHotels([id])}
            userHotels={assignedHotels}
            isEditable
          />
        </>
      ) : (
        <div className={cx('tiles')}>
          <Params onFiltersChange={setFilters} error={errors} />

          {list.map((item) => (
            <div className={cx('tile')} key={item.id}>
              <Checkbox
                className={cx('checkbox')}
                checked={checkedHotels.includes(item.id)}
                onChange={(): void => toggleCheckedHotel(item.id)}
              />

              <div className="flex items-center justify-center ml-auto">
                {assignedHotels.some((hotel) => hotel.id === item.id) ? (
                  <>
                    {renderViewButton(item.id)}

                    <Button variant="danger" onClick={(): void => unassignHotels([item.id])}>
                      <Remove />
                    </Button>
                  </>
                ) : (
                  <Button secondary onClick={(): void => assignHotels([item.id])}>
                    <Add />
                  </Button>
                )}
              </div>

              <div className={cx('fieldWrapper', 'name')}>
                <div className={cx('title')}>Name</div>
                {item.name}
              </div>

              <div className={cx('fieldWrapper')}>
                <div className={cx('title')}>City</div>
                {item.city}
              </div>

              <div className={cx('fieldWrapper')}>
                <div className={cx('title')}>Country</div>
                {item.country}
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="flex items-center justify-center pt-5">
        <Pagination
          current={meta.current_page || 1}
          total={meta.total || 1}
          onChange={setPage}
          showSizeChanger={false}
          hideOnSinglePage
          pageSize={meta.per_page || 10}
        />
      </div>

      <div className="flex items-center gap-2 mt-4">
        <Button variant="primary" onClick={() => assignHotels()} isDisabled={checkedHotels.length === 0 || isLoading}>
          <span className="inline-flex items-center gap-2">
            Assign selected <PlusIcon className="w-5" />
          </span>
        </Button>

        <Button onClick={() => unassignHotels()} isDisabled={checkedHotels.length === 0 || isLoading}>
          <span className="inline-flex items-center gap-2">
            Unassign selected <MinusIcon className="w-5" />
          </span>
        </Button>
      </div>
    </Spin>
  )
}

export default Hotels
