import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import PageLoader from 'ui/PageLoader'
import { useLoginWithToken } from 'api/login'
import { loginDemo, setProfile } from '../../slices/auth'

const TokenLogin: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { hash } = useParams<{ hash: string }>()

  const { mutate: loginWithToken } = useLoginWithToken()

  useEffect(() => {
    if (hash) {
      loginWithToken(
        { hash },
        {
          onSuccess: ({
            data: {
              token,
              hotel: { id },
              gds_ids,
            },
          }) => {
            dispatch(loginDemo(token))
            dispatch(setProfile({ hotels: [{ id }], gds: gds_ids }))
            history.push(`/dashboard/hotels/${id}`)
          },
        },
      )
    }
  }, [dispatch, hash, history, loginWithToken])

  return <PageLoader />
}

export default TokenLogin
