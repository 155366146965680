import React from 'react'
import { PlusIcon } from '@heroicons/react/16/solid'
import { PencilIcon } from '@heroicons/react/24/solid'

import { Button, Table } from 'ui-v2'
import HotelSearchListItem from 'modules/HotelsModule/types/HotelSearchListItem'

interface ListProps {
  hotels: HotelSearchListItem[]
  chooseHotel: (hotel: HotelSearchListItem) => void
  isSabreAvailable: boolean
  isTravelPortAvailable: boolean
  noDataMessage?: string
}

const List = ({ hotels, isSabreAvailable, isTravelPortAvailable, noDataMessage, chooseHotel }: ListProps) => {
  const renderEmail = (hotel: HotelSearchListItem) => (
    <div className="flex items-center justify-between gap-2 min-w-20">
      <span>{hotel.custom_emails?.[0]?.email || '-'}</span>
      <Button
        variant={hotel.custom_emails?.[0]?.email ? 'secondary' : 'success'}
        className="p-0 w-[34px] flex items-center justify-center"
        onClick={() => chooseHotel(hotel)}
      >
        {!hotel.custom_emails?.[0]?.email ? <PlusIcon className="w-5" /> : <PencilIcon className="w-5" />}
      </Button>
    </div>
  )

  return (
    <div>
      <div className="flex flex-col gap-2 lg:hidden">
        {hotels.map((hotel) => (
          <div className="flex flex-col gap-2 border border-indigo-100 rounded p-2 even:bg-bg-light" key={hotel.id}>
            <div className="w-full">
              <div className="text-xs leading-none font-normal text-gray-500">Hotel name</div>
              <div className="text-base leading-6 font-normal text-gray-700">{hotel.name}</div>
            </div>

            <div className="flex gap-2">
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">Country</div>
                <div className="text-base leading-6 font-normal text-gray-700">{hotel.country}</div>
              </div>
              <div className="w-1/2">
                <div className="text-xs leading-none font-normal text-gray-500">City</div>
                <div className="text-base leading-6 font-normal text-gray-700">{hotel.city}</div>
              </div>
            </div>

            <div className="flex gap-2">
              {isSabreAvailable && (
                <div className="w-1/2">
                  <div className="text-xs leading-none font-normal text-gray-500">Sabre ID</div>
                  <div className="text-base leading-6 font-normal text-gray-700">
                    {hotel.property_ids.sabre_id || '-'}
                  </div>
                </div>
              )}
              {isTravelPortAvailable && (
                <div className="w-1/2">
                  <div className="text-xs leading-none font-normal text-gray-500">Travelport ID</div>
                  <div className="text-base leading-6 font-normal text-gray-700">
                    {hotel.property_ids.travelport_id || '-'}
                  </div>
                </div>
              )}
            </div>

            <div className="w-full">
              <div className="text-xs leading-none font-normal text-gray-500">Email</div>
              <div className="text-base leading-6 font-normal text-gray-700">{renderEmail(hotel)}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="hidden lg:block">
        <Table>
          <thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>City</Table.Th>
              <Table.Th>ID</Table.Th>
              <Table.Th className="w-14">Email</Table.Th>
            </Table.Tr>
          </thead>
          <tbody>
            {hotels.map((hotel) => (
              <Table.Tr key={hotel.id}>
                <Table.Td>{hotel.name}</Table.Td>
                <Table.Td>{hotel.city}</Table.Td>
                <Table.Td>
                  <div className="flex gap-2">
                    {isSabreAvailable && (
                      <div className="w-1/2">
                        <div className="text-base leading-6 font-normal text-gray-700">
                          {hotel.property_ids.sabre_id || '-'}
                        </div>
                        <div className="text-xs leading-none font-normal text-gray-500">Sabre</div>
                      </div>
                    )}
                    {isTravelPortAvailable && (
                      <div className="w-1/2">
                        <div className="text-base leading-6 font-normal text-gray-700">
                          {hotel.property_ids.travelport_id || '-'}
                        </div>
                        <div className="text-xs leading-none font-normal text-gray-500">Travelport</div>
                      </div>
                    )}
                  </div>
                </Table.Td>
                <Table.Td>{renderEmail(hotel)}</Table.Td>
              </Table.Tr>
            ))}
          </tbody>
        </Table>
      </div>

      {hotels.length === 0 && <div className="text-center text-base text-gray-500 p-5">{noDataMessage}</div>}
    </div>
  )
}

export default List
