import React from 'react'
import classNames from 'classnames'
import { ArrowRightAlt, Close } from '@material-ui/icons'

import { HotelListItem } from 'types/domain/HotelListItem'

import styles from './HotelSelectModal.module.scss'

type Props = {
  hotelList: HotelListItem[]
  selectedHotelId: Nullable<number>
  onSelectHotel: (hotel: HotelListItem) => void
  onClose: () => void
}

const HotelSelectModal = (props: Props) => {
  const { hotelList, onClose, onSelectHotel, selectedHotelId } = props

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.close} onClick={onClose}>
          <Close />
        </div>
      </div>
      <div className={styles.list}>
        {hotelList.map((hotel) => (
          <div
            key={hotel.id}
            className={classNames(styles.item, { [styles.active]: selectedHotelId === hotel.id })}
            onClick={() => onSelectHotel(hotel)}
          >
            {hotel.name}
            <ArrowRightAlt />
          </div>
        ))}
      </div>
    </div>
  )
}

export default HotelSelectModal
