import React from 'react'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import api from 'api'
import type { Error } from 'api'
import { notification } from 'helpers'
import InvitationSetPassword from './InvitationSetPassword'
import { login } from '../../slices/auth'

const InvitationSetPasswordContainer: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { hash } = useParams<{ hash?: string }>()

  const {
    mutate: onSubmit,
    isLoading,
    error,
  } = useMutation(
    async (data: {
      password: string
      password_confirmation: string
    }): Promise<{
      data: {
        token: string
      }
    }> => api.patch(`/trial-invitations/${hash}`, data),
    {
      onSuccess: ({ data: { token } }) => {
        notification.success({
          message: 'Password has been set',
        })
        dispatch(login(token))
        history.push('/')
      },
      onError: (error: Error) => {
        notification.error({ message: error.message })
      },
    },
  )

  return <InvitationSetPassword onSubmit={onSubmit} isSubmitting={isLoading} apiErrors={error?.errors} />
}

export default InvitationSetPasswordContainer
