import React from 'react'
import { Link } from 'react-router-dom'

import AccountDropdown from '../AccountDropdown'
import GdsSelect from '../GdsSelect'

import logo from 'assets/logo-small.svg'

type Props = {
  hideGdsSelect?: boolean
  hideProfileMenuOptions?: boolean
}

const TopBar: React.FC<Props> = ({ hideGdsSelect, hideProfileMenuOptions }) => {
  return (
    <div className="flex items-center justify-between px-4 h-[42px] shadow-md gap-6 z-10 md:px-7">
      <Link className="flex-shrink-0 md:hidden" to={'/'}>
        <img src={logo} alt="logo" className="h-[30px]" />
      </Link>
      <GdsSelect isHiddenSelect={hideGdsSelect} />
      <AccountDropdown isProfileOptionsHidden={hideProfileMenuOptions} />
    </div>
  )
}

export default TopBar
