import { QueryKey, useQuery, UseQueryOptions } from 'react-query'
import qs from 'qs'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { ApiResponseWithMeta } from 'api/types/ApiResponseWithMeta'
import { ApiPaginationQuery } from 'api/types/ApiPaginationQuery'
import { RequestHeadersWithGdsScope } from 'api/types/RequestHeadersWithGdsScope'
import { HotelSearchListResponseItem } from 'types/domain/HotelSearchListResponseItem'

import { HOTELS_ENDPOINT_KEY } from './constants'

type GetHotelSearchListResourceRequestQuery = ApiPaginationQuery & {
  name?: string
  country?: string
  email?: string
  city?: string
  property_id?: string
  page: number
  length: number
}

type GetHotelSearchListResourceResponse = ApiResponseWithMeta<Array<HotelSearchListResponseItem>, unknown>

export const getHotelSearchListResource = ({
  headers,
  query,
}: {
  headers?: RequestHeadersWithGdsScope
  query?: GetHotelSearchListResourceRequestQuery
}): Promise<GetHotelSearchListResourceResponse> =>
  api.get(`/hotels-search?${qs.stringify(query, { skipNulls: true })}`, { headers })

export const useGetHotelSearchListResource = ({
  headers,
  query,
  queryOptions,
  customQueryKey,
}: {
  headers?: RequestHeadersWithGdsScope
  query?: GetHotelSearchListResourceRequestQuery
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getHotelSearchListResource>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || [HOTELS_ENDPOINT_KEY, ...Object.values(query || {}), ...Object.values(headers || {})],
    () => getHotelSearchListResource({ query, headers }),
    queryOptions,
  )

export const useGetRecentHotelSearchListResource = ({
  queryOptions,
  customQueryKey,
}: {
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getHotelSearchListResource>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || ['hotels-search-recent'],
    (): Promise<GetHotelSearchListResourceResponse> => api.get('/hotels-search/recent'),
    queryOptions,
  )
