import React, { useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useFormik, FormikHelpers } from 'formik'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ArrowRightIcon, TrashIcon, XMarkIcon } from '@heroicons/react/16/solid'

import { useGetHotelResource } from 'api/hotels/getHotelResource'
import { buildUpdateHotelQueryKey, UpdateGdsHotelRequestBodyPart, useUpdateHotel } from 'api/hotels/updateHotel'
import { useGetGdsListResource } from 'api/gds/getGdsListResource'
import { notification } from 'helpers'
import { parseGdsListToObject } from 'helpers/gds'
import { getErrorMessage } from 'helpers/error'
import { Button, Header, Input } from 'ui-v2'

import validationSchema from './validationSchema'

const INITIAL_GDS_FIELDS_FORM = {
  id: undefined,
  gds_id: undefined,
  chain_code: '',
  property_id: '',
}

const INITIAL_FORM_DATA = {
  name: '',
  travel_port: INITIAL_GDS_FIELDS_FORM,
  sabre: INITIAL_GDS_FIELDS_FORM,
}

type GdsFieldsForm = {
  id: Nullable<number>
  gds_id?: number
  chain_code: string
  property_id: string
}

type FormValues = {
  name: string
  travel_port: GdsFieldsForm
  sabre: GdsFieldsForm
  email?: string
}

const PropertyForm = () => {
  const { hotelId } = useParams<{ hotelId: string }>()
  const { push: routerPush } = useHistory()

  const queryClientCache = useQueryClient()
  const gdsListResource = useGetGdsListResource()
  const hotelResource = useGetHotelResource({ params: { id: parseInt(hotelId, 10) } })
  const updateHotel = useUpdateHotel({ params: { id: parseInt(hotelId, 10) } })

  const getInitialFormValues = useCallback((): FormValues => {
    const hotelData = hotelResource.data?.data
    const gdsData = gdsListResource.data?.data

    if (gdsData && hotelData) {
      const gdsList = parseGdsListToObject(gdsData)
      const travelPortData = hotelData.gds_hotels.find((item) => item.gds_id === gdsList.travel_port.id)
      const sabreData = hotelData.gds_hotels.find((item) => item.gds_id === gdsList.sabre.id)

      return {
        name: hotelData.name,
        travel_port: travelPortData || { ...INITIAL_GDS_FIELDS_FORM, gds_id: gdsList.travel_port.id },
        sabre: sabreData || { ...INITIAL_GDS_FIELDS_FORM, gds_id: gdsList.sabre.id },
        email: hotelData.email,
      }
    }

    return INITIAL_FORM_DATA
  }, [gdsListResource.data?.data, hotelResource.data?.data])

  const backToList = useCallback(() => {
    routerPush(`/admin/properties`)
  }, [routerPush])

  const onSubmit = useCallback(
    async (data: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      formikHelpers.setSubmitting(true)

      const hotelData = hotelResource.data?.data ? hotelResource.data.data : {}

      try {
        const newHotelData = await updateHotel.mutateAsync({
          ...hotelData,
          name: data.name,
          email: data.email,
          gds_hotels: [data.travel_port, data.sabre].filter(
            (data: GdsFieldsForm): data is UpdateGdsHotelRequestBodyPart =>
              !!(data.gds_id && data.property_id && data.chain_code),
          ),
        })

        // TODO: move to separate folder for edit cache functions
        queryClientCache.setQueryData(
          buildUpdateHotelQueryKey({ params: { id: parseInt(hotelId, 10) } }),
          () => newHotelData,
        )

        notification.success({ message: 'Success.' })
        backToList()
      } catch (error) {
        notification.error({ message: getErrorMessage(error) })
        formikHelpers.setSubmitting(false)
      }
    },
    [hotelResource.data?.data, updateHotel, queryClientCache, hotelId, backToList],
  )

  const { values, errors, setFieldValue, isSubmitting, handleSubmit } = useFormik<FormValues>({
    initialValues: getInitialFormValues(),
    enableReinitialize: true,
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
  })

  useEffect(() => {
    const listener = (e: { code: string }) => e.code === 'Enter' && handleSubmit()
    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [handleSubmit])

  if (hotelResource.isLoading) {
    return (
      <div className="flex justify-center align-centerw-full mt-[75px]">
        <Spin spinning indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
      </div>
    )
  }

  return (
    <form>
      <div className="flex flex-col justify-between gap-4 bg-bg-light">
        <Header
          size="big"
          title={hotelResource.data?.data.name || ''}
          className="flex justify-between border-b border-indigo-100"
        >
          <Button
            variant="secondary"
            className="p-1 w-[34px] rounded flex items-center justify-center"
            onClick={backToList}
          >
            <XMarkIcon className="w-5" />
          </Button>
        </Header>
        <div className="grid grid-cols-3 gap-y-6 gap-x-4 p-2 lg:p-4 mx-[30px] bg-white rounded-lg">
          <div className="col-span-2 p-2 bg-bg-light rounded-md md:p-6 relative flex flex-col gap-2 lg:gap-4">
            <h2 className="text-lg leading-8 tracking-wider uppercase text-gray-800 font-semibold">General</h2>
            <div className="flex w-full gap-2 flex-row">
              <Input
                label="Name"
                value={values.name}
                onChange={(value) => setFieldValue('name', value)}
                error={errors?.name}
                className="flex-1"
              />
              <Input label="Internal ID" value={hotelResource.data?.data.id.toString() || ''} readOnly isDisabled />
            </div>
          </div>
          <div className="p-2 bg-bg-light rounded-md md:p-6 relative flex flex-col gap-2 lg:gap-4">
            <h2 className="text-lg leading-8 tracking-wider uppercase text-gray-800 font-semibold">Hotel email</h2>
            <Input
              label="Email"
              value={values.email || ''}
              error={errors?.email}
              onChange={(value) => setFieldValue('email', value)}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-y-6 gap-x-4 p-2 lg:p-4 mx-[30px] bg-white rounded-lg">
          <div className="p-2 bg-bg-light rounded-md md:p-6 relative flex flex-col gap-2 lg:gap-4">
            <h2 className="text-lg leading-8 tracking-wider uppercase text-gray-800 font-semibold">Sabre</h2>
            <div className="flex gap-2 flex-row">
              <Input
                value={values.sabre.property_id}
                error={errors?.sabre?.property_id}
                onChange={(value) => setFieldValue('sabre.property_id', value)}
                label="Property ID"
                className="flex-1"
              />
              <Input
                label="Chain code"
                value={values.sabre.chain_code}
                error={errors?.sabre?.chain_code}
                onChange={(value) => setFieldValue('sabre.chain_code', value)}
                className="flex-1"
              />
            </div>
          </div>
          <div className="p-2 bg-bg-light rounded-md md:p-6 relative flex flex-col gap-2 lg:gap-4">
            <h2 className="text-lg leading-8 tracking-wider uppercase text-gray-800 font-semibold">Travelport</h2>
            <div className="flex gap-2 flex-row">
              <Input
                value={values.travel_port.property_id}
                error={errors?.travel_port?.property_id}
                onChange={(value) => setFieldValue('travel_port.property_id', value)}
                label="Property ID"
                className="flex-1"
              />
              <Input
                label="Chain code"
                value={values.travel_port.chain_code}
                error={errors?.travel_port?.chain_code}
                onChange={(value) => setFieldValue('travel_port.chain_code', value)}
                className="flex-1"
              />
            </div>
          </div>
          <div className="col-span-2 ml-auto mt-2 flex items-center justify-end gap-2">
            <Button size="large" onClick={backToList}>
              <span className="flex items-center gap-2">
                Cancel <TrashIcon className="w-5" />
              </span>
            </Button>

            <Button size="large" variant="primary" onClick={handleSubmit} isLoading={isSubmitting}>
              <span className="flex items-center gap-2">
                Save <ArrowRightIcon className="w-5" />
              </span>
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default PropertyForm
