import { TABLET_BREAK_POINT } from 'constants/breakpoints'
import { useDimensions } from './index'


const useIsTablet = (): boolean => {
  const { width } = useDimensions()

  return width <= TABLET_BREAK_POINT
}

export default useIsTablet
