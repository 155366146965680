import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { LoadingOutlined } from '@ant-design/icons'

import styles from './ActionButton.module.scss'

// TODO:
// as link

export type Props = {
  Icon?: ReactNode
  type?: 'button' | 'submit' | 'reset'
  color?: 'white' | 'blue'
  disabled?: boolean
  onClick?: () => void
  size?: 'small' | 'normal'
  loading?: boolean
  classes?: {
    root?: string
    loader?: string
    loaderContainer?: string
    content?: string
  }
}

const ActionButton: React.FC<Props> = (props) => {
  const { Icon, type = 'button', children, color = 'blue', disabled, onClick, size = 'normal', loading, classes } = props
  const canHandleClick = !loading && !disabled

  return (
    <button
      type={type}
      onClick={canHandleClick && onClick ? onClick : undefined}
      className={classNames(styles.root, classes?.root, styles[color], styles[size], {
        [styles.disabled]: disabled,
        [styles.loading]: loading,
      })}
    >
      {loading && (
        <div className={classNames(styles.loaderContainer, classes?.loaderContainer)}>
          <LoadingOutlined className={classNames(styles.loader, classes?.loader)} spin />
        </div>
      )}
      <div className={classNames(styles.content, classes?.content)}>
        {children}
        {Icon}
      </div>
    </button>
  )
}

export default ActionButton
