import { Route } from 'routes/types/Route'

import HotelPage from './pages/HotelPage'
import { HotelCardPage, HotelListPage, HotelMatchPage, HotelEmailsPage } from './pages'
import BookingCodePreview from './pages/BookingCodePreview'

const routes: Route[] = [
  { path: '/admin/properties', component: HotelListPage, layout: 'admin' },
  { path: '/admin/gds-match', component: HotelMatchPage, layout: 'admin' },
  { path: '/hotel/:hotelId/card', component: HotelCardPage, layout: 'main' },
  { path: '/admin/users/:userId/hotel/:hotelId/card', component: HotelCardPage, layout: 'main' },
  { path: '/hotel/:tabKey?', component: HotelPage, layout: 'main' },
  { path: '/admin/users/:userId/hotel/:tabKey?', component: HotelPage, layout: 'main' },
  { path: '/booking-code-preview/:token/:tabKey?', component: BookingCodePreview, layout: 'preview' },
  { path: '/hotel-emails', component: HotelEmailsPage, layout: 'main' },
]

export default routes
