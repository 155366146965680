import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { UserPlusIcon } from '@heroicons/react/24/solid'

import { RootState } from 'store'
import { Button, Select, Input, PhoneInput, Checkbox } from 'ui-v2'
import { ACCOUNT_TYPE_KEYS, ACCOUNT_TYPE_LIST } from 'constants/accounts'

interface Props {
  isSubmitting: boolean
  onSubmit: (values: { [key: string]: unknown }) => void
  apiErrors?: { [key: string]: string }
  invitationEmail: string | null
  isInvitation: boolean
}

const Register: React.FC<Props> = ({ onSubmit, isSubmitting, apiErrors, isInvitation, invitationEmail }) => {
  const history = useHistory()
  const { isAuthenticated } = useSelector((state: RootState) => state.auth)

  const { values, errors, handleSubmit, setFieldValue, setErrors } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: isInvitation ? invitationEmail || '' : '',
      password: '',
      phone: '',
      account_type: '',
      business_name: '',
      acceptance1: false,
      acceptance2: false,
    },
    validationSchema: yup.object({
      first_name: yup.string().required('First name is required'),
      last_name: yup.string().required('Last name is required'),
      email: yup.string().email('Incorrect e-mail address').required('Email is required'),
      password: yup.string().required('Password is required'),
      phone: yup.string().required('Phone is required'),
      account_type: yup.string().required('Account type is required'),
      business_name: yup.string().required('Hotel / Travel agency name is required'),
      acceptance1: yup.bool().oneOf([true], 'Acceptance is required'),
      acceptance2: yup.bool().oneOf([true], 'Acceptance is required'),
    }),
    validateOnChange: false,
    onSubmit: (values) =>
      onSubmit({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password,
        password_confirmation: values.password,
        phone: values.phone,
        account_type: values.account_type,
        about: isInvitation
          ? undefined
          : {
              hotel: values.account_type === ACCOUNT_TYPE_KEYS.PROPERTY_OWNER ? values.business_name : '',
              travel_agency: values.account_type === ACCOUNT_TYPE_KEYS.TRAVEL_AGENT ? values.business_name : '',
            },
      }),
  })

  useEffect(() => {
    apiErrors && setErrors(apiErrors)
  }, [apiErrors])

  if (isAuthenticated) {
    history.replace('/')
  }

  return (
    <div className="w-full">
      <div className="pb-4 mb-4 border-b border-secondary">
        <h1 className="text-3xl leading-9 font-normal text-gray-800 m-0 mb-2">Create account</h1>
        <div className="text-sm leading-5 font-normal text-gray-600">
          Already have an account?
          <Link to="/login" className="ml-2 font-medium text-blue-800">
            Sign In
          </Link>
        </div>
      </div>

      <form>
        <div className="flex flex-col items-center gap-4">
          <Input
            value={values.email}
            label="Email"
            onChange={(value) => setFieldValue('email', value)}
            error={errors.email}
            className="w-full"
            isDisabled={isInvitation}
          />

          <Input
            value={values.password}
            htmlType="password"
            label="Password"
            onChange={(value) => setFieldValue('password', value)}
            error={errors.password}
            className="w-full"
          />

          <PhoneInput
            label="Phone number"
            value={values.phone}
            onChange={(value) => setFieldValue('phone', value)}
            error={errors.phone}
            className="w-full"
          />

          <Input
            value={values.first_name}
            label="First name"
            onChange={(value) => setFieldValue('first_name', value)}
            error={errors.first_name}
            className="w-full"
          />

          <Input
            value={values.last_name}
            label="Last name"
            onChange={(value) => setFieldValue('last_name', value)}
            error={errors.last_name}
            className="w-full"
          />

          <Select
            options={ACCOUNT_TYPE_LIST}
            label="Account type"
            value={values.account_type}
            error={errors.account_type}
            onChange={(value) => setFieldValue('account_type', value)}
            className="w-full"
          />

          <Input
            value={values.business_name}
            label="Hotel / Travel agency name"
            onChange={(value) => setFieldValue('business_name', value)}
            error={errors.business_name}
            className="w-full"
          />

          <label className="flex items-center justify-start gap-2 p-2">
            <Checkbox
              isChecked={values.acceptance1}
              onChange={(isChecked) => setFieldValue('acceptance1', isChecked)}
              isError={!!errors.acceptance1}
            />

            <div className="text-sm leading-none font-normal text-gray-500">
              <span>
                I declare that I have read the{' '}
                <a
                  href={`${import.meta.env.VITE_LANDING_URL}/terms`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-800"
                >
                  Terms
                </a>{' '}
                and the{' '}
                <a
                  href={`${import.meta.env.VITE_LANDING_URL}/privacy-policy`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-800"
                >
                  Privacy Policy
                </a>{' '}
                of the website and accept their provisions.
              </span>

              {errors.acceptance1 && <span className="text-validation-error text-xs block">{errors.acceptance1}</span>}
            </div>
          </label>

          <label className="flex items-center gap-2 p-2">
            <Checkbox
              isChecked={values.acceptance2}
              onChange={(isChecked) => setFieldValue('acceptance2', isChecked)}
              isError={!!errors.acceptance2}
            />

            <div className="text-sm leading-none font-normal text-gray-500">
              <span>
                I consent to the processing by TRAVEL MINDS Sp. z o. o. of my personal data for purposes related to the
                use of the website, including the contact form in agreement with and in accordance with the rules set
                out in the{' '}
                <a
                  href={`${import.meta.env.VITE_LANDING_URL}/privacy-policy`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-800"
                >
                  Privacy Policy
                </a>
              </span>

              {errors.acceptance2 && <span className="text-validation-error text-xs block">{errors.acceptance2}</span>}
            </div>
          </label>

          <Button
            variant="primary"
            isLoading={isSubmitting}
            onClick={(event) => {
              event.preventDefault()
              handleSubmit()
            }}
            className="mt-8 w-full max-w-[248px]"
            htmlType="submit"
          >
            <span className="inline-flex items-center gap-2">
              Create account
              <UserPlusIcon className="w-5" />
            </span>
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Register
