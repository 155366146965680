import React, { useCallback } from 'react'
import { useFormik, FormikProvider, FormikHelpers } from 'formik'
import { useHistory } from 'react-router-dom'

import ActionButtonGroup from 'ui/ActionButtonGroup'
import Input, { FormikInput } from 'ui/Input'
import { UpdateGdsHotelRequestBodyPart, UpdateHotelRequestBody } from 'api/hotels/updateHotel'
import { SABRE_ID, TRAVEL_PORT_ID } from 'constants/gds'
import { useAddHotel } from 'api/hotels/addHotel'
import { HeaderWithActions } from 'ui'
import { notification } from 'helpers'

import validationSchema from './validationSchema'
import styles from './PropertyForm.module.scss'

type GdsFieldsForm = {
  id: Nullable<number>
  gds_id?: number
  chain_code: string
  property_id: string
}

type FormValues = {
  name: string
  country: string
  city: string
  address: string
  travel_port: GdsFieldsForm
  sabre: GdsFieldsForm
}

const PropertyAddForm = () => {
  const { mutate } = useAddHotel({})
  const history = useHistory()

  const onSubmit = useCallback(
    async (data: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      formikHelpers.setSubmitting(true)

      mutate(
        {
          name: data.name,
          country: data.country,
          city: data.city,
          address: data.address,
          gds_hotels: [data.travel_port, data.sabre].filter(
            (data: GdsFieldsForm): data is UpdateGdsHotelRequestBodyPart =>
              !!(data.gds_id && data.property_id && data.chain_code),
          ),
        },
        {
          onSuccess: (data) => {
            notification.success({ message: 'Property added successfully' })
            history.push(`/admin/properties/${data?.data.id}`)
          },
          onError: (error) => {
            notification.error({ message: error.message })
          },
        },
      )

      formikHelpers.setSubmitting(false)
    },
    [history, mutate],
  )

  const formik = useFormik<FormValues>({
    initialValues: {
      name: '',
      country: '',
      city: '',
      address: '',
      travel_port: {
        id: null,
        gds_id: TRAVEL_PORT_ID,
        chain_code: '',
        property_id: '',
      },
      sabre: {
        id: null,
        gds_id: SABRE_ID,
        chain_code: '',
        property_id: '',
      },
    },
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
  })

  const onCancel = () => {
    history.push(`/admin/properties`)
  }

  return (
    <FormikProvider value={formik}>
      <div className={styles.root}>
        <div className={styles.content}>
          <div className={styles.header}>
            <HeaderWithActions title="New property" onClose={onCancel} />
          </div>
          <h2 className={styles.sectionHeader}>General</h2>
          <FormikInput displayPlaceholderAsLabel placeholder="Name" name="name" />
          <Input displayPlaceholderAsLabel placeholder="Internal ID" disabled />
          <FormikInput displayPlaceholderAsLabel placeholder="Email" name="email" />
          <FormikInput displayPlaceholderAsLabel placeholder="Country" name="country" />
          <FormikInput displayPlaceholderAsLabel placeholder="City" name="city" />
          <FormikInput displayPlaceholderAsLabel placeholder="Address" name="address" />
          <h2 className={styles.sectionHeader}>Travel Port</h2>
          <FormikInput displayPlaceholderAsLabel placeholder="Property ID" name="travel_port.property_id" />
          <FormikInput displayPlaceholderAsLabel placeholder="Brand" name="travel_port.chain_code" />
          <h2 className={styles.sectionHeader}>Sabre</h2>
          <FormikInput displayPlaceholderAsLabel placeholder="Property ID" name="sabre.property_id" />
          <FormikInput displayPlaceholderAsLabel placeholder="Brand" name="sabre.chain_code" />
          <ActionButtonGroup
            classes={{ root: styles.actions }}
            cancelButton={{ onClick: onCancel, disabled: formik.isSubmitting }}
            submitButton={{ onClick: formik.handleSubmit, loading: formik.isSubmitting }}
          />
        </div>
      </div>
    </FormikProvider>
  )
}

export default PropertyAddForm
