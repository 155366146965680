import React, { useMemo, useState } from 'react'
import { uniq, uniqBy } from 'lodash'
import isEmail from 'validator/lib/isEmail'
import { twMerge } from 'tailwind-merge'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { ArrowRightIcon, TrashIcon } from '@heroicons/react/16/solid'

import { Button, Modal, Select, Input } from 'ui-v2'

type Props = {
  onCancel: () => void
  managers: { id: number; name: string }[]
  invite: (data: { emails: string[]; invited_to?: number }) => void
  isInviting: boolean
}

const AddModal: React.FC<Props> = ({ onCancel, managers, invite, isInviting }) => {
  const [emails, setEmails] = useState<string[]>([])
  const [manager, setManager] = useState<{ id: number; name: string } | undefined>(managers[0])

  const inputValue = useMemo(() => emails.join(','), [emails])
  const emailList = useMemo(
    () => emails.map((email) => ({ email, isCorrect: isEmail(email.trim()) })).filter((item) => !!item.email.trim()),
    [emails],
  )
  const isError = useMemo(() => emailList.some((item) => !item.isCorrect), [emailList])

  const onInputChange = (value: string): void => setEmails(value.split(','))
  const removeEmail = (email: string): void =>
    setEmails(emailList.map((item) => item.email).filter((item) => item !== email))

  const onSubmit = (): void => {
    invite({ emails: uniq(emailList.map((item) => item.email)), invited_to: manager ? manager.id : undefined })
  }

  return (
    <Modal title="Add new employee" onClose={onCancel}>
      <div className="flex flex-col gap-8 mt-8">
        <Input
          label="Email or a list of emails separated by a coma"
          value={inputValue}
          onChange={(value): void => onInputChange(value || '')}
        />

        {managers.length > 0 && (
          <Select
            label="Manager"
            value={manager?.id.toString()}
            onChange={(value): void => setManager(managers.find((manager) => manager.id.toString() === value))}
            options={managers.map((manager) => ({ value: manager.id.toString(), label: manager.name }))}
            isDisabled={managers.length === 1}
          />
        )}

        <div className="flex flex-wrap gap-2">
          {uniqBy(emailList, 'email').map(({ email, isCorrect }) => (
            <span
              key={email}
              className={twMerge(
                'text-gray-800 bg-gray-100 inline-flex items-center gap-1 rounded-full text-sm leading-5 font-medium py-0.5 pl-2.5 pr-1',
                isCorrect ? 'bg-blue-100 text-blue-800' : 'bg-red-100 text-red-800',
              )}
            >
              {email}
              <XMarkIcon
                onClick={() => removeEmail(email)}
                className={twMerge('w-4 cursor-pointer', isCorrect ? 'text-cyan-400' : 'text-red-400')}
              />
            </span>
          ))}
        </div>

        <div className="flex items-center justify-end gap-2">
          <Button onClick={onCancel}>
            <span className="inline-flex items-center gap-2">
              Cancel <TrashIcon className="w-4" />
            </span>
          </Button>

          <Button
            isDisabled={emailList.length === 0 || isError}
            isLoading={isInviting}
            onClick={onSubmit}
            variant="primary"
          >
            <span className="inline-flex items-center gap-2">
              Add & Send registration link
              <ArrowRightIcon className="w-3" />
            </span>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AddModal
