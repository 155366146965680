import { QueryKey, useQuery, UseQueryOptions } from 'react-query'
import qs from 'qs'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { ApiResponseWithMeta } from 'api/types/ApiResponseWithMeta'
import { HotelListItem } from 'types/domain/HotelListItem'
import { ApiPaginationQuery } from 'api/types/ApiPaginationQuery'
import { RequestHeadersWithGdsScope } from 'api/types/RequestHeadersWithGdsScope'
import { ApiSortDirection } from 'api/types/ApiSortDirection'

import { HOTELS_ENDPOINT_KEY } from './constants'

type GetHotelListResourceRequestQuery = ApiPaginationQuery & {
  only_active?: boolean
  property_id?: string
  chain_code?: string
  user_id?: number
  query?: string
  name?: string
  city?: string
  country?: string
  travel_port_property_id?: number
  sabre_property_id?: number
  sort_direction?: ApiSortDirection
  sort_by?: 'updated_at' | 'created_at'
  pagination?: boolean
}

type GetHotelListResourceResponse = ApiResponseWithMeta<Array<HotelListItem>, unknown>

export const getHotelListResource = ({
  headers,
  query,
}: {
  headers?: RequestHeadersWithGdsScope
  query?: GetHotelListResourceRequestQuery
}): Promise<GetHotelListResourceResponse> => api.get(`/hotels?${qs.stringify(query, { skipNulls: true })}`, { headers })

export const useGetHotelListResource = ({
  headers,
  query,
  queryOptions,
  customQueryKey,
}: {
  headers?: RequestHeadersWithGdsScope
  query?: GetHotelListResourceRequestQuery
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getHotelListResource>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || [HOTELS_ENDPOINT_KEY, ...Object.values(query || {}), ...Object.values(headers || {})],
    () => getHotelListResource({ query, headers }),
    queryOptions,
  )
