import { useQuery, QueryKey, UseQueryOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { SubscriptionPlanListItem } from 'types/domain/SubscriptionPlanListItem'

import { PLANS_ENDPOINT_KEY } from './constants'

type GetPlanListResourceResponse = {
  data: Array<SubscriptionPlanListItem>
}

export const getPlanListResource = (): Promise<GetPlanListResourceResponse> => api.get('/plans')

export const useGetPlanListResource = ({
  queryOptions,
  customQueryKey,
}: {
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getPlanListResource>>, ApiError>
  customQueryKey?: QueryKey
} = {}) => useQuery(customQueryKey || [PLANS_ENDPOINT_KEY], getPlanListResource, queryOptions)
