import axios from 'axios'
import { Error as ApiError, ListQueryParams as ApiListQueryParams, Meta as ApiMeta } from './types'
import store from 'store'
import { logout } from 'modules/AuthModule/slices/auth'

const { VITE_API_BASE_URL, VITE_X_APP_TOKEN } = import.meta.env

if (!VITE_API_BASE_URL) {
  throw new Error('Specify VITE_API_BASE_URL in your .env file')
}

const api = axios.create({
  baseURL: VITE_API_BASE_URL,
  headers: {
    common: {
      Accept: 'application/json',
      ...(VITE_X_APP_TOKEN ? { 'X-App-Token': VITE_X_APP_TOKEN } : {}),
    },
    post: {
      'Content-Type': 'application/json',
    },
  },
})

api.interceptors.request.use((config) => {
  const headers: { [key: string]: string } = {}

  const authToken = localStorage.getItem('authToken')

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`
  }

  config.headers = {
    ...config.headers,
    ...headers,
  }

  return config
})

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const normalizedError: ApiError = {
      message: 'Something went wrong',
      errors: {},
    }

    if (error.response) {
      const { data, status, config } = error.response

      if (status === 401 && config.url !== '/logout') {
        store.dispatch(logout())
      }

      if (status === 402) {
        document.location.href = '/payment-required'
      }

      if (status === 412) {
        document.location.href = '/account-activation'
      }

      if (status === 426) {
        document.location.href = '/upgrade-required'
      }

      normalizedError.status = status

      if (data.message) {
        normalizedError.message = data.message
      }

      normalizedError.errors = Object.keys(data.errors || {}).reduce(
        (acc, fieldName) => ({ ...acc, [fieldName]: data.errors[fieldName][0] }),
        {},
      )
    }

    return Promise.reject(normalizedError)
  },
)

export type Error = ApiError
export type ListQueryParams = ApiListQueryParams
export type Meta = ApiMeta

export default api
