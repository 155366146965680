import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type TrProps = {
  children?: ReactNode
  className?: string
  onClick?: (item: any) => void
}
export const Tr = ({ children, className, onClick }: TrProps) => {
  return (
    <tr className={twMerge('even:bg-slate-50', onClick && 'cursor-pointer', className)} onClick={onClick}>
      {children}
    </tr>
  )
}
