import React, { useCallback, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import { Button, Input, Select } from 'ui-v2'
import { ApiError } from 'api/types/ApiError'
import { UserStatus } from 'modules/UsersModule/types'

export interface UserSearchParams {
  name?: string
  email?: string
  status?: string
}

interface ParamsProps {
  onFiltersChange: (values: UserSearchParams) => void
  error: ApiError | null
  isLoading?: boolean
}

const statuses = [
  { value: UserStatus.Active, label: 'Active' },
  { value: UserStatus.Inactive, label: 'Inactive' },
  { value: UserStatus.Unconfirmed, label: 'Not confirmed' },
]

const Params = ({ error, onFiltersChange, isLoading }: ParamsProps) => {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [status, setStatus] = useState<string>('')

  const handleSearch = useCallback(() => {
    onFiltersChange({ name, email, status })
  }, [onFiltersChange, name, email, status])

  useEffect(() => {
    const listener = (e: { code: string }) => e.code === 'Enter' && handleSearch()
    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [handleSearch])

  return (
    <>
      <div className="p-4 bg-bg-accent-7 flex flex-col gap-3 md:px-7 lg:flex-row lg:items-start">
        <div className="lg:hidden">
          <div className="flex items-start justify-between gap-2 mb-2">
            <Input
              className="w-28"
              label="Name"
              value={name}
              onChange={(value) => setName(value || '')}
              error={error?.errors?.name}
            />

            <Input
              className="flex-1"
              label="Email"
              value={email}
              onChange={(value) => setEmail(value || '')}
              error={error?.errors?.email}
            />

            <Select
              options={statuses}
              label="Select status"
              value={status.label}
              onChange={(value) => setStatus(value || '')}
              error={error?.errors?.status}
            />

            <Button
              isLoading={isLoading}
              className="w-10 rounded p-0 flex items-center justify-center"
              onClick={handleSearch}
              size="large"
              variant="primary"
            >
              <MagnifyingGlassIcon className="text-white w-5" />
            </Button>
          </div>
        </div>

        <Input
          className="hidden lg:block lg:w-40"
          label="Name"
          value={name}
          onChange={(value) => setName(value || '')}
          error={error?.errors?.name}
        />

        <Input
          className="hidden lg:block lg:flex-1 lg:min-w-40"
          label="E-mail"
          value={email}
          onChange={(value) => setEmail(value || '')}
          error={error?.errors?.email}
        />

        <Select
          className="min-w-[160px]"
          options={statuses}
          label="Select status"
          value={status?.label}
          onChange={(value) => setStatus(value || '')}
          error={error?.errors?.status}
        />

        <Button
          className={twMerge('lg:block lg:w-auto')}
          onClick={handleSearch}
          isLoading={isLoading}
          variant="primary"
          size="large"
        >
          Search
        </Button>
      </div>
    </>
  )
}

export default Params
