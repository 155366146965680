import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dropdown, Button as AntButton } from 'antd'
import { ChevronDownIcon } from '@heroicons/react/16/solid'

import { getCxFromStyles, userInitials } from 'helpers'
import { logout } from 'modules/AuthModule/slices/auth'
import { UserRoles } from 'modules/UsersModule/types'
import { RootState } from 'store'
import { Button } from 'ui'
import useIsTravelAgent from 'hooks/useIsTravelAgent'

import styles from './AccountDropdown.module.scss'
import { getMenuDisabled } from '../../utils'

type Props = {
  isProfileOptionsHidden?: boolean
}

const AccountDropdown: React.FC<Props> = ({ isProfileOptionsHidden }) => {
  const cx = getCxFromStyles(styles)
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = history.location

  const profile = useSelector((state: RootState) => state.auth.profile)
  const initials = userInitials(profile?.first_name || '', profile?.last_name || '')

  const disabledNavigation = getMenuDisabled(pathname, profile)
  const paymentDisabledNavigation = pathname.includes('payment-required')

  const isTravelAgent = useIsTravelAgent()

  const onLogout = (): void => {
    dispatch(logout())
    history.push('/')
  }

  const overlay = (
    <div
      className={cx('dropdown', {
        isProfileOptionsHidden,
      })}
    >
      {!isProfileOptionsHidden && (
        <section className={cx('userInfo')}>
          <span className={cx('avatar')}>{initials}</span>
          <span className={cx('userName')}>
            <span className={cx('name')}>
              {profile?.first_name} {profile?.last_name}
            </span>
            <span className={cx('email')}>{profile?.email}</span>
          </span>
        </section>
      )}

      {!profile?.is_admin && !isProfileOptionsHidden && (
        <section>
          <Button block disabled={paymentDisabledNavigation} onClick={(): void => history.push('/profile/profile')}>
            Profile
          </Button>

          {profile?.role !== UserRoles.Employee && !isTravelAgent && (
            <Button
              block
              disabled={disabledNavigation}
              onClick={(): void => {
                !disabledNavigation && history.push('/profile/employees')
              }}
            >
              Employees
            </Button>
          )}

          {isTravelAgent && (
            <Button
              block
              disabled={disabledNavigation}
              onClick={(): void => {
                history.push('/profile/gds_credentials')
              }}
            >
              GDS Credentials
            </Button>
          )}

          {profile?.role !== UserRoles.TravelAgent && (
            <Button
              block
              disabled={disabledNavigation}
              onClick={(): void => {
                !disabledNavigation && history.push('/profile/hotels')
              }}
            >
              Hotels
            </Button>
          )}

          {(profile?.role === UserRoles.Owner || profile?.role === UserRoles.TravelAgent) && (
            <Button
              block
              disabled={disabledNavigation}
              onClick={(): void => {
                !disabledNavigation && history.push('/profile/billing')
              }}
            >
              Subscription & Billing
            </Button>
          )}
        </section>
      )}
      <section>
        <Button block onClick={onLogout}>
          Log out
        </Button>
      </section>
    </div>
  )

  return (
    <div className={cx('root')}>
      <Dropdown overlay={overlay} trigger={['click']}>
        <div>
          <AntButton className={cx('button')}>
            {!isProfileOptionsHidden && (
              <div>
                <div className="flex items-center justify-center w-7 h-7 mr-3 rounded bg-blue-50 text-gray-500 text-[13px]">
                  {initials}
                </div>
                <span>
                  {profile?.first_name} {profile?.last_name}
                </span>
              </div>
            )}
            <ChevronDownIcon className="w-4 h-4" />
          </AntButton>

          <AntButton className={cx('button-mobile')}>
            <div className="flex items-center justify-center w-7 h-7 rounded bg-blue-50 text-gray-500 text-[13px]">
              {initials}
            </div>
            <ChevronDownIcon className="w-4 h-4" />
          </AntButton>
        </div>
      </Dropdown>
    </div>
  )
}

export default AccountDropdown
