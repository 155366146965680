import { Route } from 'routes/types/Route'

import {
  LoginPage,
  RegisterPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  EmailVerificationPage,
  PaymentRequiredPage,
  UpgradeRequiredPage,
  TokenLoginPage,
  AccountActivationPage,
  InvitationSetPasswordPage,
} from './pages'

const routes: Route[] = [
  { path: '/login/:hash', component: TokenLoginPage },
  { path: '/login', component: LoginPage },
  { path: '/register/:hash?', component: RegisterPage },
  { path: '/email-verification/:hash?', component: EmailVerificationPage },
  { path: '/forgot-password', component: ForgotPasswordPage },
  { path: '/reset-password', component: ResetPasswordPage },
  { path: '/trial-invitations/:hash?', component: InvitationSetPasswordPage },
  { path: '/payment-required', component: PaymentRequiredPage, layout: 'main' },
  { path: '/upgrade-required', component: UpgradeRequiredPage, layout: 'main' },
  { path: '/account-activation', component: AccountActivationPage, layout: 'main' },
]

export default routes
