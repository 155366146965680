import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'
import User from 'modules/UsersModule/types/User'
import { clearTrialBarCloseFromLocalStorage } from 'components/TrialBar/utils'

interface AuthState {
  isAuthenticated: boolean
  isDemoUser?: boolean
  profile?: User
}

const initialState: AuthState = {
  isAuthenticated: !!localStorage.getItem('authToken'),
  isDemoUser: !!localStorage.getItem('isDemoUser'),
}

export const fetchProfile = createAsyncThunk('auth/profile', async () => {
  const { data } = await api.get('/profile')

  return data
})

export const logout = createAsyncThunk('auth/logout', async () => {
  await api.post('/logout')
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, { payload: token }): void {
      clearTrialBarCloseFromLocalStorage()
      localStorage.setItem('authToken', token)
      state.isAuthenticated = true
    },
    loginDemo(state, { payload: token }): void {
      clearTrialBarCloseFromLocalStorage()
      localStorage.setItem('authToken', token)
      localStorage.setItem('isDemoUser', 'true')
      state.isAuthenticated = true
      state.isDemoUser = true
    },
    setProfile(state, { payload: profile }): void {
      state.profile = profile
    },
  },
  extraReducers: {
    [fetchProfile.fulfilled.toString()]: (state, action): void => {
      state.profile = action.payload
    },
  },
})

export const { login, setProfile, loginDemo } = authSlice.actions

export default authSlice.reducer
