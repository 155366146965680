import React from 'react'
import { GlobeAsiaAustraliaIcon } from '@heroicons/react/24/solid'
import { twMerge } from 'tailwind-merge'

import { GoogleMap } from 'ui'
import amenities from 'modules/HotelsModule/amenities'
import { HotelDetails } from 'types/domain/HotelDetails'
import labelIcon from 'assets/label-important.svg'

type Props = {
  hotelDetails?: HotelDetails
}

const GeneralInfo = (props: Props) => {
  const { hotelDetails } = props

  return (
    <div className="flex flex-col-reverse p-4 bg-white rounded-lg lg:flex-row lg:items-start">
      <div className="p-4 flex flex-col gap-4 lg:w-1/2">
        <div className="text-lg leading-8 tracking-wider uppercase text-gray-800 font-semibold">Amenities</div>

        <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
          {hotelDetails?.amenities.filter(Boolean).map((amenity) => {
            const icon = amenities.find((item) => item.id === amenity.id)?.icon
            return (
              <div
                key={amenity.id}
                className="text-gray-800 text-base leading-6 font-normal inline-flex items-center gap-2"
              >
                <div className="w-4 mr-2 flex justify-center">{icon || <img className="w-4" src={labelIcon} />}</div>
                {amenity.value}
              </div>
            )
          })}
        </div>
      </div>

      <div className="bg-bg-light rounded-md p-4 flex flex-col gap-4 lg:w-1/2">
        <div className="text-lg leading-8 tracking-wider uppercase text-gray-800 font-semibold">Location</div>

        <div className="h-[320px]">
          <GoogleMap marker={{ latitude: hotelDetails?.latitude, longitude: hotelDetails?.longitude }} />
        </div>

        <div className="flex gap-4 items-center text-gray-700 text-base leading-none font-normal">
          <span className="inline-flex gap-2 items-center">
            <GlobeAsiaAustraliaIcon className="w-5 text-gray-500" /> Location
          </span>

          <span
            className={twMerge(
              'rounded bg-white p-2 border border-indigo-300 text-base leading-none font-normal text-gray-800',
              !hotelDetails?.location && 'text-validation-error bg-validation-error-bg border-validation-error-outline',
            )}
          >
            {hotelDetails?.location || 'Missing Location'}
          </span>
        </div>
      </div>
    </div>
  )
}

export default GeneralInfo
