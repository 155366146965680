import { useQuery, QueryKey, UseQueryOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { PaymentMethod } from 'types/domain/PaymentMethod'
import { PaymentHistoryListItem } from 'types/domain/PaymentHistoryListItem'
import { Subscription } from 'types/domain/Subscription'

import { SUBSCRIPTION_ENDPOINT_KEY } from './constants'

type GetSubscriptionUserResourceResponse = {
  data: {
    payment_method: PaymentMethod
    payments_history: PaymentHistoryListItem[]
    subscription: Subscription
    trial_ends_at: Nullable<string>
  }
}

type GetSubscriptionUserResourceRequestParams = {
  user_id: number
}

export const getSubscriptionUserResource = ({
  params,
}: {
  params: GetSubscriptionUserResourceRequestParams
}): Promise<GetSubscriptionUserResourceResponse> => api.get(`/subscription/${params.user_id}`)

export const useGetSubscriptionUserResource = ({
  params,
  queryOptions,
  customQueryKey,
}: {
  params: GetSubscriptionUserResourceRequestParams
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionUserResource>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(customQueryKey || [SUBSCRIPTION_ENDPOINT_KEY, ...Object.values(params)], () => getSubscriptionUserResource({ params }), queryOptions)
