// TODO: refactor
import React, { useState } from 'react'
import { Moment } from 'moment'
import { Typography } from 'antd'
import { Close } from '@material-ui/icons'

import { getCxFromStyles } from 'helpers'
import { RateDetails } from 'types/domain/RateDetails'

import { Section } from '../types'
import { RatesList, RatesSettingsForm } from './components'
import styles from './CompareRatesMobile.module.scss'

interface Props {
  onClose: () => void
  isLoading: boolean
  currentDate: Moment | null
  currentRate: RateDetails | null
  selectedDate?: Moment
  setSelectedDate: (date: Moment) => void
  rate?: RateDetails
  rates: RateDetails[]
  onRateChange: (value: string) => void
  sections: Array<Section>
}

const CompareRatesMobile: React.FC<Props> = ({
  onClose,
  isLoading,
  currentDate,
  currentRate,
  selectedDate,
  setSelectedDate,
  rate,
  rates,
  onRateChange,
  sections,
}) => {
  const cx = getCxFromStyles(styles)

  const [isEdit, setIsEdit] = useState(true)

  return (
    <div className={cx('root')}>
      <div className={cx('header')}>
        <Typography.Title level={2}>Compare Rate Details</Typography.Title>

        <div className={cx('close')} onClick={onClose}>
          <Close />
        </div>
      </div>

      {isEdit ? (
        <RatesSettingsForm
          compare={(): void => setIsEdit(false)}
          isLoading={isLoading}
          currentDate={currentDate}
          currentRate={currentRate}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          rate={rate}
          rates={rates}
          onRateChange={onRateChange}
        />
      ) : (
        <RatesList
          showSettings={(): void => setIsEdit(true)}
          rate={rate}
          currentRate={currentRate}
          selectedDate={selectedDate}
          currentDate={currentDate}
          sections={sections}
        />
      )}
    </div>
  )
}

export default CompareRatesMobile
