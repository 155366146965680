import React, { useCallback, useMemo, useState } from 'react'
import GdsSelectContext, { GdsSelectContextValue } from './GdsSelectContext'

const GdsSelectContextProvider: React.FC = (props) => {
  const [disabled, setDisabled] = useState(false)
  const [value, setValue] = useState<GdsSelectContextValue['value']>('')

  const handleSetValue = useCallback((val: string) => {
    setValue(val)
    localStorage.setItem('selected-gds', val)
  }, [])

  const contextValue = useMemo(
    () => ({
      value,
      disabled,
      setDisabled,
      setValue: handleSetValue,
    }),
    [value, disabled, handleSetValue],
  )

  return <GdsSelectContext.Provider value={contextValue}>{props.children}</GdsSelectContext.Provider>
}

export default GdsSelectContextProvider
