import { QueryKey, useMutation, UseMutationOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { Hotel } from 'types/domain/Hotel'

import { HOTELS_ENDPOINT_KEY } from './constants'

type UpdateHotelRequestParams = {
  id: number
}

export type UpdateGdsHotelRequestBodyPart = {
  id: Nullable<number>
  gds_id: number
  property_id: string
  chain_code: string
}

export type UpdateHotelRequestBody = {
  name?: string
  gds_hotels?: Array<UpdateGdsHotelRequestBodyPart>
  email?: string
}

type UpdateHotelResponse = {
  data: Hotel
}

export const updateHotel = ({
  params,
  body,
}: {
  params: UpdateHotelRequestParams
  body: UpdateHotelRequestBody
}): Promise<UpdateHotelResponse> => api.patch(`/hotels/${params.id}`, body)

export const buildUpdateHotelQueryKey = ({ params }: { params: UpdateHotelRequestParams }) => [
  HOTELS_ENDPOINT_KEY,
  ...Object.values(params),
]

export const useUpdateHotel = ({
  params,
  queryOptions,
  customQueryKey,
}: {
  params: UpdateHotelRequestParams
  queryOptions?: UseMutationOptions<Awaited<ReturnType<typeof updateHotel>>, ApiError, UpdateHotelRequestBody>
  customQueryKey?: QueryKey
}) =>
  useMutation(
    customQueryKey || buildUpdateHotelQueryKey({ params }),
    (body) => updateHotel({ params, body }),
    queryOptions,
  )
