import { useState } from 'react'
import moment, { Moment } from 'moment'

export const useDisableNavigation = () => {
  const [prevNavDisabled, setPrevNavDisabled] = useState<boolean>(false)
  const [nextNavDisabled, setNextNavDisabled] = useState<boolean>(false)

  const getEarliestAvailableDate = (availableDates: string[]) => {
    const momentAvailableDates = availableDates.map((d) => moment(d))

    return moment.min(momentAvailableDates)
  }

  const setNavDisabled = ({
    navDate,
    minDate,
    maxDate,
    availableDates,
  }: {
    navDate: Moment | null
    minDate?: Moment
    maxDate?: Moment
    availableDates: string[]
  }) => {
    const today = moment().format('YYYY-MM-DD')
    const earliestDate: Moment = getEarliestAvailableDate([
      ...availableDates,
      today,
      ...(minDate ? [minDate.format('YYYY-MM-DD')] : [moment(0).format('YYYY-MM-DD')]),
    ])

    setNextNavDisabled(!!(!!maxDate && navDate?.isSameOrAfter(maxDate)))
    setPrevNavDisabled(!!(!!earliestDate && navDate?.isSameOrBefore(earliestDate)))
  }

  return {
    prevNavDisabled,
    nextNavDisabled,
    setNavDisabled,
  }
}
