import React from 'react'
import { useMutation } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import api from 'api'
import type { Error } from 'api'
import { notification } from 'helpers'

import Register from './Register'

const RegisterContainer: React.FC = () => {
  const history = useHistory()
  const { hash } = useParams<{ hash: string }>()
  const urlQuery = new URLSearchParams(useLocation().search)
  const email = urlQuery.get('email')

  const {
    mutate: onSubmit,
    isLoading,
    error,
  } = useMutation(
    async (data: { [key: string]: unknown }): Promise<void> => api.post(`/register${hash ? `/${hash}` : ''}`, data),
    {
      onSuccess: () => {
        notification.success({
          message: 'Registration completed successfully',
          description: !hash ? 'Check your email for verification link.' : undefined,
          duration: 6,
        })
        history.push('/')
      },
      onError: (error: Error) => {
        notification.error({ message: error.message })
      },
    },
  )

  return (
    <Register
      onSubmit={onSubmit}
      isSubmitting={isLoading}
      apiErrors={error?.errors}
      invitationEmail={email}
      isInvitation={!!hash}
    />
  )
}

export default RegisterContainer
