import React, { ReactNode } from 'react'
import { Chart as ChartJS, ArcElement } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

import { getRateChartData, getRateChartPlugins } from 'helpers/rateChart'

export interface Tile {
  title: string
  icon: ReactNode
  percent?: number | null
}

export interface Props {
  title: ReactNode
  items: Tile[]
}

const AccuracySummary: React.FC<Props> = ({ items, title }) => {
  ChartJS.register(ArcElement)

  return (
    <div className="p-4 rounded-lg bg-white border border-indigo-100">
      <h2 className="text-2xl leading-loose tracking-tight font-medium text-gray-800 mb-2">{title}</h2>

      <div className="grid grid-cols-2 gap-2 md:grid-cols-3 xl:grid-cols-6 lg:gap-6">
        {items.map((item) => (
          <div className="px-2 py-4 rounded border border-indigo-50 flex flex-col items-center gap-2" key={item.title}>
            {typeof item.percent === 'number' ? (
              <div className="flex w-full items-center justify-center max-h-[125px]">
                <Doughnut data={getRateChartData(item.percent)} plugins={getRateChartPlugins(item.percent)} />
              </div>
            ) : (
              <div>-</div>
            )}

            <div className="flex self-center">
              <div className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-100">{item.icon}</div>

              <div
                className="flex flex-col ml-2 justify-center text-baseline text-sm leading-none font-normal text-gray-700"
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AccuracySummary
