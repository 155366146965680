import React, { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet'

import { Tabs } from 'ui-v2'
import { useGetPreviewByToken } from 'api/previews/getPreviewByToken'
import { useGetPreviewRules } from 'api/previews/getPreviewRules'
import useGdsSelectContext from 'components/GdsSelect/useGdsSelectContext'
import RateDetailsTab from 'modules/HotelsModule/pages/HotelPage/components/RateDetailsTab'
import RateRulesTab from 'modules/HotelsModule/pages/HotelPage/components/RateRulesTab'
import unavailableImage from 'assets/unavailable.svg'
import logo from 'assets/logo-dark.svg'

import styles from './BookingCodePreview.module.scss'

const RATE_DETAILS_TAB = {
  key: 'details',
  label: 'Rate Details',
}
const RATE_RULES_TAB = {
  key: 'rules',
  label: 'Rate Rules',
}
const TABS = [RATE_DETAILS_TAB, RATE_RULES_TAB]
const DEFAULT_TAB_KEY = RATE_DETAILS_TAB.key

const BookingCodePreview = () => {
  useGdsSelectContext({ disabled: true })
  const { token, tabKey: selectedTabKey } = useParams<{ token: string; tabKey: string }>()
  const { push: routerPush } = useHistory()
  const [isRulesFetchEnabled, setIsRulesFetchEnabled] = useState<boolean>(false)

  const { data, isFetching } = useGetPreviewByToken({
    params: { token },
    queryOptions: {
      onSuccess: (data) => {
        setIsRulesFetchEnabled(!data.data.rate_details)
      },
    },
  })
  const { data: rulesData, isFetching: isRulesFetching } = useGetPreviewRules({
    params: { token },
    queryOptions: {
      enabled: isRulesFetchEnabled,
    },
  })

  const onSelectTab = useCallback(
    (tabKey) => {
      routerPush(`/booking-code-preview/${token}/${tabKey}`)
    },
    [token, routerPush],
  )

  return (
    <div className={styles.root}>
      <Helmet>
        <title>Rate Cleaner | Accurate Room Rate Descriptions</title>
      </Helmet>

      <div className="relative z-10 h-11 w-full py-1.5 bg-white shadow-[0_3px_4px_0_rgba(21,25,60,0.08)]">
        <img className="w-[222px] h-8" src={logo} alt="logo" />
      </div>
      <Spin spinning={isFetching} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}>
        <div className={styles.headerContainer}>
          <span className={styles.hotelName}>{data?.data.name}</span>
          <div className={styles.bookingCode}>
            Booking Code: <span className="text-gray-800">{data?.data.rate.rate_plan}</span>
          </div>
          <div className={styles.gdsInfoContainer}>
            <span>
              GDS: <span className="text-gray-800">{data?.data.gds_name}</span>
            </span>
            <span>
              Chain code: <span className="text-gray-800">{data?.data.chain_code}</span>
            </span>
            <span>
              Property ID: <span className="text-gray-800">{data?.data.property_id}</span>
            </span>
          </div>
        </div>
        <div className={styles.container}>
          <div className="w-full">
            <Tabs items={TABS} onChange={onSelectTab} selectedKey={selectedTabKey || DEFAULT_TAB_KEY} />
            <div className="bg-white px-2 py-5 flex flex-col gap-5 lg:px-4">
              {(selectedTabKey ? selectedTabKey === 'details' : true) && data?.data && (
                <RateDetailsTab rateDetails={data?.data.rate} isLoading={false} checkup={data?.data.checkup} />
              )}
              {selectedTabKey === 'rules' &&
                !isRulesFetching &&
                (!rulesData && !data?.data.rate_details ? (
                  <div className="flex flex-col items-center gap-12 rounded-lg bg-white p-4 md:pt-12 md:pb-24">
                    <img src={unavailableImage} alt="image" />

                    <div className="text-center">
                      <h1 className="text-3xl leading-9 font-semibold text-gray-800">Unavailable</h1>
                      <div className="text-base leading-none font-normal text-gray-500">
                        Select different dates to load rate rules.
                      </div>
                    </div>
                  </div>
                ) : (
                  <RateRulesTab
                    rateDetails={data?.data.rate}
                    isLoading={isRulesFetching}
                    rateRules={data?.data.rate_details || rulesData?.data}
                    checkup={data?.data.checkup}
                  />
                ))}
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default BookingCodePreview
