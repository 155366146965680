import React from 'react'
import moment from 'moment'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'

import { PaymentHistoryListItem } from 'types/domain/PaymentHistoryListItem'
import { Table } from 'ui-v2'

type Props = {
  paymentsHistory: PaymentHistoryListItem[]
}

const PaymentHistoryList = (props: Props) => {
  const { paymentsHistory } = props

  return (
    <div>
      <div className="text-2xl leading-8 font-medium text-gray-800 mb-3">Payment history</div>

      <div>
        <div className="flex flex-col gap-2 lg:hidden">
          {paymentsHistory.map((payment) => (
            <div
              className="flex flex-col gap-2 border border-indigo-100 rounded p-2 even:bg-bg-light"
              key={payment.created_at}
            >
              <div className="flex gap-2">
                <div className="w-1/2">
                  <div className="text-xs leading-none font-normal text-gray-500">Date</div>
                  <div className="text-base leading-6 font-normal text-gray-700">
                    {moment(payment.created_at).format('YYYY-MM-DD')}
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="text-xs leading-none font-normal text-gray-500">Subscription plan</div>
                  <div className="text-base leading-6 font-normal text-gray-700">{payment.name}</div>
                </div>
              </div>

              <div className="flex items-center justify-between gap-2">
                <div>
                  <div className="text-xs leading-none font-normal text-gray-500">Amount</div>
                  <div className="text-base leading-6 font-normal text-gray-700">{payment.total}</div>
                </div>

                <a
                  href={payment.invoice_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-indigo-100 text-blue-800"
                >
                  <ArrowDownTrayIcon className="w-4" />
                </a>
              </div>
            </div>
          ))}
        </div>

        <div className="hidden lg:block">
          <Table>
            <thead>
              <Table.Tr>
                <Table.Th>Date</Table.Th>
                <Table.Th>Subscription plan</Table.Th>
                <Table.Th>Amount</Table.Th>
                <Table.Th className="w-14"></Table.Th>
              </Table.Tr>
            </thead>
            <tbody>
              {paymentsHistory.map((payment) => (
                <Table.Tr key={payment.created_at}>
                  <Table.Td>{moment(payment.created_at).format('YYYY-MM-DD')}</Table.Td>
                  <Table.Td>{payment.name}</Table.Td>
                  <Table.Td>{payment.total}</Table.Td>
                  <Table.Td>
                    <a
                      href={payment.invoice_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-indigo-100 text-blue-800"
                    >
                      <ArrowDownTrayIcon className="w-4" />
                    </a>
                  </Table.Td>
                </Table.Tr>
              ))}
            </tbody>
          </Table>
        </div>

        {paymentsHistory.length === 0 && <div className="text-center text-base text-gray-500 p-5">No history yet.</div>}
      </div>
    </div>
  )
}

export default PaymentHistoryList
