import React from 'react'
import GoogleMapReact from 'google-map-react'

import { getCxFromStyles } from 'helpers'

import styles from './GoogleMap.module.scss'

interface Props {
  marker?: {
    latitude?: string
    longitude?: string
  }
}

const GoogleMap: React.FC<Props> = ({ marker }) => {
  const cx = getCxFromStyles(styles)
  const lat = Number(marker?.latitude)
  const lng = Number(marker?.longitude)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderMarkers = (map: any, maps: any): void => {
    new maps.Marker({
      position: {
        lat,
        lng,
      },
      map,
      title: 'Hello World!',
    })
  }

  return (
    <>
      {lat && lng ? (
        <GoogleMapReact
          bootstrapURLKeys={{ key: import.meta.env.VITE_GOOGLE_MAP_KEY as string }}
          defaultCenter={{
            lat,
            lng,
          }}
          defaultZoom={11}
          onGoogleApiLoaded={({ map, maps }): void => renderMarkers(map, maps)}
        />
      ) : (
        <div className="w-full h-full flex items-center justify-center bg-[url('/src/assets/mapPlaceholder.svg')]">
          <span className="text-red-600 text-base h-10 p-2 bg-red-100 rounded shadow border border-red-300">
            Unknown Latitude/Longitude
          </span>
        </div>
      )}
    </>
  )
}

export default GoogleMap
