import { Route } from 'routes/types/Route'

import DemoListContainer from './pages/DemoList'
import Demo from './pages/Demo'
import { DEMO_LINKS_URL } from './const'

const routes: Route[] = [
  { path: `${DEMO_LINKS_URL}/add`, component: Demo, layout: 'admin' },
  { path: `${DEMO_LINKS_URL}/edit`, component: Demo, layout: 'admin' },
  { path: DEMO_LINKS_URL, component: DemoListContainer, layout: 'admin' },
]

export default routes
