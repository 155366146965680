import React from 'react'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'

import api from 'api'
import type { Error } from 'api'
import { notification } from 'helpers'
import ResetPassword from './ResetPassword'

const ResetPasswordContainer: React.FC = () => {
  const history = useHistory()
  const urlQuery = new URLSearchParams(useLocation().search)

  const token = urlQuery.get('token')
  const email = urlQuery.get('email')?.replaceAll(' ', '+')

  const {
    mutate: onSubmit,
    isLoading,
    error,
  } = useMutation(
    async (data: { [key: string]: string }): Promise<void> => api.post('/reset-password', { ...data, token, email }),
    {
      onSuccess: () => {
        notification.success({
          message: 'Password has been changed',
        })
        history.push('/')
      },
      onError: (error: Error) => {
        notification.error({ message: error.message })
      },
    },
  )

  return <ResetPassword onSubmit={onSubmit} isSubmitting={isLoading} apiErrors={error?.errors} />
}

export default ResetPasswordContainer
