import React, { useCallback, useMemo } from 'react'
import { Checkbox, Input, Typography } from 'antd'
import { Moment } from 'moment/moment'
import { ArrowRightIcon, TrashIcon } from '@heroicons/react/16/solid'

import useGdsSelectContext from 'components/GdsSelect/useGdsSelectContext'
import { useGetHotelAgentEmails } from 'api/hotels/getHotelAgentEmails'
import { useAddIssue } from 'api/issues/addIssue'
import { Form, Modal } from 'ui'
import { GDS_LIST } from 'constants/gds'
import { FormItem } from 'ui/Form'
import { Button } from 'ui-v2'
import { notification } from 'helpers'

import styles from './ReportIssueModal.module.scss'

type Props = {
  rateId: number
  gdsHotel?: {
    gds_id: number
    chain_code: string
    property_id: string
  }
  hotelId: number
  hotelName: string
  onClose: () => void
  bookingCode: string
  xlsDate: Moment
}

const ReportIssueModal = (props: Props) => {
  const { rateId, hotelName, gdsHotel, bookingCode, onClose, hotelId, xlsDate } = props

  const { headers } = useGdsSelectContext()

  const gdsName = useMemo(() => GDS_LIST.find((gds) => gds.id === gdsHotel?.gds_id)?.label, [gdsHotel])

  const { data: agentEmailsData } = useGetHotelAgentEmails(hotelId)
  const { mutate, isLoading: isIssueSending } = useAddIssue({
    headers,
    queryOptions: {
      onSuccess: () => {
        notification.success({ message: 'Issue has been reported' })
        onClose()
      },
      onError: () => notification.error({ message: 'An error occurred while submitting an issue' }),
    },
  })

  const handleSubmit = useCallback(
    ({ description, email, xls }) => {
      mutate({
        checkup_rate_id: rateId,
        description,
        email: email || undefined,
        xls: !!xls,
        xls_date: xlsDate.format('YYYY-MM-DD'),
      })
    },
    [mutate, rateId],
  )

  const initialValues = useMemo(
    () => ({
      description: '',
      email: agentEmailsData?.data[0]?.email || '',
    }),
    [agentEmailsData?.data[0]?.email],
  )

  if (!gdsHotel) {
    notification.error({ message: 'GDS hotel data error' })
    return <></>
  }

  return (
    <Modal
      visible
      onCancel={onClose}
      bodyStyle={{ padding: 32 }}
      closeButtonClassName={styles.closeButton}
      className={styles.reportIssueModal}
    >
      <div className={styles.modalContent}>
        <Typography.Title level={2}>Notify hotel</Typography.Title>
        <div className={styles.infoContainer}>
          <span className={styles.hotelName}>{hotelName}</span>
          <div className={styles.gdsInfoContainer}>
            <span className={styles.gds}>
              GDS: <b>{gdsName}</b>
            </span>
            <span className={styles.chainCode}>
              Chain code: <b>{gdsHotel.chain_code}</b>
            </span>
            <span className={styles.propertyId}>
              Property ID: <b>{gdsHotel.property_id}</b>
            </span>
          </div>
          <span className={styles.bookingCode}>
            Booking Code: <b>{bookingCode}</b>
          </span>
        </div>
        <Form onSubmit={handleSubmit} initialValues={initialValues} reinitialize>
          {({ form, errors }): JSX.Element => (
            <>
              <FormItem
                name="email"
                rules={[{ type: 'email', message: 'Incorrect e-mail address' }]}
                validateStatus={errors['email'] ? 'error' : ''}
                help={errors['email'] || ''}
              >
                <Input placeholder={`Hotel e-mail (optional)`} size="large" />
              </FormItem>
              <FormItem
                name="description"
                validateStatus={errors['description'] ? 'error' : ''}
                help={errors['description'] || ''}
              >
                <Input.TextArea placeholder="Issue description" size="middle" className={styles.textArea} />
              </FormItem>
              <FormItem name="xls" valuePropName="checked">
                <Checkbox>Attach XLS file with all Booking Codes</Checkbox>
              </FormItem>

              <div className="flex justify-end gap-2">
                <Button size="large" onClick={onClose}>
                  <span className="inline-flex gap-2">
                    Cancel <TrashIcon className="w-4" />
                  </span>
                </Button>

                <Button size="large" variant="primary" isLoading={isIssueSending} onClick={form.submit}>
                  <span className="inline-flex gap-2">
                    Save & Send <ArrowRightIcon className="w-4" />
                  </span>
                </Button>
              </div>
            </>
          )}
        </Form>
      </div>
    </Modal>
  )
}

export default ReportIssueModal
