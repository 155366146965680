import { QueryKey, useMutation, UseMutationOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { ReportedIssue as Issue } from 'types/domain/ReportedIssue'
import { RequestHeadersWithGdsScope } from '../types/RequestHeadersWithGdsScope'

import { ISSUES_ENDPOINT_KEY } from './constants'

export type AddIssueRequestBody = {
  checkup_rate_id: number
  description?: string
  email?: string
  xls?: boolean
  xls_date?: string
}

type UpdateIssueResponse = {
  data: Issue
}

export const addIssue = (
  body: AddIssueRequestBody,
  headers?: RequestHeadersWithGdsScope,
): Promise<UpdateIssueResponse> => api.post(`/${ISSUES_ENDPOINT_KEY}`, body, { headers })

export const useAddIssue = ({
  headers,
  queryOptions,
  customQueryKey,
}: {
  headers?: RequestHeadersWithGdsScope
  queryOptions?: UseMutationOptions<Awaited<ReturnType<typeof addIssue>>, ApiError, AddIssueRequestBody>
  customQueryKey?: QueryKey
}) =>
  useMutation(
    customQueryKey || [ISSUES_ENDPOINT_KEY, ...Object.values(headers || {})],
    (body) => addIssue(body, headers),
    queryOptions,
  )
