import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ArrowRightIcon, TrashIcon } from '@heroicons/react/16/solid'

import { useAddHotelAgentEmail } from 'api/hotels/addHotelAgentEmail'
import { Modal, Input, Button } from 'ui-v2'
import { notification } from 'helpers'
import HotelSearchListItem from 'modules/HotelsModule/types/HotelSearchListItem'

type Props = {
  hotel: HotelSearchListItem
  onClose: () => void
  onSuccess: () => void
  isSabreAvailable: boolean
  isTravelPortAvailable: boolean
}

const EmailModal = ({ hotel, onClose, onSuccess, isTravelPortAvailable, isSabreAvailable }: Props) => {
  const { values, setFieldValue, errors, handleSubmit } = useFormik({
    initialValues: {
      email: hotel.custom_emails?.[0]?.email || '',
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: yup.object({
      email: yup.string().email('Incorrect e-mail address'),
    }),
    onSubmit: ({ email }) => {
      saveEmail({
        email,
      })
    },
  })

  const { mutate: saveEmail, isLoading: isSaving } = useAddHotelAgentEmail({
    params: { hotelId: hotel.id },
    queryOptions: {
      onSuccess: () => {
        notification.success({ message: 'Email has been saved' })
        onSuccess()
        onClose()
      },
      onError: () => notification.error({ message: 'An error occurred while saving an email' }),
    },
  })

  return (
    <Modal onClose={onClose} title={hotel.custom_emails?.[0]?.email ? 'Change email' : 'Add email'}>
      <div>
        <div className="my-3">
          <span className="text-lg font-medium text-gray-700">{hotel.name}</span>
          <div className="text-gray-500 flex leading-none mt-1 mb-4">
            {isTravelPortAvailable && (
              <span>
                Travelport ID: <b>{hotel.property_ids.travelport_id || '-'}</b>
              </span>
            )}
            {isSabreAvailable && (
              <span className="inline-block ml-2 pl-2 border-l border-gray-500">
                Sabre ID: <b>{hotel.property_ids.sabre_id || '-'}</b>
              </span>
            )}
          </div>
        </div>

        <div className="inline-flex w-full items-center">
          <Input
            value={values.email}
            label="E-mail"
            onChange={(value) => setFieldValue('email', value)}
            error={errors.email}
            className="border-indigo-300 flex-grow"
          />
          <Button onClick={() => setFieldValue('email', null)} variant="secondary" className="p-[9px] ml-6">
            <TrashIcon className="w-4" />
          </Button>
        </div>

        <div className="mt-6 flex items-center justify-end gap-2">
          <Button size="large" onClick={() => onClose()}>
            <span className="flex items-center gap-2">
              Cancel <TrashIcon className="w-5" />
            </span>
          </Button>

          <Button size="large" variant="primary" onClick={() => handleSubmit()} isLoading={isSaving}>
            <span className="flex items-center gap-2">
              Save <ArrowRightIcon className="w-5" />
            </span>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default EmailModal
