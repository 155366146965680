import React from 'react'
import { FormInstance } from 'antd/lib/form'

import { getCxFromStyles } from 'helpers'
import { User, UserStatus } from 'modules/UsersModule/types'
import type { Error } from 'api'

import { Input, Radio } from 'antd'
import { Button } from 'ui'
import Form, { FormItem } from 'ui/Form'

import styles from '../../Account.module.scss'

type Props = {
  user?: User
  saveUserError: Error | null
  handleSubmit: (data: { [key: string]: string }) => void
  renderActions: (form: FormInstance) => JSX.Element
  edit: () => void
  isEdited: boolean
  showActivationModal: () => void
}

const statuses = [
  { value: UserStatus.Inactive, label: 'Inactive' },
  { value: UserStatus.Active, label: 'Active' },
]

const Status: React.FC<Props> = ({
  saveUserError,
  handleSubmit,
  renderActions,
  user,
  edit,
  isEdited,
  showActivationModal,
}) => {
  const cx = getCxFromStyles(styles)

  return user?.status === UserStatus.Unconfirmed ? (
    <Button className={cx('activateBtn')} onClick={showActivationModal}>
      Activate user account
    </Button>
  ) : (
    <section className={cx('status')}>
      <Form
        errors={saveUserError?.errors || {}}
        onSubmit={handleSubmit}
        initialValues={{
          fakeStatus: 'Status: ' + (statuses.find((item) => item.value === user?.status)?.label || user?.status || ''),
          status: user?.status || '',
        }}
        reinitialize
        className={cx('form')}
      >
        {({ form }): JSX.Element => (
          <>
            {isEdited ? (
              <>
                <FormItem name="status" className={cx('input', 'status')}>
                  <Radio.Group>
                    {statuses.map((status) => (
                      <Radio key={status.value} value={status.value}>
                        {status.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </FormItem>

                {isEdited && renderActions(form)}
              </>
            ) : (
              <FormItem name="fakeStatus" className={cx('input')} help="">
                <Input size="large" readOnly className={cx('readonly')} value="ddddd" />
              </FormItem>
            )}
          </>
        )}
      </Form>

      {!isEdited && (
        <Button className={cx('editButton')} onClick={edit}>
          Edit
        </Button>
      )}
    </section>
  )
}

export default Status
