import { QueryKey, useQuery, UseQueryOptions } from 'react-query'
import qs from 'qs'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { ApiResponseWithMeta } from 'api/types/ApiResponseWithMeta'
import { ApiPaginationQuery } from 'api/types/ApiPaginationQuery'
import { HotelsImportItemListItem } from 'types/domain/HotelsImportItemListItem'

import { HOTELS_IMPORTS_ENDPOINT_KEY } from './constants'

type GetHotelsImportResourceRequestParams = {
  hotel_import: number
}

export type GetHotelsImportResourceRequestQuery = ApiPaginationQuery & {
  query?: string
  chain_code?: string
  similarity_from?: number
  similarity_to?: number
}

type GetHotelsImportResourceResponse = ApiResponseWithMeta<Array<HotelsImportItemListItem>, unknown>

export const getHotelsImportResource = ({
  params,
  query,
}: {
  params: GetHotelsImportResourceRequestParams
  query?: GetHotelsImportResourceRequestQuery
}): Promise<GetHotelsImportResourceResponse> =>
  api.get(`/hotels-imports/${params.hotel_import}?${qs.stringify(query, { skipNulls: true })}`)

export const useGetHotelsImportResource = ({
  params,
  query,
  queryOptions,
  customQueryKey,
}: {
  params: GetHotelsImportResourceRequestParams
  query?: GetHotelsImportResourceRequestQuery
  queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getHotelsImportResource>>, ApiError>
  customQueryKey?: QueryKey
}) =>
  useQuery(
    customQueryKey || [HOTELS_IMPORTS_ENDPOINT_KEY, ...Object.values(params || {}), ...Object.values(query || {})],
    () => getHotelsImportResource({ params, query }),
    queryOptions,
  )
