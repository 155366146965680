import React, { useMemo, useState } from 'react'
import HotelSelectContext from './HotelSelectContext'

const HotelSelectContextProvider: React.FC = (props) => {
  const [isShownButton, setIsShownButton] = useState(false)
  const [isShownModal, setIsShownModal] = useState(false)

  const contextValue = useMemo(
    () => ({
      isShownButton,
      isShownModal,
      setIsShownModal,
      setIsShownButton,
    }),
    [isShownButton, isShownModal],
  )

  return <HotelSelectContext.Provider value={contextValue}>{props.children}</HotelSelectContext.Provider>
}

export default HotelSelectContextProvider
