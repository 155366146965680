import React from 'react'
import { useMutation } from 'react-query'

import { notification } from 'helpers'
import api from 'api'
import type { Error } from 'api'
import { Modal } from 'ui-v2'
import ContactUsForm from './ContactUsForm'

interface Props {
  close: () => void
}

const ContactUsModal: React.FC<Props> = ({ close }) => {
  const { mutate: sendMessage, isLoading } = useMutation(
    async (data: { topic: string; message: string }): Promise<void> => api.post('/feedback', data),
    {
      onSuccess: () => {
        close()
        notification.success({
          message: 'Message has been sent',
        })
      },
      onError: (error: Error) => notification.error({ message: error.message }),
    },
  )

  return (
    <Modal onClose={close} title="Contact us">
      <ContactUsForm onCancel={close} onSubmit={sendMessage} isSending={isLoading} />
    </Modal>
  )
}

export default ContactUsModal
