import React, { useState } from 'react'
import { Moment } from 'moment'

import { Button, DateRangePicker } from 'ui-v2'

interface ParamsProps {
  filters: {
    dateRange: [Moment | null, Moment | null]
  }
  onChange: (filters: ParamsProps['filters']) => void
  isLoading?: boolean
  minDate?: Moment
}

export const Params = ({ filters, onChange, isLoading, minDate }: ParamsProps) => {
  const [localFilters, setLocalFilters] = useState(filters)

  return (
    <div className="p-4 bg-bg-dark flex items-center justify-start gap-3 md:px-7 md:justify-end">
      <DateRangePicker
        className="w-full md:w-auto"
        labels={['From', 'To']}
        value={localFilters.dateRange}
        onChange={(dates) => {
          if (!dates) {
            setLocalFilters((prevState) => ({ ...prevState, dateRange: [null, null] }))
          }

          if (dates?.[0] && dates?.[1]) {
            setLocalFilters((prevState) => ({ ...prevState, dateRange: dates }))
          }
        }}
        minDate={minDate}
        isClearable
      />

      <Button onClick={() => onChange(localFilters)} isLoading={isLoading} variant="secondary" size="large">
        Filter
      </Button>
    </div>
  )
}
