import React from 'react'
import { AttachMoney, Close, Description, MeetingRoom, RemoveRedEye, RestaurantMenu } from '@material-ui/icons'
import { Popover, Pagination } from 'antd'
import { ArrowRightIcon } from '@heroicons/react/16/solid'

import { HotelSummary } from 'types/domain/HotelSummary'
import { rateStatusColor } from 'helpers'
import { Button } from 'ui-v2'
import { TablePagination } from 'ui/Table'
import { NO_GDS_DATA_AVAILABLE_MESSAGE } from '../consts'

interface Item {
  id: number | string
  key: number | string
  name?: string
  percent?: number | null
  request_date?: string
  booking_date?: string
  summary: Omit<HotelSummary, 'all'>
}

interface Props {
  items: Item[]
  handleSelect: (itemId: number | string) => void
}

const MEAL_PLANS = 'Meal Plans'

const TILES = [
  {
    name: 'Rate Description',
    icon: <Description fontSize="small" />,
    get: (summary: Item['summary']): number | null => summary.rate_description,
  },
  {
    name: 'Room Description',
    icon: <MeetingRoom fontSize="small" />,
    get: (summary: Item['summary']): number | null => summary.room_description,
  },
  {
    name: MEAL_PLANS,
    icon: <RestaurantMenu fontSize="small" />,
    get: (summary: Item['summary']): number | null => summary.meal_plans,
  },
  {
    name: 'Cancellation Policy',
    icon: <Close fontSize="small" />,
    get: (summary: Item['summary']): number | null => summary.cancellation_policy,
  },
  {
    name: 'Pricing',
    icon: <AttachMoney fontSize="small" />,
    get: (summary: Item['summary']): number | null => summary.pricing,
  },
]

const AccuracyTiles: React.FC<Props> = ({ items, handleSelect }) => {
  return (
    <div className="flex flex-col items-center gap-2">
      {items.map((item) => {
        const isEmpty = Object.values(item.summary).every((x) => x === null)

        return (
          <div
            className="w-full p-2 rounded"
            key={item.key}
            style={{
              backgroundColor: rateStatusColor(item.percent),
            }}
          >
            <div className="text-base leading-6 font-normal text-gray-800 flex items-center justify-between mb-2">
              {item.name}
              {(!!item.request_date || !!item.booking_date) && (
                <div className="flex items-center flex-wrap gap-1 sm:gap-10">
                  {!!item.request_date && (
                    <div>
                      <span className="block text-xs leading-none">Request Date</span>
                      <span>{item.request_date}</span>
                    </div>
                  )}

                  {!!item.booking_date && (
                    <div>
                      <span className="block text-xs leading-none">Booking Date</span>
                      <span>{item.booking_date}</span>
                    </div>
                  )}
                </div>
              )}
              {!isEmpty && (
                <Button
                  variant="secondary"
                  onClick={(): void => handleSelect(item.id)}
                  className="w-[34px] p-0 flex items-center justify-center border-none bg-indigo-100"
                >
                  <ArrowRightIcon className="w-4" />
                </Button>
              )}
            </div>

            <div className="flex items-center gap-1">
              {TILES.map((tile) => {
                if (isEmpty) {
                  if (tile.name === MEAL_PLANS)
                    return (
                      <div className="text-gray-500" key={tile.name}>
                        {NO_GDS_DATA_AVAILABLE_MESSAGE}
                      </div>
                    )
                  return <div key={tile.name} />
                }

                const percent = tile.get(item.summary)

                return (
                  <Popover content={tile.name} trigger="click" key={tile.name}>
                    <div className="w-full bg-white rounded-md border border-gray-100 p-2 flex flex-col items-center">
                      <div className="w-8 h-8 flex items-center justify-center bg-gray-100 rounded-full mb-2">
                        {tile.icon}
                      </div>
                      <div className="text-base leading-none font-normal text-gray-700">
                        {typeof percent === 'number' && `${Math.round(percent)}%`}
                      </div>
                    </div>
                  </Popover>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default AccuracyTiles
