import React from 'react'
import classNames from 'classnames'
import { notification as notificationAnt } from 'antd'
import { NotificationPlacement } from 'antd/es/notification'
import { ArgsProps as AntNotificationProps } from 'antd/lib/notification'
import { Close } from '@material-ui/icons'

import styles from 'theme/notification.module.scss'

import { AlertBarProps } from '../ui/AlertBar/AlertBar'

const DISPLAY_TIME = 5

const getDefaultProps = (
  type: AlertBarProps['color'],
): {
  placement: NotificationPlacement
  duration: number
  className: string
  closeIcon: React.ReactNode
} => {
  return {
    placement: 'top',
    duration: DISPLAY_TIME,
    className: classNames(styles.root, styles[type]),
    closeIcon: React.createElement(Close, { className: styles.closeBtn }),
  }
}

export const notification = {
  error: (props: AntNotificationProps) => notificationAnt.error({ ...getDefaultProps('error'), ...props }),
  warning: (props: AntNotificationProps) => notificationAnt.warning({ ...getDefaultProps('warning'), ...props }),
  success: (props: AntNotificationProps) => notificationAnt.success({ ...getDefaultProps('success'), ...props }),
}

export default notification
