import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'

type HotelEmail = {
  id: number
  hotel_id: number
  user_id: number
  email: string
  first_name?: string
  last_name?: string
}

type GetHotelAgentEmailsResponse = {
  data: HotelEmail[]
}

export const getHotelAgentEmails = (hotelId: number): Promise<GetHotelAgentEmailsResponse> =>
  api.get(`/hotels/${hotelId}/agent-emails`)

export const useGetHotelAgentEmails = (
  hotelId: number,
  options?: UseQueryOptions<Awaited<ReturnType<typeof getHotelAgentEmails>>, ApiError>,
  customQueryKey?: QueryKey,
) => useQuery(customQueryKey || ['hotel-agent-email', hotelId], () => getHotelAgentEmails(hotelId), options)
