type ErrorWithMessage = {
  message: string
}

type ErrorWithApiErrors = {
  errors: Record<string, string>
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  )
}

function isErrorWithApiErrors(error: unknown): error is ErrorWithApiErrors {
  return (
    typeof error === 'object' &&
    error !== null &&
    'errors' in error &&
    typeof (error as Record<string, unknown>).errors === 'object' &&
    Object.values((error as { errors: Record<string, unknown> }).errors).every(value => typeof value === 'string')
  )
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError

  try {
    return new Error(JSON.stringify(maybeError))
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError))
  }
}

function toErrorWithApiErrors(maybeError: unknown): ErrorWithApiErrors {
  return {
    errors: isErrorWithApiErrors(maybeError) ? maybeError.errors : {},
  }
}

function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message
}

function getApiError(error: unknown) {
  return toErrorWithApiErrors(error).errors
}

export { getErrorMessage, getApiError }
