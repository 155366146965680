import { Route } from 'routes/types/Route'

import { HotelSearchPage } from './pages'

const routes: Route[] = [
	{ path: '/search/hotel', component: HotelSearchPage, layout: 'main' },
	{ path: '/admin/search/hotel', component: HotelSearchPage, layout: 'admin' },
	{ path: '/admin/users/:userId/search/hotel', component: HotelSearchPage, layout: 'main' }
]

export default routes
