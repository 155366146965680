import React, { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentIntentOrSetupIntentResult } from '@stripe/stripe-js/types/stripe-js/stripe'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ArrowRightIcon } from '@heroicons/react/16/solid'

import api from 'api'
import { notification } from 'helpers'
import { Button } from 'ui-v2'
import { RootState } from 'store'
import { Billing } from 'types/domain/Billing'
import { PaymentFormPayload, PaymentFormType } from 'types/PaymentForm'
import { BillingContactFormFields } from 'components/PaymentForm'

type Props = {
  initialBillingFormValues?: Billing
}

const PaymentForm: React.FC<Props> = ({ initialBillingFormValues }) => {
  const history = useHistory()
  const stripe = useStripe()
  const elements = useElements()
  const profile = useSelector((state: RootState) => state.auth.profile)

  const [isProcessing, setIsProcessing] = useState(false)

  const { mutate: saveCC } = useMutation(
    async (data: { payment_method_id: PaymentFormPayload['payment_method_id'] }): Promise<void> =>
      api.post('/billing/payment-method', data),
  )

  const { mutate: saveBilling } = useMutation(
    async (data: PaymentFormPayload): Promise<void> => api.post('/billing', data.billing),
  )

  const formik = useFormik<PaymentFormType>({
    initialValues: {
      billing: initialBillingFormValues
        ? {
            ...initialBillingFormValues,
            country: { id: initialBillingFormValues.country },
            stateUs: { id: initialBillingFormValues.country === 'US' ? initialBillingFormValues?.state || '' : '' },
            state: initialBillingFormValues.country !== 'US' ? initialBillingFormValues?.state || '' : '',
            vat_id: initialBillingFormValues.vat_id || '',
          }
        : {
            address: '',
            suite: '',
            city: '',
            stateUs: { id: '' },
            state: '',
            company: '',
            country: { id: '' },
            post_code: '',
            vat_id: '',
          },
      payment_method_id: '',
    },
    enableReinitialize: true,
    onSubmit: async (data, formikHelpers) => {
      setIsProcessing(true)

      if (!stripe || !elements) {
        notification.error({ message: 'Stripe integration error' })
        setIsProcessing(false)
        return
      }

      const params: PaymentFormPayload = {
        billing: {
          ...data.billing,
          country: data.billing.country.id,
          state: data.billing.country.id === 'US' ? data.billing.stateUs.id : data.billing.state,
        },
        payment_method_id: data.payment_method_id,
      }

      saveBilling(params, {
        onSuccess: async (data, variables) => {
          try {
            const { error: submitError } = await elements.submit()

            if (submitError) {
              notification.error({ message: submitError.message })
              setIsProcessing(false)
            } else {
              const clientSecretResponse = await api.post('/billing/payment-method/client-secret')

              const confirmParams = {
                return_url: document.location.href,
                payment_method_data: {
                  billing_details: {
                    name: params.billing.company,
                    email: profile?.email,
                    phone: profile?.phone,
                    address: {
                      city: params.billing.city,
                      country: params.billing.country.toUpperCase(),
                      line1: params.billing.address,
                      line2: '',
                      postal_code: params.billing.post_code,
                      state: '',
                    },
                  },
                },
              }

              const { error, setupIntent, paymentIntent }: PaymentIntentOrSetupIntentResult =
                clientSecretResponse.data.type === 'setup_intent'
                  ? await stripe.confirmSetup({
                      clientSecret: clientSecretResponse.data.client_secret,
                      elements,
                      confirmParams,
                      redirect: 'if_required',
                    })
                  : await stripe.confirmPayment({
                      clientSecret: clientSecretResponse.data.client_secret,
                      elements,
                      confirmParams,
                      redirect: 'if_required',
                    })

              if (error) {
                notification.error({ message: error.message })
                setIsProcessing(false)
              } else {
                saveCC(
                  {
                    payment_method_id: (paymentIntent || setupIntent).payment_method as string,
                  },
                  {
                    onSuccess: async () => {
                      history.push('/')
                    },
                    onError: (error: any) => {
                      notification.error({ message: error.message })
                      setIsProcessing(false)
                    },
                  },
                )
              }
            }
          } catch (e) {
            setIsProcessing(false)
          }
        },
        onError: (error: any) => {
          notification.error({ message: error.message })
          setIsProcessing(false)

          formikHelpers.setErrors({ billing: error.errors })
        },
      })
    },
  })

  const isCountryUnitedStates = formik.values.billing.country.id === 'US'

  return (
    <div className="flex flex-col gap-8">
      <FormikProvider value={formik}>
        <PaymentElement
          options={{
            fields: {
              billingDetails: 'never',
            },
          }}
        />

        <BillingContactFormFields fieldNamePrefix="billing." isCountryUnitedStates={isCountryUnitedStates} />

        <div className="flex justify-end">
          <Button variant="primary" isLoading={isProcessing} onClick={formik.handleSubmit}>
            <span className="inline-flex items-center gap-2">
              Save <ArrowRightIcon className="w-3" />
            </span>
          </Button>
        </div>
      </FormikProvider>
    </div>
  )
}

export default PaymentForm
