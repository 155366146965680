import React, { ReactNode } from 'react'

import { getCxFromStyles } from '../../helpers'
import styles from './PageWrapper.module.scss'

type Props = {
  children: ReactNode
  className?: string
}

const PageWrapper = ({ children, className, ...props }: Props) => {
  const cx = getCxFromStyles(styles)

  return (
    <div className={cx('root', className)} {...props}>
      {children}
    </div>
  )
}

export default PageWrapper
