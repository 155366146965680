import { QueryKey, useMutation, UseMutationOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'

import { SUBSCRIPTION_ENDPOINT_KEY } from './constants'

type UpdateSubscriptionUserRequestParams = {
  user_id: number
}

export type UpdateSubscriptionUserRequestBody = {
  plan: string
}

export const updateSubscriptionUser = ({
  params,
  body,
}: {
  params: UpdateSubscriptionUserRequestParams
  body: UpdateSubscriptionUserRequestBody
}): Promise<null> => api.post(`/subscription/${params.user_id}/update`, body)

export const useUpdateSubscriptionUser = ({
  params,
  queryOptions,
  customQueryKey,
}: {
  params: UpdateSubscriptionUserRequestParams
  queryOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSubscriptionUser>>,
    ApiError,
    UpdateSubscriptionUserRequestBody
  >
  customQueryKey?: QueryKey
}) =>
  useMutation(
    customQueryKey || [SUBSCRIPTION_ENDPOINT_KEY, ...Object.values(params)],
    (body) => updateSubscriptionUser({ params, body }),
    queryOptions,
  )
