import React, { PropsWithChildren } from 'react'
import { ArrowRightAlt, Close } from '@material-ui/icons'
import classNames from 'classnames'

import ActionButton, { Props as ActionButtonProps } from 'ui/ActionButton/ActionButton'

import styles from './ActionGroupButton.module.scss'

type Props = {
  classes?: {
    root?: string
  }
  submitButton?: PropsWithChildren<ActionButtonProps>
  cancelButton?: PropsWithChildren<ActionButtonProps>
  deleteButton?: PropsWithChildren<ActionButtonProps>
}

const ActionButtonGroup = ({ classes, submitButton, cancelButton, deleteButton }: Props) => (
  <div className={classNames(styles.root, classes?.root)}>
    {deleteButton && (
      <ActionButton color="white" classes={{ root: styles.deleteBtn }} {...deleteButton}>
        {deleteButton?.children || 'Delete'}
      </ActionButton>
    )}
    {cancelButton && (
      <ActionButton color="white" Icon={<Close />} {...cancelButton}>
        {cancelButton?.children || 'Cancel'}
      </ActionButton>
    )}
    <ActionButton color="blue" Icon={<ArrowRightAlt />} {...submitButton}>
      {submitButton?.children || 'Submit'}{' '}
    </ActionButton>
  </div>
)

export default ActionButtonGroup
