import { useQuery } from 'react-query'

import api from 'api'
import type { Error } from 'api'
import { Plan as PlanType } from 'modules/UsersModule/types'
import { notification } from 'helpers'

type ReturnType = {
  data?: PlanType[]
  isLoading: boolean
}

const usePlansQuery = (): ReturnType => {
  const fetchPlans = (): Promise<{ data: PlanType[] }> => api.get('/plans')
  const { data, isLoading } = useQuery(['plans'], fetchPlans, {
    onError: (error: Error) => {
      notification.error({ message: error.message })
    },
  })

  return {
    data: data?.data,
    isLoading,
  }
}

export default usePlansQuery
